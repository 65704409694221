/*
to send an event, we push an object to the _mtm array that is saved in the global window.
The useTracker hook handles this.  It will return a function that accepts the data object.

const track = useTracker()

//example 1: logging an event
track({ 'event': <event type> }) 

//example 2: logging an event with additional data
track({  
    'event': <event type>,
    <variable 1>: <value of variable 1>,
    <variable 2>: <value of variable 2>,
    ...
})
*/

// configure in matomo tag manager 'triggers' tab => type = Custom Event
export const MTM_EVENT_TYPES = {
    'login': 'login',
    'forgot-password': 'forgot-password',
    'option-select': 'option-select',
    'filter-calendar': 'filter-calendar',
    'unfilter-calendar': 'unfilter-calendar',
    'create-event': 'create-event',
    'update-event': 'update-event',
    'delete-event': 'delete-event',
    'view-calendar-day': 'view-calendar-day',
    'browse-pagination': 'browse-pagination',
    'view-tempest-data': 'view-tempest-data',
    'file-download': 'file-download',
    'user-confirm': 'user-confirm',
    'input-measurement': 'input-measurement',  // is always a put, so create and update is consolidated into 'input'
    'delete-measurement': 'delete-measurement',
    'tableau-authenticated': 'tableau-authenticated',
    'delete-test': 'delete-test',
    'delete-analysis': 'delete-analysis',
    'view-help': 'view-help',
    'view-faq': 'view-faq'
}

// MTM variables are essentially keys for key-value data that can be added to an event
// configure in matomo tag manager 'variables' tab
export const MTM_VARIABLES = {
    'list-type': 'list-type',
    'list-value': 'list-value',
    'event-type': 'event-type', // calendar event type, not mtm event type
    'browse-action': 'browse-action',
    'browse-destination': 'browse-destination',
    'file-type': 'file-type'
}

// everything below is values for our mtm variables, no configuration needed in matomo
export const MTM_LIST_TYPES = {
    'pitch-select': 'pitch-select',
    'venue-select': 'venue-select',
    'compare-pitch-select': 'compare-pitch-select',
    'testCategoryGroup-select': 'testCategoryGroup-select',
    'testCategory-select': 'testCategory-select',
    'eventGroup-select': 'eventGroup-select',
    'eventType-select': 'eventType-select',
    'tab-select': 'tab-select',
    'date-select': 'date-select',
    'from-date-select': 'from-date-select',
    'to-date-select': 'to-date-select',
    'zoom-select': 'zoom-select',
    'result-type-select': 'result-type-select',
    'visualisation-select': 'visualisation-select',
    'graph-select': 'graph-select'
}

export const MTM_BROWSE_ACTIONS = {
    'increment': 'increment',
    'decrement': 'decrement',
    'jump': 'jump'
}

export const MTM_FILE_TYPES = {
    'pdf': 'pdf',
    'csv': 'csv',
    'png': 'png'
}