import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import PropTypes from 'prop-types'
import { getUnixTime } from 'date-fns'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import Selector from '../_control/OptionsSelector'
import NumberScroller from '../_control/NumberScroller'
import { css } from '@emotion/react'
import PitchContext from '../../context/Pitch'

export default function AerationForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [aerationType, setAerationType] = useState()
    const [heive, setHeive] = useState()
    const [spacing, setSpacing] = useState()
    //other
    const { data: aerationData, loading: isAerationBusy } = useBridge(`/api/current/frontend-bridge/aeration-types`)
    const { data: heiveData, loading: isHeiveBusy } = useBridge(`/api/current/frontend-bridge/aeration-heive`)
    const { data: spacingData, loading: isSpacingBusy } = useBridge(`/api/current/frontend-bridge/aeration-spacing`)
    const [pitch] = useContext(PitchContext)

    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/aeration-events/${eventId}`)
                setAerationType(data.aerationType)
                setHeive(data.aerationHeive)
                setSpacing(data.aerationSpacing)
                setTimeTaken(data.timeTaken / 60)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // when the required field are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))
        if (!aerationType || !heive || !spacing) return
        
        setIsLoading(true)

        const eventPayload = {
            aerationType: aerationType,
            aerationHeive: heive,
            aerationSpacing: spacing,
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: eventPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newEventPayLoad = {
                ...eventPayload,
                name: `Aeration event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newEventPayLoad })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pitch, aerationType, heive, spacing, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => {
        return isAerationBusy || isHeiveBusy || isSpacingBusy || isLoading
    }, [isAerationBusy, isHeiveBusy, isSpacingBusy, isLoading])

    return (<>
        {isBusy ? <FormLoader /> : <>
            <EventDateTimePicker
                unixDate={selectedDateTime}
                setUnixDate={setSelectedDateTime}
            />
            <NumberScroller
                label={formatMessage({ id: 'timeTaken' })}
                minVal={0}
                maxVal={24}
                state={timeTaken}
                unit={formatMessage({ id: 'hours' })}
                step={0.5}
                setState={setTimeTaken}
                css={css`width: min-content;`}
            />
            {aerationData &&
                <Selector
                    label={formatMessage({ id: 'aerationType' })}
                    optionsObject={aerationData}
                    state={aerationType}
                    setState={setAerationType}
                />
            }
            {spacingData &&
                <Selector
                    label={formatMessage({ id: 'spacing' })}
                    optionsObject={spacingData}
                    state={spacing}
                    setState={setSpacing}
                />
            }
            {heiveData &&
                <Selector
                    label={formatMessage({ id: 'heive' })}
                    optionsObject={heiveData}
                    state={heive}
                    setState={setHeive}
                />
            }
        </>}
        <div style={{ marginTop: '40px' }}>
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={isBusy}
            >
                <FormattedMessage id='save' />
            </button>
        </div>
    </>)
}

AerationForm.propTypes = {
    pitch: PropTypes.object,
    eventType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    day: PropTypes.object,
    onPostRequest: PropTypes.func,
}
