import React, { createContext, useState, useMemo, useCallback, useEffect, useContext } from 'react'
import { subMonths, getUnixTime } from 'date-fns'
import useRawApiState from '../hooks/useRawApiState'
import { useSearchParams } from 'react-router-dom'
import useBridge from '../hooks/useBridge'
import { useIntl } from 'react-intl'
import { fromZonedTime, toZonedTime } from 'date-fns-tz'
import { startOfDay, endOfDay } from 'date-fns'
import TimezoneContext from './Timezone'

const ReporterContext = createContext()
export default ReporterContext

const GRAPH_TYPES = {
    BAR_CHART: 'barChart',
    TIMELINE: 'timeline',
    GRID_LIST: 'gridList',
    EVENT_COUNT: 'eventCount',
}

export function ReporterProvider({ children, ...props }) {

    const { formatMessage } = useIntl()

    const [params, setSearchParams] = useSearchParams()

    const lineColor1 = '#A896D6'
    const lineColor2 = '#F1AA40'
    const [pitchId, setPitchId] = useState(Number(params.get('pitch')) || undefined)
    const [testCategory1, setTestCategory1] = useState() // id
    const [compareTo, setCompareTo] = useState((Number(params.get('comparePitch')) || undefined) && { pitch: Number(params.get('comparePitch')) || undefined })
    const [fromDate, setFromDate] = useState(subMonths(startOfDay(new Date()), 4))
    const [untilDate, setUntilDate] = useState(endOfDay(new Date()))
    const [eventTypeFilter, setEventTypeFilter] = useState()
    const [multiEventTypeFilter, setMultiEventTypeFilter] = useState([])
    const [graphType, setGraphType] = useState(GRAPH_TYPES.BAR_CHART)

    const { reporterTz } = useContext(TimezoneContext)

    const [fromDateFromZoned, untilDateFromZoned] = useMemo(() => {
        // the two dates converted to UTC, taking into account the 'reporter timezone'
        // dates for the backend, not for displaying
        return [fromZonedTime(fromDate, reporterTz), fromZonedTime(untilDate, reporterTz)]
    }, [fromDate, untilDate, reporterTz])

    const uiBools = useMemo(() => {
        return {
            showTestSelection: graphType !== GRAPH_TYPES.EVENT_COUNT,
            showEventFilter: graphType === GRAPH_TYPES.TIMELINE && !compareTo?.pitch,
            showEventMultiSelect: graphType === GRAPH_TYPES.GRID_LIST,
            showZoomToggle: graphType === GRAPH_TYPES.TIMELINE,
            showScoreToggle: graphType !== GRAPH_TYPES.EVENT_COUNT && !(graphType === GRAPH_TYPES.GRID_LIST),
            showModelToggle: graphType !== GRAPH_TYPES.EVENT_COUNT && !(graphType === GRAPH_TYPES.GRID_LIST),
            showCountTimeToggle: graphType === GRAPH_TYPES.EVENT_COUNT,
            showBarGraph: graphType === GRAPH_TYPES.BAR_CHART,
            showTimelineTests: graphType === GRAPH_TYPES.TIMELINE && !compareTo?.pitch,
            showTimelinePitches: graphType === GRAPH_TYPES.TIMELINE && compareTo?.pitch && pitchId,
            showEventCount: graphType === GRAPH_TYPES.EVENT_COUNT && pitchId,
            showGridList: graphType === GRAPH_TYPES.GRID_LIST,
            showStats: !(graphType === GRAPH_TYPES.GRID_LIST) && !(graphType === GRAPH_TYPES.EVENT_COUNT),
            disabledCompareCategory: graphType === GRAPH_TYPES.GRID_LIST,
            disabledComparePitch: graphType === GRAPH_TYPES.GRID_LIST,
        }
    }, [graphType, GRAPH_TYPES, pitchId, compareTo])

    useEffect(() => {
        if (uiBools.disabledCompareCategory) setCompareTo(undefined)
    }, [uiBools.disabledCompareCategory])

    const graphTypeOptions = useMemo(() => {
        return Object.values(GRAPH_TYPES).map((name) => ({
            value: name,
            label: formatMessage({ id: name })
        }))
    }, [GRAPH_TYPES])

    const timeAxis = useMemo(() => {
        return graphType === GRAPH_TYPES.BAR_CHART ? 'discrete-day'
            : graphType === GRAPH_TYPES.TIMELINE ? 'continuous'
                : undefined
    }, [graphType])

    useEffect(() => {
        if (!pitchId) return
        setSearchParams(old => {
            old.set('pitch', pitchId)
            return old
        })
    }, [pitchId])

    const setComparePitchId = useCallback((targetId) => {
        setCompareTo({ pitch: targetId })
        setSearchParams(old => {
            targetId ? old.set('comparePitch', targetId) : old.delete('comparePitch')
            return old
        })
    }, [setSearchParams])

    const setCompareCategoryId = useCallback((targetId) => {
        setCompareTo({ category: targetId })
        if (params.get('comparePitch') !== null) {
            setSearchParams(old => {
                old.delete('comparePitch')
                return old
            })
        }
    }, [setSearchParams, params])

    const [highlightDate, setHighlightDate] = useState() // date the 'cursor' is on
    const [zoomOut, setZoomOut] = useState(false)
    const [useResultsAsAxis, setUseResultsAsAxis] = useState(true)  // measurement or score
    const [showTimeTotals, setShowTimeTotals] = useState(false)

    const {
        data: tests1,
        isBusy: tests1Busy,
    } = useRawApiState(`/api/current/pitches/${pitchId}/history/${testCategory1}`, {
        from: getUnixTime(fromDateFromZoned),
        to: getUnixTime(untilDateFromZoned),
    }, [pitchId, testCategory1, fromDateFromZoned, untilDateFromZoned])

    // this 'zoned' data is prepared so browser will parse and display dates as local to the selected timezone.
    // graphs will not need to worry about timezone when consuming this data
    const tests1Zoned = useMemo(() => {
        return tests1?.map(test => zonedTimeClone(test, reporterTz))
    }, [tests1, reporterTz])

    const {  // comparing pitches or testcategories
        data: compareData,
        isBusy: compareBusy,
    } = useRawApiState((compareTo?.pitch || compareTo?.category) && `/api/current/pitches/${compareTo?.pitch === undefined ? pitchId : compareTo?.pitch}
        /history/${compareTo?.pitch === undefined ? compareTo?.category : testCategory1}`,
        {
            from: getUnixTime(fromDateFromZoned),
            to: getUnixTime(untilDateFromZoned),
        },
        [testCategory1, pitchId, compareTo, fromDateFromZoned, untilDateFromZoned]
    )

    const compareDataZoned = useMemo(() => {
        return compareData?.map(test => zonedTimeClone(test, reporterTz))
    }, [compareData, reporterTz])

    const {
        data: events,
        isBusy: eventsBusy
    } = useRawApiState(`/api/current/pitches/${pitchId}/events`, {
        from: getUnixTime(fromDateFromZoned),
        to: getUnixTime(untilDateFromZoned),
    }, [pitchId, fromDateFromZoned, untilDateFromZoned])

    const eventsZoned = useMemo(() => {
        return events?.map(event => zonedTimeClone(event, reporterTz))
    }, [events, reporterTz])

    const { // comparing events
        data: compareEvents,
        isBusy: compareEventsBusy
    } = useRawApiState(compareTo?.pitch && `/api/current/pitches/${compareTo?.pitch}/events`, {
        from: getUnixTime(fromDateFromZoned),
        to: getUnixTime(untilDateFromZoned),
    }, [compareTo, fromDateFromZoned, untilDateFromZoned])

    const compareEventsZoned = useMemo(() => {
        return compareEvents?.map(event => zonedTimeClone(event, reporterTz))
    }, [compareEvents, reporterTz])

    const { data: eventTypes = [], } = useBridge('/api/current/frontend-bridge/event-types')

    const eventTypeOptions = useMemo(() => {
        const filtered = eventTypes
            ? eventTypes.map(({ entityShortName, entityShortNameLocalised }) => ({
                value: entityShortName,
                label: entityShortNameLocalised
            }))
            : []
        filtered.sort((a, b) => a.label.localeCompare(b.label))
        return filtered
    }, [eventTypes])

    const reporterBusy = useMemo(() => {
        return tests1Busy || compareBusy || eventsBusy || compareEventsBusy
    }, [tests1Busy, compareBusy, eventsBusy, compareEventsBusy])

    const comparing = useMemo(() => {
        return compareTo?.category || compareTo?.pitch
    }, [compareTo])

    return (
        <ReporterContext.Provider
            value={{
                pitchId, setPitchId,
                testCategory1, setTestCategory1,
                compareTo, comparing,
                fromDate, setFromDate,
                untilDate, setUntilDate,
                eventTypeFilter, setEventTypeFilter,
                setComparePitchId,
                setCompareCategoryId,
                highlightDate, setHighlightDate,
                zoomOut, setZoomOut,
                showTimeTotals, setShowTimeTotals,
                useResultsAsAxis, setUseResultsAsAxis,
                tests1: tests1Zoned,
                compareData: comparing && compareDataZoned,
                events: eventsZoned,
                compareEvents: comparing && compareEventsZoned,
                eventTypeOptions,
                reporterBusy,
                lineColor1,
                lineColor2,
                timeAxis,
                graphType, setGraphType, graphTypeOptions,
                multiEventTypeFilter, setMultiEventTypeFilter,
                uiBools
            }}
            {...props}
        >
            {children}
        </ReporterContext.Provider>
    )
}

function zonedTimeClone(obj, timezone) {
    if (!obj || typeof obj !== 'object' || Array.isArray(obj)) return obj

    const newObj = structuredClone(obj)

    if (newObj.timestamp) newObj.timestamp = getUnixTime(
        toZonedTime(new Date(newObj.timestamp * 1000), timezone)
    )
    if (newObj.analysisDate) newObj.analysisDate = getUnixTime(
        toZonedTime(new Date(newObj.analysisDate * 1000), timezone)
    )
    return newObj
}