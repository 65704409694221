import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { createRoot } from 'react-dom/client'
import log from 'loglevel'
import EmotionGlobalStyles from './style/EmotionGlobalStyles'
import App from './_App'
import isIos from './utils/isIos'
import { AuthProvider } from './context/Auth'
import { ViewPortProvider } from './context/ViewPort'
import { BridgeProvider } from './context/FrontendBridge'
import { LocaleProvider } from './context/Locale'

// set loglevel
log.setLevel(process.env.LOG_LEVEL || 'info')

// initialize matomo web tracker
var _mtm = window._mtm = window._mtm || [];
_mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
(function () {
    var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
    g.async = true; g.src = `https://cdn.matomo.cloud/rawstadia.matomo.cloud/container_${process.env.MATOMO_CONTAINER_ID}.js`; s.parentNode.insertBefore(g, s);
})()

// add root container to body
const rootId = 'app-root'
const rootElem = window.document.createElement('div')

// fixes pesky 'vh problems'
window.document.body.style.setProperty('min-height', '100vh')
window.document.body.style.setProperty('min-height', '-webkit-available-fill')

let vh = document.documentElement.clientHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`)

window.addEventListener('resize', () => {
    vh = document.documentElement.clientHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    // prevent dynamic mobile browser bars from ruining our day
});

rootElem.id = rootId
window.document.body.appendChild(rootElem)
log.debug(`⚛️ injected root node '#${rootId}' into the DOM`)

// add ios class so we can do some iOS specific css hacks
if (isIos()) {
    window.document.body.classList.add('ios')
}

const root = createRoot(rootElem)

root.render(
    <React.Fragment>
        <EmotionGlobalStyles />
        <LocaleProvider>
            <AuthProvider>
                <BridgeProvider>
                    <ViewPortProvider>
                        <App />
                    </ViewPortProvider>
                </BridgeProvider>
            </AuthProvider>
        </LocaleProvider>
    </React.Fragment>
)

if (module.hot) {
    module.hot.accept()
}

log.debug(`⚛ REACT-DOM done bootstrapping app to '#${rootId}'`)
