import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import TestCategory from './TestCategory'
import Analysis from './Analysis'
import Advice from './Advice'
import ParameterResult from './ParameterResult'
import ZoneResult from './ZoneResult'
import Assessment from './Assessment'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            name: PropTypes.string,
            analysisDate: PropTypes.number, // unix timestamp
            commment: PropTypes.string,
            testCategory: TestCategory(newDepth),
            analysis: Analysis(newDepth),
            advices: PropTypes.arrayOf(
                Advice(newDepth)
            ),
            zoneResults: PropTypes.arrayOf(
                ZoneResult(newDepth)
            ),
            parameterResults: PropTypes.arrayOf(
                ParameterResult(newDepth)
            ),
            score: PropTypes.shape({
                assessment: Assessment(newDepth),
                avg: PropTypes.number,
                score: PropTypes.number,
            }),
            completed: PropTypes.bool,
        })
    }
    return () => null
}
