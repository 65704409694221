import React from 'react'
import { css, Global } from '@emotion/react'
import emotionReset from 'emotion-reset'
import * as fontFaces from './fontFaces'
import { colors, fonts } from './vars'

export default function GlobalStyles() {
    return (
        <Global
            styles={[
                emotionReset,
                css`
                    ${fontFaces.special}
                    ${fontFaces.title}
                    ${fontFaces.main}
                    html {
                        position: fixed;
                        touch-action: manipulation;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;
                    }
                    body {
                        width: 100%;
                        height: 100%;
                        font-family: ${fonts.main}, sans-serif;
                        -webkit-text-size-adjust: none; // to make iOS safari actually respect the font sizes we set
                    }
                    * {
                        box-sizing: border-box;
                        -webkit-backface-visibility: hidden; // iOS Safari fix to disable flickering
                    }
                    *::selection {
                        background-color: ${colors.main1};
                        color: ${colors.black};
                    }
                    a {
                        text-decoration: none;
                    }
                    i {
                        font-style: italic;
                    }
                    strong {
                        font-weight: 600;
                    }

                    #sleek-widget-wrap {
                        outline: 1px solid ${colors.sublimate};
                        border-radius: 2px;
                    }

                    // ios specific styling tweaks
                    input, select {
                        appearance: none;
                        border-radius: 0;
                        border: none;
                        outline: none;
                        box-shadow: none;
                    }
                    // false hope, iOS doens't care
                    .ios * {
                        touch-action: manipulation;
                    }
                `,
            ]}
        />
    )
}
