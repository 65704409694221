import React, { useState, useEffect, useRef, useCallback } from 'react'
import { css } from '@emotion/react'
import { pitchBgSrc } from '../_general/pitch/pitchBg'
import { colors, fonts } from '../../style/vars'
import { FormattedMessage } from 'react-intl'

export default function FieldSectionSelector({
    backgroundType,
    rows = 1,
    columns = 1,
    sections,
    onChange,
}) {

    const [sectionState, setSectionState] = useState(
        sections.length ? sections : new Array(rows).fill(new Array(columns).fill(false))
    )

    useEffect(() => {
        if (sections.length) setSectionState(sections)
    }, [sections])

    useEffect(() => {
        onChange(sectionState)
    }, [sectionState])

    const handleZoneToggle = useCallback((i, j) => {
        setSectionState(prevGrid => {
            const newGrid = [...prevGrid]
            newGrid[i] = [...prevGrid[i]]
            newGrid[i][j] = !newGrid[i][j]
            return newGrid
        })
    }, [sectionState])

    const handleToggleAll = useCallback(() => {
        if (!sectionState.length || !sectionState[0].length) return
        const hasFalse = sectionState.flat().includes(false)
        setSectionState(rs => rs.map(r => r.map(() => hasFalse)))
    }, [sectionState])

    return (<>
        <div css={css`width: 100%; display: grid; place-items: center;`}>
            <button onClick={handleToggleAll} css={style.toggle}>
                <FormattedMessage id='toggleAll' />
            </button>
        </div>
        <div css={style.field(backgroundType)}>
            {sectionState.map((r, i) => <div
                key={i}
                css={style.column(rows, columns)}
            >
                {r.map((s, j) => <div
                    key={i + "-" + j}
                    className={`section ${sectionState[i][j] ? "active" : ""}`}
                    onClick={() => handleZoneToggle(i, j)}
                />)}
            </div>)}
        </div >
    </>)
}

const style = {
    field: (backgroundType) => css`
        background-image: url(${pitchBgSrc(backgroundType)});
        background-color: #363E45;
        background-size: contain;
        background-repeat: no-repeat;
        width: 450px;
        height: 300px;
        box-shadow: 0px 0px 1px 2px ${colors.eventLight};
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 600px) {
            width: 225px;
            height: 150px;
        }
    `,
    column: (rows, columns) => css`
        display: flex;
        flex-direction: row;
        margin-bottom: -1px;
        .section {
            width: calc((450px + ${columns - 1}px) / ${columns});
            height: calc((300px + ${rows - 1}px)/ ${rows});

            @media screen and (max-width: 600px) {
                width: calc((225px + ${columns - 1}px) / ${columns});
                height: calc((150px + ${rows - 1}px)/ ${rows});
            }

            border: 2px solid ${colors.eventDark};
            z-index: 1;
            cursor: pointer;
            margin-left: -1px;

            &.active {
                border: 2px solid ${colors.main1};
                background: rgba(117,251,200,.3);
                z-index: 10;
                position: relative;
            }

            &:hover {
                border: 2px solid ${colors.main1};
                z-index: 10;
                position: relative;
            }

            &:first-of-type {
                margin-left: 0;
            }
        }
    `,
    toggle: css`
        background: ${colors.eventLight};
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        color: ${colors.soft};
        font-family: ${fonts.main};
        border: none;
        padding: 0.4em 0.8em;
        cursor: pointer;

        &:hover {
            color: ${colors.white};
            background: ${colors.eventDark};
        }

        &:active {
            color: ${colors.soft};
            background: ${colors.eventLight};
        }
    `
}