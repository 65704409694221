import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import { FormattedMessage } from 'react-intl'

export default function GenericErrorBoundary({ children, ...props }) {

    return (
        <ErrorBoundary fallback={
            <div css={css`display: grid; place-items: center; height: 100%; font-size: 1.5em;`} {...props}>
                <p css={css`text-align: center;`}>
                    <FormattedMessage id={'errorBoundaryMessage'} />
                    <br />
                    <a css={css`text-decoration: underline; color: ${colors.main1}`} href='/'>
                        <FormattedMessage id={'errorBoundaryRedirectMessage'} />
                    </a>
                </p>
            </div>
        }>
            {children}
        </ErrorBoundary>
    )
}
