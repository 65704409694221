import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import Loader from '../_general/Loader'
import nextArrow from '../../assets/icons/arrow_right.svg'
import { ReactSVG } from 'react-svg'
import useWeatherFlowState from '../../hooks/useWeatherFlowState'
import { get as getWeather } from '../../services/WeatherFlow'
import log from 'loglevel'
import { FormattedMessage } from 'react-intl'
import { fromZonedTime } from 'date-fns-tz'
import { format, getUnixTime, startOfDay, addDays, startOfMonth, endOfMonth } from 'date-fns'
import { backgrounds, colors, fonts } from '../../style/vars'
import DeviceObservations from '../main/DeviceObservations'
import { MTM_EVENT_TYPES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { css } from '@emotion/react'
import LocaleContext from '../../context/Locale'
import PitchContext from '../../context/Pitch'

/*
    !!! DEVELOPERS, BEWARE !!!

    At the moment of writing, it seems like the weatherflow API is still in development
    As a result, some of the necessary features are not part of the 'public API documentation'
    These features might be subject to change later, de-stabilizing this code, or introducing bugs
    When this happens, do NOT panic:

    A list of 'temporary' aspects:
    * parameter bucket:"e" --> Necessary for the API to return daily summaries of observations
    * type: "obs_st_ext" --> non-publicly documented format for observations
        --> see https://community.weatherflow.com/t/extra-api-fields-when-bucket-step-1440/7472

*/

function WeatherHistory({ stationId, ...props }) {

    const track = useTracker()

    const { datefnsLocale } = useContext(LocaleContext)
    const [pitch] = useContext(PitchContext)

    const [allObservations, setAllObservations] = useState([])
    const [fetchingData, setFetchingData] = useState(false)
    const [day, setDay] = useState(startOfDay(new Date())) // browser local day start

    // get station metadata
    const { data: station, isBusy: fetchingStation, error } = useWeatherFlowState(
        `/stations/${stationId}`,
        {},
        [stationId])

    const timeZoneWithFallback = useMemo(() => {
        return pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [pitch])

    //fromZonedTime, so we always fetch according to pitch's timezone
    const { time_start, time_end } = useMemo(() => {
        return {
            time_start: getUnixTime(
                fromZonedTime(startOfMonth(day), timeZoneWithFallback)
            ),
            time_end: getUnixTime(
                fromZonedTime(endOfMonth(day), timeZoneWithFallback)
            ),
        }
    }, [day, timeZoneWithFallback])

    const incrementDay = useCallback((dayOffset) => {
        setDay((day) => (addDays(day, dayOffset)))
    }, [])

    const devices = useMemo(() => (station?.stations?.[0]?.devices), [station])

    useEffect(() => log.debug("devices", devices), [devices])

    // The bread and butter of this view
    const fetchObservations = useCallback(async (devices, timeframe) => {

        // for now, clear all observations on fetch
        // Alternative would be stitching together, but that would require too much extra work for the current scope
        setFetchingData(true)
        setAllObservations([])

        try {
            let buffer = []

            // historical obeservations are stored in the devices
            for (const { device_id } of devices) {

                // retrieve device data using device id
                const { data, error } = await getWeather(
                    `/observations/device/${device_id}`,
                    {
                        ...timeframe,
                        bucket: "e"
                    },
                )

                // Only process data that exists (duh!)
                if (data) {
                    const { device_id, type, obs } = data
                    if (device_id && type && obs?.length) {
                        buffer = [
                            ...buffer,
                            data
                        ]
                    }
                }

            }

            track({
                'event': MTM_EVENT_TYPES['view-tempest-data']
            })

            setAllObservations(buffer)

        }
        catch (e) {
            log.error(e)
        }
        finally {
            setFetchingData(false)
        }
    }, [])

    const isBusy = useMemo(() => (fetchingStation || fetchingData), [fetchingStation, fetchingData])


    // trigger fetch conditions - this happens once too much on load
    useEffect(() => {
        if (devices?.length) {
            fetchObservations(devices, { time_start, time_end })
        }
    }, [devices, time_start, time_end])


    const filteredObservations = useMemo(() => {
        const dayFilter = format(day, `yyyy-MM-dd`) 
        // no timezone conversion is needed:
        //although their documentation says their date is Epoch (seconds, UTC), we only get a date key string: YYYY-MM-dd
        //documentation says these observations are 'station local midnight to midnight', 
        // so let's take them for their word. tz_name checks out.
        //https://apidocs.tempestwx.com/reference/observation-record-format#sky-observation
        return allObservations?.map(({ obs, ...other }) => ({
            obs: obs?.find(([timestamp, ...values]) => (timestamp === dayFilter)),
            ...other
        }))
    }, [allObservations, day, timeZoneWithFallback])

    return (isBusy ?
        <div css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                background: ${backgrounds.weatherGradient};
                border-radius: 0.5em;
                border: 2px solid ${colors.solid};
        `}>
            <span
                css={{
                    fontSize: '2em'
                }}
            >
                <FormattedMessage id='retrievingWeatherHistory' />
            </span>
            <Loader size="6em" />
        </div>
        : <>
            <div css={css`
                display: grid;
                grid-template-rows: 4em 1fr;
                background: ${backgrounds.weatherGradient};
                border-radius: 0.5em;
                border: 2px solid ${colors.solid};
            `}>
                <div css={css`width: 20em; display: flex; place-self: center;`}>
                    <ReactSVG
                        src={nextArrow}
                        css={{
                            transform: "scaleX(-1)", // mirror
                            width: "1.2em",
                            marginRight: "2em",
                            cursor: "pointer",
                        }}
                        onClick={() => incrementDay(-1)}
                    />
                    <div css={{
                        minWidth: "8em",
                        fontFamily: fonts.title,
                        fontSize: "1.5em",
                        textAlign: "center",
                    }}>
                        {format(day, 'PP', { locale: datefnsLocale })}
                    </div>
                    <ReactSVG
                        src={nextArrow}
                        css={{
                            width: "1.2em",
                            marginLeft: "2em",
                            cursor: "pointer",
                        }}
                        onClick={() => incrementDay(1)}
                    />
                </div>

                <div css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    flex-grow: 1;
                `}>
                    {
                        filteredObservations?.map(({ device_id, type, obs }, i) => (
                            <React.Fragment
                                key={i}>
                                <DeviceObservations
                                    device={devices?.find(({ device_id: id }) => (id == device_id))}
                                    obs_type={type}
                                    observations={obs}
                                />
                            </React.Fragment>
                        ))
                    }
                </div>
            </div>


        </>
    )
}

WeatherHistory.propTypes = {
    stationId: oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired
    ]),
}

export default WeatherHistory
