import React, { useEffect, useMemo, useCallback, useContext } from 'react'
import useRaw from '../../hooks/useRaw'
import { css } from '@emotion/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { backendColors, colors, fonts } from '../../style/vars'
import Table from '../_general/Table'
import ScoreIndicator from '../_general/ScoreIndicator'
import PageTitle from '../_general/PageTitle'
import BrowsePanel from '../_control/BrowsePanel'
import ViewPortContext from '../../context/ViewPort'
import { FormattedMessage, useIntl } from 'react-intl'
import AnalysisDate from '../analysis/AnalysisDate'

export default function ClubList() {
    const [searchParams, setSearchParams] = useSearchParams()
    const { showCompactUI } = useContext(ViewPortContext)

    const pageNum = useMemo(() => {
        return parseInt(searchParams.get('page')) || 1
    }, [searchParams])

    const handlePageChange = useCallback((num) => {
        setSearchParams({ page: num })
    }, [setSearchParams])

    const navigate = useNavigate()

    const apiEndPoint = useMemo(
        () => (`/api/current/clubs?page=${pageNum}&pagination=true`),
        [pageNum]
    )

    const [
        {
            'hydra:member': clubs = [],
            'hydra:view': {
                'hydra:first': firstPage,
                'hydra:last': lastPage } = {}
        } = {},
        { busy }
    ] = useRaw(
        apiEndPoint, [apiEndPoint]
    )

    // // if only 1 club is present, automatically drill down to the pitches of that club
    useEffect(() => {
        if (clubs && clubs.length === 1 && !lastPage) {
            const { id } = clubs[0]
            navigate(`/club/${id}`)
        }
    }, [clubs, navigate])

    const { formatMessage } = useIntl()

    return (
        <div css={style.wrapper}>
            <div css={css`position: relative;`}>
                <PageTitle>
                    <span css={{ color: colors.main1 }}>
                        <FormattedMessage id='clubs' />
                    </span>
                </PageTitle>
                <BrowsePanel
                    pageNum={pageNum}
                    setPageNum={handlePageChange}
                    firstPage={getNumberFromPageLink(firstPage)}
                    lastPage={getNumberFromPageLink(lastPage)}
                    busy={busy}
                    css={style.browsePanel}
                />
            </div>

            <Table
                loading={busy}
                fallback={formatMessage({ id: 'accountHasNoClubs' })}
                data={clubs}
                rowKey={club => (club['@id'])}
                getRowHref={(club) => `/club/${club.id}`}
                sortOn='name'
                style={{ flexGrow: 1 }}
                light
                columns={[
                    {
                        key: 'name',
                        header: formatMessage({ id: 'name' }),
                        sortable: true,
                        renderData: club => (
                            <>
                                {club.logo &&
                                    <img
                                        css={style.clubLogo}
                                        alt={club.name}
                                        src={club.logo.file}
                                    />
                                }
                                <h1 css={css`font-size: 2em;`}>
                                    {club.name}
                                </h1>
                            </>
                        ),
                        wide: true,
                    },
                    {
                        key: 'date',
                        header: formatMessage({ id: 'latestAnalysis' }),
                        renderData: club => {
                            if (!club.latestStadiumAnalysis || !club.latestStadiumAnalysis.tests[0]) {
                                return '-'
                            }
                            return <AnalysisDate
                                date={club.latestStadiumAnalysis.tests[0].analysisDate}
                                timeZone={club.latestStadiumAnalysis.pitch.venue?.timeZone}
                                css={style.analysisDate}
                            />
                        },
                    },
                    {
                        key: 'averageOverallScore',
                        header: renderStringOnNewlines(formatMessage({ id: 'stadiumScore' })),
                        shrink: true,
                        renderData: club => (
                            <ScoreIndicator
                                css={css`font-size: 1.25em;`}
                                decimals={1}
                                value={club.averageOverallScore}
                                color={getOverallScoreColor(club.averageOverallScore)}
                            />
                        ),
                    },
                ].filter((col) => showCompactUI ?
                    (col.key === 'name' || col.key === 'averageOverallScore')
                    : col
                )}
            />
            {!busy &&
                <BrowsePanel
                    pageNum={pageNum}
                    setPageNum={handlePageChange}
                    firstPage={getNumberFromPageLink(firstPage)}
                    lastPage={getNumberFromPageLink(lastPage)}
                    busy={busy}
                    css={css`margin-top: 1em;`}
                />
            }
        </div>
    )
}

const style = {
    wrapper: css`
        display: flex; 
        flex-direction: column;
        align-items: stretch;
    `,
    browsePanel: css`
        position: absolute; 
        top: 50%; 
        left: 50%; 
        translate: -50% -50%;
    `,
    clubLogo: css`
        margin-right: 24px; 
        object-fit: cover; 
        width: 4em;
        aspect-ratio: 1;
        object-fit: contain;
    `,
    analysisDate: css`
        font-family: ${fonts.main};
        font-weight: unset;
        font-size: 1em;
    `
}

function getOverallScoreColor(score) {
    if (score > 9) {
        return backendColors.verygood
    }
    if (score > 7) {
        return backendColors.good
    }
    if (score > 4) {
        return backendColors.neutral
    }
    return backendColors.bad
}

function renderStringOnNewlines(string) {
    return (
        <span>
            {string.split(' ').map((piece, line) => (
                <span key={line}>
                    {piece} <br />
                </span>
            ))}
        </span>
    )
}

function getNumberFromPageLink(pageString) {
    if (pageString === undefined) return 1
    return String(pageString)
        ?.match(/page=(?<number>[\d]+)/)
        ?.groups
        ?.number
}