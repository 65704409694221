import React, { useContext } from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from '../../../style/vars'
import AnalyticsContext from '../../../context/Analytics'
import PropTypes from 'prop-types'
import { useRef } from 'react'

export default function ParameterNumeric({ dashId, parameter, isSecondary, ...props }) {

    const { flexOrder, name, label, stepSize, minVal, maxVal, selectedValue } = parameter

    const { dashboards: { setParameter } } = useContext(AnalyticsContext)

    const uniqueId = name + dashId
    const maxLength = Math.max(minVal.toString().length , maxVal.toString().length)

    const style = css`
        height: 32px;
        border-radius: 5px;
        background: ${colors.eventDark};
        order: ${flexOrder};
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 1px;
        
        &:hover {
            opacity: 0.8;
        }

        input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        > input {
            background: none;
            color: ${colors.soft};
            width: ${maxLength * 0.6}em;
            text-align: right;
            margin-right: 0.5em;
            -moz-appearance: textfield;
        }

        > label {
            margin: 0 0.5em;
        }

        label, input {
            color: ${colors.soft};
            font-family: ${fonts.special};
            font-size: 20px;
            cursor: pointer;
        }
    `
    
    const inputRef = useRef()

    return (
        <div css={style} onClick={() => inputRef.current?.focus()}>
            <label htmlFor={uniqueId} >
                {`${label}:`}
            </label>
            <input
                ref={inputRef}
                type='number'
                id={uniqueId}
                defaultValue={selectedValue}
                min={minVal}
                max={maxVal}
                step={stepSize}
                onChange={(e) => {
                    setParameter(dashId, name, Number(e.target.value), isSecondary)}
                }
                className='parameterNumeric'
                tableau-variable-name={name}
            />
        </div>
    )
}

ParameterNumeric.propTypes = {
    dashId: PropTypes.string,
    isSecondary: PropTypes.bool,
    parameter: PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string, 
        flexOrder: PropTypes.number,
        stepSize: PropTypes.number,
        minVal: PropTypes.number, 
        maxVal: PropTypes.number, 
        selectedValue: PropTypes.number,
    })
}