import React from 'react'
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'

export default function SwitcherSimple({ checked, onClick, unCheckedLabel, checkedLabel, buttonCenter, ...props }) {
    return (
        <label
            onClick={(e) => {
                e.preventDefault()  // to deal with browser differences
                onClick()
            }}
            css={[style.label, css`
                grid-template-columns: ${buttonCenter ? 'auto 60px auto' : '60px auto 1fr'};
            `]}
            {...props}
        >
            <input
                type="checkbox"
                checked={checked}
                onKeyUp={(e) => {
                    e.preventDefault() // to deal with browser differences
                    if (e.key !== 'Enter' && e.key !== ' ') return
                    onClick?.()
                }}
                readOnly
                css={style.hiddenInput} />

            <div css={[
                style.slider,
                css`
                    background: ${checked && colors.eventLight};
                    order: ${buttonCenter && '1'};
                `
            ]}>
                <div css={[
                    style.circle,
                    css`transform: ${checked && 'translateX(100%)'};`
                ]} />
            </div>

            {unCheckedLabel && checkedLabel &&
                <span css={[
                    style.switchText,
                    css`
                        opacity: ${checked ? '0.4' : 1};
                        order: ${buttonCenter && '0'};
                        text-align: ${buttonCenter && 'end'};
                        padding-right: ${buttonCenter && '0.5em'};
                        margin-left: ${buttonCenter && '0'};
                        text-transform: capitalize;
                    `
                ]}>
                    {unCheckedLabel.toLowerCase()}
                </span>
            }
            
            {checkedLabel &&
                <span css={[
                    style.switchText,
                    css`
                        opacity: ${!checked ? '0.4' : '1'};
                        order: ${buttonCenter && '2'};
                        text-transform: capitalize;
                    `
                ]}>
                    {checkedLabel.toLowerCase()}
                </span>
            }
        </label>
    )
}


const style = {
    label: css`
        position: relative;
        width: 100%;
        display: grid;
        align-items: center;
        cursor: pointer;
        user-select: none;
    `,
    hiddenInput: css`
        position: absolute;
        width: 0;
        height: 0;

        :focus-visible {
            ~ div {
                border-color: ${colors.main1};
            }
        }
    `,
    slider: css`
        border: 2px solid ${colors.eventLight};
        border-radius: 20px;
        padding: 2px;
        width: 48px;
        cursor: pointer;
        margin: 4px;
        overflow: visible;
        
        label:hover & {
            background-color: ${colors.eventDark};
        }
    `,
    circle: css`
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: ${colors.main1};
        transition: transform ${timings.smooth};
    `,
    switchText: css`
        width: 100%;
        min-width: 0; 
        overflow-wrap: break-word;
        margin: 0.25em;
    `
}