import React, { useMemo } from "react"
import { useDrop } from "react-dnd"
import dndTypes from "../../constants/dnd-types"
import Event from "../../components/events/Event"
import { get } from "lodash"
import { css } from '@emotion/react'

const wrapperStyle = css`
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 3em);
    grid-template-rows: repeat(auto-fill, 3em);
    grid-gap: 4px;
`

export default function DayDropZone({ date, children, onDrop, ...props }) {

    const [{ hover, item }, drop] = useDrop({
        accept: dndTypes.EVENTTYPE,
        drop: onDrop,
        collect: monitor => ({
            hover: monitor.isOver(),
            item: monitor.getItem()
        })
    });

    const fakeEvent = useMemo(
        () =>
            item ? { type: get(item, ["eventType", "entityShortName"]) } : {},
        [item]
    );

    return (
        <div
            ref={drop}
            {...props}
            css={wrapperStyle}>
            {children}
            <Event
                css={css`visibility: ${hover ? 'visible' : 'hidden'}`}
                event={fakeEvent}
            />
        </div>
    );
}
