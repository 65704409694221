import { uniqueId } from 'lodash'
import Proptypes, { oneOf } from 'prop-types'
import React, { useRef } from 'react'
import SvgShape from './SvgShape'
import { css } from '@emotion/react'
import { timings } from '../../style/vars'
import BodyPart from '../../context/BodyPart'
import Highlights from '../../context/Highlights'
import { useContext } from 'react'
import SurfaceLayer from '../../context/SurfaceLayer'
import SurfaceHighlights from '../../context/SurfaceHighlights'

function Highlight({ shape, part, type, ...props }) {
    
    const gradientId = useRef(uniqueId('gradient_'))
    const [partFocus, setPartFocus] = useContext(type === 'pitch' ? SurfaceLayer : BodyPart)
    const highlights = useContext(type === 'pitch' ? SurfaceHighlights : Highlights)
    const color = highlights[part]

    return (
        color ? <g
            css={css`
                    opacity: ${partFocus === '' ? 1 : partFocus === part ? 1 : 0.25};
                    transition: opacity ${timings.responsive} ease;
                `}
            onClick={(e) => {
                e.preventDefault()
                setPartFocus(current => current === part ? '' : part)
            }}
            onMouseEnter={() => {
                setPartFocus(part)
            }}
            onMouseLeave={() => {
                setPartFocus('')
            }}
        >
            <radialGradient id={gradientId.current}>
                <stop offset='0%' stopColor={color} />
                <stop offset='100%' stopColor={color} stopOpacity="0" />
            </radialGradient>
            <SvgShape
                shape={shape}
                fill={`url(#${gradientId.current})`}
                {...props}
            />
        </g> : null
    ) 
}

Highlight.propTypes = {
    bodyPart: Proptypes.string,
    shape: SvgShape.propTypes.shape,
}

export default Highlight
