import { useContext, useMemo } from "react"
import SettingsSelect from "./SettingsSelect"
import { useIntl } from "react-intl"
import useBridge from "../../hooks/useBridge"
import Loader from "../_general/Loader"
import TimezoneContext from "../../context/Timezone"

export default function ReporterTimezoneSelector({ ...props }) {

    const { formatMessage } = useIntl()

    const { reporterTz, setReporterTz, browserTimezone } = useContext(TimezoneContext)
    const { data: timeZoneOptions, loading } = useBridge('/api/current/frontend-bridge/timezones')

    const options = useMemo(() => {
        if (!timeZoneOptions) return []
        return [
            <option
                value={browserTimezone}
                label={formatMessage({ id: 'useBrowsersTimezone' })}
                key="user-browser-timezone"
            />,
            ...Object.entries(timeZoneOptions).map(([key, val]) =>
                <option
                    value={key}
                    label={val}
                    key={key}
                />
            )
        ]
    }, [timeZoneOptions, browserTimezone])

    return loading ? <Loader /> :
        <SettingsSelect
            value={reporterTz}
            onChange={(e) => setReporterTz(e.target.value)}
            options={options}
            {...props}
        />
}

