import React, {useContext} from 'react'
import { css } from '@emotion/react'
import AnalyticsContext from '../../../context/Analytics'
import downloadBtnIcon from '@assets/icons/download-button.svg'
import { colors } from '../../../style/vars'


export default function Download({targetViz, ...props}) {

    const {dashboards: { setDownloadTarget }} = useContext(AnalyticsContext)

    return(
        <button
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;

                background: ${colors.eventDark};
                border-radius: 4px;
                border: none;
                margin-left: 1px;
                width: 32px;
                height: 32px;
                margin-top: 1px;
                margin-bottom: 1px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            `}
            onClick={() => setDownloadTarget(targetViz)}
            title='download'
        >
            <img src={downloadBtnIcon} />
        </button>
    )
}