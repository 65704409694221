import { css } from "@emotion/react"
import { colors, timings } from "../../style/vars"
import { useCallback } from "react"
import { FormattedMessage } from "react-intl"

export default function GenericMultiSelect({
    options,
    selectedValues,
    setSelectedValues,
    hideAll,
    ...props }) {

    const handleToggleOption = useCallback((value) => {
        if (selectedValues.includes(value)) {
            setSelectedValues(selectedValues.filter(item => item !== value))
        } else {
            setSelectedValues([...selectedValues, value])
        }
    }, [selectedValues])

    const handleToggleAll = useCallback(() => {
        const allValues = options.map(option => option.value)

        if (selectedValues.length !== options.length) {
            setSelectedValues(allValues)
        } else {
            setSelectedValues([])
        }
    }, [options, selectedValues])

    return <ul css={style} {...props}>
        {!hideAll &&
            <li>
                <label >
                    <input
                        type="checkbox"
                        checked={selectedValues.length === options.length}
                        onChange={handleToggleAll}
                    />
                    <FormattedMessage id='allOrNone' />
                </label>
            </li>
        }
        {options.map(option => {
            const isSelected = selectedValues.includes(option.value)
            return <li
                key={option.value}
                css={css`
                    color: ${isSelected ? colors.white : colors.soft};
                    background: ${isSelected ? colors.solid : 'transparent'};
                    font-weight: ${isSelected ? 'bold' : 'unset'};
                `}
            >
                <label>
                    <input
                        type="checkbox"
                        checked={isSelected}
                        onChange={() => handleToggleOption(option.value)}
                    />
                    {option.label}
                </label>
            </li>
        })}
    </ul>
}

const style = css`
    margin: 0;
    padding: 0.5em;
    background-color: ${colors.stuff};
    color: ${colors.soft};
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }

    li {
        display: grid;
        align-items: center;

        &:hover, &:focus {
            background-color: ${colors.solid};
            transition: all ${timings.snappy} ease;
        }
    }

    label {
        height: 2em;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
`