import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import Equipment from './Equipment'
//import { testCategoryIconValues } from '../components/testCategory/TestCategoryIcon'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            name: PropTypes.string,
            importance: PropTypes.number,
            group: PropTypes.string,
            //icon: PropTypes.oneOf(testCategoryIconValues),
            weight: PropTypes.number,
            equipment: Equipment(newDepth),
            testType: PropTypes.oneOf([
                'field',
                'laboratory',
            ]),
        })
    }
    return () => null
}
