import React, { useCallback, useContext, useState, useMemo } from 'react'
import { css } from '@emotion/react'
import { format } from 'date-fns'
import { NavLink } from 'react-router-dom'
import capitalize from 'voca/capitalize'
import { ReactSVG } from 'react-svg'
import { colors, backendColors, fonts } from '../../style/vars'
import stadiumIcon from '../../assets/icons/stadium2.svg'
import practiceIcon from '../../assets/icons/pitch.svg'
import LoaderText from '../../components/_general/LoaderText'
import ScoreIndicator from '../../components/_general/ScoreIndicator'
import Table from '../../components/_general/Table'
import AnalysisDate from '../../components/analysis/AnalysisDate'
import RcpMark from '../../components/analysis/RcpMark'
import { pdf } from '@react-pdf/renderer'
import NodeToBase64 from '../../utils/domNodeToBase64Png'
import { saveAs } from 'file-saver'
import Analysisdetailspdf from '../../components/pdf/AnalysisDetailsPdf'
import stadiumBg from '../../assets/images/pitch_stadium_bg.svg'
import practiceBg from '../../assets/images/pitch_practice_bg.svg'
import editSummary from '../../assets/icons/edit-comment.svg'
import editZones from '../../assets/icons/edit-zones.svg'
import pdfDownload from '../../assets/icons/pdf-download.svg'
import compactMode from '../../assets/icons/compact-mode.svg'
import fullMode from '../../assets/icons/full-mode.svg'
import { MTM_EVENT_TYPES, MTM_FILE_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import DeleteWarnModal from '../_control/DeleteWarnModal'
import trashIcon from '../../assets/icons/trash.svg'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { useNavigate } from 'react-router-dom'
import ViewPortContext from '../../context/ViewPort'
import { useIntl } from 'react-intl'
import LocaleContext from '../../context/Locale'
import { toZonedTime } from 'date-fns-tz'
import TruncatedSummaryText from './TruncatedSummaryText'

export default function AnalysisHeader({
    pitch,
    analysis,
    compact,
    setCompact,
    setPitchModal,
    setEditSummaryModal,
    analysisWaiting,
    ...props
}) {
    const { showCompactUI } = useContext(ViewPortContext)

    const { del } = useLdjsonApi()
    let navigate = useNavigate()

    const [PdfBusy, setPdfBusy] = useState(false)
    const track = useTracker()
    const [deleteModal, setDeleteModal] = useState(false)

    const generateAndPrintPdf = useCallback(async () => {
        if (!(pitch?.name && analysis && !PdfBusy)) return

        const pitchName = pitch?.name || 'Undefined pitch'

        const timeZoneWithFallback = pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone

        const analysisDate = analysis?.date ?
            format(
                toZonedTime(analysis.date * 1000, timeZoneWithFallback),
                'PP',
                { locale: datefnsLocale },
            )
            : "Unknown Date"

        // extract card images from DOM
        // this is a hacky way, but it works
        const selectedNode = document.getElementById('testResults')

        // cast to array, so we can array.Prototype functions
        const testCardNodes = [...selectedNode?.childNodes]
        const dataSummaryNodes = [document.getElementById('analysis-data-summary')].filter(x => !!x)

        // promise magic to ensure correct flow
        const node_images = await Promise.all(
            testCardNodes.map(async (node) => await NodeToBase64(node))
        )
        const summaryImages = await Promise.all(
            dataSummaryNodes.map(async (node) => await NodeToBase64(node))
        )

        // number of columns are needed if in compact mode
        const columns = compact ?
            window.getComputedStyle(selectedNode).gridTemplateColumns.split(' ').length
            : 0

        //"Build" PDF document
        const PdfDoc = <Analysisdetailspdf
            pitchName={pitchName}
            analysisDate={analysisDate}
            cardImages={node_images}
            dataSummaryImages={summaryImages}
            columns={columns}
        />

        try {
            const pdfBlob = await pdf(PdfDoc).toBlob()
            // generate file name
            saveAs(
                pdfBlob,
                `analysis-${String(pitchName).toLowerCase().replace(" ", "_")}-${analysisDate.replace(' ', '_')}.pdf`
            )

            track({
                'event': MTM_EVENT_TYPES['file-download'],
                [MTM_VARIABLES['file-type']]: MTM_FILE_TYPES.pdf
            })

        } catch (e) {
            console.log(e)
        }

        setPdfBusy(false)

    }, [pitch, analysis, setPdfBusy, PdfBusy, compact, showCompactUI])

    const { datefnsLocale } = useContext(LocaleContext)
    const { formatMessage } = useIntl()
    const pitchTypeWords = formatMessage({ id: !!pitch?.stadium ? 'stadiumPitch' : 'trainingGround' }).split(' ')

    const [deletionError, setDeletionError] = useState('')

    const handleDelete = useCallback(async () => {
        if (analysis) {
            try {
                const res = await del(`/api/current/surface-analyses/${analysis.id}`)
                if (res.error || res['hydra:Error']) {
                    setDeletionError(
                        formatMessage({ id: 'somethingWentWrongTryAgain' })
                    )
                    throw new Error('Something went wrong')
                } else {
                    track({ 'event': MTM_EVENT_TYPES['delete-analysis'] })
                }
                setDeleteModal(false)
                navigate(`/pitch/${pitch.id}`)
            } catch (e) {
                console.error(e)
            }
        }
    }, [del, analysis, pitch, analysis, navigate])

    const deleteMessage = useMemo(() => {
        if (!analysis || !pitch) return
        return deletionError || formatMessage(
            { id: 'deleteAnalysisWarnMessage' },
            {
                num: analysis?.tests.length,
                pitch: pitch?.name,
                date: format(new Date(analysis?.date * 1000), 'PP', { locale: datefnsLocale })
            }
        )
    }, [pitch, analysis, deletionError])

    return (
        <>
            <Table
                id="testHeader"
                css={css`margin-bottom: 1em; position:relative;`}
                showHeader={false}
                data={analysis ? [analysis] : []}
                loading={analysisWaiting}
                columns={[
                    {
                        key: 'type',
                        renderData: () => (pitch
                            ?
                            <div
                                css={css`
                                    cursor: pointer;
                                    width: 100%;
                                    &: hover {
                                        opacity: 0.75;
                                    }
                                    @media screen and (max-width: 660px) {
                                        display: none;
                                    }
                                    `}
                                onClick={() => setPitchModal(current => !current)}
                            >
                                <div
                                    css={style.headImg}
                                    style={{ backgroundImage: `url(${pitch.stadium === true ? stadiumBg : practiceBg})` }}
                                />
                                <div css={style.fieldType} >
                                    {pitch
                                        ?
                                        <h4>
                                            {pitchTypeWords.map((word, i) => {
                                                return <div key={word + i}>
                                                    <span className={i === 0 && 'color-stadium'}>
                                                        {word}
                                                    </span>
                                                    {i === 0 ? <br /> : <>{' '}</>}
                                                </div>
                                            })}
                                        </h4>
                                        :
                                        <Loader size='3.5em' />
                                    }
                                    {pitch &&
                                        <ReactSVG
                                            className={'color-stadium'}
                                            css={style.pitchIcon}
                                            src={pitch.stadium === true ? stadiumIcon : practiceIcon}
                                        />
                                    }
                                </div>
                            </div>
                            :
                            <LoaderText subtle />
                        ),
                    },
                    {
                        key: 'date',
                        renderData: analysis => <AnalysisDate
                            date={analysis.date}
                            timeZone={pitch?.venue?.timeZone}
                            css={css`white-space: nowrap;`}
                        />,
                        wide: false
                    },
                    {
                        key: 'summaryShort',
                        renderData: analysis =>
                            <div css={css`
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                                display: flex;

                                &:hover {
                                    > div > div {
                                        color: white;
                                        fill: white;
                                    }
                                }
                            `}
                                onClick={() => setEditSummaryModal(true)}
                                title={formatMessage({ id: 'editSummary' })}
                            >
                                {!!analysis.summary?.length ?
                                    <TruncatedSummaryText summary={analysis.summary} />
                                    :
                                    <ReactSVG src={editSummary} css={[style.svg, css`
                                        align-self: center;
                                    `]} />
                                }
                            </div>
                        ,
                        wide: true
                    },
                    {
                        key: 'controls',
                        renderData: () =>
                            <div css={css`display: flex; gap: 0.6em;`}>
                                {analysis &&
                                    <>
                                        <NavLink /* multiple test edit */
                                            css={style.actionButton}
                                            to={(pitch && analysis) ? `/pitch/${pitch.id}/analysis/${analysis.id}/test-entry` : {}}
                                            title={formatMessage({ id: 'editMultipleTests' })}
                                        >
                                            <ReactSVG src={editZones} style={style.svg} />
                                        </NavLink>

                                        <span
                                            title={formatMessage({ id: 'downloadPDF' })}
                                            onClick={() => {
                                                if (pitch && analysis && !PdfBusy) {
                                                    setPdfBusy(true)
                                                    setTimeout(generateAndPrintPdf, 0) //hack to ensure pdfbusy occurs before
                                                }
                                            }}
                                            css={[(pitch && analysis && !PdfBusy) ?
                                                style.actionButton : PdfBusy ? style.pdfBusy : style.pdfForbidden,
                                            css`@media screen and (max-width: 600px) {
                                                    display: none;
                                                }`
                                            ]
                                            }
                                        >
                                            <ReactSVG src={pdfDownload} style={style.svg} />
                                        </span>

                                        <span
                                            title={formatMessage({ id: 'toggleCompactMode' })}
                                            onClick={() => { setCompact(current => !current) }} css={style.actionButton}
                                        >
                                            <ReactSVG src={compact ? fullMode : compactMode} style={style.svg} />
                                        </span>

                                        <span
                                            title={formatMessage({ id: 'deleteAnalysis' })}
                                            onClick={() => setDeleteModal(true)} css={style.actionButton}
                                        >
                                            <ReactSVG src={trashIcon} css={[style.svg, css`
                                                display: grid;
                                                place-items:center;
                                                div {
                                                    width: 75%;
                                                    height: 75%;
                                                }
                                            `]} />
                                        </span>
                                    </>
                                }

                            </div>,
                    },

                    {
                        key: 'testGroups',
                        renderData: (analysis) => {
                            let string = ''
                            for (const group of analysis.testGroups) {
                                string += `${capitalize(group)}, `
                            }
                            return string.slice(0, string.length - 2) || 'None'
                        },
                    },
                    {
                        key: 'numberOfTests',
                        renderData: analysis => (
                            <span css={css`font-weight: 700; white-space: nowrap;`}>
                                {analysis.rawCertified === true && <RcpMark css={css`margin: 0.4em 0;`} />}
                                {`${formatMessage({ id: 'tests' })}: `}
                                {analysis.tests.length}
                            </span>
                        ),
                    },
                    {
                        key: 'score',
                        renderData: analysis => (
                            <ScoreIndicator
                                value={analysis.score}
                                color={backendColors[analysis.assessment]}
                                css={css`font-size: 1em;`}
                            />
                        ),
                        shrink: true,
                    },
                ].filter(col => showCompactUI ?
                    (col.key === 'score' || col.key === 'controls' || col.key === 'summaryShort')
                    : true
                )}
                rowKey={row => row.id}
            />

            {deleteModal &&
                <DeleteWarnModal
                    header={formatMessage({ id: 'deleteAnalysis' })}
                    message={deleteMessage}
                    handleClose={() => {
                        setDeleteModal(false)
                        setDeletionError()
                    }}
                    handleDelete={handleDelete}
                />
            }
        </>
    )

}

const style = {
    pitchIcon: css`
    height: 3em;
    width: 3em;
    z-index: 1;
    `,
    svg: {
        fill: colors.soft,
        height: '2.6em',
        width: '2.6em',
    },
    pdfBusy: css`
    cursor: progress;
    opacity: 0.3;
    `,
    pdfForbidden: css`
    cursor: not-allowed;
        opacity: 0.3;
    `,
    actionButton: css`
        place-self: center;
        cursor: pointer;
        &: hover {
            filter: brightness(1.3);
        }
    `,
    collapsed: css`
        position:absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        font-weight: 700;
        font-size: 2em;
        font-family: ${fonts.special};
    `,
    headImg: css`
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    `,
    fieldType: css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin-right: 3em;
            display: block;
            font-size: 1.5em;
            font-weight: 300;
            white-space: nowrap;
            text-transform: uppercase;
            font-weight: 500;
            font-family: ${fonts.main};
            z-index: 1;
            max-width: '100%';
        }

        .color-stadium {
            color: ${colors.main1};
        }
    `,
}
