import React, { useState, useCallback, useContext } from "react"
import { css } from '@emotion/react'
import { colors, timings } from "../../style/vars"
import { ReactSVG } from "react-svg"
import { getCollectionColor } from "../../utils/tableauDashboards"
import AnalyticsContext from '../../context/Analytics'
import { useMemo } from "react"
import useTracker from "../../hooks/useTracker"
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from "../../utils/matomo"
import { FormattedMessage, useIntl } from "react-intl"

export default function DashPickerCard({ dashInfo, setSelectedInfo, showSportlabels, showCollectionLabel, ...props }) {

    const { dashboards: { activeDashboards, setActiveDashboards, maxCapacity } } = useContext(AnalyticsContext)

    const track = useTracker()
    const { formatMessage } = useIntl()

    const isActive = useMemo(() => {
        return activeDashboards.map(d => d.id).includes(dashInfo.id)
    }, [activeDashboards])

    const handleClick = useCallback(() => {
        setSelectedInfo?.(`(${dashInfo.collection}) ${dashInfo.label} : ${dashInfo.toolTip}`)
        return setActiveDashboards(current => {
            if (isActive) {
                return [...current].filter(d => !d.id.includes(dashInfo.id))
            } else if (maxCapacity) {
                return current
            } else {
                track({
                    'event': MTM_EVENT_TYPES["option-select"],
                    [MTM_VARIABLES["list-type"]]: MTM_LIST_TYPES["graph-select"],
                    [MTM_VARIABLES["list-value"]]: dashInfo.id
                })
                return [...current, JSON.parse(JSON.stringify(dashInfo))]
            }
        })
    }, [activeDashboards, isActive, maxCapacity])

    return (
        <div
            onClick={handleClick}
            onMouseEnter={() => { setSelectedInfo?.(`${formatMessage({ id: dashInfo.label })}: ${formatMessage({ id: dashInfo.toolTipTranslationKey })}`) }}
            css={[style.noTextSelect, style.card(dashInfo.collection), css`
                opacity: ${isActive ? '0.3' : maxCapacity && '0.08'};
                cursor: ${!isActive && maxCapacity && 'default'};
            `]}
            title={isActive ?
                formatMessage({ id: 'close' }) :
                maxCapacity ? formatMessage({ id: 'maxViewsReached' }) : formatMessage({ id: 'open' })}
        >
            <p css={css`font-size: 1.25em; min-width: 0; overflow: hidden; text-overflow: ellipsis;`}>
                <FormattedMessage id={dashInfo.label} />
            </p>
            <ReactSVG
                src={dashInfo.icon}
                css={css`min-width: 2.5em; min-height: 2.5em;`}
            />
            {showSportlabels &&
                <span
                    css={style.sportLabels}
                >
                    {dashInfo.sports.map(sport => {
                        const translatedSport = formatMessage({id: sport})
                        return <p
                            key={translatedSport}
                            title={translatedSport}
                            css={css`margin-left: 0.5em;`}
                        >
                            {translatedSport.split(' ').map(word => word[0]).join('')}
                        </p>
                    })}
                </span>
            }
            {showCollectionLabel &&
                <span
                    css={style.collectionLabel}
                >
                    {
                        (dashInfo.collection === 'Calendar' ? formatMessage({ id: 'calendar' })
                            : dashInfo.collection === 'Weather' ? formatMessage({ id: 'weather' })
                                : dashInfo.collection).toLowerCase()
                    }
                </span>
            }
        </div>
    )
}

const style = {
    card: (collection) => css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1em;
        background: ${colors.eventDark};
        border-radius: 5px;
        width: 12.5em;
        height: 5em;
        background: black;
        justify-self: start;

        box-shadow: 0 1px 2px 2px rgba(0,0,0,0.2); 
        cursor: pointer;
        border-left: solid 0.25em ${getCollectionColor(collection)};

        fill: ${colors.soft};
        color: ${colors.soft};

        transition: color, ${timings.snappy};

        &:hover {
            fill: ${colors.white};
            color: ${colors.white};
        }
    `,
    sportLabels: css`
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 6px;
        font-size: 0.75em;
        height: 10px;
        opacity: 0.2;
    `,
    collectionLabel: css`
        display: flex;
        position: absolute;
        left: 8%;
        bottom: 6px;
        font-size: 0.75em;
        height: 10px;
        opacity: 0.4;
        text-transform: capitalize;
    `,
    noTextSelect: css`
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    `,
}