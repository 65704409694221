import OswaldExtraLight from '../assets/fonts/Oswald-ExtraLight.ttf'
import OswaldLight from '../assets/fonts/Oswald-Light.ttf'
import OswaldRegular from '../assets/fonts/Oswald-Regular.ttf'
import OswaldMedium from '../assets/fonts/Oswald-Medium.ttf'
import OswaldSemiBold from '../assets/fonts/Oswald-SemiBold.ttf'
import OswaldBold from '../assets/fonts/Oswald-Bold.ttf'
import SpaceGroteskRegular from '../assets/fonts/FKGrotesk-Regular.ttf'
import SpaceGroteskBold from '../assets/fonts/FKGrotesk-Bold.ttf'
import HeeboThin from '../assets/fonts/Heebo-Thin.ttf'
import HeeboLight from '../assets/fonts/Heebo-Light.ttf'
import HeeboRegular from '../assets/fonts/Heebo-Regular.ttf'
import HeeboMedium from '../assets/fonts/Heebo-Medium.ttf'
import HeeboBold from '../assets/fonts/Heebo-Bold.ttf'
import HeeboExtraBold from '../assets/fonts/Heebo-ExtraBold.ttf'
import HeeboBlack from '../assets/fonts/Heebo-Black.ttf'

export const special = `
    @font-face {
        font-family: 'Oswald';
        src: url('${OswaldExtraLight}');
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: 'Oswald';
        src: url('${OswaldLight}');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Oswald';
        src: url('${OswaldRegular}');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Oswald';
        src: url('${OswaldMedium}');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Oswald';
        src: url('${OswaldSemiBold}');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Oswald';
        src: url('${OswaldBold}');
        font-weight: 700;
        font-style: normal;
    }
`

export const title = `
    @font-face {
        font-family: 'SpaceGrotesk';
        src: url('${SpaceGroteskRegular}');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'SpaceGrotesk';
        src: url('${SpaceGroteskBold}');
        font-weight: 700;
        font-style: normal;
    }
`

export const main = `
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboThin}');
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboLight}');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboRegular}');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboMedium}');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboBold}');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboExtraBold}');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: 'Heebo';
        src: url('${HeeboBlack}');
        font-weight: 900;
        font-style: normal;
    }
`
