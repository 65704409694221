import { css } from '@emotion/react'
import { format } from "date-fns"
import { set as setDate } from "date-fns"
import Proptypes from "prop-types"
import React, { useCallback, useContext, useMemo } from 'react'
import { ReactSVG } from 'react-svg'
import betterNextArrow from "../../assets/icons/arrow_right.svg"
import LocaleContext from '../../context/Locale'
import useTracker from '../../hooks/useTracker'
import { fonts } from "../../style/vars"
import { MTM_BROWSE_ACTIONS, MTM_EVENT_TYPES, MTM_VARIABLES } from '../../utils/matomo'

export default function MonthBrowser({ month, year, setMonth, setYear, ...props }) {

    const { datefnsLocale } = useContext(LocaleContext)

    const track = useTracker()

    const { monthFormatted, yearFormatted } = useMemo(() => {
        // we are only interested in the month and year but format() requires a day as well
        const dummyDate = setDate(
            new Date(),
            { day: 1, month, year }
        )
        return {
            monthFormatted: format(dummyDate, "MMMM", { locale: datefnsLocale }),
            yearFormatted: format(dummyDate, "yyyy", { locale: datefnsLocale })
        }
    }, [month, year])

    // months in date-fns are 0 indexed
    const handleChange = useCallback((action) => {
        let targetMonth = month
        let targetYear = year

        if (action === MTM_BROWSE_ACTIONS.increment) {
            if (month + 1 > 11) {
                targetYear = year + 1
                targetMonth = 0
            } else {
                targetMonth = month + 1
            }
        } else if (action === MTM_BROWSE_ACTIONS.decrement) {
            if (month - 1 <= -1) {
                targetYear = year - 1
                targetMonth = 11
            } else {
                targetMonth = month - 1
            }
        } else return

        setYear(targetYear)
        setMonth(targetMonth)

        const formattedDestination = format(
            setDate(
                new Date(),
                { day: 1, month: targetMonth, year: targetYear }
            ),
            'MMM yyyy'
        )

        track({
            'event': MTM_EVENT_TYPES['browse-pagination'],
            [MTM_VARIABLES['browse-action']]: action,
            [MTM_VARIABLES['browse-destination']]: formattedDestination
        })

    }, [month, year, setMonth, setYear])

    return (
        <>
            <ReactSVG
                onClick={() => handleChange(MTM_BROWSE_ACTIONS.decrement)}
                src={betterNextArrow}
                css={styles.prevMonth}
            />
            <div css={styles.calendarTitle}>
                <div css={css`font-size: 0.5em; font-weight: 600;`}>
                    {yearFormatted}
                </div>
                <div>
                    {monthFormatted}
                </div>
            </div>
            <ReactSVG
                onClick={() => handleChange(MTM_BROWSE_ACTIONS.increment)}
                src={betterNextArrow}
                css={styles.nextMonth}
            />
        </>
    )
}

MonthBrowser.propTypes = {
    month: Proptypes.number.isRequired,
    year: Proptypes.number.isRequired,
    setMonth: Proptypes.func.isRequired,
    setYear: Proptypes.func.isRequired
}

const styles = {
    prevMonth: css`
        transform: scaleX(-1);
        cursor: pointer;
        width: 1em;
    `,
    calendarTitle: css`
        min-width: 8em;
        font-family: ${fonts.title};
        font-size: 1.5em;
        text-align: center;
        text-transform: capitalize;
    `,
    nextMonth: css`
        cursor: pointer;
        width: 1em;
    `,
}
