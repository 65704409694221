import { css } from "@emotion/react"
import { colors } from "../../style/vars"
import ModalHeader from "../modals/ModalHeader"
import TextField from "../_control/TextField"
import Cta from "../_control/Cta"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"

export default function TemplateNameForm({ handleSaveTemplate, newTemplateName, setNewTemplateName, ...props }) {
    const { formatMessage } = useIntl()

    return <form css={style.nameInputLayout} onSubmit={(e) => handleSaveTemplate(e)}>

        <ModalHeader header={formatMessage({ id: 'enterTemplateName' })} />

        <TextField
            autoFocus={true}
            css={style.textField}
            value={newTemplateName}
            maxLength={30}
            onChange={(e) => setNewTemplateName(e.target.value)}
        />

        <div css={css`display: grid;`}>
            <Cta css={css`width: unset; margin-top: 1em;`}
                disabled={!newTemplateName.length}
                onClick={(e) => handleSaveTemplate(e)}
            >
                <FormattedMessage id='save' />
            </Cta>
        </div>
    </form>
}

const style = {
    nameInputLayout: css`
        display: flex;
        flex-direction: column;
        padding: 2em;
            > * {
                width: 100%;
            }

            @media screen and (max-width: 600px) {
                height: 100%; 
                margin-top: 2em;
            }
    `,
    textField: css`
        background: ${colors.eventLight};
        color: white;
        font-size: 2em;
    `,
}