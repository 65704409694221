import React from "react"
import { css } from '@emotion/react'
import { colors, fonts } from "../../style/vars"
import { useIntl } from "react-intl"

export default function CollectionButton({ collection, isSelected, ...props }) {
    // translate some collections
    const { formatMessage } = useIntl()
    const localizedName = collection === 'Calendar' ? formatMessage({ id: 'calendar' })
        : collection === 'Weather' ? formatMessage({ id: 'weather' })
            : collection

    return (
        <div
            css={style.card}
            {...props}
        >
            <div css={style.title}>
                {localizedName}
            </div>
        </div>
    )
}

const style = {
    card: css`
        position: relative;
        display: flex; 
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0.3em 0.5em;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover {
            border-bottom: 3px solid;

            > div {
                fill: white;
                color: white;
            }
        }
    `,
    title: css`
        height: 2em;
        font-family: ${fonts.main};
        font-size: 1.3em;
        display: grid;
        place-items: center;
        color: ${colors.soft};
        text-transform: uppercase;
    `
}