import { css } from '@emotion/react'
import gridButtonImg from '@assets/icons/gridToggleButton.svg'
import { colors } from '../../../style/vars'
import React, {useContext} from 'react'
import AnalyticsContext from '../../../context/Analytics'

const buttonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;

    background: ${colors.eventDark};
    border-radius: 4px;
    border: none;
    margin-left: 1px;
    width: 32px;
    height: 32px;
    margin-top: 1px;
    margin-bottom: 1px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
`

export default function SecondaryDashToggle({dashId, ...props}) {

    const {dashboards: { toggleSecondaryDash }} = useContext(AnalyticsContext)

    return (
            <button 
                css={buttonStyle}
                onClick={() => toggleSecondaryDash(dashId)}
                title={'toggle secondary viz'}
            >
                <img src={gridButtonImg} />
            </button>
    )
}