import { css } from "@emotion/react"
import Loader from "../_general/Loader"
import { useMemo } from "react"
import PitchBg from "../_general/pitch/pitchBg"
import { colors } from "../../style/vars"

// Attention! currently for events, the grid's X and Y axes 
// represent the following directions on a horizontal rectangle:
//
//  Y ---------------
//  | |             |
//  | |             |
//    ---------------
//    X ----- 
//
// This may differ from the way zones are positioned in Tests

// this is completely static for event zoneresults
const rows = 5
const columns = 7

export default function EventZones({ backgroundType, event, ...props }) {

    const zones = useMemo(() => {
        if (!event) return
        const zones = event.zoneResults
        zones?.sort((a, b) => {
            return a.posY === b.posY ?
                a.posX - b.posX
                : a.posY - b.posY
        })
        return zones
    }, [event])

    return <div
        css={styles.wrapper}
        {...props}
    >
        <PitchBg
            type={backgroundType}
            css={styles.background}
        />
        {!zones ?
            <Loader
                size='2em'
                css={styles.loader}
            />
            :
            <div css={styles.zonesGrid(rows, columns)}>
                {zones.map(zone => <div
                    key={`${event['@id']}-${zone.posX}-${zone.posY}`}
                    css={styles.zone(zone.active)} />
                )}
            </div>}
    </div>
}

const styles = {
    wrapper: css`
        position: relative; 
        display: flex;
    `,
    background: css`
        flex-grow: 1;
    `,
    loader: css`
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
    `,
    zonesGrid: (rows, columns) => css`
        position: absolute;
        height: 100%;
        width: 100%;
        display: grid;
        place-items: stretch;
        grid-template: repeat(${rows}, 1fr) / repeat(${columns}, 1fr);
        gap: 2px;
    `,
    zone: (active) => css`
        background: ${active ? colors.main1 : 'transparent'};
        opacity: 0.5;
    `,
}