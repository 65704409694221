import { useState, useRef, useEffect } from "react"

export default function LazyLoader({ fallback, children, ...props }) {
    const [doLoad, setDoLoad] = useState(false)
    const fallbackRef = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setDoLoad(true)
                    observer.unobserve(entry.target)
                }
            })
        }, { threshold: 0 })

        if (fallbackRef.current) observer.observe(fallbackRef.current) 

        return () => {
            if (fallbackRef.current) observer.unobserve(fallbackRef.current)
        }
    }, [])

    return doLoad ? children :
        <div {...props} ref={fallbackRef}>
            {fallback}
        </div>
}