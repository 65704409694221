import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import TestCategory from './TestCategory'
import Advice from './Advice'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            name: PropTypes.string,
            usage: PropTypes.string,
            testCategories: PropTypes.arrayOf(TestCategory(newDepth)),
            advices: PropTypes.arrayOf(Advice(newDepth)),
        })
    }
    return () => null
}
