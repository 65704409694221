import React, { useRef } from 'react'
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'

const style = p => css`
    position: relative;
    display: inline-block;
    outline: none;
    cursor: pointer;
    input {
        display: block;
        height: 0;
        width: 0;
        margin: 0;
        padding: 0;
        appearance: none;
    }
    input { &:focus, &:active { + span {
        border-color: ${colors.white};
        transition: all ${timings.snappy} ease;
        &::before {
            background-color: ${colors.white};
            transition: all ${timings.snappy} ease;
        }
    }}}
    input:checked { + span::before {
        transform: scale(1);
        background-color: ${colors.white};
    }}
    span {
        position: relative;
        display: block;
        height: 20px;
        width: 20px;
        border: 1px solid ${colors.liquid};
        border-radius: 0.125em;
        transition: all ${timings.fast} ease;
        background-color: transparent;
        &::before {
            pointer-events: none;
            content: '';
            position: absolute;
            top: 3px;
            bottom: 3px;
            left: 3px;
            right: 3px;
            border-radius: 0.0625em;
            background-color: ${colors.substance};
            transform: scale(0);
            transition: all ${timings.fast} ease;
        }
        &:hover {
            border-color: ${colors.white};
            transition: all ${timings.snappy} ease;
            &::before {
                background-color: ${colors.white};
                transition: all ${timings.snappy} ease;
            }
        }
    }
    ${p.disabled && `
        cursor: not-allowed;
        opacity: 0.5;
    `}
`

function Checkbox(props) {
    const checkboxRef = useRef(null)
    return (
        <label css={style(props)}>
            <input
                type='checkbox'
                ref={checkboxRef}
                {...props}
            />
            <span />
        </label>
    )
}

export default Checkbox
