import log from 'loglevel'
import { valueToStorageString, valueFromStorageString } from '../utils/jsonStorageString'

export default class LocalStorage {
    static get(key) {
        try {
            const value = valueFromStorageString(localStorage.getItem(key))
            log.debug(`💾 localStorage read: ${key} =`, value)
            return value
        } catch (e) {
            log.error(e)
        }
    }

    static getAll() {
        try {
            return Object.keys(localStorage).map(key => LocalStorage.get(key))
        } catch (e) {
            log.error(e)
        }
    }

    static set(key, value) {
        try {
            const str = valueToStorageString(value)
            localStorage.setItem(key, str)
            log.debug(`💾 localStorage write: ${key} =`, str)
        } catch (e) {
            log.error(e)
        }
    }
}
