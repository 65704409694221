function avgFromTest(test) {
    const decimals = test?.testCategory?.allowedDecimals
    const values = test?.zoneResults
        ?.map(zoneResult => zoneResult.measurement)
        ?.filter(zoneResult => zoneResult !== null)
    if (Array.isArray(values) && values.length > 0) {
        return (values.reduce((a, b) => a + b, 0) / values.length)
            .toFixed(Number.isInteger(decimals) ? decimals : 1)
    }
}

function rangePercentageFromTest(test) {
    if (!test) return
    const measurements = test?.zoneResults
        ?.map(zoneResult => zoneResult.measurement)
        .filter(zoneResult => zoneResult !== null)
    if (Array.isArray(measurements) && measurements.length > 0) {
        const [min, max] = [Math.min(...measurements), Math.max(...measurements)]
        return `± ${((max - min) * 100 / ((Math.abs(max) + Math.abs(min)) || Infinity)).toFixed(0)}%`
    }
}

export { avgFromTest, rangePercentageFromTest }