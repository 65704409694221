import React from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from '../../style/vars'

export default function SettingsSelect({ value, onChange, options, ...props }) {
    return (
        <select
            value={value}
            onChange={onChange}
            css={style.select}
            {...props}
        >
            {options}
        </select>
    )
}

const style = {
    select: css`
        width: 100%;
        font-family: ${fonts.main};
        font-size: 1em;
        color: white;
        background: ${colors.eventDark};
        border: 2px solid ${colors.eventLight};
        border-radius: 2px;
        cursor: pointer;
    `
}