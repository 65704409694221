import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { backgrounds, colors, fonts, timings } from '../../style/vars'
import { css } from '@emotion/react'
import tempHighIcon from '../../assets/icons/weather/temperatureHigh.svg'
import waterIcon from '../../assets/icons/weather/water.svg'
import humidityIcon from '../../assets/icons/weather/humidity.svg'
import rainIcon from '../../assets/icons/weather/rain.svg'
import rainChanceIcon from '../../assets/icons/weather/precip_chance.svg'
import windIcon from '../../assets/icons/weather/wind.svg'
import stepIcon from '../../assets/icons/weather/step-icon.svg'
import diseaseIcon from '../../assets/icons/weather/disease.svg'
import leafWetnessIcon from '../../assets/icons/weather/leafWetness.svg'
import evaporationIcon from '../../assets/icons/weather/evaporation.svg'
import TimeSeriesWeather from '../graphs/TimeSeriesWeather'
import WeatherFieldCard from './WeatherFieldCard'
import { calcDerivedForecast } from '../../utils/weatherUtils'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import Loader from '../_general/Loader'
import { FormattedMessage, useIntl } from 'react-intl'
import useTomorrowFields from '../../hooks/useTomorrowFields'
import { toZonedTime } from 'date-fns-tz'

export default function VenueWeather({ venue, ...props }) {
    const { formatMessage } = useIntl()
    const { get } = useLdjsonApi()
    const [data, setData] = useState()
    const [showLoader, setShowLoader] = useState(false)

    const { fieldProperties, weatherCodeMap } = useTomorrowFields()

    // weather data from tomorrow.io api in our backend cache
    const fetchTomorrow = useCallback(async (venueId) => {
        setShowLoader(true)
        const res = await get(`/api/current/venues/${venueId}/weather/timelines`)
        setData(res.data?.data?.timelines?.[0]?.intervals?.map(hour => ({
            date: new Date(Date.parse(hour.startTime)),
            ...hour.values
        })))
        setShowLoader(false)
    }, [])

    useEffect(() => {
        if (!!venue) fetchTomorrow(venue.id)
    }, [venue])

    const derivedData = useMemo(() => {
        return calcDerivedForecast(data, fieldProperties)
    }, [data])

    const oneDayForecast = useMemo(() => {
        if (!derivedData) return
        const h24 = derivedData.slice(0, 24)
        return {
            // it looks like we're repeating code 
            // but we are aggregating in different ways to make it more meaningful for an aggregate of the next 24 hours
            // this makes life a bit more difficult because the field names can't always be the same
            weatherCodeMax: Math.max(...h24.map(hour => hour.weatherCodeMax)),
            temperatureMax: Math.max(...h24.map(hour => hour.temperatureAvg)),
            temperatureMin: Math.min(...h24.map(hour => hour.temperatureAvg)),
            humidityAvg: h24.map(hour => hour.humidityAvg).reduce((a, b) => a + b) / h24.length,
            precipitationAmountMm: h24.map(hour => hour.precipitationIntensityAvg).reduce((a, b) => a + b),
            precipitationProbabilityMax: Math.max(...h24.map(hour => hour.precipitationProbabilityAvg)),
            dewPointAvg: h24.map(hour => hour.dewPointAvg).reduce((a, b) => a + b) / h24.length,
            windSpeedAvg: h24.map(hour => hour.windSpeedAvg).reduce((a, b) => a + b) / h24.length,
            evapotranspirationSum: h24.map(hour => hour.evapotranspirationSum).reduce((a, b) => a + b),
            // derived data:
            diseaseRiskAvg: h24.map(hour => hour.diseaseRiskIndex).reduce((a, b) => a + b) / h24.length,
            // gdd is actually a daily metric, so this 'sum' is actually the avg of the hourly metrics (which all are copies of the daily metric)
            //growingDegreesAvg: h24.map(hour => hour.growingDegrees).reduce((a, b) => a + b) / h24.length,
            growingDegreeDays: h24.map(hour => hour.growingDegreeDays).reduce((a, b) => a + b) / h24.length,
            leafWetnessAvg: h24.map(hour => hour.leafWetness).reduce((a, b) => a + b) / h24.length,
        }
    }, [derivedData])

    const [field1, setField1] = useState(fieldProperties.temperatureAvg.name)
    const [field2, setField2] = useState('')
    const [hoursToShow, setHoursToShow] = useState(24 * 14)  //default to 14 days

    // if we have only one selected field, always default it to field1
    useEffect(() => {
        if (!field1 && field2) {
            setField1(field2)
            setField2('')
        }
    }, [field1, field2])

    const handleSelect = useCallback((field) => {
        // if a field is already selected
        if (field1 === field) return setField1('')
        if (field2 === field) return setField2('')
        // none or one field(s) are set
        if (!field1) return setField1(field)
        if (field1 && !field2) return setField2(field)
        // both fields are set
        setField2(field1)
        setField1(field)
    }, [field1, field2])

    const [field1Data, field2Data] = useMemo(() => {
        // we make the decision to keep an hourly temporal resolution / aggregate for the graph
        // Also, convert dates to make browser display as on-pitch times instead of local times.
        const timeZoneWithFallback = venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        const toPitchTime = (date) => toZonedTime(date, timeZoneWithFallback)

        return [
            field1 && derivedData?.map(hour => ({
                x: toPitchTime(hour.date),
                y: round(hour[field1], fieldProperties[field1].decimals)
            })).slice(0, hoursToShow),
            field2 && derivedData?.map(hour => ({
                x: toPitchTime(hour.date),
                y: round(hour[field2], fieldProperties[field2].decimals)
            })).slice(0, hoursToShow)
        ]
    }, [derivedData, field1, field2, hoursToShow, venue])

    const severityColor = useMemo(() => {
        if (oneDayForecast?.weatherCodeMax === 1000) return 'green'
        const severityNumber = oneDayForecast?.weatherCodeMax?.toString().split('')[0]
        switch (severityNumber) {
            case '0': return colors.black
            case '1':
            case '2': return colors.light
            case '3':
            case '4': return 'skyblue'
            case '5': return 'blue'
            case '6':
            case '7':
            case '8': return 'orange'
            default: return colors.light
        }
    }, [oneDayForecast])

    return (
        <div {...props} css={css`
            opacity: ${(!showLoader && data) ? 1 : 0.5};
            transition: opacity ${timings.snappy};
        `}>
            <div css={css`
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0.5em;
                gap: 4px;
            `}>
                <div css={style.weatherHeader}>
                    <FormattedMessage id='twentyFourHourForecast' />
                    <h3 css={css`
                        background: ${severityColor};
                        padding: 0.2em;
                        border-radius: 5px;
                        opacity: 0.9;
                        color: white;
                        margin-left: 0.5em;
                    `}>
                        {formatMessage({ id: weatherCodeMap[oneDayForecast?.weatherCodeMax] || 'loadingEllipses' })}
                    </h3>
                </div>
                <WeatherFieldCard
                    field={fieldProperties.temperatureAvg.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.temperatureMax, 1)}
                    label={`${formatMessage({ id: 'temperature' })} ${formatMessage({ id: 'maxAbbr' })} / ${formatMessage({ id: 'minAbbr' })}`}
                    unit='°C'
                    icon={tempHighIcon}
                    secondValue={round(oneDayForecast?.temperatureMin, 1)} // we combine min and max here in one button
                />
                <WeatherFieldCard
                    field={fieldProperties.dewPointAvg.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.dewPointAvg, 1)}
                    label={`${formatMessage({ id: 'dewpoint' })} ${formatMessage({ id: 'averageAbbr' })}`}
                    unit='°C'
                    icon={waterIcon}
                />
                <WeatherFieldCard
                    field={fieldProperties.humidityAvg.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.humidityAvg, 0)}
                    label={`${formatMessage({ id: 'humidity' })} ${formatMessage({ id: 'averageAbbr' })}`}
                    unit='%'
                    icon={humidityIcon}
                />
                <WeatherFieldCard
                    field={fieldProperties.precipitationProbabilityAvg.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.precipitationProbabilityMax, 0)}
                    label={`${formatMessage({ id: 'precipChanceShort' })}`}
                    unit='%'
                    icon={rainChanceIcon}
                />
                <WeatherFieldCard
                    field={fieldProperties.precipitationIntensityAvg.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.precipitationAmountMm, 2)}
                    label={`${formatMessage({ id: 'precipitation' })}`}
                    unit='mm'
                    icon={rainIcon}
                />
                <WeatherFieldCard
                    field={fieldProperties.evapotranspirationSum.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.evapotranspirationSum, 2)}
                    label={`${formatMessage({ id: 'evapotranspiration' })}`}
                    unit='mm'
                    icon={evaporationIcon}
                />
                <WeatherFieldCard
                    field={fieldProperties.windSpeedAvg.name}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.windSpeedAvg, 1)}
                    label={`${formatMessage({ id: 'wind' })} ${formatMessage({ id: 'averageAbbr' })}`}
                    unit='m/s'
                    icon={windIcon}
                />
                {/* 'Advanced' derived weather data: */}
                <WeatherFieldCard
                    field={'leafWetness'}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.leafWetnessAvg, 1)}
                    label={`${formatMessage({ id: 'leafWetness' })}`}
                    unit='h'
                    icon={leafWetnessIcon}
                />
                {/* <WeatherFieldCard
                    field={'growingDegrees'}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.growingDegreesAvg, 1)}
                    label={`${formatMessage({ id: 'growingDegrees' })} (Tb=6)`}
                    unit='°C'
                    icon={growingDegIcon}
                /> */}
                <WeatherFieldCard
                    field={'growingDegreeDays'}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.growingDegreeDays, 1)}
                    label={`${formatMessage({ id: 'growingDegreeDaysAbbr' })} (Tb=6)`}
                    unit='°C'
                    icon={stepIcon}
                />
                <WeatherFieldCard
                    field={'diseaseRiskIndex'}
                    field1={field1}
                    field2={field2}
                    handleSelect={handleSelect}
                    value={round(oneDayForecast?.diseaseRiskAvg, 1)}
                    label={`${formatMessage({ id: 'diseaseRiskIndex' })}`}
                    unit=' / 10'
                    icon={diseaseIcon}
                />
            </div>

            {/* graph here */}
            {(field1 || field2) &&
                <div css={style.graphWrapper}>
                    {showLoader &&
                        <Loader size={'5em'} css={css`
                            position: absolute; 
                            top: 50%; 
                            left: 50%; 
                            transform: translate(-50%, -50%);
                        `} />
                    }
                    <div
                        css={style.buttonWrapper}
                    >
                        <button
                            onClick={() => { setHoursToShow(24) }}
                            css={css`
                                background: ${hoursToShow === 24 ? colors.eventLight : 'none'};
                                color: ${hoursToShow === 24 ? 'white' : colors.soft};
                            `}
                        >
                            <FormattedMessage id='twentyFourHourLong' />
                        </button>
                        <button
                            onClick={() => { setHoursToShow(24 * 7) }}
                            css={css`
                                background: ${hoursToShow === (24 * 7) ? colors.eventLight : 'none'};
                                color: ${hoursToShow === (24 * 7) ? 'white' : colors.soft};
                            `}
                        >
                            <FormattedMessage id='sevenDay' />
                        </button>
                        <button
                            onClick={() => { setHoursToShow(24 * 14) }}
                            css={css`
                                background: ${hoursToShow === (24 * 14) ? colors.eventLight : 'none'};
                                color: ${hoursToShow === (24 * 14) ? 'white' : colors.soft};
                            `}
                        >
                            <FormattedMessage id='fourteenDay' />
                        </button>
                    </div>

                    {data && <TimeSeriesWeather
                        field1Data={field1Data || []}
                        label1={fieldProperties[field1]?.label}
                        unit1={fieldProperties[field1]?.unit}
                        getDomain1={fieldProperties[field1]?.getDomain}
                        curve1={fieldProperties[field1]?.curve}
                        field2Data={field2Data || []}
                        label2={fieldProperties[field2]?.label}
                        unit2={fieldProperties[field2]?.unit}
                        getDomain2={fieldProperties[field2]?.getDomain}
                        curve2={fieldProperties[field2]?.curve}
                    />}
                </div>
            }

        </div>
    )
}

function round(val, dec = 0) {
    return Math.round(val * 10 ** dec) / (10 ** dec)
}

const style = {
    weatherHeader: css`
        flex-grow: 1;
        background: ${colors.eventLight};
        padding: 1em;
        border-radius: 5px;
        align-items: center;
        user-select: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.3em;
    `,
    graphWrapper: css`
        width: 100%;
        padding: 60px 40px 35px 40px;
        height: 440px;
        overflow: hidden;
        background: ${backgrounds.weatherGradient};
        border-radius: 0.5em;
        border: 2px solid ${colors.solid};
        position: relative;
    `,
    buttonWrapper: css`
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;

        button {
            border: none;
            border-radius: 5px;
            padding: 0.1em 1em;
            font-size: 16px;
            font-family: ${fonts.special};
            cursor: pointer;
            width: 6em;
        }
    `
}