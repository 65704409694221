import React, { useCallback, useContext, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import TestCategoriesContext from '../../context/TestCategories'
import PageTitle from '../_general/PageTitle'
import Dropdown from '../_control/Dropdown'
import Cta from '../_control/Cta'
import TestCategorySelector from '../_control/TestCategorySelector'
import FallbackMessage from '../_general/FallbackMessage'
import DatePickerCta from '../_control/DatePickerCta'
import PitchTestsAndEventsTimeline from '../graphs/PitchTestsAndEventsTimeline'
import PitchesTestsTimeline from '../graphs/PitchesTestsTimeline'
import PitchesEvents from '../graphs/PitchesEvents'
import PitchesBarTimeline from '../graphs/PitchesBarTimeline'
import FieldGraph from '../graphs/FieldGraph'
import GridList from '../graphs/GridList'
import Modal from '../_layout/Modal'
import TestCategoryIcon from '../testCategory/TestCategoryIcon'
import SwitcherSimple from '../_control/SwitcherSimple'
import GenericMultiSelect from '../_control/GenericMultiSelect'
import Loader from '../_general/Loader'
import PitchListContext from '../../context/PitchList'
import ReporterContext from '../../context/Reporter'
import { format } from 'date-fns'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import { useIntl, FormattedMessage } from 'react-intl'
import PreferredViewContext from '../../context/PreferredView'

export default function PitchReport() {

    const {
        pitchId, setPitchId,
        testCategory1, setTestCategory1,
        compareTo,
        fromDate, setFromDate,
        untilDate, setUntilDate,
        setEventTypeFilter,
        setComparePitchId,
        zoomOut, setZoomOut,
        showTimeTotals, setShowTimeTotals,
        useResultsAsAxis, setUseResultsAsAxis,
        compareData,
        eventTypeOptions,
        reporterBusy,
        lineColor1,
        lineColor2,
        graphTypeOptions,
        setGraphType,
        multiEventTypeFilter,
        setMultiEventTypeFilter,
        uiBools: {
            showTestSelection,
            showEventFilter,
            showEventMultiSelect,
            showZoomToggle,
            showScoreToggle,
            showModelToggle,
            showCountTimeToggle,
            showBarGraph,
            showTimelineTests,
            showTimelinePitches,
            showEventCount,
            showStats,
            showGridList,
            disabledCompareCategory,
            disabledComparePitch,
        },
    } = useContext(ReporterContext)

    const [testSelectorOpen, setTestSelectorOpen] = useState(false)
    const [modelActive, setModelActive] = useState(false)

    const { data: testCategories, } = useContext(TestCategoriesContext)
    const { pitches } = useContext(PitchListContext)

    const demoPitchId = Number(process.env.DEMO_PITCH_ID)
    const demoTestCategoryId = Number(process.env.DEMO_TESTCATEGORY_ID)

    const basePitchOptions = useMemo(() => {
        if (!pitches) return
        return pitches.map(p => ({
            value: p.id,
            label: p.name,
        }))
    }, [pitches])

    const demoPitch = useMemo(() => {
        return basePitchOptions?.find((obj) => obj && obj.value === demoPitchId)
    }, [basePitchOptions])

    const pitchOptions = useMemo(() => {
        return basePitchOptions?.filter(o => o.value !== compareTo?.pitch)
    }, [basePitchOptions, compareTo])

    const comparePitchOptions = useMemo(() => {
        return basePitchOptions?.filter(o => o.value !== pitchId)
    }, [basePitchOptions, pitchId])

    const getTestCategoryOne = useMemo(() => {
        return testCategories?.find(cat => cat.id === testCategory1)
    }, [testCategory1])

    const getTestCategoryTwo = useMemo(() => {
        return testCategories?.find(cat => cat.id === compareTo?.category)
    }, [compareTo])

    const demoTestCategory = useMemo(() => {
        if (!testCategories) return
        const hardnessCategory = testCategories.find((obj) => {
            return obj.id === demoTestCategoryId
        })
        return hardnessCategory?.id
    }, [testCategories])

    const demoButtonVisible = useMemo(() => {
        return demoPitch && demoTestCategory && !(testCategory1 || pitchId || compareData)
    }, [demoPitch, demoTestCategory, testCategory1, pitchId, compareData])

    const guideHighlightStep = useMemo(() => {
        if (pitchId === undefined) return 1
        if (testCategory1 === undefined && showTestSelection) return 2
    }, [pitchId, testCategory1, showTestSelection])

    const applyDemoSettings = useCallback(() => {
        const demoFrom = new Date(2024, 0, 1)
        const demoUntil = new Date(2024, 2, 30)
        demoFrom.setHours(0, 0, 0, 0)
        demoUntil.setHours(23, 59, 59, 999)
        setFromDate(demoFrom)
        setUntilDate(demoUntil)
        setPitchId(demoPitch?.value)
        setTestCategory1(demoTestCategory)
    }, [demoPitch, demoTestCategory])

    const track = useTracker()

    const trackSelection = useCallback((type, value) => {
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: type,
            [MTM_VARIABLES['list-value']]: value
        })
    }, [])

    const { formatMessage } = useIntl()

    const { preferredView, setPreferredView, PREFERRED_VIEW_TYPES } = useContext(PreferredViewContext)

    const handleToggleModelType = useCallback(() => {
        setPreferredView(curr => curr === PREFERRED_VIEW_TYPES.performance ?
            PREFERRED_VIEW_TYPES.greenkeeping
            : PREFERRED_VIEW_TYPES.performance
        )
    }, [PREFERRED_VIEW_TYPES])

    return (
        <>
            <div css={grid.container(showStats)} >
                <div css={css`
                    grid-area: header;
                    display: flex; 
                    justify-content: space-between; 
                    align-items: center; 
                `}>
                    <PageTitle css={style.heading}>
                        <FormattedMessage id='pitchReporting' />
                    </PageTitle>
                    {demoButtonVisible &&
                        <Cta
                            onClick={applyDemoSettings}
                            css={css`
                                font-size: 0.875em;
                                @media screen and (max-width: 600px) {
                                    display: none;
                                }
                            `}
                        >
                            <FormattedMessage id='demoShortcut' />
                        </Cta>
                    }
                </div>
                <div css={style.datepickers}>
                    <DatePickerCta
                        date={fromDate}
                        onDateChange={(d) => {
                            setFromDate(d)
                            trackSelection(MTM_LIST_TYPES['from-date-select'], format(d, 'yyyy-MM-dd'))
                        }}
                    />
                    <span css={css`text-transform: uppercase;`}>
                        <FormattedMessage id='toFromToDate' />
                    </span>
                    <DatePickerCta
                        date={untilDate}
                        onDateChange={(d) => {
                            setUntilDate(d)
                            trackSelection(MTM_LIST_TYPES['to-date-select'], format(d, 'yyyy-MM-dd'))
                        }}
                    />
                </div>
                <div css={[grid.graph, style.box]}>
                    {!pitchId
                        ?
                        <FallbackMessage style={{
                            display: 'grid',
                            height: '100%',
                            placeItems: 'center',
                            fontSize: '2.5em'
                        }}>
                            <FormattedMessage id='selectAPitch' />
                        </FallbackMessage>
                        :
                        <div style={{ position: 'relative', flexGrow: 1, height: '100%', maxHeight: '100%' }}>
                            {showBarGraph && <PitchesBarTimeline style={{ height: '100%' }} />}
                            {showTimelineTests && <PitchTestsAndEventsTimeline style={{ height: '100%' }} />}
                            {showTimelinePitches && <PitchesTestsTimeline style={{ height: '100%' }} />}
                            {showEventCount && <PitchesEvents style={{ height: '100%' }} />}
                            {showGridList && <GridList style={{ height: '100%' }} />}
                        </div>
                    }
                </div>


                <div css={[grid.filter, style.box, css`overflow: auto; gap: 0.5em;`]}>
                    <Dropdown
                        css={css`
                            width: 100%;
                            > select { font-weight: bold};
                        `}
                        options={graphTypeOptions}
                        onChange={(e) => setGraphType(e.target.value)}
                    />
                    <Dropdown
                        placeholder={formatMessage({ id: 'selectAPitch' })}
                        options={pitchOptions}
                        value={pitchId || ''}
                        onChange={(e) => {
                            const label = [...e.target.selectedOptions][0]?.label
                            trackSelection(MTM_LIST_TYPES['pitch-select'], label ?? e.target.value)
                            setPitchId(parseInt(e.target.value, 10))
                        }}
                        style={{
                            boxShadow: guideHighlightStep === 1 && `0 0 1px 1px ${colors.main1}`
                        }}
                        css={compareTo?.pitch && css`
                            border: 3px solid ${lineColor1}; 
                            border-radius: 5px;
                        `}
                    />
                    {!disabledComparePitch && <div css={style.dropdownWrapper}>
                        <Dropdown
                            style={{ width: '100%' }}
                            placeholder={formatMessage({ id: 'compareToPitch' })}
                            options={comparePitchOptions}
                            value={compareTo?.pitch || ''}
                            onChange={(e) => {
                                const label = [...e.target.selectedOptions][0]?.label
                                trackSelection(MTM_LIST_TYPES['compare-pitch-select'], label ?? e.target.value)
                                setComparePitchId(parseInt(e.target.value, 10))
                            }}
                            disabled={!pitchId}
                            css={compareTo?.pitch && css`
                                border: 3px solid ${lineColor2}; 
                                border-radius: 5px;
                            `}
                        />
                        {compareTo?.pitch &&
                            <Cta style={{ padding: '0 8px', marginLeft: '4px', display: 'flex', alignItems: 'center' }} onClick={() => { setComparePitchId(undefined) }}>
                                X
                            </Cta>
                        }
                    </div>}
                    {showTestSelection && <div onClick={() => setTestSelectorOpen(true)}
                        css={[
                            style.testSelection,
                            { boxShadow: guideHighlightStep === 2 && `0 0 1px 1px ${colors.main1}` }]}
                    >
                        <div css={style.testSelectionRow}>
                            <div css={[style.testSelectionItem, compareTo?.category && css`
                                    border: 3px solid ${lineColor1}; 
                                    border-radius: 5px;
                                `]}>
                                {testCategory1 ? (
                                    <TestCategoryIcon
                                        iconName={getTestCategoryOne.icon}
                                        color={compareTo?.category ? lineColor1 : getTestCategoryOne.group === 'player' ? colors.player : colors.pitch}
                                        css={css`font-size: 2em;`}
                                    />
                                ) : null}
                            </div>
                            <b css={css`
                                    color: ${!testCategory1 && colors.soft}; 
                                    font-weight: ${testCategory1 && 'bold'};
                                `}>
                                {testCategory1 && getTestCategoryOne ? getTestCategoryOne.name : formatMessage({ id: 'selectATest' })}
                            </b>
                        </div>

                        {!disabledCompareCategory && <div css={style.testSelectionRow}>
                            <div css={[style.testSelectionItem, compareTo?.category && css`
                                    border: 3px solid ${lineColor2}; 
                                    border-radius: 5px;
                                `]}>
                                {compareTo?.category ? (
                                    <TestCategoryIcon
                                        iconName={getTestCategoryTwo.icon}
                                        color={compareTo?.category ? lineColor2 : getTestCategoryTwo.group === 'player' ? colors.player : colors.pitch}
                                        css={css`font-size: 2em;`}
                                    />
                                ) : null}
                            </div>
                            <b css={css`
                                        color: ${!compareTo?.category && colors.soft}; 
                                        font-weight: ${compareTo?.category && 'bold'};
                                    `}>
                                {compareTo?.category && getTestCategoryTwo ? getTestCategoryTwo.name : formatMessage({ id: 'selectATest' })}
                            </b>
                        </div>}
                    </div>}

                    {showEventFilter && <Dropdown
                        css={css`
                            width: 100%;
                            > select { font-weight: bold};
                        `}
                        options={[
                            {
                                value: '',
                                label: formatMessage({ id: 'showingAllEvents' }),
                            },
                            ...eventTypeOptions,
                        ]}
                        onChange={(e) => {
                            setEventTypeFilter(e.target.value || undefined)
                            trackSelection(MTM_LIST_TYPES['eventType-select'], e.target.value || 'all')
                        }}
                    />}

                    {showEventMultiSelect &&
                        <GenericMultiSelect
                            options={eventTypeOptions}
                            selectedValues={multiEventTypeFilter}
                            setSelectedValues={setMultiEventTypeFilter}
                            css={css`
                                flex-shrink: 1;
                                overflow: auto;
                            `}
                        />}

                    {showZoomToggle && <SwitcherSimple
                        checked={zoomOut}
                        onClick={() => {
                            trackSelection(MTM_LIST_TYPES['zoom-select'], zoomOut ? 'day' : 'month')
                            setZoomOut(current => !current)
                        }}
                        unCheckedLabel={formatMessage({ id: 'zoomDay' })}
                        checkedLabel={formatMessage({ id: 'zoomMonth' })}
                        css={css`text-transform: capitalize;`}
                    />}
                    {showScoreToggle && <SwitcherSimple
                        checked={!useResultsAsAxis}
                        onClick={() => {
                            trackSelection(MTM_LIST_TYPES['result-type-select'], useResultsAsAxis ? 'score' : 'measurement')
                            setUseResultsAsAxis(current => !current)
                        }}
                        unCheckedLabel={formatMessage({ id: 'measurements' })}
                        checkedLabel={formatMessage({ id: 'score' })}
                    />}
                    {showModelToggle && <SwitcherSimple
                        checked={modelActive}
                        onClick={() => {
                            trackSelection(MTM_LIST_TYPES['visualisation-select'], modelActive ? 'grid' : 'model')
                            setModelActive(current => !current)
                        }}
                        unCheckedLabel={formatMessage({ id: 'grid' })}
                        checkedLabel={formatMessage({ id: 'model' })}
                    />}
                    {/* show this if we're on the model option */}
                    {showModelToggle && modelActive && <SwitcherSimple
                        checked={!(preferredView === PREFERRED_VIEW_TYPES.performance)}
                        onClick={handleToggleModelType}
                        unCheckedLabel={formatMessage({ id: 'performance' }) }
                        checkedLabel={formatMessage({ id: 'grounds' })}
                    />}
                    {showCountTimeToggle && <SwitcherSimple
                        checked={showTimeTotals}
                        onClick={() => {
                            trackSelection(MTM_LIST_TYPES['visualisation-select'], showTimeTotals ? 'time-totals' : 'count')
                            setShowTimeTotals(current => !current)
                        }}
                        unCheckedLabel={formatMessage({ id: 'events' })}
                        checkedLabel={formatMessage({ id: 'timeTaken' })}
                    />}
                </div>
                {showStats && <div css={[
                    grid.stats,
                    style.box,
                    !showTestSelection && css`opacity: 0.2; pointer-events: none;`
                ]}>
                    <FieldGraph
                        testCategory1={getTestCategoryOne}
                        testCategory2={getTestCategoryTwo}
                        modelActive={modelActive}
                    />
                </div>}
            </div>
            {testSelectorOpen &&
                <Modal
                    header={
                        <div css={css`display: flex; gap: 1em;`}>
                            <FormattedMessage id='selectYourTests' />
                            {(reporterBusy) && < Loader />}
                        </div>
                    }
                    onClickClose={() => setTestSelectorOpen(false)} style={{ width: '90%' }}
                    css={css`
                        @media screen and (max-width: 600px) {
                            padding: 0;
                        }
                    `}
                >
                    <TestCategorySelector />
                </Modal>
            }
        </ >
    )
}

const grid = {
    container: (showStats) => css`
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-template-rows: 5% 60% auto;
        grid-template-areas:
        ${showStats ? `
        'header date'
        'graph filter'
        'stats stats';
        `: `
        'header date'
        'graph filter'
        'graph filter';
        `}
            
        column-gap: 8px;
        row-gap: 8px;
        max-height: calc(calc(var(--vh, 1vh) * 100) - 2rem) ;
        min-height: 50rem;
        position: relative;
        -webkit-user-select: none;
        user-select: none;

        @media (orientation: portrait) {
            max-height: max-content;
            min-height: 0;
            row-gap: 0;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 5% 40em auto auto;
            grid-template-areas:
            ${showStats ? `
            'header date'
            'graph graph'
            'stats stats'
            'filter filter';
            `: `
            'header date'
            'graph graph'
            'graph graph'
            'filter filter';
            `}
        }
    `,
    header: css`
        grid-area: header;
    `,
    filter: css`
        grid-area: filter;

        @media (orientation: portrait) {
            height: min-content;
        }
        @media screen and (max-width: 600px) {
            padding-bottom: 6em;
        }
    `,
    graph: css`
        grid-area: graph;
        min-width: 0;
        min-height: min-content;
        overflow: auto;
    `,
    stats: css`
        grid-area: stats;

        @media (orientation: portrait) {
            margin: 1em 0;
        }
    `,
}

const style = {
    heading: css`
            color: ${colors.main1};
            margin: 0;
        `,
    datepickers: css`
            display: flex;
            align-self: center;
            align-items: center;
            justify-content: center;
            grid-area: date;

            span {
                margin: 0 0.5em;
            }

            @media (orientation: portrait) {
                height: min-content;
                justify-content: flex-end;
            }
        `,
    dropdownWrapper: css`
            display: inline-flex;
            width: 100%;
        `,
    box: css`
            background-color: #232628;
            border-radius: .5em;
            padding: 1em;
            display: flex;
            flex-direction: column;
        `,
    testSelectionRow: css`
            display: flex;
            flex-direction: row;
            align-items: center;

            b {
                margin-top: 0;
                margin-left: .5em;
            }
        `,
    testSelection: css`
            display: flex;
            gap: 0.5em;
            flex-direction: column;
            width: 100%;
            background-color: ${colors.stuff};
            border-radius: 2px;
            padding: .5em;
            cursor: pointer;
            opacity: 0.75;

            &:hover {
                opacity: 1;
                background-color: ${colors.solid};
            }
        `,
    testSelectionItem: css`
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: .5em;
            width: 3em;
            height: 3em;
            background-color: #1B1C1F;
        `,
}
