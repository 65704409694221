import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { format } from 'date-fns'
import { fonts } from '../../style/vars'
import LocaleContext from '../../context/Locale'
import { toZonedTime } from 'date-fns-tz'

const style = css`
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25em;
    font-family: ${fonts.special};
`

export default function AnalysisDate({ date, timeZone, ...props }) {
    const { datefnsLocale } = useContext(LocaleContext)

    const timeZoneWithFallback = useMemo(() => {
        return timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [timeZone])

    const dateOnPitch = useMemo(() => {
        return toZonedTime(date * 1000, timeZoneWithFallback)
    }, [timeZoneWithFallback, date])

    return (
        <span css={style} {...props}>
            {format(dateOnPitch, 'PP', { locale: datefnsLocale })}
        </span>
    )
}

AnalysisDate.propTypes = {
    date: PropTypes.number.isRequired,
}