import { useContext } from "react"
import LocaleContext from "../../context/Locale"
import SettingsSelect from "./SettingsSelect"
import { useIntl } from "react-intl"

export default function WeekStartSelector({ ...props }) {
    const { weekStart, setWeekStart } = useContext(LocaleContext)
    const { formatMessage } = useIntl()

    return <SettingsSelect
        value={weekStart}
        onChange={(e) => {
            const weekStartNumber = Number(e.target.value)
            if (weekStartNumber < 0 || weekStartNumber > 6) return setWeekStart(1)
            setWeekStart(weekStartNumber)
        }}
        options={[
            <option
                value={1}
                label={formatMessage({ id: 'monday' })}
                key={1}
            />,
            <option
                value={0}
                label={formatMessage({ id: 'sunday' })}
                key={0}
            />
        ]}
        {...props}
    />
}