import React, {useContext} from 'react'
import ProfileContext from '../context/Profile'

export default function useTracker() {
    
    const [profile] = useContext(ProfileContext)

    return (mtmData = {}) => {
        if (!profile?.admin) {
            window._mtm?.push(mtmData)
        }
    }
}