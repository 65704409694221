import React from 'react'
import { css } from '@emotion/react'
import {ReactSVG} from 'react-svg'
import { colors } from '../../style/vars'
import rcpCheck from '../../assets/icons/yes.svg'

function RcpMark(props) {
    return (
        <div
            css={css`display: flex; align-items: center; color: ${colors.main1};`}
            {...props}
        >
            <ReactSVG
                css={css`
                    svg {
                        height: 1.25em;
                        width: auto;
                        display: block;
                        .ios & { // to prevent weird clipping issue
                            padding: 0.125em;
                            height: 1.25em;
                        }
                    }
                `}
                src={rcpCheck}
            />
            <div css={css`
                margin-left: 0.375em;
                .ios & {
                    margin-left: 0.125em;
                }
                font-size: 0.875em;
                line-height: 1;
                margin-top: 0.125em;
            `}>
                RCP
            </div>
        </div>
    )
}

export default RcpMark
