import React, { useContext, useState, useMemo, useCallback } from 'react'
import { css } from '@emotion/react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { colors } from '../../style/vars'
import Auth from '../../context/Auth'
import useInput from '../../hooks/useField'
import AuthBoxLayout from '../../components/_layout/AuthBoxLayout'
import TextField from '../../components/_control/TextField'
import Cta from '../../components/_control/Cta'
import { MTM_EVENT_TYPES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import ChangePasswordCard from '../../components/settings/ChangePasswordCard'
import Raw from '../../services/Raw'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import { useIntl, FormattedMessage } from 'react-intl'

export default function ResetPasswordRequest() {

    const { rememberedEmail, logIn } = useContext(Auth)
    const [emailInput, emailError] = useInput(rememberedEmail || '', val => val.length < 4)
    const [isMailSent, setIsMailSent] = useState(false)
    const [error, setError] = useState('')

    const { post } = useLdjsonApi()

    const track = useTracker()
    const { formatMessage } = useIntl()

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault()
        track({ 'event': MTM_EVENT_TYPES['forgot-password'] })

        try {
            const res = await Raw.fetchJson('POST', '/api/reset-password', {
                body: {
                    email: emailInput.value,
                    callback: window.location.href, // where we get redirected to after clicking the link in the reset password email
                },
            })

            if (!res.error) {
                setIsMailSent(true)
                setError('')
            }
        } catch (e) {
            setError('Oops, something went wrong. Try again later.')
            console.error(e)
        }

    }, [emailInput])

    const [params] = useSearchParams()
    // the recovery mail will redirect to this page with these params added
    const [token, email] = useMemo(() => {
        return [params.get('token'), params.get('email')]
    }, [params])

    const submitPassword = useCallback(async (password, onSuccessCallback) => {
        const res = await post(`/api/reset-password/${token}`, {
            body: {
                password,
                email,
            }
        })
        if (res?.data) {
            onSuccessCallback?.()
            logIn(email, password)
        } else {
            throw new Error('response did not contain data')
        }
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <AuthBoxLayout>
                {{
                    title: formatMessage({id: 'resetPass'}),
                    subtitle: formatMessage({id: 'passRememberJoke'}),
                    main: (
                        (!token || !email) ?
                            <div css={css`display: grid;`}>
                                <TextField
                                    type='email'
                                    css={css`border-radius: 0.1875em;`}
                                    placeholder={formatMessage({id: 'yourEmail'})}
                                    {...emailInput}
                                />
                                <Cta
                                    type='submit'
                                    css={css`align-self: center; min-width: 75%; margin-top: 1.75em;`}
                                    signal='subtle'
                                    disabled={isMailSent || emailError}
                                >
                                    {!isMailSent ? formatMessage({id: 'requestReset'}) : formatMessage({id: 'mailSent'})}
                                </Cta>
                                <div css={css`margin-top: 1em;`}>
                                    {error}
                                </div>
                            </div>
                            :
                            <ChangePasswordCard
                                submitPassword={submitPassword}
                                css={css`padding: 0; width: 100%;`}
                            />
                    ),
                    below: (
                        <div css={css`
                            display: flex; 
                            justify-content: 
                            flex-end;color: ${colors.liquid}; 
                            font-size: 0.875em; 
                            font-weight: 300;
                        `}>
                            <NavLink css={css`color: inherit;`} to='/login'>
                                <FormattedMessage id='rememberedPass' />
                            </NavLink>
                        </div>
                    ),
                }}
            </AuthBoxLayout>
        </form>
    )
}