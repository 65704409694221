import { useMemo } from "react"
import { colors, backendColors, testCategoryGroupColors, timings, fonts } from "../../style/vars"
import { css } from "@emotion/react"
import { avgFromTest } from "../../utils/analysisCalculations"
import Loader from "../_general/Loader"
import TestCategoryIcon from "../testCategory/TestCategoryIcon"
import ScoreIndicator from "../_general/ScoreIndicator"

export default function TestAverageLinearGauge({ test, ...props }) {

    const { avg, color, bins, binId, positionPercentage } = useMemo(() => {
        const ratings = test?.testCategory?.ratings
        if (!ratings?.length) return {}

        const testAvgResult = parseFloat(avgFromTest(test))
        const resultBin = ratings.find(r => ((r.min <= testAvgResult) && (r.max >= testAvgResult)))

        let lastFrontendCode

        const sortedRatings = ratings
            .toSorted((a, b) => a.min - b.min) // sort ratings ascending
            .filter(r => r.score >= 3) // remove ratings with extreme scores
            .map((r, i) => {  // mark where boundaries between colors are
                if (i === 0) {
                    lastFrontendCode = r.assessment.frontendCode
                    return r
                } else if (lastFrontendCode === r.assessment.frontendCode) {
                    return r
                } else {
                    lastFrontendCode = r.assessment.frontendCode
                    return { ...r, boundary: true }
                }
            })

        const gaugeMin = sortedRatings[0].min
        const gaugeMax = sortedRatings[sortedRatings.length - 1].max

        let positionPercentage  // fine positioning of result within bin
        let binId  // which bin our result belongs in
        let color

        if (testAvgResult < gaugeMin) {  // out of bounds minimum
            positionPercentage = 0
            binId = sortedRatings[0].id
            color = backendColors[sortedRatings[0].assessment.frontendCode]
        } else if (testAvgResult > gaugeMax) {  // out of bounds maximum
            positionPercentage = 100
            binId = sortedRatings[sortedRatings.length - 1].id
            color = backendColors[sortedRatings[sortedRatings.length - 1].assessment.frontendCode]
        } else if (Number.isFinite(testAvgResult)) {  // calculate position of result
            if (!resultBin) return {}
            binId = resultBin.id
            const { min, max } = resultBin
            const range = max - min
            positionPercentage = ((testAvgResult - min) / range) * 100
            color = backendColors[resultBin.assessment.frontendCode]
        }

        return {
            avg: testAvgResult,
            color,
            bins: sortedRatings,
            binId,
            positionPercentage,
        }
    }, [test])

    return !!test?.testCategory?.ratings?.length ?
        <div css={css`
            width: 100%;
            font-size: 1em;
            padding: 0 1em 1em 1em;
            color: ${colors.white};
            font-size: 1.4em;
            line-height: 1.8;
            font-family: ${fonts.special};
            font-weight: 700;
            text-transform: unset;
        `}>
            <div css={css`display: flex; align-items: center; gap: 0.5em; color: ${colors.light};`}>
                <TestCategoryIcon iconName={test.testCategory.icon} color={testCategoryGroupColors[test.testCategory.group]} />
                {test.testCategory.name}
            </div>
            <div css={css`
                margin-top: 0.2em;
                width: 100%;
                display: flex;
                border-top: 2px solid ${colors.light};
                border-bottom: 2px solid ${colors.light};
                border-left: 2px solid ${colors.light} ;
                border-right: 2px solid ${colors.light};
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;

                &:hover {

                    .boundary{
                        opacity: 0.5;
                    }
                    
                    .orb {
                        > div {
                            background: ${colors.eventLight}70;
                            border-color: transparent;
                            box-shadow: unset;
                            color: ${colors.white};
                        }
                    }
                }
            `}>

                {bins?.map((bin, i) => {
                    return <div key={bin['@id']} css={css`
                        flex-grow: 1; 
                        flex-basis: 0;
                        background: ${bin.assessment?.frontendCode === 'good'
                            ? backendColors[bin.assessment.frontendCode] : bin.assessment?.frontendCode === 'neutral'
                                ? 'rgba(255,255,255,0.2)' : 'transparent'};
                        position: relative;
                        height: 0.7em;
                        font-size: 0.8em;                        
                    `}>
                        {bin.id === binId &&
                            <div className="orb" css={css`
                                left: ${positionPercentage}%;
                                position: absolute;
                                z-index: 1;
                                font-size: 0.7em;
                                transform: translate(-50%, -35%);

                                > div {
                                    background: ${colors.eventLight};
                                }
                                
                            `}>
                                <ScoreIndicator value={avg} color={color} />
                            </div>
                        }

                        {bin.boundary &&
                            <div className="boundary" css={css`
                                position: absolute; 
                                height: 100%;
                                transform: translate(-50%, 80%);
                                opacity: 0;
                                transition: opacity ${timings.smooth};
                            `}>
                                {bin.min}
                            </div>
                        }
                    </div>
                })}
            </div>
        </div>
        :
        <Loader size={'2em'} />
}

