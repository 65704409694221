import React, { createContext, useMemo } from "react"
import { getPitchEffectColor } from "../utils/getPhysicalAssessmentColor"

const SurfaceHighlightsContext = createContext()
export default SurfaceHighlightsContext

export function SurfaceHighlightsProvider({ pitchImpacts, ...props }) {

    const highlights = useMemo(() => {
        const h = {}
        if (!pitchImpacts) return h
        for (const pitchImpact of pitchImpacts) {
            const { pitchEffect: { pitchPart } = {} } = pitchImpact
            if (!h[pitchPart]) {
                h[pitchPart] = pitchImpact
            } else {
                h[pitchPart] = getEffectToShow(h[pitchPart], pitchImpact)
            }
        }
        for (const [key, val] of Object.entries(h)) {
            h[key] = getPitchEffectColor(val)
        }
        return h
    }, [pitchImpacts])

    return <SurfaceHighlightsContext.Provider value={highlights} {...props} />
}



function getEffectToShow(ass1, ass2) {
    // resolve conflicts by showing negatives before positives, and then severes before lights
    const positionMap = {
        "negative-severe": 4,
        "negative-light": 3,
        "positive-severe": 2,
        "positive-light": 1,
    }

    const lookup1 = `${ass1.pitchEffect.negativeEffect ? 'negative' : 'positive'}-${ass1.severity}`
    const lookup2 = `${ass2.pitchEffect.negativeEffect ? 'negative' : 'positive'}-${ass2.severity}`

    return positionMap[lookup1] >= positionMap[lookup2] ? ass1 : ass2
}