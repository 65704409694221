import React, {useContext} from 'react'
import AnalyticsContext from '../../../context/Analytics'
import resetIcon from '@assets/icons/reset-button.svg'
import { useCallback } from 'react'
import { css } from '@emotion/react'
import { colors } from '../../../style/vars'

export default function Reset({dashId, controls, secondaryControls, isSecondary = false, ...props}) {
    const {
        dates:{ onLoadDateSync },
        pitches: { onLoadPitchSync },
        dashboards: { activeDashboards, setParameter, setFilter},
    } = useContext(AnalyticsContext)

    const handleClick = useCallback(() => {
        const dash = activeDashboards.find(d => d.id === dashId)
        let doPrimary = false
        let doSecondary = false
        if (isSecondary) { // if this is a secondary reset, apply only to secondary dash.
            doSecondary = true
        } else { // if only 1 dashboard (primary) has a reset, it should reset everything
            doPrimary = true
            if (dash.secondaryDashboard && !dash.secondaryDashboard?.ui.reset) doSecondary = true
            // if individual resets exist for both primary and secondary dashboard, 
            // they each should handle their own dashboard
        }

        // resets the dashboards to default
        if (doPrimary) dash.viz?.getWorkbook().revertAllAsync()
        if (doSecondary) dash.secondaryDashboard.viz?.getWorkbook().revertAllAsync()

        // now reapply all our filters and parameters
        // don't forget that secondary dashboards sometimes share controls with the primary dashboard. 
        function applyVariables(controlsDiv, isSec, linkedControlNames, skipGlobals) {
            // global filters, but apply only to this dashboard
            // if we're just applying linked filters, skip this step
            if (!skipGlobals) {
                onLoadPitchSync(dashId, isSec)
                onLoadDateSync(dashId, isSec)
            }

            // controls specific to the dashboard
            const parameterSelects = controlsDiv.getElementsByClassName('parameterSelect')
            const parameterToggles = controlsDiv.getElementsByClassName('parameterToggle')
            const parameterNumerics = controlsDiv.getElementsByClassName('parameterNumeric')
            const filterSelects = controlsDiv.getElementsByClassName('filterSelect')
            const dashDates = controlsDiv.getElementsByClassName('dashDateFilter')

            for (const pSelect of parameterSelects) {
                const varName = pSelect.attributes['tableau-variable-name'].value
                if (linkedControlNames && !linkedControlNames?.includes(varName)) continue 
                setParameter(dashId, varName, pSelect.value, isSec)
            }
            for (const pToggle of parameterToggles) {
                const varName = pToggle.attributes['tableau-variable-name'].value
                if (linkedControlNames && !linkedControlNames?.includes(varName)) continue
                setParameter(dashId, varName, pToggle.checked ? 'True' : 'False', isSec)
            }
            for (const pNumeric of parameterNumerics) {
                const varName = pNumeric.attributes['tableau-variable-name'].value
                if (linkedControlNames && !linkedControlNames?.includes(varName)) continue
                setParameter(dashId, varName, Number(pNumeric.value), isSec)
            }
            for (const fSelect of filterSelects) {
                const varName = fSelect.attributes['tableau-variable-name'].value
                if (linkedControlNames && !linkedControlNames?.includes(varName)) continue
                setFilter(dashId, varName, fSelect.value, isSec)
            }
            // proceed with dates if we are not listening to the global date filter
            if (isSec ? !dash.secondaryDashboard.behavior.globalDateFilter : !dash.behavior.globalDateFilter){
                const start = [dashDates[0], dashDates[1]].find(input => input.classList.contains('start'))
                const end = [dashDates[0], dashDates[1]].find(input => input.classList.contains('end'))
                const varName = start.attributes['tableau-variable-name'].value
                setFilter(
                    dashId, 
                    varName, 
                    { min: start.valueAsDate, max: end.valueAsDate },
                    isSec
                ) 
            }
        }
        
        if (doPrimary) applyVariables(controls)
        if (doSecondary) {
            if (secondaryControls) {
                applyVariables(secondaryControls, true)
            } else {
                applyVariables(controls, true)
            }
        }

    }, [controls, activeDashboards, setParameter, setFilter, onLoadPitchSync, onLoadDateSync])

    return (
        <button 
            onClick={handleClick}
            title='reset direct modifications and reapply controls'
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;

                background: ${colors.eventDark};
                border-radius: 4px;
                border: none;
                margin-left: 1px;
                width: 32px;
                height: 32px;
                margin-top: 1px;
                margin-bottom: 1px;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            `}
        >
            <img src={resetIcon} alt="" />
        </button>
    )
}