import React, { useState, useRef, useEffect, useCallback } from 'react'
import { css } from '@emotion/react'
import yScaleIcon from '@assets/icons/fit-vertical-button-active.svg'
import { colors, fonts } from '../../../style/vars'

export default function Resize({ targetViz, baseHeight, ...props }) {

    const [scale, setScale] = useState(100)
    const [inputVisible, setInputVisible] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
        if (inputVisible) inputRef.current.focus()
    }, [inputVisible])

    const applyScale = useCallback(() => {
        if (!Number.isInteger(scale)) return setScale(100)
        if (scale > 250) return setScale(250)
        if (scale < 50) return setScale(50)
        targetViz.setFrameSize('100%', `${Math.round(baseHeight * scale / 100)}px`)
    }, [scale, targetViz])

    return (
        <form
            onSubmit={(e) => e.preventDefault()}
            css={[style.form, css`padding-right: ${inputVisible && '0.4em'};`]}
            title='height'
            {...props}
        >
            <button
                onClick={() => {
                    if (!inputVisible) return setInputVisible(true)
                    applyScale()
                    setInputVisible(false)
                }}
                css={style.button}
            >
                <img src={yScaleIcon} />
            </button>
            {inputVisible && <input
                ref={inputRef}
                type="number"
                value={scale}
                onChange={(e) => setScale(Number(e.target.value))}
                min='50'
                max='250'
                step='10'
                placeholder='1'
                css={style.input}
            />}
            <span css={css`font-size: 14px`}>
                {inputVisible && '%'}
            </span>
        </form>
    )
}

const style = {
    form: css`
        display: flex;
        align-items: center;
        background: ${colors.eventDark};
        color: ${colors.soft}; 
        border-radius: 4px;
        border: none;
        margin-left: 1px;
        width: min-content;
        height: 32px;
        margin-top: 1px;
        margin-bottom: 1px;
        overflow: hidden;
        font-family: ${fonts.special};

        &:hover {
            opacity: 0.8;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    `,
    button: css`
        display: grid;
        place-items: center;
        border: none;
        width: min-content;
        background: none;
        padding: 0;
        cursor: pointer;
    `,
    input: css`
        display: flex;
        font-family: ${fonts.special};
        justify-content: center;
        text-align: right;
        align-items: center;
        background: none; 
        width: 2.2em;
        color: ${colors.soft}
    `
}