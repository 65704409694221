import React, { createContext, useState, useCallback } from 'react'

const GridModeContext = createContext()

function GridModeProvider({ ...props }) {

    const OPTION_NAMES = {
        'measurements': 'measurements',
        'heatmap':'heatmap', 
        'transitions': 'transitions',
    }

    const modeOptions = [
        OPTION_NAMES.measurements, 
        OPTION_NAMES.heatmap, 
        OPTION_NAMES.transitions,
    ]
    const [selectedMode, setSelectedMode] = useState(modeOptions[0])

    const step = useCallback((stepSize) => {
        const currentIndex = modeOptions.indexOf(selectedMode)
        const nextIndex = (currentIndex + stepSize) % modeOptions.length
        setSelectedMode(modeOptions[nextIndex])
    },[selectedMode])

    const setGridMode = useCallback((action) => {
        switch (action) {
            case 'increment': return step(1)
            case 'decrement': return step(-1)
        }
        setSelectedMode(modeOptions[action])
    },[modeOptions, step])

    return (
        <GridModeContext.Provider
            {...props}
            value={{
                selectedMode,
                setGridMode,
                selectedIndex: modeOptions.indexOf(selectedMode),
                OPTION_NAMES
            }}
        />
    )
}

export default GridModeContext
export { GridModeProvider }