import { curveMonotoneX, line } from 'd3'
import { getUnixTime, startOfDay } from 'date-fns'
import { groupBy } from 'lodash'
import React, { useMemo, useContext } from 'react'
import { colors } from '../../style/vars'
import EventTypeSvg from '../events/EventTypeSvg'
import FallbackMessage from '../_general/FallbackMessage'
import Loader from '../_general/Loader'
import GenericTimeSeries from './GenericTimeSeries'
import { processChartData } from './util'
import ReporterContext from '../../context/Reporter'
import { getEventTypeColor } from '../../utils/eventUtils'
import { FormattedMessage } from 'react-intl'

export default function PitchTestAndEventsTimeline({ ...props }) {

    const {
        testCategory1,
        compareTo,
        useResultsAsAxis,
        tests1,
        compareData,
        events,
        reporterBusy,
        eventTypeFilter,
        lineColor1,
        lineColor2,
    } = useContext(ReporterContext)

    const filteredEvents = useMemo(() => {
        if (!(events?.length)) return []
        if (!eventTypeFilter) return events
        return (events?.filter(
            ev => (ev.type == eventTypeFilter)
        ))
    }, [events, eventTypeFilter])

    const maxRangeTests = useMemo(() => {
        if (tests1 && tests1[0]) {
            return tests1[0].testCategory.maxRatingValue
        }
    }, [tests1])

    const maxRangeComparedTests = useMemo(() => {
        if (compareData && compareData[0]) {
            return compareData[0].testCategory.maxRatingValue
        }
    }, [compareData])

    const minRangeTests = useMemo(() => {
        if (tests1 && tests1[0]) {
            return tests1[0].testCategory.minRatingValue
        }
    }, [tests1])

    const minRangeComparedTests = useMemo(() => {
        if (compareData && compareData[0]) {
            return compareData[0].testCategory.minRatingValue
        }
    }, [compareData])

    // massaged data, for displaying
    const test1Scores = useMemo(() => (
        processChartData(tests1)
    ), [tests1])

    const test2Scores = useMemo(() => (
        processChartData(compareData)
    ), [compareData])

    // calculate event count for each filtered event per day
    const eventCountPerDay = useMemo(() => {
        const groupedEvents = groupBy(filteredEvents.map(ev => ({
            timestamp: getUnixTime(startOfDay(new Date(ev.timestamp * 1000))),
            ...ev,
        })), ({ timestamp }) => (
            getUnixTime(startOfDay(new Date(timestamp * 1000)))
        ))
        const eventCountPerDay = {}
        for (const [timestamp, eventsOfADay] of Object.entries(groupedEvents)) {
            const eventCounts = {}
            for (const event of eventsOfADay) {
                if (!eventCounts[event.type]) {
                    eventCounts[event.type] = 0
                }
                eventCounts[event.type]++
            }
            eventCountPerDay[timestamp] = eventCounts
        }
        return eventCountPerDay
    }, [filteredEvents])

    return (
        <GenericTimeSeries
            {...props}
            yAxisOneMin={minRangeTests}
            yAxisOneMax={maxRangeTests}
            yAxisTwoMin={minRangeComparedTests}
            yAxisTwoMax={maxRangeComparedTests}
            graphStyle={{ opacity: reporterBusy ? 0.25 : 1 }}
            overlay={
                <>
                    {reporterBusy &&
                        <Loader size='16em' />
                    }
                    {(!reporterBusy && (
                        !(testCategory1 || compareTo?.category)
                            ?
                            <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                <FormattedMessage id='selectATestCategory' />
                            </FallbackMessage>
                            :
                            !compareTo?.category
                                ?
                                test1Scores.length === 0
                                    ?
                                    <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                        <FormattedMessage id='noDataAvailable' />
                                    </FallbackMessage>
                                    :
                                    undefined
                                :
                                (test1Scores.length === 0 && test2Scores.length === 0)
                                    ?
                                    <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                        <FormattedMessage id='noDataAvailable' />
                                    </FallbackMessage>
                                    :
                                    undefined

                    ))}
                </>
            }
            domainRender={(({ sizes, xScale, yScale, yScaleCompared }) => (
                <>
                    {Object.entries(eventCountPerDay)
                        .sort(([keyA,], [keyB,]) => (keyB - keyA))
                        .map(([timestamp, eventCount], gi) => (
                            Object.entries(eventCount)
                                .sort(([, countA], [, countB]) => (countA - countB))
                                .map(([type, count], i) => (
                                    <g
                                        key={`${timestamp}-${type}`}>
                                        <g
                                            style={{
                                                transform: `
                                                    translateX(${xScale(timestamp) - (32 / 2)}px)
                                                    translateY(${sizes.graphH - 40 - (i * 40)}px)
                                                `
                                            }}
                                        >
                                            <EventTypeSvg
                                                eventType={type}
                                                width={32}
                                            />
                                        </g>
                                        {count > 1 &&
                                            <>
                                                <circle
                                                    key={i}
                                                    r={8}
                                                    fill={colors.eventDark}
                                                    stroke={getEventTypeColor(type)}
                                                    cx={xScale(timestamp) - (32 / 2) + 30}
                                                    cy={sizes.graphH + (0.625) * 6 - (i * 40) + 30}
                                                />
                                                <text
                                                    fill={getEventTypeColor(type)}
                                                    textAnchor='middle'
                                                    fontSize={10}
                                                    fontWeight='bold'
                                                    x={xScale(timestamp) - (32 / 2) + 30}
                                                    y={sizes.graphH + (0.42) * 6 - (i * 40) + 35}
                                                >
                                                    {count}
                                                </text>
                                            </>
                                        }
                                    </g>
                                ))
                        ))}
                    {/* line & point tests 1 */}
                    <path
                        d={
                            line()
                                .curve(curveMonotoneX)
                                .x((test) => (xScale(test.timestamp)))
                                .y((test) => (yScale(useResultsAsAxis ? test.avg : test.score)))
                                (test1Scores)
                        }
                        fill='none'
                        stroke={lineColor1}
                        strokeWidth={3}
                    />
                    {test1Scores?.map(({ id, timestamp, score, avg, rawCertified }) => (
                        <circle
                            key={id}
                            fill={lineColor1}
                            stroke={rawCertified ? colors.main1 : lineColor1}
                            strokeWidth={2}
                            r={4}
                            cx={xScale(timestamp)}
                            cy={yScale(useResultsAsAxis ? avg : score)}
                        />
                    ))}

                    {/* line & point tests 2 */}
                    <path
                        d={
                            line()
                                .curve(curveMonotoneX)
                                .x((test) => (xScale(test.timestamp)))
                                .y((test) => (yScaleCompared(useResultsAsAxis ? test.avg : test.score)))
                                (test2Scores)
                        }
                        fill='none'
                        stroke={lineColor2}
                        strokeWidth={3}
                    />
                    {test2Scores?.map(({ id, timestamp, score, avg, rawCertified }) => (
                        <circle
                            key={id}
                            fill={lineColor2}
                            stroke={rawCertified ? colors.main1 : lineColor2}
                            strokeWidth={2}
                            r={4}
                            cx={xScale(timestamp)}
                            cy={yScaleCompared(useResultsAsAxis ? avg : score)}
                        />
                    ))}
                </>
            ))}
        />
    )
}
