import React, { useRef, useEffect, useCallback, useMemo, useContext } from 'react'
import { css } from '@emotion/react'
import { debounce } from 'lodash'
import { select, scaleTime, scaleLinear, axisBottom, axisLeft, axisRight, extent, timeFormat, line, group, curveLinear } from 'd3'
import { addHours, addDays } from 'date-fns'
import { colors, fonts } from '../../style/vars'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import LocaleContext from '../../context/Locale'

export default function TimeSeriesWeather({
    field1Data,
    label1,
    unit1,
    getDomain1,
    curve1,
    field2Data,
    label2,
    unit2,
    getDomain2,
    curve2,
    ...props
}) {
    const { datefnsLocale } = useContext(LocaleContext)

    const svgRef = useRef()

    const mode = useMemo(() => {
        return field1Data.length === 24 ? '24h' : field1Data.length === (24 * 7) ? '7d' : '14d'
    }, [field1Data])

    const showDataset2 = useMemo(() => {
        return field2Data?.length > 0
    }, [field2Data])

    const renderGraph = useCallback((width, height) => {
        if (field1Data?.length === 0) return

        //domains of our data
        const [xMin, xMax] = extent(field1Data.map(d => d.x))
        const [yMinPrim, yMaxPrim] = extent(field1Data.map(d => d.y))
        const [yMinSec, yMaxSec] = extent(field2Data.map(d => d.y))
        // if a function was provided for domain calculation, use it, otherwise just take min and max
        let primaryDomain = getDomain1 ? getDomain1(yMinPrim, yMaxPrim) : [yMinPrim, yMaxPrim]
        let secondaryDomain = getDomain2 ? getDomain2(yMinSec, yMaxSec) : [yMinSec, yMaxSec]
        // if units are the same, synchronise axes
        if (unit1 === unit2) {
            primaryDomain = [Math.min(...primaryDomain, ...secondaryDomain), Math.max(...primaryDomain, ...secondaryDomain)]
            secondaryDomain = primaryDomain
        }

        //select svg element with d3
        const svg = select(svgRef.current)

        // x scale
        const xScale = scaleTime().domain([xMin, xMax]).range([0, width])
        // X AXIS //
        const xAxis = axisBottom(xScale)
        xAxis.ticks(
            Math.min(
                Math.floor((width / 65)),
                mode === '14d' ? 14 : mode === '7d' ? 7 : 24
            ))
            .tickSizeOuter(0)
            .tickFormat(date => {
                return mode !== '24h' ?
                    format(date, 'eeeeee d', { locale: datefnsLocale }) :
                    format(date, 'HH:mm', { locale: datefnsLocale })
            })
        svg.select(".x-axis")
            .attr("transform", `translate( 0, ${height})`)
            .transition().call(xAxis)

        // y scales
        const yScalePrim = scaleLinear().domain(primaryDomain).range([height, 0])
        const yScaleSec = scaleLinear().domain(secondaryDomain).range([height, 0])
        // Y AXES //
        const yAxisPrim = axisLeft(yScalePrim)
        const yAxisSec = axisRight(yScaleSec)
        yAxisPrim.tickSizeOuter(0)
        yAxisSec.tickSizeOuter(0)
        svg.select(".y-primary-axis")
            .transition().call(yAxisPrim)
        svg.select(".y-secondary-axis")
            .attr("transform", `translate( ${width}, 0)`)
            .transition().call(yAxisSec)

        // LINES //
        // group data for easier iteration with d3. 
        const flatData = [
            ...field1Data.map(d => ({ ...d, field: 'primary' })),
            ...field2Data.map(d => ({ ...d, field: 'secondary' }))
        ]
        const groupedData = group(flatData, d => d.field) // resulting groups are an array ['groupname', {data}]

        svg.select(".dataLines").selectAll('path') // create a path for each group
            .data(groupedData)
            .join('path')
            .transition()
            .attr("d", group => {
                return line()
                    .curve((group[0] === 'primary' ? curve1 : curve2) ?? curveLinear)
                    .x(d => xScale(d.x))
                    .y(d => group[0] === 'primary' ? yScalePrim(d.y) : yScaleSec(d.y)) // this results in a function
                    (group[1]) // pass in the data
            })
            .attr('stroke', group => group[0] === 'primary' ? colors.dataPrimary : colors.dataSecondary)

        // GRIDLINES //
        const gridDates = []
        for (let i = 0; i < (mode === '24h' ? 24 : mode === '7d' ? 7 : 14); i++) {
            (mode === '24h') ?
                gridDates.push(addHours(
                    new Date(xMin.getTime()).setMinutes(0, 0, 0),
                    i
                )) :
                gridDates.push(addDays(
                    new Date(xMin.getTime()).setHours(0, 0, 0, 0),
                    i + 1)
                )
        }
        svg.select(".gridGroup").selectAll("line")
            .data(gridDates)
            .join('line')
            .transition()
            .attr('x1', (d) => xScale(d))
            .attr('x2', (d) => xScale(d))
            .attr('y1', height)

        // HOVER HIGLIGHTS //
        svg.select('.circleGroup').selectAll('circle')
            .data(flatData)
            .join('circle')
            .attr('cx', d => xScale(d.x))
            .attr('cy', d => d.field === 'primary' ? yScalePrim(d.y) : yScaleSec(d.y))
            .attr('r', 10)
            .attr('fill', d => d.field === 'primary' ? colors.dataPrimary : colors.dataSecondary)
            .on('mouseover', (e, d) => {
                svg.select('.valueTooltip')
                    .attr('x', xScale(d.x))
                    .attr('y', (d.field === 'primary' ? yScalePrim(d.y) : yScaleSec(d.y)) - 15)
                    .text(`${d.y} ${d.field === 'primary' ? unit1 : unit2}`)
                svg.select('.tooltipGroup').select('rect')
                    .attr('y', height + 4)
                    .attr('x', xScale(d.x))
                svg.select('.timeTooltip')
                    .attr('y', height + 4 + 12)
                    .attr('x', xScale(d.x))
                    .text(timeFormat("%H:00")(d.x))
            })
            .on('mouseleave', () => svg.select('.tooltipGroup').attr('display', 'none'))
            .on('mouseenter', () => svg.select('.tooltipGroup').attr('display', 'block'))

    }, [field1Data, field2Data, label1, label2, unit1, unit2, mode])

    // resize triggers rerender
    useEffect(() => {
        const debouncedObserveFunc = debounce(
            (entries) => {
                const { width, height } = entries[0].target.getBoundingClientRect()
                renderGraph(width, height)
            },
            50
        )
        const observer = new ResizeObserver(debouncedObserveFunc)
        observer.observe(svgRef.current)

        return () => {
            if (!svgRef.current) return
            observer.unobserve(svgRef.current)
        }
    }, [field1Data, field2Data])

    return (
        <svg ref={svgRef}
            // includes some styles for static stuff or things we don't need d3 renderfunction for
            // z position within svg goes from bottom to top in element order, so we use groups to make life easier
            css={css`
                width: 100%;
                height: 100%;
                overflow: visible;
                user-select: none;
            `}
        >
            {/* gridlines */}
            <g className="gridGroup" css={css`
                stroke: grey;
                stroke-width: 1;
                opacity: 0.2;
            `} />

            {/* axes */}
            <g className="x-axis" css={css`
                color: ${colors.soft}; 
                font-size: 14px;
                font-family: ${fonts.special};
            `} />

            <g className="y-primary-axis" css={css`
                color: ${colors.dataPrimary}; 
                font-size: 14px;
                font-family: ${fonts.special};
            `} />

            {showDataset2 &&
                <g className="y-secondary-axis" css={css`
                    color: ${colors.dataSecondary}; 
                    font-size: 14px;
                    font-family: ${fonts.special};
                `} />
            }

            {/* lines */}
            <g className='dataLines' css={css`
                stroke-width: 2px;
                fill: none;
            `} />


            {/* axis labels */}
            <g css={css`
                @media screen and (max-width: 800px) {
                    display: none;
                }
            `}>
                <text
                    fill='white'
                    fontFamily={fonts.main}
                    fontSize={14}
                    transform='translate(-10, -22)'
                >
                    {label1} {unit1 && `(${unit1})`}
                </text>

                {label2 &&
                    <text
                        fill='white'
                        fontFamily={fonts.main}
                        textAnchor='end'
                        x='100%'
                        fontSize={14}
                        transform='translate(10, -22)'
                    >
                        {label2} {unit2 && `(${unit2})`}
                    </text>
                }
            </g>

            {/* Highlights */}
            <g className='circleGroup' css={css`
                circle {
                    opacity: 0;

                    &:hover {
                        opacity: 0.4;
                    }
                }
            `} />

            {/* Tooltips */}
            <g className='tooltipGroup' display='none'>
                <text
                    className='valueTooltip'
                    fill='white'
                    fontFamily={fonts.special}
                    textAnchor='middle'
                />
                <rect
                    width='50'
                    height='20'
                    rx='5'
                    fill={colors.eventLight}
                    transform='translate(-25, 0)'
                />
                <text
                    className='timeTooltip'
                    fill='white'
                    fontSize={16}
                    fontFamily={fonts.special}
                    dominantBaseline="middle"
                    textAnchor="middle"
                />
            </g>
        </svg>
    )
}

TimeSeriesWeather.propTypes = {
    field1Data: PropTypes.array,
    label1: PropTypes.string,
    unit1: PropTypes.string,
    getDomain1: PropTypes.func,
    field2Data: PropTypes.array,
    label2: PropTypes.string,
    unit2: PropTypes.string,
    getDomain2: PropTypes.func,
}