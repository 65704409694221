import React, { useContext, useRef, useEffect } from "react"
import { css } from '@emotion/react'
import AnalyticsContext from "../../context/Analytics"
import Info from "./dashboardUi/Info"
import Download from "./dashboardUi/Download"
import Resize from "./dashboardUi/Resize"
import Dispose from "./dashboardUi/Dispose"
import ParameterSelect from "./dashboardUi/ParameterSelect"
import ParameterToggle from "./dashboardUi/ParameterToggle"
import DashDateFilter from "./dashboardUi/DashDateFilter"
import FilterSelect from "./dashboardUi/FilterSelect"
import SecondaryDashToggle from "./dashboardUi/SecondaryDashToggle"
import ParameterNumeric from "./dashboardUi/ParameterNumeric"
import Reset from "./dashboardUi/Reset"
import { colors, fonts } from "../../style/vars"
import { FormattedMessage } from "react-intl"

const server = process.env.TABLEAU_URL

export default function Dashboard({ state, ...props }) {
    const {
        dates: { onLoadDateSync },
        pitches: { onLoadPitchSync },
        dashboards: { setActiveDashboards, onLoadCreateUI }
    } = useContext(AnalyticsContext)

    const {
        id,
        viz,
        label,
        urlPart,
        secondaryDashboard = false,
        ui: { download, infoHtml, resize, baseHeight, dispose, parameters, filters, reset, hideBorder },
    } = state

    const dashRef = useRef()
    const dashRef2 = useRef()
    const controlsRef = useRef()
    const controlsRef2 = useRef()

    const defaultWidth = '100%'

    useEffect(() => {
        //try to load on component's first render 
        const vizUrl = `${server}/views/${urlPart}`
        const element = dashRef.current
        const options = {
            hideTabs: true,
            hideToolbar: true,
            width: defaultWidth,
            height: `${baseHeight}px`,
            onFirstInteractive: () => {
                // keep new dashboard's filters in sync with ui
                onLoadDateSync(id)
                onLoadPitchSync(id)
                onLoadCreateUI(id)
            },
        }
        // this loads the dashboard
        const viz = new window.tableau.Viz(element, vizUrl, options)

        //also load a secondary dashboard if required:
        let viz2
        if (secondaryDashboard) {
            const vizUrl2 = `${server}/views/${secondaryDashboard.urlPart}`
            const element2 = dashRef2.current
            const options2 = {
                hideTabs: true,
                hideToolbar: true,
                width: defaultWidth,
                height: `${secondaryDashboard.ui.baseHeight}px`,
                onFirstInteractive: () => {
                    onLoadDateSync(id, true)
                    onLoadPitchSync(id, true)
                    onLoadCreateUI(id, true)
                },
            }
            viz2 = new window.tableau.Viz(element2, vizUrl2, options2)
        }

        // save viz in state
        setActiveDashboards(current => {
            const thisDashboard = current.find(d => d.id === id)
            thisDashboard['viz'] = viz
            if (viz2) thisDashboard.secondaryDashboard['viz'] = viz2
            return current.map(d => d.id === id ? thisDashboard : d)
        })

        return () => {
            // cleans up viz resources
            viz.dispose()
            if (viz2) viz2.dispose()
        }

    }, [])

    return (
        <div css={css`
            display: flex; 
            flex-direction: ${secondaryDashboard?.ui?.hide ? 'column' : 'row'};
            height: min-content;
            flex-wrap: wrap;
            justify-content: space-around;
        `}>
            <div css={css`
                flex-grow: 1;
                display: ${secondaryDashboard && 'flex'}; 
                flex-direction: column;
                justify-content: space-between;
            `}>
                <div css={[style.header, secondaryDashboard && !(secondaryDashboard?.ui?.hide === true) && css`max-width: 460px; align-self: center;`]}>
                    <div css={css`margin: 0.2em 1em;`}>
                        <FormattedMessage id={label} />
                    </div>
                    <div ref={controlsRef} css={style.controls}>
                        {parameters && parameters.map(p => {
                            // type is list for both bools and lists in tableau.. so check values instead
                            return (p.selectedValue === true || p.selectedValue === false) ?
                                <ParameterToggle dashId={id} parameter={p} key={p.name} /> :
                                (p.type === 'list') ?
                                    <ParameterSelect dashId={id} parameter={p} key={p.name} /> :
                                    <ParameterNumeric dashId={id} parameter={p} key={p.name} />
                        })}
                        {filters?.map(f => {
                            if (f.type === 'list') return <FilterSelect dashId={id} filter={f} key={f.name} />
                            if (f.type === 'date') return <DashDateFilter dashId={id} filter={f} key={f.name} />
                        }
                        )}
                        <div css={style.groupedButtons}>
                            {reset && <Reset dashId={id} controls={controlsRef.current} secondaryControls={controlsRef2.current} />}
                            {resize && <Resize targetViz={viz} baseHeight={baseHeight} />}
                            {download && <Download targetViz={viz} />}
                            {!secondaryDashboard.ui?.download && secondaryDashboard && <Download targetViz={secondaryDashboard.viz} />}
                            {secondaryDashboard && <SecondaryDashToggle dashId={id} />}
                            {infoHtml && <Info infoHtml={infoHtml} />}
                            {dispose && <Dispose id={id} />}
                        </div>
                    </div>
                </div>
                <div ref={dashRef} css={[style.dashboard, css`
                    border: ${!hideBorder && `2px solid gray`};
                `]}>
                    {/* dashboard is inserted here, keep this div empty */}
                </div>
            </div>

            {/* secondary dashboard: */}
            {secondaryDashboard &&
                <div css={
                    [
                        css`
                            flex-grow: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                        `,
                        secondaryDashboard.isHidden && style.hidden
                    ]
                }>
                    {!secondaryDashboard.ui.hide &&
                        <div css={[style.header, css`align-self: center; max-width: 460px;`]}>
                            <div css={css`margin: 0.2em 1em;`}>
                                <FormattedMessage id={secondaryDashboard.label} />
                            </div>
                            <div
                                ref={controlsRef2}
                                css={style.controls}
                            >
                                {secondaryDashboard.ui.parameters && secondaryDashboard.ui.parameters.map(p => {
                                    // type is list for both bools and lists in tableau.. so check values instead
                                    return (p.selectedValue === true || p.selectedValue === false) ?
                                        <ParameterToggle dashId={id} parameter={p} key={p.name} isSecondary={true} /> :
                                        <ParameterSelect dashId={id} parameter={p} key={p.name} isSecondary={true} />
                                })}
                                {secondaryDashboard.ui.filters?.map(f => {
                                    if (f.type === 'list') return <FilterSelect dashId={id} filter={f} key={f.name} isSecondary={true} />
                                    if (f.type === 'date') return <DashDateFilter dashId={id} filter={f} key={f.name} isSecondary={true} />
                                })}
                                <div css={style.groupedButtons}>
                                    {secondaryDashboard.ui.reset &&
                                        <Reset dashId={id} controls={controlsRef.current} secondaryControls={controlsRef2.current} isSecondary={true} />
                                    }
                                    {secondaryDashboard.ui.download &&
                                        <Download targetViz={secondaryDashboard.viz} />
                                    }
                                </div>
                            </div>
                        </div>}
                    <div ref={dashRef2} css={style.dashboard}>
                        {/* dashboard is inserted here, keep this div empty */}
                    </div>
                </div>}
        </div>
    )
}

const style = {
    // when hidden, make sure the dashboard stays on the page somewhere so we can keep it synchronised
    hidden: css`
        width: 2px;
        height: 2px;
        overflow: hidden;
        position: fixed;
        top: -10%;
    `,
    header: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: space-between;
        font-family: ${fonts.special};
        font-size: 24px;
        margin: 0.3em 0.2em 0.2em 0.2em;
        flex-grow: 1;
    `,
    controls: css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        grow: 1;

        > div {
            margin: 0.2em 1px 0.2em 0;
        }
    `,
    groupedButtons: css`
        order: 10;
        display: flex;
        margin: 0;
    `,
    dashboard: css`
        height: min-content; 
        border-radius: 5px; 
        overflow: hidden;
    `
}