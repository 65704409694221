export function filterChartData(tests) {
    if (!tests) return []
    return tests.filter(
        (test) => test?.score?.avg != null
            || test?.score?.score != null
    )
}

export function processChartData(tests) {
    return filterChartData(tests).map((test) => ({
        id: test.id,
        timestamp: test.analysisDate,
        score: test.score.score,
        avg: test.score.avg,
        rawCertified: test.rawCertified,
    }))
}

export function roundToOne(num) {
    if (num >= 1000) {
        return `${num / 1000}K`
    }

    if (num > 100) {
        return Math.round(num)
    }
    return +(Math.round(num + "e+1") + "e-1");
}

export function getDateKey(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function toAveragedPerDay(timeSortedArray) {
    const output = []

    const keyGroups = timeSortedArray.reduce((groups, data) => {
        const dateKey = getDateKey(data.date)
        if (!groups[dateKey]) {
            groups[dateKey] = []
        }
        groups[dateKey].push(data)
        return groups
    }, {})

    Object.entries(keyGroups).forEach(([dateKey, arr]) => {
        const valueSum = arr.reduce((sum, { value }) => sum + value, 0)
        const timestampSum = arr.reduce((sum, { timestamp }) => sum + timestamp, 0)
        const averageValue = valueSum / arr.length
        const averageTimestamp = timestampSum / arr.length
        output.push({
            value: averageValue,
            timestamp: averageTimestamp,
            date: new Date(averageTimestamp * 1000),
            isAggregate: arr.length > 1
        })
    })

    output.sort((a, b) => a.timestamp - b.timestamp)
    return output
}