import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import log from 'loglevel'
import { find, findIndex, isEqual, uniqWith } from 'lodash'
import { NavLink } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import Auth from '../../context/Auth'
import Pitch from '../../context/Pitch'
import Analysis from '../../context/Analysis'
import LoaderText from '../_general/LoaderText'
import PageTitle from '../_general/PageTitle'
import { colors, fonts } from '../../style/vars'
import Zones from '../Zones'
import Raw from '../../services/Raw'
import MeasurementPicker from '../_control/MeasurementPicker'
import TestCategoryIcon from '../testCategory/TestCategoryIcon'
import Modal from '../_layout/Modal'
import pencilIcon from '../../assets/icons/pencil.svg'
import FallbackMessage from '../_general/FallbackMessage'
import Orientation from '../test/Orientation'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'
import { FormattedMessage } from 'react-intl'
import AnalysisDate from '../analysis/AnalysisDate'

export default function AnalysisTestEntry({ onlyShowGroup, ...props }) {
    // contexts
    const { jwt } = useContext(Auth)
    const [pitch] = useContext(Pitch)
    const [analysis, analysisMeta] = useContext(Analysis)

    // state
    const [gridSize, setGridSize] = useState()
    const [gridPosition, setGridPosition] = useState()
    const [_zoneResultsWithCat, _setZoneResultsWithCat] = useState([]) // zoneResults we are currently editing, _ prefix means it's a bit 'dirty'
    const [dirty, setDirty] = useState(false)
    const [putBusy, setPutBusy] = useState(false)
    const [warningModalOpened, setWarningModalOpened] = useState(false)

    // data massaging
    const busy = putBusy || analysisMeta.busy
    const tests = useMemo(() => (
        (analysis ? analysis.tests : []).filter(test => (
            // only use field tests
            test.testCategory.testType === 'field' &&
            // only use fixed grid-based tests
            test.testCategory?.pitchTemplate?.templateType === 'fixed' &&
            // if a testCategory group is given, filter on it
            (!onlyShowGroup || test.testCategory.group === onlyShowGroup)
        ))
    ), [analysis, onlyShowGroup])
    const gridSizes = useMemo(() => (
        uniqWith(tests.map(getTestGridSize), isEqual).sort((a, b) => ((a.x * a.y) - (b.x * b.y)))
    ), [tests])
    const currentGridTests = useMemo(() => (
        tests.filter(test => (
            isEqual(getTestGridSize(test), gridSize)
        ))
            // sort by name
            .sort((a, b) => (
                a.testCategory.name < b.testCategory.name ? -1 : 1
            ))
            // sort by category
            .sort((a, b) => (
                a.testCategory.group < b.testCategory.group ? -1 : 1
            ))
    ), [gridSize, tests])

    const zonesStats = useMemo(() => {
        const zonesStats = {}
        for (const test of currentGridTests) {
            for (const zoneResult of test.zoneResults) {
                const x = zoneResult.posX - 1
                const y = zoneResult.posY - 1
                const i = getGridString({ x, y })
                // initialize stats object for position if it doesn't exist yet
                if (!zonesStats[i]) {
                    zonesStats[i] = {
                        totalTestAmount: 0,
                        completedTestAmount: 0,
                    }
                }
                // count stats
                zonesStats[i].totalTestAmount++
                if ((!zoneResult.weight) || typeof zoneResult.measurement === 'number') {
                    zonesStats[i].completedTestAmount++
                }

                if (zoneResult.weight === 0) zonesStats[i].hidden = true
            }
        }
        return zonesStats
    }, [currentGridTests])

    // click handlers
    const changeGrid = useCallback((gridSizeStr) => {
        if (!dirty) {
            setGridSize(gridSizeStr)
            setGridPosition({ x: 0, y: 0 })
        } else {
            setWarningModalOpened(true)
        }
    }, [dirty])

    const changePosition = useCallback((position) => {
        if (!dirty) {
            setGridPosition(position)
        } else {
            setWarningModalOpened(true)
        }
    }, [dirty])

    // editing data
    const updateZoneResultMeasurement = useCallback((zoneResultId, value) => {
        _setZoneResultsWithCat((_zoneResultsWithCat) => {
            const i = findIndex(_zoneResultsWithCat.map(({ zoneResult }) => zoneResult), { '@id': zoneResultId })
            const __zoneResultsWithCat = [..._zoneResultsWithCat]
            __zoneResultsWithCat[i].zoneResult.measurement = value
            return __zoneResultsWithCat
        })
        setDirty(true)
    }, [])

    // resetting editable data (or freshly setting editable zoneResultsWithCat)
    const setEditableData = useCallback(() => {
        if (gridPosition) {
            const zoneResultsWithCat = []
            for (const test of currentGridTests) {
                // our positions are 0-indexed, actual zoneResults from API are 1-indexed...
                const zoneResult = find(test.zoneResults, { posX: gridPosition.x + 1, posY: gridPosition.y + 1 }) || {}
                zoneResultsWithCat.push({
                    testCategory: test.testCategory,
                    zoneResult: { ...zoneResult }, // prevent mutating analysis state
                })
            }
            _setZoneResultsWithCat(zoneResultsWithCat)
        } else {
            _setZoneResultsWithCat([])
        }
        setDirty(false)
    }, [currentGridTests, gridPosition])

    // effects
    useEffect(() => {
        setEditableData()
    }, [setEditableData])

    // saving edited data
    const save = useCallback(async () => {
        try {
            setPutBusy(true);
            await Promise.all(_zoneResultsWithCat
                .filter(({ zoneResult }) => zoneResult?.weight)
                .map(({ zoneResult }) => (
                    Raw.fetchJson('PUT', zoneResult['@id'], { jwt, body: zoneResult })
                )));
            setDirty(false);
            await analysisMeta.fetch(); // refetch analysis data so it's never stale
        }
        catch (e) {
            log.error(e);
        }
        finally {
            setPutBusy(false);
        }
    }, [_zoneResultsWithCat, analysisMeta, jwt])

    return (
        analysisMeta.waiting
            ?
            <FallbackMessage>
                <LoaderText />
            </FallbackMessage>
            :
            <React.Fragment>
                <div {...props}>
                    <PageTitle css={css`margin-bottom: 1em;`}>
                        <NavLink to='/analyses' css={css`text-decoration: none; color: ${colors.white};`}>
                            <FormattedMessage id='analysis' />
                        </NavLink>
                        <NavLink to={(pitch && analysis) ? `/pitch/${pitch.id}/analysis/${analysis.id}` : {}} activeStyle={{ color: 'inherit' }} css={css`color: white;`}>
                            {' / '}
                            {analysisMeta.failed
                                ? 'nonexistant'
                                : analysisMeta.waiting
                                    ? <LoaderText subtle />
                                    : <AnalysisDate
                                        date={analysis.date}
                                        timeZone={pitch?.venue?.timeZone}
                                        css={style.analysisDate}
                                    />
                            }
                        </NavLink>
                        <span css={css`color: ${colors.main1};`}>
                            {' / '}
                            <FormattedMessage id='testDataEntry' />
                        </span>
                    </PageTitle>
                    {gridSizes.length === 0 &&
                        <FallbackMessage>
                            <FormattedMessage id='analysisHasNoGrids' />
                        </FallbackMessage>
                    }
                    <div css={style.wrapper}>
                        <div css={style.gridSelector}>
                            <div wrap css={css`display: flex; flex-wrap: wrap; margin-bottom: 1em;`}>
                                {gridSizes.map(gridSize => (
                                    <button
                                        css={css`padding: 0.5em; font-size: 1.5em;`}
                                        key={getGridString(gridSize)}
                                        onClick={() => { changeGrid(gridSize) }}
                                        type='button'
                                    >
                                        {gridSize.x * gridSize.y}
                                        {' '}
                                        <FormattedMessage id='grid' />
                                    </button>
                                ))}
                            </div>
                            <div css={[style.card, css`margin-bottom: 1em;`]}>
                                <Zones
                                    backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                                    css={css`width: 100%;`}
                                    dimX={gridSize ? gridSize.x : 0}
                                    dimY={gridSize ? gridSize.y : 0}
                                    renderZone={(x, y) => {
                                        const isActive = (x === gridPosition.x && y === gridPosition.y)
                                        const { totalTestAmount, completedTestAmount, hidden } = zonesStats[getGridString({ x, y })]
                                        const isCompleted = (totalTestAmount === completedTestAmount)
                                        if (hidden) return
                                        return (
                                            <div
                                                css={style.zone(isActive)}
                                                onClick={() => { changePosition({ x, y }) }}
                                            >
                                                {isCompleted
                                                    ?
                                                    <span> ✓ </span>
                                                    :
                                                    <ReactSVG
                                                        src={pencilIcon}
                                                        css={style.pencil(isActive)}
                                                    />
                                                }
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                            {pitch &&
                                <Orientation heading={pitch.orientation} />
                            }
                        </div>
                        {(gridSize && gridPosition) &&
                            <div css={[style.card, css`padding: 1em; flex-grow: 1;`]}>
                                <div css={css`display: grid; grid-gap: 1.5rem; border:`}>
                                    {_zoneResultsWithCat.map(({ testCategory, zoneResult }) => (
                                        <div key={zoneResult['@id']}>
                                            <div css={css`display: flex; align-items: center; margin-bottom: 0.25em; gap: 1rem;`}>
                                                <TestCategoryIcon
                                                    css={css`font-size: 2em;`}
                                                    iconName={testCategory.icon}
                                                    color={getTestCategoryGroupColor(testCategory.group)}
                                                />
                                                <h4 css={style.title}>
                                                    {testCategory.name}
                                                </h4>
                                            </div>
                                            <MeasurementPicker
                                                min={testCategory.minRatingValue}
                                                max={testCategory.maxRatingValue}
                                                decimals={testCategory.allowedDecimals}
                                                value={zoneResult.measurement}
                                                onChange={(value) => { updateZoneResultMeasurement(zoneResult['@id'], value) }}
                                                disabled={busy || !zoneResult?.weight}
                                            />
                                        </div>
                                    ))}
                                    {_zoneResultsWithCat.length > 0 &&
                                        <div css={css`display: flex;`}>
                                            {(dirty && !busy) &&
                                                <button
                                                    css={css`flex-grow: 1; padding: 0.5em; font-size: 1.5em;`}
                                                    onClick={setEditableData}
                                                    type='button'
                                                >
                                                    <FormattedMessage id='cancel' />
                                                </button>
                                            }
                                            {(dirty || busy) &&
                                                <button
                                                    css={css`flex-grow: 1; padding: 0.5em; font-size: 1.5em;`}
                                                    onClick={save}
                                                    type='button'
                                                    disabled={busy}
                                                >
                                                    {busy ?
                                                        <FormattedMessage id='saving' /> :
                                                        <FormattedMessage id='save' />
                                                    }
                                                </button>
                                            }
                                            {(!dirty && !busy) &&
                                                <button
                                                    css={css`flex-grow: 1; padding: 0.5em; font-size: 1.5em;`}
                                                    type='button'
                                                    disabled
                                                >
                                                    <FormattedMessage id='unchanged' />
                                                </button>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {warningModalOpened &&
                    <Modal onClickClose={() => { setWarningModalOpened(false) }}>
                        <FormattedMessage id='unsavedChangesWarn' />
                    </Modal>
                }
            </React.Fragment>
    )
}

AnalysisTestEntry.propTypes = {
    onlyShowGroup: PropTypes.string,
}

function getTestGridSize(test) {
    const xPositions = [...test.zoneResults.map(zone => zone.posX), 0]
    const yPositions = [...test.zoneResults.map(zone => zone.posY), 0]
    const x = Math.max(...xPositions)
    const y = Math.max(...yPositions)
    return { x, y }
}

function getGridString(gridSize) {
    const { x, y } = gridSize
    return `${x}x${y}`
}

const style = {
    card: css`
        background-color: ${colors.stuff};
        border-radius: 0.25em;
        padding: 0.5em;
    `,
    title: css`
        display: inline-block;
        font-size: 1.25em;
        line-height: 1.2;
        font-family: ${fonts.special};
        font-weight: 700;
        text-transform: uppercase;
    `,
    zone: isActive => css`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(255,255,255,0.2);
        border-color: ${isActive ? 'white' : ''};
        background-color: ${isActive ? 'rgba(0,0,0,0.5)' : 'transparent'};
        transition: all 200ms ease;
    `,
    pencil: isActive => css`
        height: 60%;
        width: 60%;
        max-height: 2em;
        max-width: 2em;
        > div {
            height: 100%;
            width: 100%;
            svg {
                width: 100%;
                height: 100%;
            }
        }
        ${isActive
            ? 'filter: drop-shadow(0 0 0.25em white);'
            : ''
        }
        transition: all 200ms ease;
    `,
    analysisDate: css`
        font-family: ${fonts.main};
        font-weight: unset;
        font-size: 1em;
    `,
    gridSelector: css`
        width: min(360px, 100%); 
        top: 0.2em;
        padding: 4px;
        z-index: 5;
        border-radius: 5px;
        position: sticky;

        @media screen and (max-width: 1200px) {
            box-shadow: 0 0 2px 2px ${colors.dark};
            border: 2px solid ${colors.eventLight};
            background: ${colors.eventDark};
        }
    `,
    wrapper: css`
        display: flex; 
        gap: 1em; 

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        })
    `,
}