import React, { useContext, useRef } from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from '../../../style/vars'
import AnalyticsContext from '../../../context/Analytics'
import { useEffect } from 'react'
import { useCallback } from 'react'

export default function ParameterSelect({ dashId, parameter, isSecondary, ...props }) {

    const { flexOrder, name, values, selectedValue, conditionalRenderingRules } = parameter

    const { dashboards: { setParameter } } = useContext(AnalyticsContext)

    const style = css`
        height: 32px;
        border-radius: 5px;
        background: ${colors.eventDark};
        font-family: ${fonts.special};
        font-size: 20px;
        color: ${colors.soft};
        order: ${flexOrder};
        cursor: pointer;
        margin: 1px;

        text-align: center;
        display: flex;
        justify-content: center;
        -webkit-appearance: menulist;

        &:hover {
            opacity: 0.8;
        }
    `

    const ref = useRef()

    const hideControls = useCallback(() => {
        if (!conditionalRenderingRules) return
        const rule = conditionalRenderingRules.find(r => r.valEquals === ref.current.value)
        const siblings = [...ref.current.parentNode.childNodes]
        for (let i = 0; i < siblings.length; i++) {
            siblings[i].style.display = 'flex'
            if (rule.hideElementIndices.includes(i)) siblings[i].style.display = 'none'
        }
    }, [])

    useEffect(hideControls, [])

    return (
        <select
            ref={ref}
            defaultValue={selectedValue}
            css={style}
            onChange={(e) => {
                if (conditionalRenderingRules) hideControls()
                setParameter(dashId, name, e.target.value, isSecondary)
            }}
            className='parameterSelect'
            tableau-variable-name={name}
        >
            {values.map(v => {
                return <option
                    key={v}
                    value={v}
                >
                    {v}
                </option>
            })}
        </select>
    )
}