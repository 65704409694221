import log from 'loglevel'

/*
    -> WeatherFlow Client
    -> Function: wrapper for requests to WeatherFlow API
    -> Uses API token
    -> Only performs GET calls
*/

/**
 * Performs a GET call to the configured Weatherflow backend
 * @param {string} resource resource URL (e.g. /observations/device/{device_id})
 * @param {Object.<string, string>} query query parameters, passed as key-value object (e.g. {units_temp: 'f'})
 * @returns {Object} json object of request, if successful
 *
 */
async function get(resource, query = {}) {
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const requestUrl = `${process.env.WEATHERFLOW_URL}${resource}`
    const queryObject = { ...query, token: process.env.WEATHERFLOW_TOKEN, }
    const urlSearchParams = new URLSearchParams(queryObject)
    const urlQuery = `?${urlSearchParams.toString()}`

    let data = {}, error = null

    try {
        log.debug(`GET ${requestUrl}`)
        const response = await fetch(`${requestUrl}${urlQuery}`, options)
        const json = await response.json()

        if (response.ok) {
            data = json
        }
    } catch (e) {
        log.error(e);
        error = e
    }

    return { data, error }
}

export { get }
