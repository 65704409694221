import React from 'react'
import PropTypes, { string } from 'prop-types'
import { css } from '@emotion/react'
import { ReactSVG } from 'react-svg'
import { colors, timings } from '../../style/vars'
import arrowIcon from '../../assets/icons/sort_arrow.svg'

const style = {
    cont: css`
        position: relative;
    `,
    select: css`
        padding: 0.625em 0.875em;
        padding-right: 2em;
        opacity: 0.8;
        background-color: ${colors.stuff};
        color: ${colors.white};
        border: none;
        border-radius: 0.125em;
        font-weight: 300;
        font-size: 0.9375em;
        overflow: hidden;
        appearance: none;
        position: relative;
        width: 100%;
        cursor: pointer;
        transition: all ${timings.fast} ease;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
            + .arrow {
                opacity: 0.5;
            }
            &:hover {
                opacity: 0.5;
                background-color: ${colors.stuff};
                + .arrow {
                    color: ${colors.subtle};
                }
            }
        }

        + .arrow {
            transition: all ${timings.fast} ease;
        }

        &:hover, &:focus {
            opacity: 1;
            background-color: ${colors.solid};
            transition: all ${timings.snappy} ease;
            + .arrow {
                transition: all ${timings.snappy} ease;
                color: ${colors.white};
            }
        }

        &:focus {
            outline-color: ${colors.main1};
        }
    `,
    arrow: css`
        display: block;
        position: absolute;
        right: 0.75em;
        top: 50%;
        transform: translateY(-50%);
        width: 0.5em;
        height: 0.5em;
        pointer-events: none;
        color: ${colors.subtle};
        transition: all ${timings.fast} ease;

        > div, > div > div {
            height: 100%;
            width: 100%;
            line-height: 0;
        }
    `,
}

function Dropdown({
    options = [],
    placeholder,
    style: styleProp,
    className,
    css,
    ...props
}) {
    return (
        <div style={styleProp} className={className} css={[style.cont, css]}>
            <select css={[style.select, { fontWeight: !!props.value ? 'bold' : 'normal' }]} {...props}>
                {placeholder &&
                    <option value='' disabled>{placeholder}</option>
                }
                {options.map(option => (
                    (typeof option == 'string') ?
                        <option key={`${option || ''}`} value={option}>
                            {option}
                        </option>
                        :
                        <option key={`${option.value}${option.label || ''}`} value={option.value}>
                            {option.label || option.value}
                        </option>
                ))}
            </select>
            <div css={style.arrow} className='arrow'>
                <ReactSVG src={arrowIcon} />
            </div>
        </div>
    )
}

Dropdown.propTypes = {
    css: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape({
                value: PropTypes.any,
                label: PropTypes.string,
            })
        ])
    ),
    placeholder: PropTypes.string,
}

export default Dropdown
