
import React, { useState, useCallback, useContext, useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import NumberScroller from '../_control/NumberScroller'
import { css } from '@emotion/react'
import Selector from '../_control/OptionsSelector'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../context/Pitch'

export default function HeatingForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [heatingType, setHeatingtype] = useState()
    const [temperature, setTemperature] = useState()
    const [currentMeterValue, setMeterValue] = useState()
    //other
    const { data: heatingTypeOptions, loading: waitingForTypes } = useBridge(`/api/current/frontend-bridge/heating-types`)
    const { data: temperatureOptions, loading: waitingForTemperatures } = useBridge(`/api/current/frontend-bridge/heatingTemperatures`)
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/heating-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setHeatingtype(data.heatingType)
                setTemperature(data.temperature)
                setMeterValue(data.currentMeterValue)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        //ensure required fields
        if (!heatingType) return setFormError(formatMessage({ id: 'pleaseSelectHeatingType' }))
        if (!temperature) return setFormError(formatMessage({ id: 'pleaseSelectTemp' }))

        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            heatingType,
            temperature,
            currentMeterValue,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Heating event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [heatingType, temperature, currentMeterValue, pitch, selectedDateTime, timeTaken])

    const isBusy = !!(waitingForTypes || waitingForTemperatures || isLoading)

    return (<>
        {isBusy ? <FormLoader /> :
            <div css={css`display: flex; flex-direction: column; width: 100%; align-items: flex-start;`}>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
                <NumberScroller
                    label={formatMessage({ id: 'timeTaken' })}
                    minVal={0}
                    maxVal={24}
                    state={timeTaken}
                    unit={formatMessage({ id: 'hours' })}
                    step={0.5}
                    setState={setTimeTaken}
                />
                <Selector
                    label={formatMessage({ id: 'type' })}
                    optionsObject={heatingTypeOptions}
                    setState={setHeatingtype}
                    state={heatingType}
                />
                <Selector
                    label={formatMessage({ id: 'temperature' })}
                    optionsObject={temperatureOptions}
                    setState={setTemperature}
                    state={temperature}
                />
                <NumberScroller
                    label={formatMessage({ id: 'meterValueOptional' })}
                    minVal={0}
                    maxVal={999999999}
                    setState={setMeterValue}
                    state={currentMeterValue}
                    step={100}
                    isOptional={true}
                />
            </div>
        }
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            type="button"
            onClick={(e) => validateAndSubmit(e)}
            disabled={isBusy}
        >
            <FormattedMessage id='save' />
        </button>
    </>)
}