import { useCallback, useMemo, useState } from 'react'

export default function useField(initialValue = '', checkError) {
    const [value, setValue] = useState(initialValue || '')
    const hasError = useMemo(() => (
        typeof checkError === 'function' ? checkError(value) : false
    ), [value, checkError])

    const handleChange = useCallback((e) => {
        const newValue = e.target.value
        setValue(newValue)
    }, [setValue])

    return [
        {
            value,
            onChange: handleChange,
        },
        hasError,
    ]
}
