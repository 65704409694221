import { useCallback, useState } from 'react'
import log from 'loglevel'
import { MTM_EVENT_TYPES, MTM_VARIABLES } from '../utils/matomo'
import useTracker from './useTracker'

export default function useOptions(initialValue, controlName) {
    const [value, setValue] = useState(initialValue)
    const track = useTracker()

    const onChange = useCallback((e) => {
        const val = e.hasOwnProperty('target') ? e.target.value : e.value
        const label = e.hasOwnProperty('target') ? [...e.target.selectedOptions][0]?.label : e.label
        setValue(val)
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: controlName,
            [MTM_VARIABLES['list-value']]: label ?? val
        })
        log.debug(`selected option: ${val}`)
    }, [setValue, controlName, track])

    return [
        { value, onChange },
        setValue,
    ]
}
