import React, { useCallback, useContext, useState } from 'react'
import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import { colors } from '../../style/vars'
import Auth from '../../context/Auth'
import useField from '../../hooks/useField'
import useChecked from '../../hooks/useChecked'
import AuthBoxLayout from '../../components/_layout/AuthBoxLayout'
import TextField from '../../components/_control/TextField'
import Cta from '../../components/_control/Cta'
import Checkbox from '../../components/_control/Checkbox'
import { FormattedMessage, useIntl } from 'react-intl'

function Login() {
    const { rememberedEmail, logIn, busy } = useContext(Auth)
    const [emailInput, emailError] = useField(rememberedEmail, val => val.length < 5)
    const [passwordInput, passwordError] = useField('', val => val.length < 4)
    const [checked, onChange] = useChecked(!!rememberedEmail)
    const [loginError, setLoginError] = useState(false)

    const formIsNotValid = emailError || passwordError

    const handleLogin = useCallback((e) => {
        e.preventDefault()
        setLoginError(false)

        // clears the tableau cookie by setting an expired date
        document.cookie = "ttime=0; expires=Sun, 20 Aug 2000 12:00:00 UTC; SameSite=Lax; Secure"

        const handleError = () => {
            setLoginError(true)
        }

        logIn(emailInput.value, passwordInput.value, checked, handleError)

    }, [emailInput, passwordInput, checked])

    const { formatMessage } = useIntl()

    return (
        <form onSubmit={handleLogin}>
            <AuthBoxLayout>
                {{
                    title: formatMessage({id: 'login'}),
                    subtitle: formatMessage({id: 'logInToAccessDashboard'}),
                    main: (
                        <div css={css`display: grid;`}>
                            <div css={css`display: grid; border-radius: 0.1875em; overflow: hidden;`}>
                                <TextField
                                    type='email'
                                    css={css`border-bottom: 1px solid #ddd;`}
                                    placeholder={formatMessage({id: 'emailAddress'})}
                                    {...emailInput}
                                />
                                <TextField
                                    type='password'
                                    placeholder={formatMessage({id: 'password'})}
                                    {...passwordInput}
                                />
                                {loginError &&
                                    <div css={css`
                                        margin-top: 0.5em;
                                        width: 100%;
                                        display: grid;
                                        place-items: center;
                                    `}>
                                        <FormattedMessage id='wrongEmailOrPassword' />
                                    </div>
                                }
                            </div>
                            <Cta
                                css={css`align-self: center; min-width: 75%; margin-top: 1.75em;`}
                                disabled={busy || formIsNotValid}
                                loading={busy}
                                type='submit'
                            >
                                <FormattedMessage id='signIn' />
                            </Cta>
                        </div>
                    ),
                    below: (
                        <div css={css`display: flex; 
                            align-items: center;
                            justify-content: space-between; 
                            color: ${colors.liquid}; 
                            font-size: 0.875em; 
                            font-weight: 300;
                            padding: 0 0.3em;
                        `}>
                            <label css={css`display: flex; align-items: center; gap: 0.5em; cursor: pointer; user-select: none;`}>
                                <Checkbox checked={checked} onChange={onChange} />
                                <FormattedMessage id='rememberMe' />
                            </label>

                            <NavLink css={css`color: inherit;`} to='/request-recover'>
                                <FormattedMessage id='forgotPass' />
                            </NavLink>
                        </div>
                    ),
                }}
            </AuthBoxLayout>
        </form>
    )
}

export default Login
