import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import log from 'loglevel'
import LocalStorage from '../services/LocalStorage'
import Raw from '../services/Raw'

const AuthContext = createContext()
export default AuthContext

export function AuthProvider(props) {
    // state
    const [busy, setBusy] = useState(false)
    const [{
        jwt,
        email,
        rememberedEmail,
    }, dispatch] = useReducer(authReducer, getInitialAuthState())

    // LOGIN
    const logIn = useCallback((email, password, remember, handleError) => {
        setBusy(true)
        Raw.getJwt(email, password)
            .then((jwt) => {
                dispatch({
                    type: 'login',
                    payload: {
                        email,
                        jwt,
                        remember,
                    },
                })
            })
            .catch((e) => {
                handleError()
            })
            .finally(() => {
                setBusy(false)
            })
    })

    // LOGOUT
    const logOut = useCallback(() => {
        dispatch({
            type: 'logout',
        })
    })

    // REFRESH TOKEN
    useEffect(() => {
        // refresh token if we already have one and it's about to expire
        if (jwt) {
            try {
                const { iat, exp } = jwtDecode(jwt)

                // if token is expired, log out
                const safetyMargin = 1000 * 60 // in ms
                if (exp * 1000 < Date.now() - safetyMargin) {
                    log.warn('AUTH: current jwt is almost expired, logging out...')
                    logOut()
                    return
                }

                // we want to refresh a jwt when it is halfway between iat and exp
                const shouldRefreshAt = ((iat + exp) / 2) * 1000

                // if shouldRefreshAt is in the past, try to refresh anyway
                const msUntilRefresh = Math.max(shouldRefreshAt - Date.now(), 0)

                const refreshTimeout = setTimeout(() => {
                    log.warn('AUTH: jwt is about to expire, attempting to get a new one...')
                    Raw.getRefreshedJwt(jwt)
                        .then((newJwt) => {
                            log.info('AUTH: refreshed jwt')
                            dispatch({
                                type: 'refresh-jwt',
                                payload: {
                                    jwt: newJwt,
                                },
                            })
                        })
                        .catch(() => {
                            log.warn('AUTH: current jwt is not valid anymore, could not refresh auth')
                            logOut()
                        })
                }, msUntilRefresh)

                return () => {
                    clearTimeout(refreshTimeout)
                }
            } catch (e) {
                log.error(e)
                logOut()
            }
        }
    }, [jwt])

    return (
        <AuthContext.Provider
            value={{
                hasAuth: !!jwt,
                jwt,
                email,
                rememberedEmail,
                busy,
                logIn,
                logOut,
            }}
            {...props}
        />
    )
}


const authLocalStorageKey = 'auth'

function getInitialAuthState() {
    return LocalStorage.get(authLocalStorageKey) || {}
}

// every time this reducer runs (aka: auth state changes),
// we write to localStorage, this way it'll _always_ be in sync with in-mem state
function authReducer(state, { type, payload }) {
    function calcState() {
        switch (type) {
            case 'login':
                return {
                    jwt: payload.jwt,
                    email: payload.email,
                    rememberedEmail: payload.remember ? payload.email : undefined,
                }
            case 'logout':
                return {
                    rememberedEmail: state.rememberedEmail,
                }
            case 'refresh-jwt':
                return {
                    ...state,
                    jwt: payload.jwt,
                }
            default:
                throw new Error('invalid action')
        }
    }
    const newState = calcState()
    LocalStorage.set(authLocalStorageKey, newState)
    return newState
}
