import React, { useMemo, useContext } from 'react'
import { line, curveMonotoneX } from 'd3'
import GenericTimeSeries from './GenericTimeSeries'
import Loader from '../_general/Loader'
import FallbackMessage from '../_general/FallbackMessage'
import { colors } from '../../style/vars'
import { processChartData } from './util'
import ReporterContext from '../../context/Reporter'
import { FormattedMessage } from 'react-intl'

export default function PitchesTestsTimeline({ ...props }) {

    const {
        testCategory1,
        useResultsAsAxis,
        tests1,
        compareData,
        reporterBusy,
        lineColor1,
        lineColor2,
    } = useContext(ReporterContext)

    // massaged data, for displaying
    const testScores = useMemo(() => (processChartData(tests1)), [tests1]);

    const maxRangeTests = useMemo(() => {
        if (tests1 && tests1[0]) {
            return tests1[0].testCategory.maxRatingValue
        }
    }, [tests1]);

    // massaged data, for displaying
    const compareTestScores = useMemo(() => (processChartData(compareData)), [compareData]);

    const maxRangeComparedTests = useMemo(() => {
        if (compareData && compareData[0]) {
            return compareData[0].testCategory.maxRatingValue
        }
    }, [compareData]);

    const minRangeTests = useMemo(() => {
        if (tests1 && tests1[0]) {
            return tests1[0].testCategory.minRatingValue
        }
    }, [tests1]);

    const minRangeComparedTests = useMemo(() => {
        if (compareData && compareData[0]) {
            return compareData[0].testCategory.minRatingValue
        }
    }, [compareData]);

    return (
        <GenericTimeSeries
            {...props}
            yAxisOneMin={minRangeTests}
            yAxisOneMax={maxRangeTests}
            yAxisTwoMin={minRangeComparedTests}
            yAxisTwoMax={maxRangeComparedTests}
            graphStyle={{ opacity: reporterBusy ? 0.25 : 1 }}
            overlay={
                <>
                    {reporterBusy &&
                        <Loader size='16em' />
                    }
                    {!reporterBusy && (
                        !testCategory1
                            ?
                            <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                <FormattedMessage id='selectATestCategory' />
                            </FallbackMessage>
                            :
                            testScores.length === 0 && compareTestScores.length === 0 &&
                            <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                                <FormattedMessage id='noDataAvailable' />
                            </FallbackMessage>
                    )}
                </>
            }
            domainRender={(({ xScale, yScale, yScaleCompared }) => (
                <>
                    {/* line & point */}
                    <g>
                        <path
                            d={
                                line()
                                    .curve(curveMonotoneX)
                                    .x((test) => (xScale(test.timestamp)))
                                    .y((test) => (yScale(useResultsAsAxis ? test.avg : test.score)))
                                    (testScores)
                            }
                            fill='none'
                            stroke={lineColor1}
                            strokeWidth={3}
                        />
                        {testScores?.map(({ id, timestamp, score, avg, rawCertified }) => (
                            <circle
                                key={id}
                                fill={lineColor1}
                                stroke={rawCertified ? colors.main1 : lineColor1}
                                strokeWidth={2}
                                r={4}
                                cx={xScale(timestamp)}
                                cy={yScale(useResultsAsAxis ? avg : score)}
                            />
                        ))}
                    </g>

                    {/* compare line & point */}
                    <g>
                        <path
                            d={
                                line()
                                    .curve(curveMonotoneX)
                                    .x((test) => (xScale(test.timestamp)))
                                    .y((test) => (yScaleCompared(useResultsAsAxis ? test.avg : test.score)))
                                    (compareTestScores)
                            }
                            fill='none'
                            stroke={lineColor2
                            }
                            strokeWidth={3}
                        />
                        {compareTestScores?.map(({ id, timestamp, score, avg, rawCertified }) => (
                            <circle
                                key={id}
                                fill={lineColor2
                                }
                                stroke={rawCertified ? colors.main1 : lineColor2
                                }
                                strokeWidth={2}
                                r={4}
                                cx={xScale(timestamp)}
                                cy={yScaleCompared(useResultsAsAxis ? avg : score)}
                            />
                        ))}
                    </g>
                </>
            ))}
        />
    )
}