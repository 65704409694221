import React from 'react'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'

export default function LoaderBackground() {
    
    const style = {
        grid: css`
            position: relative;
            width: calc(100% -4em);
            height: 100%;
            margin: 2em;
            display: grid;
            grid-template: 1fr 1fr / 13em 1fr;
            grid-gap: 1em;
            overflow: hidden;
            animation: fadeIn 0.2s;

            @keyframes fadeIn {
                0% {opacity: 0;}
                100% {opacity: 0.8;}
            }
        `,
        controls: css`
            grid-area: 1 / 1 / span 2 / span 1;    
            border-radius: 5px;
            background: ${colors.eventDark};
            display: flex;
            flex-direction: column;

            > div {
                width: 90%;
                margin-left: 5%;
                opacity: 0.1;
                background: ${colors.soft};
                border-radius: 5px;
            }
        `,
        graph1: css`
            grid-area: 1 / 2 / span 1 / span 1;    
            border-radius: 5px;
            background: ${colors.eventDark};
            border-radius: 5px;
            display: flex;
            align-items: flex-end;

            > div {
                opacity: 0.1;
                background: ${colors.soft};
                border-radius: 5px;
                width: 5%;
                margin-bottom: 1em;
            }
        `,
        graph2: css`
            grid-area: 2 / 2 / span 1 / span 1;
            transform: scaleX(-1);
        `,
        shimmer: css`
            position: absolute; 
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            background: linear-gradient(100deg,
                rgba(255,255,255,0) 20%,
                rgba(255,255,255,0.07) 50%,
                rgba(255,255,255,0) 80%);

            animation: shimmer 1.5s infinite linear;

            @keyframes shimmer {
                0% {transform: translateX(-300%);}
                100% {transform: translateX(300%);}
            }
        `
    }

    return (
        <div css={style.grid}>
            <div css={style.controls}>
                <div css={css`margin-top: 25%; height: 3em;`} />
                <div css={css`margin-top: 50%; height: 3em;`} />
                <div css={css`margin-top: 20%; height: 3em;`} />
                <div css={css`margin-top: 5%; height: 3em;`} />
                <div css={css`margin-top: 20%; flex-grow: 1;`} />
                <div css={css`margin-top: 20%; height: 3em; margin-bottom: 1em;`} />
            </div>
            <div css={style.graph1}>
                <div css={css`margin-left: 22.5%; height: 50%;`} />
                <div css={css`margin-left: 5%; height: 75%;`} />
                <div css={css`margin-left: 5%; height: 15%;`} />
                <div css={css`margin-left: 15%; height: 50%;`} />
                <div css={css`margin-left: 15%; height: 20%;`} />
            </div>
            <div css={[style.graph1, style.graph2]}>
                <div css={css`margin-left: 22.5%; height: 50%;`} />
                <div css={css`margin-left: 5%; height: 75%;`} />
                <div css={css`margin-left: 5%; height: 15%;`} />
                <div css={css`margin-left: 15%; height: 50%;`} />
                <div css={css`margin-left: 15%; height: 20%;`} />
            </div>
            <div css={style.shimmer}/>
        </div>
    )
}