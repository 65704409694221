import { useState, useRef, useLayoutEffect } from "react"

export default function RenderWhenVisible({ fallback, children, ...props }) {
    const [doRender, setDoRender] = useState(false)
    const wrapperRef = useRef()

    useLayoutEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setDoRender(true)
                } else {
                    setDoRender(false)
                }
            })
        }, { threshold: 0 })

        if (wrapperRef.current) observer.observe(wrapperRef.current)

        return () => {
            if (wrapperRef.current) observer.unobserve(wrapperRef.current)
        }
    }, [])

    return <div {...props} ref={wrapperRef}>
        {doRender ? children : fallback}
    </div>
}