import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors, timings } from '../../style/vars'

const style = {
    card: shy => css`
        display: grid;
        width: 100%;
        position: relative;
        transition: all ${timings.fast} ease;
        outline: none;
        ${shy ? `
            opacity: 0.66;
            .cont {
                background-color: ${colors.stuff};
            }
            .below {
                opacity: 0;
                pointer-events: none;
                transform: translateY(calc(-100% - 2em));
            }
            &:focus, &:hover {
                opacity: 1;
                .cont {
                    transform: translateY(-0.25em);
                    box-shadow: 0 0.5em 1em ${rgba(colors.black, 0.5)};
                    background-color: ${colors.solid};
                }
                .below {
                    opacity: 1;
                    transform: none;
                    pointer-events: all;
                }
            }
            padding-bottom: 1em;
        ` : ''}
    `,
    cont: css`
        display: grid;
        overflow: hidden;
        border-radius: 0.125em;
        flex-grow: 1;
        background-color: ${colors.solid};
        transition: all ${timings.fast} ease;
        z-index: 5;
        box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
    `,
    head: css`
        background-color: ${colors.black};
    `,
    body: css`
        flex-grow: 1;
        display: grid;
        color: ${colors.white};
    `,
    below: css`
        display: flex;
        justify-content: end;
        margin-top: 1em;
        transition: all ${timings.fast} ease;
    `,
}

function DetailCard({ children: { head, body, below }, shy = false, ...props }) {
    return (
        <div tabIndex={0} css={style.card(shy)} {...props}>
            <div css={style.cont} className='cont'>
                {head &&
                    <div css={style.head}>
                        {head}
                    </div>
                }
                {body &&
                    <div css={style.body}>
                        {body}
                    </div>
                }
            </div>
            {below &&
                <div css={style.below} className='below'>
                    {below}
                </div>
            }
        </div>
    )
}

DetailCard.propTypes = {
    children: PropTypes.shape({
        head: PropTypes.node,
        body: PropTypes.node,
        below: PropTypes.node,
    }),
    shy: PropTypes.bool,
}

export default DetailCard
