import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import { css } from '@emotion/react'
import { groupBy } from 'lodash'
import { colors, backendColors } from '../../style/vars'
import Pitch from '../../context/Pitch'
import useRaw from '../../hooks/useRaw'
import PageTitle from '../_general/PageTitle'
import LoaderText from '../_general/LoaderText'
import ScoreIndicator from '../_general/ScoreIndicator'
import PitchCardWithData from '../pitch/PitchCardWithData'
import Table from '../_general/Table'
import AnalysisDate from '../analysis/AnalysisDate'
import TestCategoriesContext from '../../context/TestCategories'
import TestCategoryIcon from '../testCategory/TestCategoryIcon'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'
import BrowsePanel from '../_control/BrowsePanel'
import Cta from '../_control/Cta'
import Modal from '../_layout/Modal'
import TestCategoryCheckbox from '../testCategory/TestCategoryCheckbox'
import { xor } from 'lodash'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import { useIntl, FormattedMessage } from 'react-intl'
import capitalize from 'voca/capitalize'
import TruncatedSummaryText from '../analysis/TruncatedSummaryText'
import ViewPortContext from '../../context/ViewPort'

function getNumberFromPageLink(pageString) {
    if (pageString === undefined) return 1
    return String(pageString)
        ?.match(/page=(?<number>[\d]+)/)
        ?.groups
        ?.number
}

const resultsPerPage = 20

export default function PitchAnalyses({ ...props }) {

    const { formatMessage } = useIntl()
    const [searchParams, setSearchParams] = useSearchParams()

    const pageNum = useMemo(() => {
        return searchParams.get('page') || 1
    }, [searchParams])

    const handlePageChange = useCallback((page) => {
        setSearchParams({ page })
    }, [setSearchParams])

    const [pitch] = useContext(Pitch)

    // handlers
    const getRowHref = useCallback((analysis) => {
        return `/pitch/${pitch.id}/analysis/${analysis.id}`
    }, [pitch])

    // data fetching
    const pitchId = pitch ? pitch.id : undefined
    const [
        {
            'hydra:member': analyses = [],
            'hydra:view': {
                'hydra:first': firstPage,
                'hydra:last': lastPage
            } = {}
        } = {},
        { busy: analysesBusy }
    ] = useRaw(
        `/api/current/pitches/${pitchId}/analyses?limit=${resultsPerPage}&page=${pageNum}`,
        [pitchId, pageNum, resultsPerPage]
    )

    const { data: categoriesData } = useContext(TestCategoriesContext)
    const [columnsModal, setColumnsModal] = useState(false)
    const [selectedColumns, setSelectedColumns] = useState([])

    const handleToggleColumn = useCallback((id) => {
        if (selectedColumns.includes(id)) {
            setSelectedColumns(current => current.filter(entry => entry !== id))
        } else {
            setSelectedColumns(current => [...current, id])
        }
    }, [selectedColumns])

    useEffect(() => {
        let localColumns
        try {
            localColumns = JSON.parse(localStorage.getItem('localColumns'))
        } catch (e) {
            console.log('could not parse saved columns from local storage', e)
        }
        Array.isArray(localColumns) && setSelectedColumns(localColumns)
    }, [])

    useEffect(() => {
        if (selectedColumns.length > 0) localStorage.setItem('localColumns', JSON.stringify(selectedColumns))
    }, [selectedColumns])

    const categoriesToRender = useMemo(() => {
        if (!categoriesData) return []
        return selectedColumns.length === 0 ?
            categoriesData.slice(0, 8)
            : categoriesData.filter(cat => selectedColumns.includes(cat.id))
    }, [categoriesData, pitch, selectedColumns])

    const { showCompactUI } = useContext(ViewPortContext)

    const colums = useMemo(() => {
        const preColums = [
            {
                key: 'date',
                header: formatMessage({ id: 'analysisDate' }),
                renderData: analysis => <AnalysisDate
                    date={Number(analysis.date)}
                    timeZone={pitch?.venue?.timeZone}
                    css={css`white-space: nowrap;`}
                />,
                sortable: true,
            },
            {
                key: 'summaryText',
                header: capitalize(formatMessage({ id: 'summary' }).toLowerCase()),
                renderData: analysis => <TruncatedSummaryText
                    summary={analysis.summary}
                    css={css`padding: 0;`}
                />,
                wide: true,
            },
            {
                key: 'score',
                header: formatMessage({ id: 'score' }),
                renderData: analysis => (
                    <ScoreIndicator
                        value={analysis.score}
                        color={backendColors[analysis.assessment]}
                        css={css`font-size: 1em;`}
                    />
                ),
                sortable: true,
                shrink: true,
            }
        ].filter(col => showCompactUI ? col.key !== 'summaryText' : true)

        let testColums = []
        for (const { id, name, icon } of categoriesToRender) {
            testColums.push({
                key: `${id}-${name}`,
                header: (
                    <div title={name}>
                        <TestCategoryIcon
                            css={css`height: 3.5em; width: 3.5em; margin: 0.5em 0;`}
                            iconName={icon}
                            color={getTestCategoryGroupColor("player")}
                        />
                    </div>
                ),
                renderData: analysis => {
                    const test = analysis.tests.find(test => test.testCategory.id === id)
                    const hasMultipleParameters = test?.parameterResults?.length > 0
                    return (
                        <ScoreIndicator
                            value={hasMultipleParameters ? test?.score?.score : test?.score?.avg ?? null}
                            color={backendColors[test?.score?.assessment?.frontendCode]}
                            decimals={hasMultipleParameters ? 1 : test?.testCategory?.allowedDecimals}
                            css={css`font-size: 1em;`}
                        />
                    )
                },
                sortable: false,
                shrink: true,
            })
        }
        return [...preColums, ...testColums]
    }, [categoriesToRender, showCompactUI])

    const groupedCategories = useMemo(() => {
        const grouped = groupBy(categoriesData, 'group')
        for (const [key, array] of Object.entries(grouped)) {
            grouped[key] = array.toSorted((a, b) => {
                // Compare by weight
                if (a.weight < b.weight) return -1
                if (a.weight > b.weight) return 1
                // If weights are the same, compare ids
                if (a.id < b.id) return -1
                if (a.id > b.id) return 1
                return -1
            })
        }
        return grouped
    }, [categoriesData])

    const track = useTracker()

    const trackCategorySelection = useCallback(() => {
        const catNames = categoriesData?.filter(cat => selectedColumns.includes(cat.id))?.map(cat => cat.name)
        for (const name of catNames) {
            track({
                'event': MTM_EVENT_TYPES['option-select'],
                [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['testCategory-select'],
                [MTM_VARIABLES['list-value']]: name
            })
        }
    }, [selectedColumns, categoriesData])

    return (
        <div css={css`display: grid; height: 100%; grid-template-rows: auto 1fr;`} {...props} >
            <div css={style.header}>
                <PageTitle css={css`margin-bottom: 0;`}>
                    <span>
                        {pitch ?
                            <NavLink
                                css={css`
                                    text-decoration: none; 
                                    color: inherit;
                                    cursor: ${!!pitch.club?.id ? 'pointer' : 'unset'};
                                `}
                                to={!!pitch.club?.id ? `/club/${pitch.club?.id}` : undefined}
                            >
                                {pitch.club?.name || formatMessage({ id: 'noClub' })}
                            </NavLink>
                            :
                            <LoaderText subtle />
                        }
                        {' / '}
                    </span>
                    <span>
                        {pitch
                            ?
                            <NavLink css={css`text-decoration: none; color: inherit;`} to={`/pitch/${pitch.id}`}>
                                {pitch.name}
                            </NavLink>
                            :
                            <LoaderText subtle />
                        }
                    </span>
                    <span css={css`color: ${colors.main1};`}> / <FormattedMessage id='history' /></span>
                </PageTitle>
                <BrowsePanel
                    pageNum={pageNum}
                    setPageNum={handlePageChange}
                    firstPage={getNumberFromPageLink(firstPage)}
                    lastPage={getNumberFromPageLink(lastPage)}
                    busy={analysesBusy}
                />
                <Cta onClick={() => setColumnsModal(true)}>
                    <FormattedMessage id='selectColumns' />
                </Cta>
            </div>
            <div css={css`
                display: flex; 
                flex-grow: 1; 
                gap: 2em;
            `}>
                <PitchCardWithData
                    pitch={pitch}
                    venue={pitch?.venue}
                    hideHistory={true}
                    css={[{
                        width: '18em',
                        minWidth: '18em',
                        height: 'max-content',
                        position: 'sticky',
                        top: '2em',
                        zIndex: 1,
                    },
                    css`
                        @media screen and (max-width: 600px) {
                            display: none;
                        }
                    `
                    ]}
                />
                <div css={css`display: grid; width: 100%;`}>
                    <Table
                        data={analyses}
                        sortOn='date'
                        sortDirection='desc'
                        rowKey={row => (row.id)}
                        getRowHref={getRowHref}
                        loading={analysesBusy}
                        fallback='No analyses yet'
                        columns={colums}
                        css={{ flexGrow: 1 }}
                        stickyHeader={true}
                        headerBg={colors.dark}
                    />
                    {!analysesBusy &&
                        <BrowsePanel
                            pageNum={pageNum}
                            setPageNum={handlePageChange}
                            firstPage={getNumberFromPageLink(firstPage)}
                            lastPage={getNumberFromPageLink(lastPage)}
                            busy={analysesBusy}
                            css={css`margin-top: 1em`}
                        />}
                </div>
            </div>
            {columnsModal && <Modal
                header={
                    <div css={css`display: flex; gap: 1em;`}>
                        <FormattedMessage id='selectYourTests' />
                    </div>
                }
                css={css`width: min(95%, 80em);`}
                onClickClose={() => {
                    trackCategorySelection()
                    setColumnsModal(false)
                }}
            >
                <div css={style.group}>
                    {Object.entries(groupedCategories).map(([groupName, groupData]) => (
                        <div key={groupName} style={{ display: 'flex', flexDirection: 'column' }}>
                            <button
                                onClick={() => {
                                    const groupIds = groupData.map(cat => cat.id)
                                    const exactGroupIdsChecked = xor(groupIds, selectedColumns).length === 0
                                    exactGroupIdsChecked ?
                                        setSelectedColumns([])
                                        : setSelectedColumns(groupData.map(cat => cat.id))
                                }}
                                css={style.groupButton}
                            >
                                <h3 style={{
                                    color: groupName == 'player' ? colors.player : groupName == 'pitch' ? colors.pitch : colors.super, //colors.pitch, 
                                    fontWeight: 'bold',
                                    fontSize: '20px',
                                    whiteSpace: 'nowrap'
                                }} >
                                    {groupName.toUpperCase()}
                                </h3>
                            </button>
                            <div css={style.wrapper}>
                                {groupData.map((testCategory) => (
                                    <TestCategoryCheckbox
                                        key={testCategory.id}
                                        style={{ fontSize: '0.5em' }}
                                        testCategory={testCategory}
                                        checked={selectedColumns.includes(testCategory.id)}
                                        onClick={() => { handleToggleColumn(testCategory.id) }}
                                    />
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>}
        </div>
    )
}

const style = {
    header: css`
        display: flex; 
        flex-wrap: wrap;
        align-items: center; 
        justify-content: space-between; 
        margin-bottom: 2em;
        gap: 2em;
    `,
    group: css`
        display: flex;
        flex-direction: column;
    `,
    wrapper: css`
        display: inline-flex;
        flex-wrap: wrap;
        margin: 0 -8px 1em -8px;
    `,
    groupButton: css`
        width: min-content;
        background: ${colors.eventDark};
        cursor: pointer;
        border: none;
        border-radius: 5px;
        padding: 0.6em;

        &:hover {
            background: ${colors.eventLight};
        }
    `
}