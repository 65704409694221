import React, { createContext, useState, useEffect } from 'react'

const TimezoneContext = createContext()
export default TimezoneContext

const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const localStorageStateName = 'reporterTz'

export function TimezoneProvider(props) {

    const [reporterTz, setReporterTz] = useState(
        localStorage.getItem(localStorageStateName) || browserTimezone
    )

    useEffect(() => {
        localStorage.setItem(localStorageStateName, reporterTz)
    }, [reporterTz])

    return (
        <TimezoneContext.Provider
            value={{
                reporterTz,
                setReporterTz,
                browserTimezone,
            }}
            {...props}
        />
    )
}