import { css } from '@emotion/react'
import { useCallback, useState } from "react"
import { colors } from "../../style/vars"
import Loader from "../_general/Loader"
import Modal from "../_layout/Modal"
import Cta from "./Cta"
import { FormattedMessage } from 'react-intl'

export default function DeleteWarnModal({ header, message, handleClose, handleDelete, ...props }) {

    const [isDeleting, setIsDeleting] = useState(false)

    const handleConfirm = useCallback(async () => {
        setIsDeleting(true)
        await handleDelete()
        setIsDeleting(false)
    }, [handleDelete])

    return (
        <Modal
            header={header}
            onClickClose={handleClose}
            css={css`width: min(90%, 40em);`}
            {...props}
        >
            <div css={style.contentGrid}>
                <div css={style.message}>
                    {isDeleting
                        ?
                        <div css={css`display: flex; align-items: center`}>
                            <Loader size='2em' css={css`margin-right: 1em;`} />
                            <div css={css`opacity: 0.25`}>
                                <FormattedMessage id='deleting' />
                            </div>
                        </div>
                        :
                        <div>
                            {message}
                        </div>
                    }
                </div>

                <div css={css`color: ${colors.warn}; display: grid; place-items: center;`}>
                    <FormattedMessage id='actionCannotBeUndone' />
                    <Cta
                        disabled={isDeleting}
                        onClick={handleConfirm}
                        css={style.deleteCta}>
                        <FormattedMessage id='delete' />
                    </Cta>
                </div>

                <Cta
                    disabled={isDeleting}
                    onClick={handleClose}
                    css={style.cancelCta}
                >
                    <FormattedMessage id='cancel' />
                </Cta>

            </div>
        </Modal>
    )
}

const style = {
    contentGrid: css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1em;
    `,
    message: css`
        grid-area: span 1 / span 2; 
        text-align: justify
    `,
    deleteCta: css`
        color: ${colors.warn};
        border: 2px solid ${colors.warn};
        width: 100%;
    `,
    cancelCta: css`
        border: 2px solid grey; 
        width: 100%;
        align-self: flex-end;
    `,
}