import PropTypes from 'prop-types'
import _ldJsonPropTypes from './_ld-json'


export default (depth = 1) => {
    if (depth >= 0) {
        return PropTypes.shape({
            ..._ldJsonPropTypes,
            label: PropTypes.string,
        })
    }
    return () => null
}
