import React from 'react'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css'

export default function FancyDropdown({ options, hideArrow, openUpwards, ...props }) {

    const fancyDropdownHeight = `${options.length * 3.4}em`

    return (
        <Dropdown
            css={css`
            .Dropdown-option {
                padding-top: 1em;
                padding-bottom: 1em;
                color:  white
            }
            .Dropdown-option:hover {
                color: #30353B;
            }
            .Dropdown-option.is-selected {
                background: transparent;
                color: ${colors.main1};
            }
            .Dropdown-placeholder {
                cursor:pointer;
                color: ${colors.main1};
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
                font-size: 1.125em;
            }
            .Dropdown-arrow {
                top: 50%;
                right: 16px;
                ${hideArrow ? 'display: none;' : ''}
            }
            .Dropdown-control {
                cursor:pointer;
                background: transparent;
                color: white;
                border: none;
            }
            .Dropdown-menu {
                border: none;
                color: white;
                background: #30353B;
                max-height: 50vh;
                height: ${fancyDropdownHeight};
                
                transform: translateY(${openUpwards ? '-100%' : '0'});
            }`}
            options={options}
            {...props}
        />
    )
}