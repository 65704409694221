import React, { useEffect, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { css } from '@emotion/react'
import { ReactSVG } from 'react-svg'
import { colors, fonts, timings } from '../../style/vars'
import logo from '@assets/icons/logo.svg'
import homeIcon from '@assets/icons/nav/home.svg'
import analysisIcon from '@assets/icons/nav/analysis.svg'
import calendarIcon from '@assets/icons/nav/calendar.svg'
import weatherIcon from '@assets/icons/nav/weather.svg'
import pitchReportingIcon from '@assets/icons/nav/pitch_reporting.svg'
import analyticsIcon from '@assets/icons/nav/analytics.svg'
import newAnalysisIcon from '@assets/icons/nav/new_analysis.svg'
import UserBadge from '../user/UserBadge'
import hamburgerIcon from '@assets/icons/nav/hamburger-menu.svg'
import infoIcon from '@assets/icons/nav/help.svg'
import bellIcon from '@assets/icons/nav/notification.svg'
import { useIntl } from 'react-intl'

function MenuBarLink({
    children,
    active,
    name,
    ...props
}) {
    const { formatMessage } = useIntl()

    const formattedName = useMemo(() => {
        if (!name) return
        return formatMessage({ id: name }).toLowerCase()
    }, [name, formatMessage])

    return (
        <NavLink
            css={[style.link, css`
                position: relative;
            `]}
            className={active ? "active" : ""}
            {...props}
        >
            <span css={css`
                position: absolute;
                align-self: center;
                top: 50%;
                right: -20%;
                transform: translate(100%, -50%);
                color: ${colors.soft};
                white-space: nowrap;
                display: ${formattedName ? 'flex' : 'none'};
                padding: 0.2em;
                background: rgba(0,0,0,0.5);
                border-radius: 5px;
                font-family: ${fonts.special};
                text-transform: capitalize;
            `}>
                {formattedName}
            </span>
            {children}
        </NavLink>
    )
}
MenuBarLink.propTypes = {
    children: PropTypes.node.isRequired,
}

function Menu({
    ...props
}) {
    const { pathname } = useLocation()
    const [mobileNav, setMobileNav] = useState(window.innerWidth <= 600)  //switch between regular and mobile nav
    const [mobileNavExpanded, setMobileNavExpanded] = useState(false)  // expand / collapse mobile nav

    const showName = useMemo(() => {
        return mobileNavExpanded && mobileNav
    }, [mobileNavExpanded, mobileNav])

    const handleResize = useCallback(() => {
        if (!(window.innerWidth <= 600)) {
            setMobileNav(false)
        } else {
            setMobileNav(true)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (<>
        <nav css={style.cont(mobileNav, mobileNavExpanded)} {...props}>
            <Link css={style.logo} to='/'>
                <ReactSVG src={logo} />
            </Link>
            <MenuBarLink
                to='/'
                active={pathname === '/' || pathname.includes('/club')}
                name={showName && 'homePageShort'}
            >
                <ReactSVG src={homeIcon} css={style.svg} />
            </MenuBarLink>
            <MenuBarLink to='/analyses' name={showName && 'analyses'}>
                <ReactSVG src={analysisIcon} css={style.svg} />
            </MenuBarLink>
            <MenuBarLink to='/events' name={showName && 'calendar'}>
                <ReactSVG src={calendarIcon} css={style.svg} />
            </MenuBarLink>
            <MenuBarLink to='/weather' name={showName && 'weather'}>
                <ReactSVG src={weatherIcon} css={style.svg} />
            </MenuBarLink>
            <MenuBarLink to='/reporting' name={showName && 'reporting'}>
                <ReactSVG src={pitchReportingIcon} css={style.svg} />
            </MenuBarLink>
            <MenuBarLink to='/analytics' name={showName && 'analytics'}>
                <ReactSVG src={analyticsIcon} css={style.svg} />
            </MenuBarLink>
            <MenuBarLink to='/create-analysis' name={showName && 'createNewAnalysis'}>
                <ReactSVG src={newAnalysisIcon} css={style.svg} className='highlighted' />
            </MenuBarLink>
            <div css={style.notification}>
                <button data-sleek data-badge-changelog>
                    <ReactSVG src={bellIcon} css={style.svg} />
                </button>
            </div>
            <div css={style.help}>
                <a href="https://playingsurface.rawstadia.com" target="_blank" >
                    <button>
                        <ReactSVG src={infoIcon} css={style.svg} />
                    </button>
                </a>
            </div>
            <MenuBarLink css={[style.accountLink, css`font-size: 1.8em;`]} to='/account' name={showName && 'account'}>
                <UserBadge />
            </MenuBarLink>
        </nav>
        {(mobileNav && !mobileNavExpanded) && <button
            onClick={() => setMobileNavExpanded(prev => !prev)}

            css={css`
                position: fixed;
                bottom: 12px;
                left: 0;
                z-index: 10002;
                width: 46px;
                height: 46px;
                background: ${colors.black};
                border: none;
                border: 2px solid ${colors.eventDark};
                border-left: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                color: ${colors.eventDark};
                cursor: pointer;
                color: white;
                opacity: 0.85;
                display: grid;
                place-items: center;

                &:hover {
                    background: ${colors.eventDark};
                    color: ${colors.eventLight};
                }
            `}>
            <ReactSVG src={hamburgerIcon} css={css`fill: white; width: 2em; height: 2em;`} />
        </button>}
        {(mobileNav && mobileNavExpanded) && <div
            onClick={() => setMobileNavExpanded(false)}
            css={css`
                position: fixed;
                height: 100%;
                width: 100%;
                background: black;
                z-index: 10000;
                opacity: 0.75;
            `}
        />}
    </>)
}


const style = {
    cont: (mobileNav, mobileNavExpanded) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: ${colors.black};
        width: 4em;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        color white;
        position: relative;
        transition: transform ${timings.smooth};

        @media screen and (max-width: 600px) {
            transform: ${mobileNavExpanded ? 'translate(0,0)' : 'translate(-150%, 0)'};
            ${mobileNav ? " position: fixed; left: 0; z-index: 10001; " : ""}
        }
    `,
    logo: css`
        width: 50%;
        place-self: center;
        margin: 1.5em 0;
        color: ${colors.white};
        border-bottom: ${process.env.API_URL.includes('staging') ? '6px solid orange' :
            process.env.API_URL.includes('dev') ? '6px solid purple'
                : 'none'
        }
    };
        transition: all 128ms ease;
        &:hover {
            transition: all 64ms ease;
            color: ${colors.main1};
        }
    `,
    link: css`
        font-size: 2em;
        color: ${colors.white};
        fill: ${colors.white};
        aspect-ratio: 1;
        min-height: 2em;
        text-align: center;
        transition: all ${timings.fast} ease;
        position: relative;
        z-index: 1;

        > div {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .highlighted {
            color: ${colors.main1};
            fill: ${colors.main1};
        }

        &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            bottom: 0;
            left: 0;
            right: -0.25em;
            background-color: ${colors.main1};
            z-index: -1;
            border-radius: 0 0.25em 0.25em 0;
            transform: translateX(-100%);
            transition: transform ${timings.smooth} ease;
        }

        &:hover {
            background: ${colors.dark};
        }

        &.active {
            cursor: default;
            pointer-events: none;
            color: ${colors.black};
            fill: ${colors.black};
            &::before {
                transform: none;
            }

            &::after {
                opacity: 0;
            }

            // some special overrides for certain SVGs
            svg {
                .extra {
                    fill: transparent;
                    stroke: ${colors.black};
                }
            }
            .highlighted {
                color:${colors.black};
                fill:${colors.black};
            }
        }

        // some special overrides for certain SVGs
        svg {
            .extra {
                transition: all ${timings.fast} ease;
            }
        }
    `,
    svg: css`
        width: 50%;
        height: auto;
        svg {
            display: block;
        }
    `,
    help: css`
        display: grid;
        margin-top: auto;
        min-height: 2em;
        max-height: 2em;
        height: 2em;
        place-items: center;
        margin: 0.4em 0;
    
        > a { 
            height: 100%;
            padding: 0;
            display: grid;
            place-items: center;

            > button {
                height: 85%;
                padding: 0;
                border: none;
                cursor: pointer;
                aspect-ratio: 1;
                background: none;

                > * {
                    width: 100%;
                    border-radius: 100%;
                    transition: background,fill ${timings.smooth} ease;
                    fill: ${colors.soft};
                    
                    &:hover {
                        fill: ${colors.white};
                        background: ${colors.dark};
                    }
                }
            }
        }
    `,
    notification: css`
        display: grid;
        margin-top: auto;
        margin-bottom: 0.3em;
        min-height: 2em;
        max-height: 2em;
        height: 2em;
        place-items: center;
        
        > button { 
            border: none;
            padding: 0;
            cursor: pointer;
            border-radius: 100%;
            height: 85%;
            aspect-ratio: 1;
            background: none;
            transition: background,color ${timings.smooth} ease;
            color: ${colors.soft};
            
            &:hover {
                color: ${colors.white};
                background: ${colors.dark};
            }

            > * {
                width: 100%;
            }
        }
`,
}

export default Menu