import { css } from '@emotion/react'
import { formatDistanceToNow, fromUnixTime } from "date-fns"
import { groupBy } from "lodash"
import React, { useMemo, useContext } from "react"
import { NavLink } from "react-router-dom"
import useRawApiState from "../../hooks/useRawApiState"
import { colors } from "../../style/vars"
import RcpMark from "../analysis/RcpMark"
import CompactGroupedTests from "../test/CompactGroupedTests"
import Loader from "../_general/Loader"
import LoaderText from "../_general/LoaderText"
import { useIntl } from 'react-intl'
import LocaleContext from '../../context/Locale'

export default function LastAnalysisSection({ pitch, ...props }) {
    const { formatMessage } = useIntl()

    const latestAnalysis = useMemo(() => (
        pitch?.latestAnalysis || undefined
    ), [pitch])

    const { data: latestAnalysisDetails, isBusy: loadingLatestAnalysis } = useRawApiState(
        `${latestAnalysis?.['@id']}`,
        {},
        [latestAnalysis]
    )

    const { datefnsLocale } = useContext(LocaleContext)

    const latestAnalysisLink = useMemo(() => {
        if (pitch && latestAnalysis) {
            const analysisId = latestAnalysis?.['@id']?.split('/').pop()
            return `/pitch/${pitch.id}/analysis/${analysisId}`
        }
        return
    }, [pitch, latestAnalysis])

    const latestDate = useMemo(() => { // when undefined renders loader
        if (!pitch) return
        if (!latestAnalysis) return formatMessage({id: 'never'})
        if (loadingLatestAnalysis) return
        if (!latestAnalysisDetails) return formatMessage({id: 'errorNotFound'})
        return formatDistanceToNow(fromUnixTime(latestAnalysisDetails.date), { addSuffix: true, locale: datefnsLocale })
    }, [latestAnalysisDetails, pitch, loadingLatestAnalysis, datefnsLocale])

    const groupedTests = useMemo(() => {
        if (!latestAnalysisDetails?.tests) return
        const testData = latestAnalysisDetails?.tests?.map(test => {
            const hasMultipleParameters = test.parameterResults?.length > 0
            return {
                avg: hasMultipleParameters ? test.score?.score : test.score?.avg ?? null,
                name: test.testCategory?.name,
                weight: test.testCategory?.weight,
                color: test.score.assessment?.frontendCode,
                decimals: hasMultipleParameters ? 1 : test.testCategory?.allowedDecimals,
                icon: test.testCategory?.icon,
                group: test.testCategory?.group === 'player' || test.testCategory?.group === 'pitch'
                    ? test.testCategory.group
                    : 'other'
            }
        })
        testData.sort((a, b) => a.weight - b.weight)
        return groupBy(testData, 'group')
    }, [latestAnalysisDetails])


    return (
        <>
            <div css={css`
                display: flex;
                gap: 0.5em;
                align-items: center;
                justify-content: space-between;
    
                > span {
                    color: ${colors.light}; 
                }
            `}>
                <span>
                    {`${formatMessage({ id: 'lastAnalysis' })}: `}
                    {latestDate ? latestDate : <LoaderText subtle />}
                </span>
                {latestAnalysisDetails?.rawCertified && <RcpMark />}
            </div>

            {(loadingLatestAnalysis || !pitch) ? <Loader size={'25px'} /> :
                (groupedTests && latestAnalysisLink) &&
                <NavLink to={latestAnalysisLink}>
                    <CompactGroupedTests groupedTests={groupedTests} />
                </NavLink>
            }
        </>
    )
}