import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'

function EZGrid({
    w = '16rem',
    gap = '1rem',
    ...props
}) {
    return (
        <div
            css={css`
                display: grid;
                grid-gap: ${gap};
                grid-template-columns: repeat(auto-fill, minmax(${w}, 1fr));
            `}
            {...props}
        />
    )
}

EZGrid.propTypes = {
    w: PropTypes.string.isRequired,
    gap: PropTypes.string.isRequired,
}

export default EZGrid
