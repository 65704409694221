import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import arrowIcon from '../../assets/icons/sort_arrow.svg'
import {ReactSVG} from 'react-svg'
import { css } from '@emotion/react'

function Collapse({
    data = [],
    defaultOpen,
    ...props
}) {

    const style = {
        collapse: css`
            background-color: #2E3338;
            border: 2px solid #394047;
            margin-bottom: -2px;

            & :first-of-type {
                border-top-right-radius: .25em;
                border-top-left-radius: .25em;
            }

            & :last-of-type {
                border-bottom-right-radius: .25em;
                border-bottom-left-radius: .25em;
            }
        `,
    }

    const [open, setOpen] = useState(!!defaultOpen)

    return (
        <div className="collapse" {...props} css={style.collapse}>
            <div className="collapse--item">
                <div className="collapse--header" style={{
                    display: 'flex',
                    position: 'relative',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: '5px',
                    padding: '3px 20px',
                    cursor: 'pointer',
                }} onClick={() => setOpen(!open)}>
                    {data[0].header}
                    <div style={{
                        width: '10px',
                        height: '10px',
                        color: '#74FAC8',
                        transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}>
                        <ReactSVG src={arrowIcon} />
                    </div>
                </div>

                {
                    open ? (
                        <div>
                            {
                                data.map((item, key) => {
                                    return (
                                        <div key={key} className="collapse--row" style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            padding: '10px 10px',
                                            borderTop: '2px solid  #394047',
                                            backgroundColor: '#1D2326',
                                            color: "#6D7884",
                                        }}>
                                            {item.content}
                                            {item.actions}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ) : null
                }
            </div>
        </div >
    )
}

Collapse.propTypes = {
    defaultOpen: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.any,
            content: PropTypes.any,
            actions: PropTypes.any
        })
    ),
}

export default Collapse
