import { createContext, useEffect, useState } from "react"


const PreferredViewContext = createContext()
export default PreferredViewContext

// keep these the same as the corresponding translation keys
const PREFERRED_VIEW_TYPES = {
    'performance': 'performance',
    'greenkeeping': 'greenkeeping',
}

export function PreferredViewContextProvider({ ...props }) {

    const [preferredView, setPreferredView] = useState(getInitState())

    useEffect(() => {
        localStorage.setItem('preferred-view-type', preferredView)
    }, [preferredView])

    return <PreferredViewContext.Provider
        value={{
            preferredView,
            setPreferredView,
            PREFERRED_VIEW_TYPES,
        }}
        {...props}
    />
}

function getInitState() {
    const localData = localStorage.getItem('preferred-view-type')
    if (Object.keys(PREFERRED_VIEW_TYPES).includes(localData)) {
        return localData
    } else {
        return PREFERRED_VIEW_TYPES.performance
    }
}