import React, { createContext } from 'react'
import useRawApiState from '../hooks/useRawApiState'

const TestCategoryGroupsContext = createContext()

function TestCategoryGroupsProvider({
    ...props
}) {
    const v = useRawApiState('/api/current/test-category-groups')
    return (
        <TestCategoryGroupsContext.Provider
            {...props}
            value={v}
        />
    )
}

export default TestCategoryGroupsContext
export { TestCategoryGroupsProvider }
