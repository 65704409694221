import React, { useEffect } from 'react'
import Loader from "../_general/Loader"
import { css } from '@emotion/react'
import useRawV2 from '../../hooks/useRawV2'
import { useState } from 'react'

const sessionMinutes = 10
const timeoutCookieName = "ttime"

const server = process.env.TABLEAU_URL
const site = ""

const authViewUrlPart = "PlayerRLS2/authenticatorDash"

const STATUS = {
    invalid: 'No session',
    valid: 'Session is valid',
    awaitTicket: 'Awaiting ticket...',
    noTicket: 'Ticket fetch failed',
    awaitRedemption: 'Awaiting ticket redemption at analytics server...',
    redemptionFailed: 'Redemption attempt not ok'
}

export default function TableauAuth({ setAuthOk, ...props }) {

    const [currentStatus, setCurrentStatus] = useState(isAuthExpired() ? STATUS.invalid : STATUS.valid)

    useEffect(() => {
        // keep track of current status
        console.log('STATUS: ', currentStatus)
        // if we have a session, let parent know stuff is ready to be loaded
        if (currentStatus === STATUS.valid) setAuthOk(true)
        if (currentStatus === STATUS.noTicket || currentStatus === STATUS.redemptionFailed) setAuthOk('error')
        // periodically check if a valid session has become expired, repeat auth flow if necessary
        function renewIfExpired() {
            if (isAuthExpired() && currentStatus === STATUS.valid) {
                setCurrentStatus(STATUS.invalid)
            }
        }

        const reChecker = setInterval(renewIfExpired, 5000)
        //cleanup interval
        return () => clearInterval(reChecker)
    }, [currentStatus])

    // only retrieve a ticket if we don't have a session already
    const [ticket, { failed }] = useRawV2(currentStatus !== STATUS.valid && '/api/current/tableau')

    useEffect(() => {
        // if ticket fetch fails, we can't proceed
        if (failed) setCurrentStatus(STATUS.noTicket)
    }, [failed])

    useEffect(() => {
        // don't do anything if we already have a session
        if (currentStatus === STATUS.valid) return
        // wait on our ticket if we don't have one yet
        if (!ticket) return setCurrentStatus(STATUS.awaitTicket)
        // when we have the ticket, try to redeem it
        setCurrentStatus(STATUS.awaitRedemption)
        ticketRedemption()
    }, [ticket])

    async function ticketRedemption() {
        // see if our ticket works for /trusted/ (Trusted Authentication) routes 
        try {
            await imgLoadPromise(`${server}/trusted/${ticket.token}${site && ("/t/" + site)}/views/${authViewUrlPart}.png`)
            // if ok we should have a session for x minutes, set cookie to keep track
            setTimeoutCookie()
            setCurrentStatus(STATUS.valid)
        } catch (e) {
            // handle errors
            console.error(e)
            setCurrentStatus(STATUS.redemptionFailed)
        }
    }

    return (
        <div
            css={css`
            display: flex;
            gap: 1em;   
            opacity: 0.25;
        `}
            {...props}
        >
            {(currentStatus === STATUS.awaitTicket || currentStatus === STATUS.awaitRedemption) &&
                <Loader />
            }
            {currentStatus !== STATUS.valid &&
                <p>{currentStatus}</p>
            }
        </div>
    )
}

function setTimeoutCookie() {
    const d = new Date()
    d.setTime(d.getTime() + sessionMinutes * 60 * 1000)
    document.cookie = `${timeoutCookieName}=${d.valueOf()};path=/;SameSite=Lax;Secure`
}

function getTimeoutCookie() {
    const cookies = decodeURIComponent(document.cookie).split(";")
    for (const cookie of cookies) {
        if (cookie.includes(timeoutCookieName)) return cookie.split("=")[1]
    }
}

function isAuthExpired() {
    const ttime = getTimeoutCookie()
    if (!ttime) return true
    const currentTime = Date.now()
    const cookieTime = parseInt(ttime, 10)
    return currentTime > cookieTime
}

function imgLoadPromise(url) {
    return new Promise((resolve, reject) => {
        const redemptionImg = new Image()
        redemptionImg.onload = () => resolve("Image redemption succeeded!")
        redemptionImg.onerror = () => reject(new Error("Image redemption failed"))
        // Actually load the image here
        redemptionImg.src = url
    })
}