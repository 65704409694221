import React, { useCallback, useState, useRef } from 'react'
import { css } from '@emotion/react'
import Cta from '../../components/_control/Cta'
import { colors } from '../../style/vars'
import { useEffect } from 'react'
import { MTM_BROWSE_ACTIONS, MTM_EVENT_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'

export default function BrowsePanel({ pageNum, setPageNum, firstPage, lastPage, busy, ...panelProps }) {

    const track = useTracker()

    const pageIncrement = useCallback(() => {
        let targetNum = Number(pageNum) + 1
        if (targetNum > lastPage) targetNum = 1

        track({
            'event': MTM_EVENT_TYPES['browse-pagination'],
            [MTM_VARIABLES['browse-action']]: MTM_BROWSE_ACTIONS.increment,
            [MTM_VARIABLES['browse-destination']]: targetNum
        })

        setPageNum(targetNum)
        setFormActive(false)
    }, [pageNum, firstPage, lastPage, setPageNum])

    const pageDecrement = useCallback(() => {
        let targetNum = Number(pageNum) - 1
        if (targetNum < 1) targetNum = lastPage

        track({
            'event': MTM_EVENT_TYPES['browse-pagination'],
            [MTM_VARIABLES['browse-action']]: MTM_BROWSE_ACTIONS.decrement,
            [MTM_VARIABLES['browse-destination']]: targetNum
        })

        setPageNum(targetNum)
        setFormActive(false)
    }, [pageNum, firstPage, lastPage, setPageNum])

    const pageJump = useCallback((targetNum) => {
        track({
            'event': MTM_EVENT_TYPES['browse-pagination'],
            [MTM_VARIABLES['browse-action']]: MTM_BROWSE_ACTIONS.jump,
            [MTM_VARIABLES['browse-destination']]: targetNum
        })

        setPageNum(targetNum)
        setFormActive(false)
    }, [pageNum, firstPage, lastPage, setPageNum])

    const [formActive, setFormActive] = useState(false)

    const inputRef = useRef()

    useEffect(() => {
        if (formActive) inputRef.current?.focus()
    }, [formActive])

    return (<div css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            position: relative;
            font-size: 1em;
            @media(max-width: 600px) {
                gap: .5em;
            }
        `}
        {...panelProps}
    >
        <Cta
            className='cta'
            onClick={pageDecrement}
            disabled={busy || lastPage === 1}
        >
            {'<'}
        </Cta>
        <div css={style.middle} onClick={() => setFormActive(current => !current)} >
            {formActive &&
                <form onSubmit={(e) => {
                    e.preventDefault()
                    const num = Number(e.target.firstChild.value)
                    if (num && num >= firstPage && num <= lastPage) pageJump(num)
                    setFormActive(false)
                }}
                    css={style.form}
                >
                    <input type="number" ref={inputRef} onBlur={() => setFormActive(false)} />
                </form>}

            <b css={formActive && css`visibility: hidden`}>
                {pageNum} {!busy ? `/ ${lastPage}` : `/ ...`}
            </b>
        </div>
        <Cta
            className='cta'
            onClick={pageIncrement}
            disabled={busy || lastPage === 1}
        >
            {'>'}
        </Cta>
    </div >
    )
}

const style = {
    middle: css`
    cursor: default;
    margin: 0.8em 2em;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;

    @media(max-width: 600px) {
        display: none;
    }

    &:hover {
        color: ${colors.light};
    }
    overflow: hidden;
`,
    form: css`
    input {
        position: absolute;
        width: 4em;
        font-size: 1.2em;
        background: none;
        color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }
`
}