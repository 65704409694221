import React from 'react'
import { css } from '@emotion/react'
import TestResultCard from './TestResultCard'

export default function TestHistory({historyData, test, ...props }) {

    return (
        <div
            css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill,minmax(${test?.parameterResults?.length ? '30em' : '24em'}, 1fr));
            grid-row-gap: 2em;
            grid-column-gap: 2em;
        `}>
            {historyData && historyData.map(test => (
                <TestResultCard
                    key={test.id}
                    test={test}
                    showAnalysisDate
                    noNavigate
                />
            ))}
        </div>
    )
}