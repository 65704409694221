import { useCallback, useEffect, useMemo, useState } from 'react'
import log from 'loglevel'
import useLdjsonApi from './useLdjsonApi'

// re-simplified version of useRawV2
export default function useRawApiState(
    resource,
    query = {},
    dependencies = []
) {
    if (!Array.isArray(dependencies)) {
        throw new Error('received dependencies were not an array')
    }

    const { get, post } = useLdjsonApi()

    const [data, setData] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [isFetching, setIsFetching] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    const isBusy = useMemo(() => isFetching || isUpdating, [isFetching, isUpdating])

    const fetch = useCallback(async () => {
        if (resource === undefined) return
        try {
            setIsFetching(true)
            const { data, error } = await get(resource, { query })
            setData(data)
            setError(error)
        } catch (err) {
            log.error(err)
            setData(undefined)
        } finally {
            setIsFetching(false)
        }
    }, [resource, get, ...Object.keys(query), ...Object.values(query)])

    const update = useCallback(async (newProperties) => {
       if (resource === undefined) return
        try {
            setIsUpdating(true)
            const { data, error } = await post(resource, { query, body: newProperties })
            setData(data)
            setError(error)
        } catch (err) {
            log.error(err)
            setData(undefined)
        } finally {
            setIsUpdating(false)
        }
    }, [resource, post, query])

    useEffect(() => {
        if (!dependencies.includes(undefined)) {
            fetch()
        }
        else {
            setIsFetching(false);
            setIsUpdating(false);
            setData(undefined)
            setError(undefined)
        }
    }, [fetch, ...dependencies])

    return {
        data,
        error,
        fetch,
        update,
        isBusy,
        isFetching,
        isUpdating,
    }
}
