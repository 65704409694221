import React, { createContext, useContext } from 'react'
import PropTypes, { number, string } from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { get } from 'lodash'
import useRaw from '../hooks/useRawV2'
import Pitch from './Pitch'
import FallbackMessage from '../components/_general/FallbackMessage'
import { FormattedMessage } from 'react-intl'
import { colors } from '../style/vars'

const AnalysisContext = createContext()
export default AnalysisContext

export function RouteBasedAnalysisProvider({ children }) {

    const { analysisId } = useParams()

    return (
        <AnalysisProvider analysisId={analysisId} children={children} />
    )
}

export function AnalysisProvider({ analysisId, ...props }) {
    const [pitch] = useContext(Pitch)
    const analysisResult = useRaw(`/api/current/surface-analyses/${analysisId}`, [analysisId])

    // enforce a matching pitch for the current analysis
    if ((pitch && analysisResult[0]) && get(pitch, '@id') !== get(analysisResult, [0, 'pitch', '@id'])) {
        return (
            <div>
                <FallbackMessage>
                    <FormattedMessage id='analysisDoesntBelongWarn'
                        values={{
                            pitchId: get(pitch, 'id')
                        }}
                    />
                    <br />
                    <Link to='/' style={{ color: colors.main1, textDecoration: 'underline' }}>
                        <FormattedMessage id='returnToHomePage' />
                    </Link>
                </FallbackMessage>
            </div>
        )
    }

    return (
        <AnalysisContext.Provider
            value={analysisResult}
            {...props}
        />
    )
}

AnalysisProvider.propTypes = {
    analysisId: PropTypes.oneOfType([number, string]),
}

