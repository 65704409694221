import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { rgba, } from 'polished'
import { colors, timings } from '../../style/vars'

const typeColorMap = {
    'positive': {
        color: colors.main1,
        colorHover: colors.white,
    },
    'subtle': {
        color: rgba(colors.white, 0.9),
        colorHover: colors.white,
    },
    'admin': {
        color: colors.super,
        colorHover: colors.white,
    },
    'warn': {
        color: colors.warn,
        colorHover: colors.white,
    },
}

const style = (signal) => {
    const c = typeColorMap[signal] ?? typeColorMap.subtle;
    return css`
        display: inline-flex;
        align-items: center;
        color: ${c.color};
        transition: all ${timings.fast} ease;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0.75em 1.5em;
        border-radius: 1.25rem;
        font-size: 0.825em;
        font-weight: 300;
        position: relative;
        background-color: ${rgba(colors.white, 0.05)};
        cursor: pointer;
        &:hover&:not([disabled]) {
            transition: all ${timings.snappy} ease;
            color: ${c.colorHover};
            background-color: ${rgba(colors.white, 0.15)};
        }
        &[disabled] {
            opacity: 0.4;
            cursor: not-allowed;
        }
    `;
}

export default function CtaLink({ signal = 'subtle', disabled, onClick, ...props }) {
    return (
        <span
            css={style(signal)}
            disabled={disabled}
            onClick={() => !disabled && onClick?.()}
            {...props}
        />
    )
}

CtaLink.propTypes = {
    signal: PropTypes.oneOf(Object.keys(typeColorMap)),
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
}