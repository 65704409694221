import { convert } from "html-to-text"
import { useMemo } from "react"
import { css } from "@emotion/react"
import { colors } from "../../style/vars"

// turns the summary markup into truncatable text, for a short preview
// or short messages 
const options = { wordwrap: false }

export default function TruncatedSummaryText({ summary, showFullSummary = false, ...props }) {

    const text = useMemo(() => {
        const fullText = convert(summary, options)
        if (showFullSummary) return fullText

        const hasMultipleParagraphs = fullText.includes("\n")
        // Split by paragraphs and return the first one
        const firstParagraph = fullText.split("\n").find(p => p.trim().length > 0)

        return (hasMultipleParagraphs ? (firstParagraph + ' ...') : firstParagraph) || ''

    }, [summary, showFullSummary])

    return <div css={style.wrapper} {...props}>
        <div css={style.content}>
            {text}
        </div>
    </div>
}

const style = {
    wrapper: css`
        position: relative;
        width: 100%;
        height: 100%;
    `,
    content: css`
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        width: 100%;
        max-height: 3.6em;
        color: ${colors.light};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    `,
}