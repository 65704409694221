import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import {ReactSVG} from 'react-svg'
import { colors, timings } from '../../style/vars'
import ballBounceIcon from '../../assets/icons/tests/ball_bounce.svg'
import ballRollIcon from '../../assets/icons/tests/ball_roll.svg'
import densityIcon from '../../assets/icons/tests/density.svg'
import electricalConductivityIcon from '../../assets/icons/tests/electrical_conduction.svg'
import energyRestitutionIcon from '../../assets/icons/tests/energy_restitution.svg'
import grassHeightIcon from '../../assets/icons/tests/grass_height.svg'
import hardnessIcon from '../../assets/icons/tests/hardness.svg'
import leafTissueIcon from '../../assets/icons/tests/leaf_tissue.svg'
import infiltration1Icon from '../../assets/icons/tests/infiltration.svg'
import moistureIcon from '../../assets/icons/tests/moisture.svg'
import nematodeAnalysisIcon from '../../assets/icons/tests/nematode_analysis.svg'
import nutrientAnalysisIcon from '../../assets/icons/tests/nutrient_analysis.svg'
import penetrationIcon from '../../assets/icons/tests/penetration.svg'
import phIcon from '../../assets/icons/tests/ph.svg'
import rootDepthIcon from '../../assets/icons/tests/root_depth.svg'
import rotationalTractionIcon from '../../assets/icons/tests/rotational_traction.svg'
import shockAbsorptionIcon from '../../assets/icons/tests/shock_absorption.svg'
import surfaceDeformationIcon from '../../assets/icons/tests/surface_deformation.svg'
import springRateIcon from '../../assets/icons/tests/spring_rate.svg'
import studForceIcon from '../../assets/icons/tests/stud_force.svg'
import thatchDepthIcon from '../../assets/icons/tests/thatch_depth.svg'
import surfaceAnalysisIcon from '../../assets/icons/tests/surface_analysis.svg'
import sunIcon from '../../assets/icons/tests/sun.svg'
import torqueAtTenIcon from '../../assets/icons/tests/torqueAtTen.svg'
import rotationalStiffnessIcon from '../../assets/icons/tests/rotationalStiffness.svg'

const style = color => css`
    width: 1em;
    height: 1em;
    color: ${color};
    fill: ${color};
    transition: all ${timings.fast} ease;

    > div {
        height: 100%;
        width: 100%;
        position: relative;

        > svg {
            height: 100%;
            max-height: 100%;
            width: 100%;
            max-width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
`

const apiIconSvgMap = {
    ball_bounce: ballBounceIcon,
    ball_roll: ballRollIcon,
    density: densityIcon,
    electrical_conductivity: electricalConductivityIcon,
    energy_restitution: energyRestitutionIcon,
    grass_height: grassHeightIcon,
    hardness: hardnessIcon,
    infiltration: infiltration1Icon,
    leaf_tissue: leafTissueIcon,
    moisture: moistureIcon,
    nematode_analysis: nematodeAnalysisIcon,
    nutrient_analysis: nutrientAnalysisIcon,
    penetration: penetrationIcon,
    ph: phIcon,
    root_depth: rootDepthIcon,
    rotational_traction: rotationalTractionIcon,
    shock_absorption: shockAbsorptionIcon,
    spring_rate: springRateIcon,
    stud_force: studForceIcon,
    sun: sunIcon,
    surface_analysis: surfaceAnalysisIcon,
    surface_deformation: surfaceDeformationIcon,
    thatch_depth: thatchDepthIcon,
    torque_at_ten: torqueAtTenIcon,
    rotational_stiffness: rotationalStiffnessIcon,
    vial: phIcon,
    [undefined]: phIcon
}

export const testCategoryIconValues = Object.keys(apiIconSvgMap)

function TestCategoryIcon({ iconName, color = colors.main1, ...props }) {
    return (
        <ReactSVG css={style(color)} src={apiIconSvgMap[iconName] ? apiIconSvgMap[iconName] : phIcon} {...props} />
    )
}

TestCategoryIcon.propTypes = {
    color: PropTypes.string,
}

export default TestCategoryIcon
