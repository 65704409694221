import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import Assessment from './Assessment'
import Equipment from './Equipment'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            audienceLevel: PropTypes.string,
            assessment: Assessment(newDepth),
            instruction: PropTypes.string,
            tools: Equipment(newDepth),
        })
    }
    return () => null
}
