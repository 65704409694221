import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import Pitch from './Pitch'
import Test from './Test'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            tests: PropTypes.arrayOf(Test(newDepth)),
            numberOfTests: PropTypes.number,
            date: PropTypes.number,
            pitch: Pitch(newDepth),
            rawCertified: PropTypes.bool,
            summary: PropTypes.string,
        })
    }
    return () => null
}
