import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import Assessment from './Assessment'

export default (depth = 1) => {
    if (depth > 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            assessment: Assessment(newDepth),
            score: PropTypes.number,
        })
    }
    return () => null
}
