import React, { useMemo, useState, useEffect } from 'react'
import BrowsePanel from './BrowsePanel'
import { css } from '@emotion/react'

export default function ClientPagination({ completeData, setDataSubset, entriesPerPage, children, ...props }) {

    const [currentPageNum, setCurrentPageNum] = useState(1)

    const numOfPages = useMemo(() => {
        return Math.ceil(completeData.length / entriesPerPage)
    }, [completeData, entriesPerPage])

    const currentPageData = useMemo(() => {
        const from = (currentPageNum * entriesPerPage) - entriesPerPage
        const to = from + entriesPerPage
        return completeData.slice(from, to)
    }, [completeData, entriesPerPage, currentPageNum])

    useEffect(() => {
        setDataSubset(currentPageData)
    },[currentPageData])

    return (
        <div css={css`
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: strech;
            gap: 1em;
        `}
            {...props}
        >
            <BrowsePanel
                pageNum={currentPageNum}
                setPageNum={setCurrentPageNum}
                firstPage={1}
                lastPage={numOfPages}
            />
            {children}
            <BrowsePanel
                pageNum={currentPageNum}
                setPageNum={setCurrentPageNum}
                firstPage={1}
                lastPage={numOfPages}
            />
        </div>
    )
}