import React, { useContext } from 'react'
import { css } from '@emotion/react'
import { backendColors, colors, dataColors } from '../../style/vars'
import GridModeContext from '../../context/GridMode'
import { useIntl } from 'react-intl'

export default function GridModeSwitcher({ ...props }) {

    const { selectedMode, setGridMode, OPTION_NAMES } = useContext(GridModeContext)
    const { formatMessage } = useIntl()
    const translatedSelectedMode = formatMessage({id: selectedMode})

    return (
        <div
            css={style.wrapper}
            onClick={(e) => {
                e.preventDefault()
                setGridMode('increment')
            }}
            title={translatedSelectedMode?.toLowerCase()}
            {...props}
        >
            <div css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                background: ${selectedMode === OPTION_NAMES.measurements ? backendColors.good :
                    selectedMode === OPTION_NAMES.heatmap ? dataColors.cold :
                        selectedMode === OPTION_NAMES.transitions ? backendColors.bad :
                            dataColors.neutral
                };
                width: 70%;
                aspect-ratio: 1;
                border-radius: 50%;
                color: ${colors.eventLight};
                display: grid;
                place-items: center;
                font-weight: 800;
            `}>
                {translatedSelectedMode[0]?.toUpperCase()}
            </div>
            <div css={css`
                background: radial-gradient(circle closest-side,${selectedMode === OPTION_NAMES.measurements ? 'rgba(0, 255, 99, 0.4)' :
                    selectedMode === OPTION_NAMES.heatmap ? 'rgba(110, 131, 255, 0.5)' :
                        selectedMode === OPTION_NAMES.transitions ? 'rgba(246, 114, 114, 0.5)' :
                            'rgba(180, 180, 180, 0.5)'
                } 75%,transparent 100%);
                width: 100%;
                aspect-ratio: 1;
                border-radius: 50%;
            `} />
        </div>
    )
}

const style = {
    wrapper: css`
        pointer-events: unset;
        width: 2em;
        aspect-ratio: 1;
        border-radius: 50%;
        background: rgba(200,200,200,0.1);
        display: grid;
        place-items: center;
        position: relative;
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;

        &:hover {
            background: rgba(200,200,200,0.3);
        }
    `,
}

