import React, { useContext, useEffect } from "react"
import { css } from '@emotion/react'
import tableauDashboards, { getSportLabel } from "../../utils/tableauDashboards"
import Sports from "../../context/Sports"
import DashPickerCard from "./DashPickerCard"
import { useMemo } from "react"
import { useState } from "react"
import CollectionButton from "./CollectionButton"
import { colors, timings } from "../../style/vars"
import Loader from "../_general/Loader"
import AnalyticsContext from "../../context/Analytics"
import AnalyticsHelp from "./AnalyticsHelp"
import Cta from "../_control/Cta"
import useTracker from "../../hooks/useTracker"
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from "../../utils/matomo"
import { FormattedMessage } from "react-intl"

export default function DashboardPicker({ ...props }) {
    const { data: userSports } = useContext(Sports)
    const { dashboards: { activeDashboards, setActiveDashboards, maxCapacity } } = useContext(AnalyticsContext)

    const [userDashboards, collections, showSportlabels] = useMemo(() => {
        if (userSports?.length === 0) return []
        const userSportsLabels = userSports?.map(sport => getSportLabel(sport))
        // list of allowed dashboards
        const filteredDashboards = tableauDashboards
            .filter(dash => dash.sports.filter(sport => userSportsLabels.includes(sport)).length > 0)
            // update sports list of each dashboard to only include the ones we have
            .map(dash => ({
                ...dash,
                sports: dash.sports.filter(s => userSportsLabels.includes(s)),
            }))
        // list of the leftover collections
        const collections = [...new Set(filteredDashboards.map(dash => dash.collection))]
        // if we have more than one sport, show sport labels
        const showSportlabels = userSportsLabels.length > 1
        return [filteredDashboards, collections, showSportlabels]
    }, [userSports])

    const [selectedCollection, setSelectedCollection] = useState()
    const [selectedInfo, setSelectedInfo] = useState()

    useEffect(() => {
        if (activeDashboards.length === 0 && !selectedCollection) setSelectedCollection(collections?.[0])
    }, [activeDashboards, selectedCollection, collections])

    const collectionDashboards = useMemo(() => {
        if (!userDashboards) return
        if (!selectedCollection) return []
        return userDashboards.filter(dash => dash.collection === selectedCollection)
    }, [selectedCollection, userDashboards])

    const lastDashboardIds = useMemo(() => {
        let lastDashboards
        try {
            lastDashboards = JSON.parse(localStorage.getItem('lastDashboards'))
        } catch (e) {
            console.error('could not parse localstorage lastDashboards', e)
        }
        if (!Array.isArray(lastDashboards)) return
        return (activeDashboards.length === 0 && lastDashboards.length > 0) && lastDashboards
    }, [activeDashboards])

    const [help, setHelp] = useState(false)

    const track = useTracker()

    return (<>
        <div css={style.card(!!selectedCollection)} {...props} >
            {!userDashboards && <Loader
                size='5em'
                css={style.loader}
            />}

            <div css={style.header}>
                {!!collections &&
                    collections?.map(coll => <CollectionButton
                        key={`${coll}`}
                        onClick={() => setSelectedCollection(coll === selectedCollection ? undefined : coll)}
                        collection={coll}
                        isSelected={coll === selectedCollection}
                        css={selectedCollection === coll && style.selectedCollection}
                    />)}
            </div>

            {!!selectedCollection &&
                <>
                    <div css={css`overflow-Y: auto; max-height: 300px;`}>
                        {userSports.map((sport, i) => {
                            const dashboardsForSport = collectionDashboards
                                ?.filter(d => d.sports.map(s => s.toLowerCase()).includes(sport))

                            return dashboardsForSport.length > 0 ?
                                <>
                                    {userSports.length > 1 && <div css={css`
                                        display: grid; 
                                        margin-left: 1em;
                                        padding: 0.2em;
                                        color: ${colors.soft}; 
                                        text-transform: capitalize;
                                    `} key={`header-${sport}-${i}`}>
                                        <FormattedMessage id={sport}/>
                                    </div>}
                                    <div css={style.list} key={`list-${sport}-${i}`}>
                                        {dashboardsForSport
                                            .map(dash => {
                                                return <DashPickerCard
                                                    key={`dash-${sport}-${dash.id}`}
                                                    dashInfo={dash}
                                                    setSelectedInfo={setSelectedInfo}
                                                    showSportlabels={showSportlabels}
                                                    showCollectionLabel={!selectedCollection}
                                                />
                                            })

                                        }
                                    </div>
                                </>
                                : null
                        })}

                        {lastDashboardIds && <div css={css`
                            display: grid;
                            place-items: center;
                            color: ${colors.soft};
                        `}>
                            <hr css={css`width: 98%; opacity: 0.2;`} />

                            <div css={css`margin-top: 1em; display: grid; gap: 1em;`}>
                                <FormattedMessage id='previousDashboards' />
                                {lastDashboardIds.length > 1 && <Cta onClick={() => {
                                    const filtered = tableauDashboards.filter(d => lastDashboardIds.includes(d.id))
                                    const deepCopy = JSON.parse(JSON.stringify(filtered))
                                    setActiveDashboards(deepCopy)
                                    setSelectedCollection()
                                    track({
                                        'event': MTM_EVENT_TYPES["option-select"],
                                        [MTM_VARIABLES["list-type"]]: MTM_LIST_TYPES["graph-select"],
                                        [MTM_VARIABLES["list-value"]]: `Previous ${lastDashboardIds.length} dashboard${lastDashboardIds.length > 1 ? 's' : ''}`
                                    })
                                    for (const id of lastDashboardIds) {
                                        track({
                                            'event': MTM_EVENT_TYPES["option-select"],
                                            [MTM_VARIABLES["list-type"]]: MTM_LIST_TYPES["graph-select"],
                                            [MTM_VARIABLES["list-value"]]: id
                                        })
                                    }
                                }}>
                                    <FormattedMessage id='reloadAll' />
                                </Cta>}
                            </div>

                            <div css={style.list}
                                onClick={() => setSelectedCollection()}
                            >
                                {tableauDashboards?.filter(d => lastDashboardIds.includes(d.id)).map(d => {
                                    const deepCopy = JSON.parse(JSON.stringify(d))
                                    return <DashPickerCard
                                        key={deepCopy.id}
                                        dashInfo={deepCopy}
                                        showCollectionLabel={true}
                                        setSelectedInfo={setSelectedInfo}
                                    />
                                })}
                            </div>

                            <hr css={css`width: 98%; opacity: 0.2;`} />

                        </div>}

                    </div>

                    <p css={style.info}>
                        {selectedInfo}
                    </p>

                    <div /* closer div */
                        css={css`
                            position: absolute;
                            top: 100%;
                            height: 100vh;
                            width: 100%;

                            @media screen and (max-width: 600px) {
                                background: rgba(0,0,0,0.5);
                            }
                        `}
                        onPointerDown={() => activeDashboards?.length > 0 && setSelectedCollection()}
                        onWheel={() => activeDashboards?.length > 0 && setSelectedCollection()}
                    />

                    <div css={style.optionButtons}>
                        <button
                            onClick={() => { setActiveDashboards([]) }}
                            css={style.optionButton}
                            disabled={!activeDashboards?.length}
                        >
                            <FormattedMessage id='clearDashboards' />
                        </button>
                        <button
                            onClick={() => { activeDashboards?.length > 0 && setSelectedCollection() }}
                            css={style.optionButton}
                            disabled={activeDashboards?.length === 0}
                        >
                            <FormattedMessage id='close' />
                        </button>
                        <button
                            css={style.optionButton}
                            onClick={() => { setHelp(true) }}
                        >
                            <FormattedMessage id='help' />
                        </button>
                    </div>
                </>
            }
        </div>
        {help && <AnalyticsHelp setHelp={setHelp} />}
    </>)
}

const style = {
    card: (isOpen) => css`
        position: relative;
        display: grid;
        gap: 0.2em;
        grid-template: 1fr / 1fr;
        background: ${isOpen ? colors.substance + 'F2' : 'transparent'};
        transform: translateY(0);
        box-shadow: ${isOpen && '0 0 3px ' + colors.sublimate};
    `,
    loader: css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% , -50%);
    `,
    header: css`
        display: flex;
        justify-content: space-around;
        height: 3.6em;
        margin-bottom: 1em;
    `,
    selectedCollection: css`
        background: ${colors.eventDark};
        border-bottom: 3px solid ${colors.main1};
            
        div, p {
            fill: ${colors.white};
            color: ${colors.white};
        }
    `,
    info: css`
        height: 2em;
        display: grid;
        place-items: center;
        padding: 0 0 1em 0;
        color: ${colors.soft};

        @media(max-width: 900px) {
            display: none;
        }
    `,
    list: css`
        overflow-Y: auto;
        padding: 1em;
        width: 100%;
        display: grid;
        grid-gap: 1em;
        grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
        place-items: center;
    `,
    optionButtons: css`
        position: absolute;
        top: 100%;
        left: 50%;
        background: ${colors.substance};
        display: flex;
        transform: translateX(-50%);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid rgba(100,100,100,0.4);
        border-top: none;
    `,
    optionButton: css`
        width: 4em;
        height: 2em;
        margin: 0.2em 0.5em;
        border: none;
        border-radius: 5px;
        background: ${colors.dark};
        color: ${colors.soft};
        cursor: pointer;
        font-size: 1.1em;
        transition: color ${timings.responsive};
        text-transform: capitalize;

        &:hover {
            color: ${colors.white};
        }

        &:disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    `
}