import React, { useContext } from 'react'
import { css } from '@emotion/react'
import AnalyticsContext from '../../context/Analytics'
import { colors, timings } from '../../style/vars'
import Cta from '../_control/Cta'
import { FormattedMessage } from 'react-intl'

export default function InfoModal({ ...props }) {
    const { dashboards: { infoModal, setInfoModal } } = useContext(AnalyticsContext)

    return (infoModal &&
        <div
            onClick={() => { setInfoModal('') }}
            css={style.card}
            {...props}
        >
            {infoModal}
            <Cta>
                <FormattedMessage id='close' />
            </Cta>
        </div>
    )
}

const style = {
    card: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    background: ${colors.substance};
    box-shadow: 0 0.2em 0.8em 0.1em black;
    cursor: pointer;

    animation-name: slide-down;
    animation-duration: ${timings.responsive};

    @keyframes slide-down {
        from {transform: translateY(100%)}
        to {transform: translateY(0)}
    }
    
    > p {
        max-width: 50em;
        padding: 15px;
        color: ${colors.soft};
        background: ${colors.dark};
        text-align: left;
        font-family: Heebo;
        font-weight: 300;
        font-size: 16px;

        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
`
}