import React, { useState, useRef, useCallback } from 'react'
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'
import { useEffect } from 'react'
import Cta from '../_control/Cta'
import AnalyticsContext from '../../context/Analytics'
import { useContext } from 'react'
import { isEqual } from 'lodash'
import { isTouch } from "../../utils/isTouchDevice"
import PitchOption from './PitchOption'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import { useIntl } from 'react-intl'

export default function AnalyticsPitchList({ ...props }) {

    const { formatMessage } = useIntl()

    const {
        pitches: {
            pitches,
            filteredPitches,
            setFilteredPitches
        }
    } = useContext(AnalyticsContext)

    //====================
    // handle dragging and clicking ( = dragging with no movement)
    const defaults = {
        dragStarted: false,
        dragCompleted: false,
        dragMoved: false,
        from: undefined,
        to: undefined,
        toggleKey: false,
        shiftKey: false
    }
    const parentRef = useRef()
    const [dragState, setDragState] = useState(defaults)
    const [selectedIds, setSelectedIds] = useState([])

    useEffect(() => {
        setSelectedIds(filteredPitches)
    }, [filteredPitches])

    const [toggleMode, setToggleMode] = useState(isTouch)

    const handleDrag = useCallback((e) => {
        e.persist()
        const { top } = parentRef.current.getBoundingClientRect()
        if (e.type === 'mousedown') {
            setDragState(current => ({
                ...current,
                from: e.clientY - top + parentRef.current.scrollTop,
                dragStarted: true,
            }))
        }
        if (e.type === 'mousemove') {
            if (!dragState.from || !dragState.dragStarted) return
            setDragState(current => ({
                ...current,
                to: e.clientY - top + parentRef.current.scrollTop,
                dragMoved: true,
            }))
        }
        if (e.type === 'mouseup') {
            if (!dragState.from || !dragState.dragStarted) return setDragState(defaults)
            setDragState(current => ({
                ...current,
                to: e.clientY - top + parentRef.current.scrollTop,
                dragCompleted: true,
                toggleKey: e.ctrlKey || e.metaKey,
                shiftKey: e.shiftKey
            }))
        }
        if (e.type === 'mouseleave') {
            setDragState(defaults)
        }
    }, [dragState])

    useEffect(() => {
        if (!dragState.dragCompleted) return
        const children = Object.values(parentRef.current.children).filter(el => el.tagName !== 'SPAN')
        const selectedOptions = children.filter(child => {
            const childRange = [child.offsetTop, child.offsetTop + child.offsetHeight]
            for (const point of childRange) {
                if (
                    point <= Math.max(dragState.from, dragState.to)
                    &&
                    point >= Math.min(dragState.from, dragState.to)
                ) return true
            }
            for (const point of [dragState.from, dragState.to]) {
                if (
                    point <= Math.max(...childRange)
                    &&
                    point >= Math.min(...childRange)
                ) return true
            }
        })

        //if toggle mode is on, just function like a list of checkboxes
        if (toggleMode) {
            const selectedOption = selectedOptions.filter(option => {
                return (dragState.to >= option.offsetTop) && (dragState.to <= (option.offsetTop + option.offsetHeight))
            })[0]
            setDragState(defaults)
            if (!selectedOption) return
            const selectedId = Number(selectedOption.firstChild?.attributes.value?.value)
            return setSelectedIds(current => {
                if (current.includes(selectedId)) {
                    return current.filter(id => id !== selectedId)
                } else {
                    return [...current, selectedId]
                }
            })
        }

        //ctrl click should toggle one item, or add multiple items
        if (dragState.toggleKey) {
            setDragState(defaults)
            return setSelectedIds(current => {
                //multiple
                const idsToToggle = selectedOptions.map(option => Number(option.firstChild?.attributes.value?.value))
                if (selectedOptions.length > 1) {
                    const newList = [...current]
                    for (const id of idsToToggle) {
                        if (!newList.includes(id)) {
                            newList.push(id)
                        }
                    }
                    return newList
                }
                //single
                if (selectedOptions.length === 1) {
                    if (current.includes(idsToToggle[0])) {
                        return current.filter(id => id !== idsToToggle[0])
                    } else {
                        return [...current, idsToToggle[0]]
                    }
                }
                return current
            })
        }

        //shift click: take everything inclusive between highest and lowest of previous and current selection
        if (dragState.shiftKey) {
            if (selectedOptions.length === 0) return
            setDragState(defaults)
            const prevOptions = children.filter(option => selectedIds.includes(Number(option.firstChild?.attributes.value?.value)))
            const highestPrev = prevOptions[0]
            const lowestPrev = prevOptions[prevOptions.length - 1]
            const highestSelect = selectedOptions[0]
            const lowestSelect = selectedOptions[selectedOptions.length - 1]
            const arr = [highestPrev, lowestPrev, highestSelect, lowestSelect]
            arr.sort((a, b) => a.offsetTop - b.offsetTop)
            const [minOffset, maxOffset] = [arr[0].offsetTop, arr[arr.length - 1].offsetTop]
            const combinedOptions = children.filter(option => ((option.offsetTop >= minOffset) && (option.offsetTop <= maxOffset)))
            return setSelectedIds(combinedOptions.map(option => Number(option.firstChild?.attributes.value?.value)))
        }

        //regular drag or click
        if (dragState.dragCompleted) {
            setDragState(defaults)
            if (selectedOptions.length === 0) return
            return setSelectedIds(selectedOptions.map(option => Number(option.firstChild?.attributes.value?.value)))
        }

    }, [dragState, selectedIds])
    //======================

    const track = useTracker()

    return (
        <>
            <div
                ref={parentRef}
                onMouseDown={(e) => handleDrag(e)}
                onMouseUp={(e) => handleDrag(e)}
                onMouseMove={(e) => handleDrag(e)}
                onMouseLeave={(e) => handleDrag(e)}
                css={style.pitchList}
                {...props}
            >
                {!toggleMode && <span
                    css={[style.dragSelection, css`
                        display: ${dragState.dragMoved ? 'flex' : 'none'};
                        top: ${Math.min(dragState.from, dragState.to)}px;
                        height: ${Math.abs(dragState.from - dragState.to)}px;
                    `]} />}

                {pitches?.map(pitch => {
                    return <PitchOption
                        pitch={pitch}
                        key={pitch.id}
                        checked={selectedIds?.includes(pitch.id)}
                    />
                })}
            </div>

            <div css={css`display: flex; gap: 0.2em;`}>
                <button css={style.settingButton}
                    onClick={() => setSelectedIds(current => {
                        const all = [...pitches.map(p => p.id)]
                        if (isEqual(all, current)) {
                            return []
                        }
                        return all
                    })}
                    title={formatMessage({id: 'selectDeselectAll'})}
                >
                    <div>&#8734;</div>
                </button>
                <button css={style.settingButton}
                    onClick={() => setToggleMode(current => !current)}
                    title={toggleMode ? formatMessage({id: 'singleSelectMode'}) : formatMessage({id: 'multiSelectMode'})}
                >
                    {toggleMode ? <div>&#x2611;</div> : <div>&#x2630;</div>}
                </button>
            </div>
            <Cta
                onClick={() => {
                    track({
                        'event': MTM_EVENT_TYPES['option-select'],
                        [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['pitch-select'],
                        [MTM_VARIABLES['list-value']]: `${selectedIds.length} pitch${selectedIds.length === 1 ? '' : 'es'}`,
                    })
                    setFilteredPitches(selectedIds)
                }}
                disabled={isEqual(selectedIds, filteredPitches) || !selectedIds?.length}
                css={style.confirm}
                title={formatMessage({id: 'confirmSelection'})}
            >
                &#10003;
            </Cta>
        </>
    )
}

const style = {
    pitchList: css`
        position: relative;
        width: 100%;
        font-size: 0.8em;
        color: ${colors.soft};
        padding: 0 0.2em;
        background: ${colors.dark};
        border-radius: 5px;
        flex-grow: 1;
        min-height: 5em;
        overflow-Y: auto;
        overflow-X: hidden;
    `,
    dragSelection: css`
        position: absolute;
        background: ${colors.main1};
        pointer-events: none;
        width: 100%;
        opacity: 0.12;
        left: 0;
        z-index: 0;
    `,
    confirm: css`
        display: flex;
        font-size: 0.55em;
        align-items: center;

        span {
            margin: 0 2em;
        }
    `,
    settingButton: css`
        width: 50%;
        height: 2em;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        background: ${colors.dark};
        color: ${colors.soft};
        box-shadow: 0 0.1em 2px 0 black;
        transition: color, box-shadow, ${timings.responsive};

        &:hover{ 
            color: ${colors.white};
            box-shadow: 0 0.2em 3px 0 black;
        }

        div {
            font-size: 1.5em;
            transform: translate(0, -0.08em);
        }
    `,
}