import React, { useCallback, useState, Fragment, useMemo, useEffect, useContext } from 'react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import PropTypes from 'prop-types'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import Selector from '../_control/OptionsSelector'
import NumberScroller from '../_control/NumberScroller'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../context/Pitch'
import { colors } from '../../style/vars'
import ProfileContext from '../../context/Profile'
import TemplateNameForm from '../events/TemplateNameForm'
import Cta from '../_control/Cta'
import { sanitize } from 'dompurify'
import DeleteWarnModal from '../_control/DeleteWarnModal'
import UserGroupPicker from '../user/UserGroupPicker'
import FertilisingTemplatePicker from '../events/FertilisingTemplatePicker'

const amountMin = 0
const amountMax = 20000

export default function FertiliserForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get, del } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [fertiliserType, setFertiliserType] = useState('liquid')
    const [additionals, setAdditionals] = useState([])
    const [amount, setAmount] = useState(0)
    const [unit, setUnit] = useState('litres')
    const [gravity, setGravity] = useState(1)
    const [productN, setProductN] = useState(0)
    const [productP, setProductP] = useState(0)
    const [productK, setProductK] = useState(0)
    //other
    const { data: fertiliserTypesOptions, loading: isTypesBusy } = useBridge(`/api/current/frontend-bridge/fertiliser-types`)
    const { data: additionalsData, loading: isAdditionalsBusy } = useBridge(`/api/current/frontend-bridge/fertiliser-additional-nutritions`)
    const { data: unitsOptions, loading: isUnitsBusy } = useBridge(`/api/current/frontend-bridge/units`)

    const [pitch] = useContext(PitchContext)
    const [profile] = useContext(ProfileContext)

    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/fertilising-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setFertiliserType(data.fertilisingType)
                setAdditionals(data.additionalNutrition)
                setAmount(data.amount)
                setUnit(data.unit)
                setGravity(data.gravity)
                setProductN(data.productNitrogen)
                setProductP(data.productPhosphorus)
                setProductK(data.productPotassium)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // derived data
    const isLiquid = useMemo(() => {
        return fertiliserType === 'liquid'
    }, [fertiliserType])

    const calculateResult = useCallback((product) => (
        isLiquid && gravity ?
            amount * gravity * product / 100
            :
            amount * product / 100
    ), [isLiquid, amount, gravity])

    const resultN = useMemo(() => calculateResult(productN), [productN, calculateResult])
    const resultP = useMemo(() => calculateResult(productP), [productP, calculateResult])
    const resultK = useMemo(() => calculateResult(productK), [productK, calculateResult])

    const handleUnitChange = useCallback((val) => {
        setUnit(isLiquid ? unitsOptions.litres : val)
    }, [isLiquid, unitsOptions])

    const HandleFertilizerChange = useCallback((val) => {
        if (val === 'liquid') {
            setUnit('litres')
            if (!gravity) setGravity(1)
        }
        setFertiliserType(val)
    }, [gravity])

    const putAdditionals = useCallback((type) => {
        setAdditionals(prev => {
            return prev.includes(type) ?
                prev.filter(el => el != type)
                :
                [...prev, type]
        })
    }, [])

    const validateAndSubmit = useCallback(async () => {
        if (!fertiliserType) return
        if (!Array.isArray(additionals)) return
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)
        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            amount: parseInt(amount),
            unit: unit,
            fertilisingType: fertiliserType,
            gravity: isLiquid ? parseFloat(gravity) : null,
            productNitrogen: parseInt(productN),
            productPhosphorus: parseInt(productP),
            productPotassium: parseInt(productK),
            additionalNutrition: additionals,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Fertiliser event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pitch, amount, unit, fertiliserType, gravity, productP, productN, productK, additionals, selectedDateTime, timeTaken])

    // save template logic flow
    const [savingTemplate, setSavingTemplate] = useState(false)
    const [firstStepOk, setFirstStepOk] = useState(false)
    const [selectedUsergroup, setSelectedUsergroup] = useState()
    const [newTemplateName, setNewTemplateName] = useState('')

    const handleStartSavingTemplate = useCallback(() => {
        if (profile?.userGroups.length === 0) {
            setSelectedUsergroup()  // automatically save on author instead of usergroup when you have no usergroups (admin)
            setFirstStepOk(true)
        }
        setSavingTemplate(true)
    }, [profile])

    const handleUserGroupChoice = useCallback((uG) => {
        if (firstStepOk) return
        setSelectedUsergroup(uG)
        setFirstStepOk(true)
    }, [firstStepOk])

    const handleSaveTemplate = useCallback(async (e) => {
        e.preventDefault()

        if (!fertiliserType) return
        if (!Array.isArray(additionals)) return
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        const payload = {
            name: sanitize(newTemplateName),
            userGroup: selectedUsergroup ? selectedUsergroup['@id'] : null,
            author: `/api/current/users/` + profile.id,
            timeTaken: parseInt(timeTaken * 60),
            fertilisingType: fertiliserType,
            amount: parseInt(amount),
            unit: unit,
            gravity: isLiquid ? parseFloat(gravity) : null,
            productNitrogen: parseInt(productN),
            productPhosphorus: parseInt(productP),
            productPotassium: parseInt(productK),
            additionalNutrition: additionals,
        }

        await post(`/api/current/fertilising-event-templates`, { body: payload })

        setNewTemplateName('')
        setSavingTemplate(false)
        setSelectedUsergroup()
        setFirstStepOk(false)

    }, [amount, unit, fertiliserType, gravity, productP, productN, productK, additionals, timeTaken, newTemplateName, selectedUsergroup, profile])

    // load template logic flow

    const [startPickingTemplate, setStartPickingTemplate] = useState(false)
    const [templateToDelete, setTemplateToDelete] = useState()
    const [optimisticDeletedTemplateIds, setOptimisticDeletedTemplateIds] = useState([])

    const handleDeleteTemplate = useCallback(async () => {
        if (templateToDelete) {
            try {
                setOptimisticDeletedTemplateIds(curr => [...curr, templateToDelete.id])
                const res = await del(`/api/current/fertilising-event-templates/${templateToDelete.id}`)
                if (res.error || res['hydra:Error']) {
                    /* setDeletionError(
                        formatMessage({ id: 'somethingWentWrongTryAgain' })
                    ) */
                    setOptimisticDeletedTemplateIds(curr => curr.filter(x => x !== templateToDelete.id))
                    throw new Error('Something went wrong')
                }
                setTemplateToDelete()
            } catch (e) {
                console.error(e)
            }
        }
    }, [templateToDelete, del])

    const handleStartPickingTemplate = useCallback(() => {
        if (profile?.userGroups.length === 1) {
            setSelectedUsergroup(profile.userGroups[0])
            setFirstStepOk(true)
        }
        setStartPickingTemplate(true)
    }, [profile])

    const handleStopOverlay = useCallback(() => {
        setSavingTemplate(false)
        setFirstStepOk(false)
        setStartPickingTemplate(false)
        setSelectedUsergroup()
    }, [])

    const handleLoadTemplate = useCallback((templ) => {
        if (validateTemplateTypes(templ)) {
            setIsLoading(true)
            setAmount(parseInt(templ.amount))
            setProductN(parseInt(templ.productNitrogen))
            setProductP(parseInt(templ.productPhosphorus))
            setProductK(parseInt(templ.productPotassium))
            setGravity(parseFloat(templ.gravity))
            setUnit(templ.unit)
            setFertiliserType(templ.fertilisingType)
            setAdditionals(templ.additionalNutrition)
            setIsLoading(false)
            handleStopOverlay()
        }
    }, [handleStopOverlay])

    const showOverlay = useMemo(() => {
        return savingTemplate || startPickingTemplate
    }, [savingTemplate, startPickingTemplate])

    const isBusy = useMemo(() => (
        isTypesBusy || isAdditionalsBusy || isLoading || isUnitsBusy
    ), [isTypesBusy, isAdditionalsBusy, isLoading, isUnitsBusy])

    return (<>
        {isBusy ? <FormLoader /> : <div css={css`
            position: relative; 
            @media screen and (max-width: 600px) {
                max-height: ${showOverlay ? '60vh' : 'unset'};
                overflow: ${showOverlay ? 'hidden' : 'unset'};
            }
        `}>

            {/* TEMPLATE MANAGEMENT OVERLAY */}
            {showOverlay &&
                <div css={styles.overlay}>
                    {savingTemplate && (!firstStepOk ?
                        <UserGroupPicker
                            handleSelect={(uG) => handleUserGroupChoice(uG)}
                            userGroups={profile?.userGroups}
                        />
                        :
                        <TemplateNameForm
                            handleSaveTemplate={handleSaveTemplate}
                            newTemplateName={newTemplateName}
                            setNewTemplateName={setNewTemplateName}
                        />
                    )}

                    {startPickingTemplate &&
                        <div css={styles.templatePicker}>
                            <FertilisingTemplatePicker
                                handleLoadTemplate={handleLoadTemplate}
                                setTemplateToDelete={setTemplateToDelete}
                                optimisticDeletedTemplateIds={optimisticDeletedTemplateIds}
                            />
                        </div>
                    }
                </div>
            }

            {/* MAIN FORM */}
            <EventDateTimePicker
                unixDate={selectedDateTime}
                setUnixDate={setSelectedDateTime}
            />
            <Selector
                label={formatMessage({ id: 'fertiliserType' })}
                optionsObject={fertiliserTypesOptions}
                setState={HandleFertilizerChange}
                state={fertiliserType} />

            <div css={styles.row} >
                <NumberScroller
                    label={formatMessage({ id: 'amount' })}
                    minVal={amountMin}
                    maxVal={amountMax}
                    setState={setAmount}
                    state={amount}
                />
                <Selector
                    state={unit}
                    setState={handleUnitChange}
                    optionsObject={unitsOptions}
                    btnWidth={100}
                    disabled={isLiquid}
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-top: 1.8em;
                    `}
                />
                {isLiquid &&
                    <NumberScroller
                        label={formatMessage({ id: 'specificGravity' })}
                        minVal={1}
                        maxVal={2}
                        setState={setGravity}
                        state={gravity}
                        step={0.01}
                    />
                }
                <NumberScroller
                    label={formatMessage({ id: 'timeTaken' })}
                    minVal={0}
                    maxVal={24}
                    state={timeTaken}
                    unit={formatMessage({ id: 'hours' })}
                    step={0.5}
                    setState={setTimeTaken}
                />
            </div>

            <h3>
                <FormattedMessage id='product' />
            </h3>
            <div css={styles.row}>
                <div css={styles.product}>
                    <NumberScroller
                        label="N"
                        minVal={0}
                        maxVal={100}
                        setState={setProductN}
                        state={productN}
                    />
                </div>
                <div css={styles.product}>
                    <NumberScroller
                        label="P"
                        minVal={0}
                        maxVal={100}
                        setState={setProductP}
                        state={productP}
                    />
                </div>
                <div css={styles.product}>
                    <NumberScroller
                        label="K"
                        minVal={0}
                        maxVal={100}
                        setState={setProductK}
                        state={productK}
                    />
                </div>
                <div css={styles.totals}>
                    <div><FormattedMessage id='nitrogen' />: {resultN.toFixed(2)}</div>
                    <div><FormattedMessage id='phosphorus' />: {resultP.toFixed(2)}</div>
                    <div><FormattedMessage id='potassium' />: {resultK.toFixed(2)}</div>
                </div>
            </div>

            {additionalsData && <>
                <h3 style={{ margin: '16px 0' }}>
                    <FormattedMessage id='additionalNutrition' />
                </h3>
                {Object.keys(additionalsData).map((type) =>
                    <Fragment key={type}>
                        <button
                            onClick={() => putAdditionals(type)}
                            css={[styles.additional, css`
                                border: ${additionals.includes(type) ? '2px solid #75FBC9' : '2px solid #3A424A'};
                                color: ${additionals.includes(type) ? '#75FBC9' : colors.soft};
                            `]}>
                            {additionalsData[type]}
                        </ button>
                    </ Fragment>
                )}
            </>}

        </div>}

        <div css={styles.bottomButtons}>
            {!showOverlay && <>
                <Cta
                    onClick={handleStartSavingTemplate}
                    css={css`border-color: ${colors.soft} !important;`}
                >
                    <FormattedMessage id='saveTemplate' />
                </Cta>
                <Cta
                    onClick={handleStartPickingTemplate}
                    css={css`border-color: ${colors.soft} !important;`}
                >
                    <FormattedMessage id='loadTemplate' />
                </Cta>
            </>}

            {showOverlay && <>
                <Cta
                    onClick={handleStopOverlay}
                    css={css`border-color: ${colors.soft} !important;`}
                >
                    <FormattedMessage id='cancel' />
                </Cta>
                <div />
            </>}

            <div />

            <Cta
                onClick={() => validateAndSubmit()}
                disabled={isBusy || showOverlay}
            >
                <FormattedMessage id='save' />
            </Cta>
        </div>

        {templateToDelete && <DeleteWarnModal
            handleClose={() => setTemplateToDelete()}
            handleDelete={() => handleDeleteTemplate()}
            header={formatMessage({ id: "deleteTemplate" })}
            message={
                formatMessage({
                    id: "deleteTemplateWarn",
                }, {
                    templatename: templateToDelete.name,
                    authorname: templateToDelete.author.email
                })
            }
        />}
    </>)
}

FertiliserForm.propTypes = {
    pitch: PropTypes.object,
    eventType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    day: PropTypes.object,
    onPostRequest: PropTypes.func,
}

const styles = {
    row: {
        display: 'flex',
        margin: '1em 0',
        alignItems: "center",
        justifyContent: "start",
        flexWrap: 'wrap',
        gap: '2em'
    },
    overlay: css`
        position: absolute;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        transform: translate(-10px, -10px);
        border-radius: 5px;
        background: rgb(50,52,55);
        user-select: none;

        display: grid; 
        place-items: center;

        z-index: 1;
    `,
    additional: css`
        background-color: #2D3339;
        padding: 8px 16px;
        border-radius: 5px;
        margin-right: 16px;
        margin-bottom: 8px;
        outline: none;
        cursor: pointer;
        text-transform: capitalize;
    `,
    product: css`
        background-color: #26292C;
        border: 1px solid #292C2F;
        margin-right: 1em;
        text-align: center;
        display: flex;
        justify-content: center;
        padding-top: 1em;
        border-radius: 3px;

        label {
            color: #8A9AA8;
            font-weight: bold;
            font-size: 1.5em;
        }
    `,
    totals: css`
        > div {
            margin: 1em 0;
        }
    `,
    bottomButtons: css`
        display: grid;
        grid-template-columns: max-content max-content 1fr max-content;
        gap: 1em;
        margin-top: 2em;

        > button.btn {
            margin: 0;
        }

        @media screen and (max-width: 600px) {
           grid-template-columns: 1fr;
        }
    `,
    templatePicker: css`
        width: 100%;
        height: 100%;
        padding: 2em;
        display: grid;
        gap: 1em;
        grid-auto-rows: min-content;
        overflow: auto;
    `
}

function validateTemplateTypes(templ) {
    return (
        typeof templ.amount === 'number' &&
        typeof templ.productNitrogen === 'number' &&
        typeof templ.productPhosphorus === 'number' &&
        typeof templ.productPotassium === 'number' &&
        (typeof templ.gravity === 'number' || templ.gravity === null) &&
        typeof templ.unit === 'string' &&
        typeof templ.fertilisingType === 'string' &&
        Array.isArray(templ.additionalNutrition)
    )
}