import React from "react"
import { css } from '@emotion/react'
import { colors } from "../../style/vars"

export default function PitchOption({ checked, pitch, ...props }) {

    const style = {
        option: css`
            padding: 0; 
            font-size: 0.7em;
            height: 1.2em;
            overflow: hidden;
            background: ${checked && 'rgba(100,100,100,0.05)'};
            opacity: ${!checked && '0.2'};
            cursor: default;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 1.2em;

            &:hover {
                opacity: 0.8;
                color: ${colors.main1};
                background: rgba(255,255,255,0.1);
            }
        `,
        colorBox: css`
            position: absolute;
            opacity: ${!checked && '0.2'};
            background: ${pitch.color};
            top: 50%;
            left: 0;
            aspect-ratio: 1;
            height: 70%;
            transform: translate(0, -50%);
            pointer-events: none;
        `
    }

    return (
        <div
            css={css`position: relative;  z-index: 1; user-select: none;`}
            {...props}
        >
            <div css={style.option} value={pitch.id} >
                {pitch.name}
            </div>
            {pitch.color && <div css={style.colorBox}/>}
        </div>
    )
}

