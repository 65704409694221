import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'

const style = busy => css`
    color: ${colors.white};
    line-height: 1.25;
    > label {
        display: block;
        margin-bottom: 0.375em;
        font-size: 0.875em;
        color: #9299AA;
        font-weight: 300;
    }
    > div {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        font-size: 0.875em;
        transition: all 200ms ease;
        opacity: ${busy ? 0.5 : 1};
    }
`

function KeyValue({ k, v, busy = false, ...props }) {
    return (
        <div css={style(busy)} {...props}>
            <label>{k}</label>
            <div>{v}</div>
        </div>
    )
}

KeyValue.propTypes = {
    k: PropTypes.node,
    v: PropTypes.node,
    busy: PropTypes.bool,
}

export default KeyValue
