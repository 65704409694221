import React from 'react'
import PropTypes, { oneOf } from 'prop-types'
import { uniqueId } from 'lodash';

function SvgShape({ shape, ...props }) {
    const id = uniqueId()

    switch (shape) {
        case 'ellipse': return (<ellipse {...props} />);
        case 'rect': return (<rect {...props} />);
        case 'polygon': return (<polygon {...props} />);
        default:
            return (<circle {...props} />);
    }
}

SvgShape.propTypes = {
    shape: oneOf(['ellipse', 'rect', 'polygon', 'circle'])
}

export default SvgShape
