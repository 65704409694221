import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import { colors, timings, fonts } from '../../style/vars'
import { format, subMonths } from 'date-fns'
import { useIntl } from 'react-intl'

export default function AnalyticsDatePicker({ date, setDate, onChange, ...props }) {

    const { formatMessage } = useIntl()

    const displayValue = useMemo(() => {
        if (date === null) return
        return format(date, 'yyyy-MM-dd')
    }, [date])

    return (
        <div css={style.wrapper}>
            <input
                type="date"
                value={displayValue}
                onChange={(e) => setDate(e.target.valueAsDate)}
                css={style.input}
                {...props}
            />
            <div css={css`
                display: flex;
                gap: 0.2em;
            `}>
                <button css={[style.jumpButton, css`transform: scaleX(-100%);`]}
                    onClick={() => setDate(current => current === null ? null : subMonths(current, 1))}
                    title={formatMessage({id: 'shiftByOneMonth'})}
                >
                    &#10148;
                </button>
                <button css={style.jumpButton}
                    onClick={() => setDate(current => current === null ? null : subMonths(current, -1))}
                    title={formatMessage({id: 'shiftByOneMonth'})}
                >
                    &#10148;
                </button>
            </div>
        </div>
    )
}

const style = {
    wrapper: css`
        display: grid;
        grid-gap: 0.2em;
        margin: 0.1em 0;
    `,
    jumpButton: css`
        width: 50%;
        height: 2em;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        background: ${colors.dark};
        color: ${colors.soft};

        box-shadow: 0 0.1em 2px 0 black;
        transition: color, box-shadow, ${timings.responsive};
        
        &:hover{ 
            color: ${colors.white};
            box-shadow: 0 0.2em 3px 0 black;
        }
    `,
    input: css`
        width: 100%;
        height: 2em;
        font-size: 0.8em;
        color: ${colors.soft};
        padding: 0.2em;
        cursor: pointer;
        background: ${colors.dark};
        border-radius: 5px;
        text-align: center;
        font-family: ${fonts.special};

        //for safari:
        display: flex;
        justify-content: center;
        //
        
        box-shadow: 0 0.1em 2px 0 black;
        transition: color, box-shadow, ${timings.responsive};
        
        &:hover{ 
            color: ${colors.white};
            box-shadow: 0 0.2em 3px 0 black;
        }

        &::-webkit-calendar-picker-indicator{
            filter: invert();
            opacity: 0.8;
            cursor: pointer;
            transform: scale(1.4);
        }
    `
}