import React, { createContext, useState } from 'react'

const CalendarContext = createContext()
export default CalendarContext

export function CalendarProvider(props) {
    const [selectedDay, setSelectedDay] = useState()

    return (
        <CalendarContext.Provider
            value={{ selectedDay, setSelectedDay }}
            {...props}
        />
    )
}
