import { useCallback, useContext } from 'react'
import log from 'loglevel'
import Raw from '../services/Raw'
import Auth from '../context/Auth'
import LocaleContext from '../context/Locale'

// returns GET, POST, PUT, DELETE operations for a given API resource
export default function useLdjsonApi() {
    const { jwt } = useContext(Auth)
    const { locale } = useContext(LocaleContext)

    const get = useCallback(async (resource, { query, detailedError } = {}) => doCall('GET', resource, { query, jwt, detailedError, locale }), [jwt, locale])
    const post = useCallback(async (resource, { query, body, detailedError }) => doCall('POST', resource, { query, body, jwt, detailedError, locale }), [jwt, locale])
    const put = useCallback(async (resource, { query, body, detailedError }) => doCall('PUT', resource, { query, body, jwt, detailedError, locale }), [jwt, locale])
    const del = useCallback(async (resource, { query, detailedError } = {}) => doCall('DELETE', resource, { query, jwt, detailedError, locale }), [jwt, locale])

    return {
        get,
        post,
        put,
        del,
    }
}

async function doCall(method, resource, { query, body, jwt, detailedError, locale }) {
    try {
        const urlSearchParams = new URLSearchParams(query)
        const queryString = urlSearchParams.toString()
        const d = await Raw.fetchJson(method, `${resource}${query ? '?' : ''}${queryString}`, { jwt, body, returnOnError: detailedError, acceptLanguage: locale })
        // unpack 'hydra:member", if it exists
        if (!d) {
            return {}
        }
        const data = Array.isArray(d['hydra:member']) ? d['hydra:member'] : d
        return { data }
    } catch (error) {
        log.error(error)
        return { error }
    }
}
