import SwitcherSimple from "../_control/SwitcherSimple"
import ModalHeader from "../modals/ModalHeader"
import Table from "../_general/Table"
import Loader from "../_general/Loader"
import { css } from "@emotion/react"
import { colors } from "../../style/vars"
import { ReactSVG } from "react-svg"
import useRawApiState from '../../hooks/useRawApiState'
import { useContext, useMemo, useState } from "react"
import ViewPortContext from '../../context/ViewPort'
import ProfileContext from "../../context/Profile"
import trashIcon from '../../assets/icons/trash.svg'
import { useIntl } from "react-intl"

export default function FertilisingTemplatePicker({ handleLoadTemplate, setTemplateToDelete, optimisticDeletedTemplateIds, ...props }) {
    const { data: templates, isBusy: templatesBusy } = useRawApiState(`/api/current/fertilising-event-templates`)
    const { showCompactUI } = useContext(ViewPortContext)

    const [profile] = useContext(ProfileContext)
    const [onlyShowMine, setOnlyShowMine] = useState(false)

    const optimisticTemplates = useMemo(() => {
        const optimisticTemplates = templates?.filter(t => !optimisticDeletedTemplateIds.includes(t.id)) ?? []
        return optimisticTemplates
            .filter(t => onlyShowMine ? t.author?.email === profile.email : t)
            .toSorted((a, b) => b.name.localeCompare(a.name))
    }, [optimisticDeletedTemplateIds, templates, profile, onlyShowMine])

    const { formatMessage } = useIntl()

    return <>
        <ModalHeader header={formatMessage({ id: 'chooseTemplate' })} />

        <SwitcherSimple
            checked={onlyShowMine}
            checkedLabel={formatMessage({ id: 'myTemplates' })}
            unCheckedLabel={formatMessage({ id: 'allTemplates' })}
            buttonCenter={true}
            onClick={() => setOnlyShowMine(curr => !curr)}
        />

        {templatesBusy ? <Loader size={'40px'} /> :
            optimisticTemplates.length > 0 ?
                <Table
                    showHeader={true}
                    data={optimisticTemplates}
                    onRowClick={row => handleLoadTemplate(row)}
                    rowKey={r => r.id}
                    columns={[
                        {
                            key: 'templateName',
                            header: formatMessage({ id: 'nameAuthor' }),
                            sortable: true,
                            renderData: row => <div css={css`
                                display: flex; 
                                flex-wrap: wrap;
                                gap: 0.3em 3em;
                                justify-content: space-between;
                                width: 100%;
                                align-items: center;
                            `}>
                                <span css={css`font-size: 1.5em;`}>{row.name}</span>
                                {!showCompactUI && <span css={css`opacity: 0.15;`}>{row.author?.email}</span>}
                            </div>,
                            wide: true
                        },
                        {
                            key: 'amount',
                            header: formatMessage({ id: 'amount' }),
                            sortable: true,
                            renderData: row => row.amount + ' ' + (row.unit === 'litres' ? 'L' : 'kg'),
                            shrink: true
                        },
                        {
                            key: 'N',
                            header: 'N',
                            sortable: true,
                            renderData: row => row.productNitrogen,
                            shrink: true
                        },
                        {
                            key: 'P',
                            header: 'P',
                            sortable: true,
                            renderData: row => row.productPhosphorus,
                            shrink: true
                        },
                        {
                            key: 'K',
                            header: 'K',
                            sortable: true,
                            renderData: row => row.productPotassium,
                            shrink: true
                        },
                        {
                            key: 'delete',
                            header: '',
                            sortable: false,
                            renderData: row => <div title={formatMessage({ id: 'delete' }).toLowerCase()}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setTemplateToDelete(row)
                                }}
                            >
                                <ReactSVG src={trashIcon} css={css`
                                    width: 18px;
                                    height: 18px;
                                    cursor: pointer;
                                    svg {
                                        fill: #6d7884;
                                    }

                                    &:hover {
                                        svg {
                                            fill: ${colors.warn};
                                        }
                                    }
                                `} />
                            </div>,
                            shrink: true
                        },
                    ].filter(row => showCompactUI ? ['amount', 'templateName', 'N', 'delete'].includes(row.key) : true)}
                />
                :
                formatMessage({ id: 'noTemplates' })
        }
    </>
}