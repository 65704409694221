
import React, { useCallback, useEffect } from 'react';
import {ReactSVG} from 'react-svg'
import { css, jsx } from '@emotion/react'
import smileHappy from '../../assets/icons/smile.svg'
import smileNeutral from '../../assets/icons/neutral-smile.svg'
import smileSad from '../../assets/icons/sad-smile.svg'
import smileHappyActive from '../../assets/icons/smile-active.svg'
import smileNeutralActive from '../../assets/icons/neutral-smile-active.svg'
import smileSadActive from '../../assets/icons/sad-smile-active.svg'

function HappinessWidget({ props,
    label,
    state,
    setState,
}) {

    const styles = {
        wrapper: css`
            background: #1D1F21;
            border-radius: 5px;
            padding: 16px;
            min-width: 200px;
            min-height: 75px;
        `,
        label: css`
            margin-bottom: 8px;
            display: block;
        `,
        button: css`
            background: transparent;
            border: none;
            padding: 0;
            margin: 0 8px;
            cursor: pointer;

            &:active, &:focus {
                border: none;
                outline: none;
            }

            svg {
                height: 40px;
                width: 40px;
            }
        `
    }

    return (
        <div>
            <label css={styles.label}>{label}</label>
            <div css={styles.wrapper}>
                <button css={styles.button} onClick={() => setState(100)}>
                    <ReactSVG hidden={state != 100} src={smileHappyActive} />
                    <ReactSVG hidden={state == 100} src={smileHappy} />
                </button>
                <button css={styles.button} onClick={() => setState(50)}>
                    <ReactSVG hidden={state != 50} src={smileNeutralActive} />
                    <ReactSVG hidden={state == 50} src={smileNeutral} />
                </button>
                <button css={styles.button} onClick={() => setState(0)}>
                    <ReactSVG hidden={state != 0} src={smileSadActive} />
                    <ReactSVG hidden={state == 0} src={smileSad} />
                </button>
            </div>
        </div>
    )
}

export default HappinessWidget;
