import React, { useEffect, useState, useContext } from "react"
import { css } from '@emotion/react'
import DashboardPicker from "./DashboardPicker"
import AnalyticsControls from "./AnalyticsControls"
import DashboardList from "./DashboardList"
import InfoModal from "./InfoModal"
import DownloadModal from "./DownloadModal"
import useTracker from "../../hooks/useTracker"
import { MTM_EVENT_TYPES } from "../../utils/matomo"
import { colors, timings } from "../../style/vars"
import { ReactSVG } from "react-svg"
import filterIcon from '@assets/icons/filter.svg'
import AnalyticsContext from "../../context/Analytics"

export default function AnalyticsContent({ ...props }) {

    const track = useTracker()

    useEffect(() => {
        // this component can only mount if we successfully went through tableau's auth flow
        track({ 'event': MTM_EVENT_TYPES["tableau-authenticated"] })
    }, [])

    // for switching behavior that occurs only in mobile screenwidths
    const [expanded, setExpanded] = useState(false)
    const { dashboards: { activeDashboards } } = useContext(AnalyticsContext)

    return (
        <>
            {expanded && <div css={css`
                position: fixed;
                width: 100vw;
                height: 100vh;
                background: rgba(0,0,0,0.5);
                z-index: 1;
                display: none;
                @media screen and (max-width: 600px) {
                    display: flex;
                    opacity: ${activeDashboards.length > 0 ? 1 : 0.15};
                }
                `}
                onClick={() => setExpanded(false)}
            />}
            {!expanded && <div css={css`
                position: fixed;
                width: 46px;
                aspect-ratio: 1;
                color: ${colors.eventDark};
                cursor: pointer;
                color: white;
                background: black;
                opacity: 0.85;
                right: 0;
                bottom: 12px;
                z-index: 3;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border: 2px solid ${colors.eventDark};
                border-right: none;
                &:hover {
                    background: ${colors.eventDark};
                    color: ${colors.eventLight};
                }
                display: none;
                @media screen and (max-width: 600px) {
                    display: grid;
                    opacity: ${activeDashboards.length > 0 ? 1 : 0.15};
                }
                `}
                onClick={() => setExpanded(true)}
            >
                <ReactSVG
                    css={css`fill: rgba(255,255,255,0.9); width: 55%; height: 55%; place-self: center;`}
                    src={filterIcon}
                />
            </div>}
            <div css={style.controls(expanded, activeDashboards.length)}>
                <AnalyticsControls />
            </div>

            <div css={style.dashboardList}>
                <DashboardList />
            </div>
            <DashboardPicker
                css={style.pickerMenu}
            />
            <InfoModal css={style.infoModal} />
            <DownloadModal css={style.infoModal} />
        </>
    )
}

// make it easier to align layouts of fixed and non-fixed position elements
const marginHorizontal = 2 //left and right of entire page
const marginBottom = 1.7
const topMargin = 0.2 // margin between header and rest of the page
const fixedTopOffset = 4.8 // for fixed controlbar 
const controlBarWidth = 13
const sectionGap = 1 // between controls and dashboards
const navWidth = 7.99

const style = {
    controls: (expanded, numOfDashboards) => css`
        position: fixed;
        height: calc(100% - ${fixedTopOffset}em);
        width: ${controlBarWidth}em;
        margin-top: ${fixedTopOffset + topMargin}em;
        margin-left: ${marginHorizontal}em;
        z-index: 2;
        transition: transform ${timings.smooth};

        @media screen and (max-width: 600px) {
            right: 1em;
            width: ${controlBarWidth}em;
            transform: ${expanded ? 'translate(0,0)' : 'translate(calc(100% + 1em), 0)'};
            opacity: ${numOfDashboards > 0 ? 1 : 0.15};
        }
    `,
    dashboardList: css`
        display: grid;
        grid-template: auto / 1fr;
        margin-top: ${topMargin}em;
        margin-right: ${marginHorizontal}em;
        margin-bottom: ${marginBottom}em;
        margin-left: ${controlBarWidth + marginHorizontal + sectionGap}em;
        height: 100%;

        @media screen and (max-width: 600px) {
            margin-left: 2em;
            width: calc(100% - ${2*marginHorizontal}em); 
        }
    `,
    pickerMenu: css`
        position: fixed;
        top: 0.7em;
        margin-left: ${marginHorizontal + controlBarWidth + sectionGap}em;
        width: calc(100% - ${marginHorizontal + controlBarWidth + sectionGap + navWidth}em); 
        height: auto;
        border-radius: 5px;
        z-index: 5;

        @media screen and (max-width: 600px) {
            margin-left: 1em;
            width: calc(100% - ${marginHorizontal}em); 
        }
    `,
    infoModal: css`
        position: fixed;
        bottom: ${marginBottom}em;
        margin-left: ${marginHorizontal + controlBarWidth + sectionGap}em;
        width: calc(100% - ${marginHorizontal + controlBarWidth + sectionGap + navWidth}em); 
        border-radius: 5px;
        overflow: hidden;

        @media screen and (max-width: 600px) {
            right: 1em;
            width: calc(100% - ${marginHorizontal}em); 
        }
    `
}