import React, { useCallback, useEffect, useMemo, useState, useContext } from 'react'
import { css } from '@emotion/react'
import { useNavigate, useParams } from 'react-router'
import TestCategoryCheckbox from '../testCategory/TestCategoryCheckbox'
import Cta from '../_control/Cta'
import FancyDatePicker from '../_control/FancyDatePicker'
import FancyDropdown from '../_control/FancyDropdown'
import Loader from '../_general/Loader'
import PageTitle from '../_general/PageTitle'
import TabBox from '../_layout/TabBox'
import useOption from '../../hooks/useOption'
import useRaw from '../../hooks/useRaw'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'
import PitchListContext from '../../context/PitchList'
import TestCategoriesContext from '../../context/TestCategories'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { colors, fonts } from '../../style/vars'
import { FormattedMessage, useIntl } from 'react-intl'
import LocaleContext from '../../context/Locale'
import NewAnalysisDateTimePicker from '../analysis/NewAnalysisDateTimePicker'
import { fromZonedTime } from 'date-fns-tz'
import useRawApiState from '../../hooks/useRawApiState'
import AnalysisDate from '../analysis/AnalysisDate'
import { getUnixTime, format } from 'date-fns'

function AnalysisCreate({ ...props }) {
    const [activeTabId, setActiveTabId] = useState(0)

    const navigate = useNavigate()
    const { pitchId } = useParams()
    const track = useTracker()
    const { formatMessage } = useIntl()
    const { datefnsLocale } = useContext(LocaleContext)

    // fetch data
    const { pitches, isBusy: pitchesBusy } = useContext(PitchListContext)
    const pitchOptions = useMemo(() => (
        pitches.map(pitch => ({
            label: pitch.name,
            value: pitch['@id'],
        }))
    ), [pitches])

    const { data: testCategories = [], busy: testCategoriesBusy } = useContext(TestCategoriesContext)

    // Extraction of the groups found in the categories
    const categoryGroups = []
    testCategories.map(cat => {
        if (!categoryGroups.includes(cat.group)) {
            categoryGroups.push(cat.group)
        }
    })

    // step 1
    const [newAnalysisDate, setNewAnalysisDate] = useState(new Date())
    const [chosenPitchIdProps] = useOption(
        pitchId ? `/api/current/pitches/${pitchId}` : '',
        MTM_LIST_TYPES['pitch-select']
    )

    const chosenPitch = useMemo(() => (
        pitches.find(pitch => pitch['@id'] === chosenPitchIdProps.value)
    ), [pitches, chosenPitchIdProps])

    const { data: chosenPitchDetails, isBusy: pitchDetailsBusy } = useRawApiState(chosenPitch?.['@id'])

    const [selectedTestCategories, setSelectedTestCategories] = useState({})
    const handleClickTestTile = useCallback((testCategoryId) => {
        setSelectedTestCategories(orig => ({
            ...orig,
            [testCategoryId]: !orig[testCategoryId], // toggle selection state
        }))
    }, [setSelectedTestCategories])


    // step 2
    const [submitAnalysis, { fetching: submitBusy, fetch: doSubmit }] = useRaw(
        '/api/current/surface-analyses/start', [], { method: 'POST', autoFetch: false, fetch }
    )

    const submit = useCallback(() => {

        for (const testCategoryId of Object.keys(selectedTestCategories)) {
            const name = testCategories?.find(cat => cat['@id'] === testCategoryId)?.name
            track({
                'event': MTM_EVENT_TYPES['option-select'],
                [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['testCategory-select'],
                [MTM_VARIABLES['list-value']]: name
            })
        }

        // user wants input this as 'time on the pitch' but is still local browser time, so convert local time to pitch time:
        const timezoneWithFallback = chosenPitchDetails?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        const dateOnPitch = fromZonedTime(newAnalysisDate, timezoneWithFallback)

        doSubmit({
            rawCertified: false,
            pitch: chosenPitch['@id'],
            date: `${getUnixTime(dateOnPitch)}`, // create unix timestamp
            tests: Object.entries(selectedTestCategories)
                .filter(([testCategoryId, selected]) => testCategoryId && selected)
                .map(([testCategoryId]) => ({
                    testCategory: testCategoryId,
                })),
        })
    }, [chosenPitch, chosenPitchDetails, selectedTestCategories, submitAnalysis, doSubmit, testCategories, newAnalysisDate])

    // if creation was successfull, route to the created analysis
    useEffect(() => {
        if (chosenPitch && submitAnalysis && submitAnalysis.id) {
            navigate(`/pitch/${chosenPitch.id}/analysis/${submitAnalysis.id}`)
        }
    }, [submitAnalysis, navigate])

    const busy = useMemo(() => {
        return pitchesBusy || testCategoriesBusy
    }, [pitchesBusy, testCategoriesBusy])

    const firstStepOk = !!newAnalysisDate && !!chosenPitchIdProps.value && !pitchDetailsBusy

    const secondStepOk = Object.entries(selectedTestCategories)
        .filter(([, selectedTestCategory]) => selectedTestCategory)
        .length > 0

    return (
        <div {...props}>
            <PageTitle css={css`color: ${colors.main1}`}>
                <FormattedMessage id='createNewAnalysis' />
            </PageTitle>
            {busy
                ?
                <Loader size='3rem' />
                :
                <TabBox
                    css={css`background-image: none;`}
                    activeTabId={activeTabId}
                    onClickTab={(tabId) => { setActiveTabId(tabId) }}
                    showHeader={false}
                    tabs={[
                        {
                            id: 0,
                            clickable: true,
                            children: (
                                <>
                                    <div css={css`display: flex; flex-direction: column; width: 100%;`}>
                                        <div css={style.topButtonWrapper}>
                                            <div css={css`flex-grow: 1;`}>
                                                <h2 css={style.inputTitle}>
                                                    <FormattedMessage id='chooseDate' />
                                                </h2>
                                                <div css={css`
                                                    display: grid; 
                                                    grid-template-columns: 1.5fr 0.8fr;
                                                    gap: 2em;
                                                `}>
                                                    <FancyDatePicker
                                                        date={newAnalysisDate}
                                                        onDateChange={(date) => { setNewAnalysisDate(date) }}
                                                    />
                                                    <NewAnalysisDateTimePicker
                                                        formDate={newAnalysisDate}
                                                        setFormDate={setNewAnalysisDate}
                                                    />
                                                </div>
                                            </div>

                                            <div css={css`flex-grow: 1`}>
                                                <h2 css={style.inputTitle}>
                                                    <FormattedMessage id='chooseAPitch' />
                                                </h2>
                                                <FancyDropdown
                                                    options={pitchOptions}
                                                    disabled={!!pitchId}
                                                    placeholder={formatMessage({ id: 'chooseAPitch' })}
                                                    {...chosenPitchIdProps}
                                                    css={style.dropdown}
                                                />
                                            </div>
                                        </div>
                                        <div css={css`display: flex; flex-direction: column; width: 100%`}>
                                            <h2 css={style.inputTitle}>
                                                <FormattedMessage id='testSelections' />
                                            </h2>
                                            {testCategories?.length === 0 && formatMessage({ id: 'youDontHaveTests' })}
                                            {categoryGroups.map(group => {
                                                return (
                                                    <div key={`group-${group}`}>
                                                        <h2 css={style.testTitle(group)}>
                                                            {group}
                                                        </h2>
                                                        <div css={style.cardGrid}>
                                                            {testCategories.filter(cat => cat.group === group).map(testCategory => (
                                                                <TestCategoryCheckbox
                                                                    css={style.testCategory}
                                                                    key={testCategory['@id']}
                                                                    testCategory={testCategory}
                                                                    checked={selectedTestCategories[testCategory['@id']]}
                                                                    onClick={() => { handleClickTestTile(testCategory['@id']) }}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div css={style.lowerControlWrapper} >
                                        <Cta
                                            signal='positive'
                                            disabled={!secondStepOk || !firstStepOk}
                                            onClick={() => { setActiveTabId(1) }}
                                        >
                                            <FormattedMessage id='continue' />
                                        </Cta>
                                    </div>
                                </>
                            ),
                        },
                        {
                            id: 1,
                            clickable: secondStepOk,
                            children: (
                                <>
                                    <div css={css`display: flex; flex-direction: column; align: stretch; width: 100%; gap: 1em;`}>
                                        <h4 css={style.bigTitle}>
                                            <FormattedMessage id='analysisInformation' />
                                        </h4>
                                        <div css={css`display: grid; gap: 1em; font-size: 1em;`}>
                                            {chosenPitch &&
                                                <span css={style.results}>
                                                    <b>
                                                        <FormattedMessage id='sportsField' />{': '}
                                                    </b>
                                                    {chosenPitch.name}
                                                </span>
                                            }
                                            {newAnalysisDate &&
                                                <span css={style.results}>
                                                    <b>
                                                        <FormattedMessage id='analysisDate' />{': '}
                                                    </b>
                                                    <AnalysisDate
                                                        date={getUnixTime(newAnalysisDate)}
                                                        timeZone={chosenPitchDetails?.venue?.timeZone}
                                                        css={css`
                                                            font-family: ${fonts.main};
                                                            font-weight: unset;
                                                            font-size: 0.9em;
                                                        `}
                                                    />
                                                    &nbsp;&nbsp;&nbsp;
                                                    {format(newAnalysisDate, 'HH:mm', { locale: datefnsLocale })}
                                                </span>
                                            }
                                        </div>
                                        <div css={style.cardGrid}>
                                            {testCategories.map(testCategory => (
                                                selectedTestCategories[testCategory['@id']] && (
                                                    <TestCategoryCheckbox
                                                        css={style.testCategory}
                                                        key={testCategory['@id']}
                                                        testCategory={testCategory}
                                                        checked
                                                        readOnly
                                                    />
                                                )
                                            ))}
                                        </div>
                                    </div>
                                    <div css={style.lowerControlWrapper} >
                                        <Cta
                                            signal='subtle'
                                            onClick={() => { setActiveTabId(0) }}
                                            css={css`margin-right: 1em;`}
                                        >
                                            <FormattedMessage id='previous' />
                                        </Cta>
                                        <Cta
                                            signal='positive'
                                            onClick={() => {
                                                track({
                                                    'event': MTM_EVENT_TYPES['user-confirm'],
                                                })
                                                submit()
                                            }}
                                            disabled={submitBusy || !firstStepOk || !secondStepOk}
                                            loading={submitBusy}
                                        >
                                            <FormattedMessage id='submitData' />
                                        </Cta>
                                    </div>
                                </>
                            ),
                        },
                    ]}
                />
            }
        </div >
    )
}

export default AnalysisCreate

const style = {
    bigTitle: css`
        font-size: 1.5em;
        font-weight: bold;
        opacity: 1;
        margin-bottom: .8em;
    `,
    smallTitle: css`
        text-transform: uppercase;
        font-size: 0.875em;
        font-weight: 300;
        margin-bottom: 1em;
    `,
    results: css`
        color: #07D0A5;

        b {
            color: white
        }
    `,
    cardGrid: css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(9em, 1fr));
        width: 100%;
    `,
    testCategory: css`
        flex-basis: 0;
        flex-grow: 1;
        width: 7.5em;
        height: 7.5em;
    `,
    topButtonWrapper: css`
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 2em;
        flex-wrap: wrap;
        gap: 1em 1em;

        > * {
            min-width: 15em;
        }
    `,
    inputTitle: css`
        font-size: 1em;
        margin-bottom: 1em;
        text-transform: uppercase;
    `,
    testTitle: (group) => css`
        padding-left: 8px;
        font-size: 1.5em;
        text-transform: uppercase;
        margin-top: .6em;
        color: ${getTestCategoryGroupColor(group)}
    `,
    dropdown: css`
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;
        cursor: pointer;

        > div {
            padding: 1.2em;
        }

        .Dropdown-arrow {
            top: 50%;
            right: 20px;
        }
    `,
    lowerControlWrapper: css`
        width: 100%; 
        display: flex; 
        justify-content: flex-end; 
        margin-top: 2em;
        margin-right:6em;
    `
}
