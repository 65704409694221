import React, { useContext } from "react"
import AnalyticsContext from "../../context/Analytics"
import Dashboard from "./Dashboard"

export default function DashboardList() {
    const { dashboards: { activeDashboards } } = useContext(AnalyticsContext)

    return (
        <>
            {activeDashboards?.map(dash => <Dashboard key={dash.id} state={dash} />)}
        </>
    )
}