import React from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from '../../style/vars'

function PageTitle(props) {
    return (
        <h2
            css={css`
                font-family: ${fonts.title};
                font-size: 1.6875em;
                line-height: 1.5;
                margin-bottom: 0.75em;
                color: ${colors.white};
                text-transform: uppercase;
            `}
            {...props}
        />
    )
}

export default PageTitle
