import React, { createContext, useMemo } from 'react'
import useRawApiState from '../hooks/useRawApiState'

const TestCategoriesContext = createContext()

function compareWeightIds(a, b) {
    // Compare by weight
    if (a.weight < b.weight) return -1
    if (a.weight > b.weight) return 1
    // If weights are the same, compare ids
    if (a.id < b.id) return -1
    if (a.id > b.id) return 1
    return -1
}

function TestCategoriesProvider({
    ...props
}) {
    const fetchResult = useRawApiState('/api/current/test-categories?pagination=false')

    const sortedFetchResult = useMemo(() => {
        const data = fetchResult.data
        if (data && Array.isArray(data)) {
            fetchResult.data = data.toSorted(compareWeightIds)
        }
        return fetchResult
    }, [fetchResult])

    return (
        <TestCategoriesContext.Provider
            {...props}
            value={sortedFetchResult}
        />
    )
}

export default TestCategoriesContext
export { TestCategoriesProvider }
