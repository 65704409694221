import React, { createContext, useState, useEffect, useMemo } from 'react'
import messages from '../utils/translations'
import * as dateFnsLocales from 'date-fns/locale'

const LocaleContext = createContext()
export default LocaleContext

export function LocaleProvider(props) {

    const [locale, setLocale] = useState(localStorage.getItem('locale') || 'en')
    const [weekStart, setWeekStart] = useState(Number(localStorage.getItem('weekStart')) ?? 1)

    const datefnsLocale = useMemo(() => {
        return dateFnsLocales[locale]
    }, [locale])

    useEffect(() => {
        if (!locale) return
        localStorage.setItem('locale', locale)
    }, [locale])

    const localeMessages = useMemo(() => {
        return messages[locale]
    }, [locale])

    useEffect(() => {
        if (weekStart < 0 || weekStart > 6) return
        localStorage.setItem('weekStart', weekStart)
    }, [weekStart])

    return (
        <LocaleContext.Provider
            value={{
                locale,
                datefnsLocale,
                setLocale,
                locales: Object.keys(messages),
                localeMessages,
                weekStart,
                setWeekStart
            }}
            {...props}
        />
    )
}