import { css } from "@emotion/react"

export default function ModalHeader({ header, ...props }) {

    return <div css={css`
            background-color: #1C1F21;
            color: #97F6CB;
            width: 100%;
            padding:  1em 2.5em;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;
        `} {...props}>
        {header}
    </div>
}