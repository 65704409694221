import PropTypes from 'prop-types'
import _ldJsonPropTypes from './_ld-json'
import HealthEffect from './HealthEffect'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ..._ldJsonPropTypes,
            severity: PropTypes.oneOf([
                'light',
                'severe',
            ]),
            healthEffect: HealthEffect(newDepth),
        })
    }
    return () => null
}
