import { useEffect, useState, useContext, useMemo } from "react"
import FancyDropdown from "./FancyDropdown"
import { css } from "@emotion/react"
import { colors } from "../../style/vars"
import CalendarContext from "../../context/Calendar"
import { FormattedMessage } from "react-intl"
import { toZonedTime, fromZonedTime } from "date-fns-tz"
import PitchContext from "../../context/Pitch"

// user chooses time from pitch perspective, uses pitch timezone to get correct unix
// this component expects useState variable and setter props, will fill in empty initial value automatically
export default function EventDateTimePicker({ unixDate, setUnixDate, ...props }) {

    const { selectedDay } = useContext(CalendarContext)
    const [pitch] = useContext(PitchContext)

    const timeZone = useMemo(() => {
        return pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
    }, [pitch])

    const [hour, setHour] = useState(unixDate ?
        toZonedTime(unixDate, timeZone).getHours() :
        (new Date()).getHours()
    )

    const [minute, setMinute] = useState(getPrevFiveMins(unixDate ?
        toZonedTime(unixDate, timeZone).getMinutes() :
        (new Date()).getMinutes()
    ))

    useEffect(() => {
        if (!setUnixDate) return
        setUnixDate(date => {
            if (date) {
                // update unsaved value or event exists with value
                const zoned = toZonedTime(date, timeZone)
                zoned.setHours(hour, minute)
                const converted = fromZonedTime(zoned, timeZone)
                return converted
            } else {
                // new event without initial value
                const newUnix = new Date(selectedDay)
                newUnix.setHours(hour, minute)
                // conversion to treat this as zoned occurs here:
                const zonedUtcEquivalent = fromZonedTime(newUnix, timeZone)
                return zonedUtcEquivalent
            }
        })
    }, [setUnixDate, hour, minute, selectedDay, timeZone])

    return (
        <>
            <h3 style={{ marginBottom: "8px", textTransform: 'capitalize' }}>
                <FormattedMessage id='time' />
            </h3>
            <div css={css`
                display: flex;
                align-items: center;
                width: min-content;
                border-radius: 5px;
                border: 2px solid #394047;
                background-color: #2F353B;
                margin-bottom: 1em;
            `} {...props}>
                <FancyDropdown
                    options={hoursOptions}
                    css={dropdownStyle}
                    value={hoursOptions.find(x => x.value === hour)}
                    onChange={(option) => setHour(option.value)}
                    hideArrow={true}
                />
                <span css={css`color: ${colors.main1};`}>
                    :
                </span>
                <FancyDropdown
                    options={minutesOptions}
                    css={dropdownStyle}
                    value={minutesOptions.find(x => x.value === minute)}
                    onChange={(option) => setMinute(option.value)}
                    hideArrow={true}
                />
            </div>
        </>
    )
}

const dropdownStyle = css`

    height: 2.3em;
    
    > .Dropdown-control {
        height: 100%;
        padding: 0;
        min-height: 0;

        .Dropdown-placeholder {
            height: 100%;
            padding: 0 1.5em;
            display: flex;
            place-items: center;
        }

        .Dropdown-arrow-wrapper {
            display: none;
        }        
    }

    .Dropdown-option {
        display: grid;
        place-items: center;
        text-align: center;
    }
`

const hoursOptions = [
    { value: 0, label: '00' },
    { value: 1, label: '01' },
    { value: 2, label: '02' },
    { value: 3, label: '03' },
    { value: 4, label: '04' },
    { value: 5, label: '05' },
    { value: 6, label: '06' },
    { value: 7, label: '07' },
    { value: 8, label: '08' },
    { value: 9, label: '09' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
    { value: 21, label: '21' },
    { value: 22, label: '22' },
    { value: 23, label: '23' },
]

const minutesOptions = [
    { value: 0, label: '00' },
    { value: 5, label: '05' },
    { value: 10, label: '10' },
    { value: 15, label: '15' },
    { value: 20, label: '20' },
    { value: 25, label: '25' },
    { value: 30, label: '30' },
    { value: 35, label: '35' },
    { value: 40, label: '40' },
    { value: 45, label: '45' },
    { value: 50, label: '50' },
    { value: 55, label: '55' },
]

function getPrevFiveMins(mins) {
    if (!Number.isFinite(mins) || !(mins >= 0)) return 0
    while (mins % 5 !== 0) {
        mins = mins - 1
    }
    return mins
}