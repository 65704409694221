import React from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

function LoaderText({
    children = 'Loading',
    subtle = false,
}) {
    const { formatMessage } = useIntl()

    return (
        <span css={subtle ? css`opacity: 0.25;` : undefined}>
            {children ?? formatMessage({id: 'loading'})}
        </span>
    )
}

LoaderText.propTypes = {
    children: PropTypes.any,
    subtle: PropTypes.bool,
}

export default LoaderText
