import { useContext, useState, useEffect } from "react"
import BridgeContext from "../context/FrontendBridge"

export default function useBridge(resource, query) {
    const { getBridge } = useContext(BridgeContext)
    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let isMounted = true

        if (resource) {
            getBridge(resource, query).then(fetchedData => {
                if (isMounted) {
                    setData(fetchedData)
                    setLoading(false)
                }
            }).catch(() => {
                if (isMounted) {
                    setLoading(false)
                }
            })
        } else {
            setLoading(false)
        }

        return () => {
            isMounted = false
        }
    }, [resource, getBridge])

    return { data, loading }
}