import { useCallback, useContext, useLayoutEffect, useState } from 'react'
import log from 'loglevel'
import Raw from '../services/Raw'
import Auth from '../context/Auth'
import LocaleContext from '../context/Locale'

// simplified version of useRaw
export default function useRawV2(
    resource,
    dependencies = [],
    {
        method = 'GET',
        autoFetch = true,
        body,
    } = {}
) {
    if (!Array.isArray(dependencies)) {
        throw new Error('useRaw: received dependencies were not an array')
    }

    const { jwt } = useContext(Auth)
    const { locale } = useContext(LocaleContext)

    const [data, setData] = useState(undefined)
    const [waiting, setWaiting] = useState(true) // waiting for first fetch to complete
    const [busy, setBusy] = useState(false) // currently fetching
    const [ok, setOk] = useState(false) // most recent fetch was successfull
    const [failed, setFailed] = useState(false) // most recent fetch failed

    const fetch = useCallback(async (overrideBody) => {
        if (!resource) return
        const options = {
            body: overrideBody || body,
            jwt: jwt || undefined,
            acceptLanguage: locale,
        }

        // start call, reset previous meta states
        setBusy(true)

        // do call, handle result async
        Raw.fetchJson(method, resource, options)
            .then((data) => {
                setData(data)
                setOk(true)
                setFailed(false)
            })
            .catch((error) => {
                setData(undefined)
                setOk(false)
                setFailed(true)
                log.error(error)
            })
            .finally(() => {
                setWaiting(false)
                setBusy(false)
            })
    }, [method, resource, jwt, body, locale])

    useLayoutEffect(() => {
        if (autoFetch) {
            // if there are dependencies, and they are not all set to a value, do not fetch
            if (Array.isArray(dependencies)) {
                for (const dependency of dependencies) {
                    if (typeof dependency !== 'number' && typeof dependency !== 'string') {
                        return
                    }
                }
            }
            fetch()
        }
    }, [...dependencies, fetch])

    return [
        data,
        {
            waiting,
            busy: (busy || waiting),
            ok,
            failed,
            fetch,
        },
    ]
}
