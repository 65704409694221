import { css } from '@emotion/react'
import React, { useContext } from 'react'
import AnalyticsContext from '../../../context/Analytics'
import { colors } from '../../../style/vars'

export default function ParameterToggle({ dashId, parameter, isSecondary, ...props }) {
    const { name, flexOrder, label, selectedValue} = parameter
    const {dashboards: { setParameter }} = useContext(AnalyticsContext)

    const uniqueId = name + dashId

    return (
        <div css={css`
            order: ${flexOrder};
            user-select: none;
        `}>
            <label
                htmlFor={uniqueId}
                css={style.labelStyle}
            >
            <input
                id={uniqueId}
                type="checkbox"
                css={style.inputStyle}
                defaultChecked={selectedValue}
                onChange={e => {
                    const boolString = e.target.checked ? 'True' : 'False'
                    setParameter(dashId, name, boolString, isSecondary)
                }}
                className='parameterToggle'
                tableau-variable-name={name}
            />
            <div css={style.artificialCheckbox} /> 
                {label}
            </label>
        </div>
    )
}

const style = {
    inputStyle: css`
        visibility: hidden;
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        overflow: hidden;

        &:checked + div {
            background: ${colors.main1};
            border: none;
        }

        &:hover + div {
            opacity: 0.8;
        }
    `,
    artificialCheckbox: css`
        height: 16px;
        width: 16px;
        margin-right: 3px;
        border: 2px solid ${colors.soft};
        display: inline-block;
        border-radius: 10px;
        transform: translateY(1px);
    `,
    labelStyle: css`
        font-family: oswald;
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer;
    `
}