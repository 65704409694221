import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'

export default (depth = 1) => {
    if (depth >= 0) {
        // const newDepth = depth - 1;
        return PropTypes.shape({
            ...ldJsonPropTypes,
            frontendCode: PropTypes.oneOf([
                'bad',
                'neutral',
                'good',
                'verygood',
            ]),
            label: PropTypes.string,
        })
    }
    return () => null
}
