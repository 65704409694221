import React, { useContext, useCallback } from 'react'
import { css } from '@emotion/react'
import TestCategoryCheckbox from '../testCategory/TestCategoryCheckbox'
import TestCategoriesContext from '../../context/TestCategories'
import ReporterContext from '../../context/Reporter'
import { colors } from '../../style/vars'
import { useMemo } from 'react'
import { groupBy } from 'lodash'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES, MTM_LIST_TYPES, MTM_VARIABLES } from '../../utils/matomo'

export default function TestCategorySelector() {
    const { data: testCategories = [] } = useContext(TestCategoriesContext)

    const {
        testCategory1,
        setTestCategory1,
        compareTo,
        setCompareCategoryId,
        reporterBusy,
        uiBools: { disabledCompareCategory },
    } = useContext(ReporterContext)

    const groupedCategories = useMemo(() => {
        const grouped = groupBy(testCategories, 'group')
        for (const [key, array] of Object.entries(grouped)) {
            grouped[key] = array.toSorted((a, b) => {
                // Compare by weight
                if (a.weight < b.weight) return -1
                if (a.weight > b.weight) return 1
                // If weights are the same, compare ids
                if (a.id < b.id) return -1
                if (a.id > b.id) return 1
                return -1
            })
        }
        return grouped
    }, [testCategories])

    const track = useTracker()

    const handleSetCategory = useCallback((testCategory) => {
        const id = testCategory.id

        if (disabledCompareCategory) {
            if (id === testCategory1) {
                return setTestCategory1(undefined)
            } else {
                return setTestCategory1(id)
            }
        }

        if (compareTo?.pitch) {
            track({
                'event': MTM_EVENT_TYPES['option-select'],
                [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['testCategory-select'],
                [MTM_VARIABLES['list-value']]: testCategory.name
            })
            return setTestCategory1(id)
        }

        if (id === testCategory1) {
            setTestCategory1(compareTo?.category)
            setCompareCategoryId(undefined)
        }
        else if (id === compareTo?.category) {
            setCompareCategoryId(undefined)
        }
        else {
            track({
                'event': MTM_EVENT_TYPES['option-select'],
                [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['testCategory-select'],
                [MTM_VARIABLES['list-value']]: testCategory.name
            })
            setCompareCategoryId(testCategory1)
            setTestCategory1(id)
        }
    }, [testCategory1, compareTo, disabledCompareCategory])

    return (
        <div css={style.group}>
            {Object.entries(groupedCategories).map(([groupName, groupData]) => (
                <div key={groupName}>
                    <h3 style={{
                        color: groupName == 'player' ? colors.player : groupName == 'pitch' ? colors.pitch : colors.super, //colors.pitch, 
                        fontWeight: 'bold',
                        fontSize: '20px'
                    }}>
                        {groupName.toUpperCase()}
                    </h3>
                    <div css={style.wrapper}>
                        {groupData.map((testCategory) => (
                            <TestCategoryCheckbox
                                key={testCategory.id}
                                style={{
                                    fontSize: '0.5em',
                                    opacity: reporterBusy && 0.6,
                                    pointerEvents: reporterBusy && 'none',
                                }}
                                testCategory={testCategory}
                                checked={testCategory.id === testCategory1 || testCategory.id === compareTo?.category}
                                onClick={() => { handleSetCategory(testCategory) }}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
}

const style = {
    group: css`
        display: flex;
        flex-direction: column;
    `,
    wrapper: css`
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 -8px;
`}