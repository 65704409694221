import React, { useContext } from 'react'
import dataDownloadIcon from '@assets/icons/data-download-icon.svg'
import imgDownloadIcon from '@assets/icons/image-download-icon.svg'
import pdfDownloadIcon from '@assets/icons/pdf-download-icon.svg'
import AnalyticsContext from "../../context/Analytics"
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'
import { useCallback } from 'react'
import { MTM_EVENT_TYPES, MTM_FILE_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { FormattedMessage } from 'react-intl'
import Cta from '../_control/Cta'

export default function DownloadModal({ ...props }) {

    const { dashboards: { downloadTarget, setDownloadTarget } } = useContext(AnalyticsContext)

    const track = useTracker()

    const trackDownload = useCallback((fileType) => {
        track({
            'event': MTM_EVENT_TYPES['file-download'],
            [MTM_VARIABLES['file-type']]: fileType
        })
    }, [])

    return (downloadTarget &&
        <div
            onClick={() => { setDownloadTarget(null) }}
            css={style.card}
            {...props}>
            <div css={style.wrapper}>
                <div css={style.option}>
                    <img
                        src={dataDownloadIcon}
                        alt="data"
                        onClick={() => {
                            downloadTarget.showExportDataDialog()
                            trackDownload(MTM_FILE_TYPES.csv)
                            setDownloadTarget(null)
                        }}
                    />
                    <div>Data</div>
                </div>
                <div css={style.option}>
                    <img
                        src={imgDownloadIcon}
                        alt="image"
                        onClick={() => {
                            downloadTarget.showExportImageDialog()
                            trackDownload(MTM_FILE_TYPES.png)
                            setDownloadTarget(null)
                        }}
                    />
                    <div>Image</div>
                </div>
                <div css={style.option}>
                    <img
                        src={pdfDownloadIcon}
                        alt="pdf"
                        onClick={() => {
                            downloadTarget.showExportPDFDialog()
                            trackDownload(MTM_FILE_TYPES.pdf)
                            setDownloadTarget(null)
                        }}
                    />
                    <div>PDF</div>
                </div>
            </div>
            <Cta >
                <FormattedMessage id='close' />
            </Cta>
        </div>
    )
}

const style = {
    card: css`
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2em;
        padding: 1em;
        background: ${colors.substance};
        box-shadow: 0 0.2em 0.8em 0.1em black;
        cursor: pointer;

        animation-name: slide-down;
        animation-duration: ${timings.responsive};

        @keyframes slide-down {
            from {transform: translateY(100%)}
            to {transform: translateY(0)}
        }
    `,
    wrapper: css`
        display: flex;
        justify-content: center;
        gap: 5em;
    `,
    option: css`
        display: flex; 
        flex-direction: column; 
        align-items: center;
        background: ${colors.dark};
        aspect-ratio: 1;
        border-radius: 5px;
        padding: 0.5em;

        &:hover {
            background: ${colors.eventDark}
        }
    `
}