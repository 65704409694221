import React, { useCallback, useMemo } from 'react'
import { interpolateRgbBasis } from 'd3'
import { css } from '@emotion/react'

export default function ZoneTransition({ zone, zoneResults, allowedDecimals, smallestZoneDimLength, ...props }) {

    const [diffRight, diffBottom] = useMemo(() => {
        const compareZoneRight = zoneResults.find(zR => (zR.posX === zone.posX && zR.posY === zone.posY + 1))
        const compareZoneBottom = zoneResults.find(zR => ((zR.posX === zone.posX - 1) && zR.posY === zone.posY))

        let diffRight
        if (
            compareZoneRight &&
            compareZoneRight.measurement !== undefined &&
            compareZoneRight.measurement !== null &&
            zone.measurement !== null
        ) {
            diffRight = Math.abs(compareZoneRight.measurement - zone.measurement).toFixed(allowedDecimals)
        }

        let diffBottom
        if (
            compareZoneBottom &&
            compareZoneBottom.measurement !== undefined &&
            compareZoneBottom.measurement !== null &&
            zone.measurement !== null
        ) {
            diffBottom = Math.abs(compareZoneBottom.measurement - zone.measurement).toFixed(allowedDecimals)
        }

        return [diffRight, diffBottom]
    }, [zoneResults, zone])

    const measurementRange = useMemo(() => {
        const measurements = zoneResults?.filter(z => z.measurement !== null).map(z => z.measurement)
        if (!measurements?.length) return
        return Math.max(...measurements) - Math.min(...measurements)
    }, [zoneResults])

    const getDiffColor = useCallback((diff) => {
        const color1 = '#00FF63'

        // we want to reach 'maximum red' at 50% of our range 
        if (!measurementRange) return color1
        const fractionOfRange = diff / (measurementRange * 0.5)

        return interpolateRgbBasis([
            color1, '#EDFF00', '#F67272'
        ])(fractionOfRange)

    }, [measurementRange])

    const [diffRightColor, diffBottomColor] = useMemo(() => {
        return [
            getDiffColor(diffRight),
            getDiffColor(diffBottom)
        ]
    }, [getDiffColor, diffRight, diffBottom])

    return (
        <div
            key={props.key}
            css={css`
            position: relative; 
            color: black;
            ${!zone.weight && 'visibility: hidden;'}
        `}>

            <div css={css`
                position: absolute;
                ${smallestZoneDimLength}: 55%;
                aspect-ratio: 1;
                border-radius: 50%;
                background: rgba(255,255,255,0.05);
                top: 50%;
                left: 50%;
                translate: -50% -50%;
            `} />

            {diffRight !== undefined && <div css={css`
                position: absolute;
                display: grid;
                right: 0;
                top: 50%;
                translate: 50% -50%;
                ${smallestZoneDimLength}: 55%;
                aspect-ratio: 1;
                z-index: 1;
            `}>
                <div css={css`
                    ${smallestZoneDimLength}: 100%;
                    aspect-ratio: 1;
                    background: ${diffRightColor};
                    border-bottom-right-radius: 50%;
                    border-top-left-radius: 50%;
                    rotate: 45deg;
                    filter: drop-shadow(0 0 0.5em ${diffRightColor});
                `} />
                <div css={css`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                `}>
                    {diffRight}
                </div>
            </div>}

            {diffBottom !== undefined && <div css={css`
                position: absolute;
                display: grid;
                right: 50%;
                top: 100%;
                translate: 50% -50%;
                ${smallestZoneDimLength}: 55%;
                aspect-ratio: 1;
                z-index: 1;
            `}>
                <div css={css`
                    ${smallestZoneDimLength}: 100%;
                    aspect-ratio: 1;
                    background: ${diffBottomColor};
                    border-bottom-left-radius: 50%;
                    border-top-right-radius: 50%;
                    rotate: 45deg;
                    filter: drop-shadow(0 0 0.5em ${diffBottomColor});
                `} />
                <div css={css`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                `}>
                    {diffBottom}
                </div>
            </div>}
        </div>
    )
}