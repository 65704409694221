import React, { useMemo } from 'react'
import { css } from '@emotion/react'
import capitalize from 'voca/capitalize'
import Test from '../../prop-types/Test'
import { colors, fonts, backendColors } from '../../style/vars'
import DetailCard from '../_layout/DetailCard'
import ScoreIndicator from '../_general/ScoreIndicator'
import KeyValue from '../_general/KeyValue'
import Loader from '../_general/Loader'
import TestCategoryIcon from '../testCategory/TestCategoryIcon'
import Pitch from '../../prop-types/Pitch'
import LoaderText from '../_general/LoaderText'
import getTestCategoryGroupColor from '../../utils/getTestCategoryGroupColor'
import { avgFromTest, rangePercentageFromTest } from '../../utils/analysisCalculations'
import { useIntl } from 'react-intl'

const style = {
    head: css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        background-color: ${colors.black};
        color: ${colors.white};
        padding: 1.5em 2em;
        padding-bottom: 1.25em;
        min-height: 10em;
        font-family: ${fonts.main};
    `,
    name: css`
        font-family: ${fonts.special};
        font-size: 1.625em;
        text-transform: uppercase;
        font-weight: 700;
    `,
    venueName: css`
        display: block;
        font-size: 1.0625em;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        color: ${colors.soft};
        margin-bottom: 0.5em;
    `,
    pitchName: css`
        display: block;
        font-size: 1.25em;
        font-weight: 300;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    `,
}

function TestCard({ test, pitch, ...props }) {

    const [averageMeasurement, rangePercentage] = useMemo(() => {
        return [avgFromTest(test), rangePercentageFromTest(test)]
    }, [test])

    const { formatMessage } = useIntl()

    return (
        <DetailCard {...props}>
            {{
                head: (
                    <div css={style.head} >
                        <div css={css`display: flex; justify-content: space-between; align-items: center;`}>
                            {(test && test.testCategory)
                                ?
                                <TestCategoryIcon
                                    css={css`height: 3.5em; width: 3.5em;`}
                                    iconName={test.testCategory.icon}
                                    color={getTestCategoryGroupColor(test.testCategory.group)}
                                />
                                :
                                <Loader size='3.5em' />
                            }
                            {(test && test.score && test.score.assessment) &&
                                <ScoreIndicator
                                    value={test.score.score}
                                    color={backendColors[test.score.assessment.frontendCode]}
                                />
                            }
                        </div>
                        <h3 css={style.name}>
                            {(test && test.testCategory)
                                ? test.testCategory.name
                                : <LoaderText subtle />
                            }
                        </h3>
                    </div>
                ),
                body: (
                    <div css={css`display: grid; grid-gap: 1em; padding: 2em;`}>
                        <KeyValue
                            k={formatMessage({ id: 'sportsField' })}
                            v={pitch
                                ?
                                <span>
                                    {pitch.venue && `${pitch.venue.name} - `}
                                    {pitch.name}
                                </span>
                                :
                                <LoaderText subtle />
                            }
                        />
                        <KeyValue
                            k={formatMessage({ id: 'testCategory' })}
                            v={(test && test.testCategory)
                                ? capitalize(test.testCategory.group)
                                : <LoaderText subtle />
                            }
                        />
                        <KeyValue
                            k={formatMessage({ id: 'averageValue' })}
                            v={test ?
                                averageMeasurement === undefined ?
                                    formatMessage({ id: 'notApplicableAbbr' })
                                    : averageMeasurement
                                : <LoaderText subtle />}
                        />
                        <KeyValue
                            k={formatMessage({ id: 'dataRange' })}
                            v={test ?
                                rangePercentage === undefined ?
                                    formatMessage({ id: 'notApplicableAbbr' })
                                    : rangePercentage
                                : <LoaderText subtle />}
                        />
                    </div>
                ),
            }}
        </DetailCard>
    )
}

TestCard.propTypes = {
    test: Test(),
    pitch: Pitch(),
}

export default TestCard
