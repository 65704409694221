import React, { createContext } from "react"
import PropTypes, { number, string } from "prop-types"
import useRaw from "../hooks/useRawV2"
import { useSearchParams } from "react-router-dom"

const VenueContext = createContext()
export default VenueContext

export function QueryBasedVenueProvider({ children }) {
    const [searchParams] = useSearchParams()
    return (
        <VenueProvider venueId={searchParams.get('venue')} children={children} />
    )
}

export function VenueProvider({ venueId, ...props }) {
    let venueResult = [undefined]
    if (venueId) venueResult = useRaw(`/api/current/venues/${venueId}`, [venueId])

    return <VenueContext.Provider value={venueResult} {...props} />
}

VenueProvider.propTypes = {
    venueId: PropTypes.oneOfType([number, string])
}

