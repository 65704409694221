import React, { useMemo, useContext, useEffect } from 'react'
import { css } from '@emotion/react'
import AnalyticsContext from '../../../context/Analytics'
import { useState } from 'react'
import { format } from 'date-fns'
import { colors, timings, fonts } from '../../../style/vars'

export default function DashDateFilter({ dashId, filter, isSecondary, ...props }) {
    const { flexOrder, name } = filter

    const { dates: { lastDateFilter }, dashboards: { setActiveDashboards, setFilter } } = useContext(AnalyticsContext)
    //initialise this component with the currently APPLIED start- and enddate values    
    const [start, setStart] = useState(lastDateFilter?.[0])
    const [end, setEnd] = useState(lastDateFilter?.[1])
    const [isSynced, setIsSynced] = useState(true)

    useEffect(() => {
        if (!isSynced || lastDateFilter === undefined) return
        const [startDate, endDate] = lastDateFilter
        setStart(startDate)
        setEnd(endDate)
    }, [lastDateFilter])

    const [displayStart, displayEnd] = useMemo(() => {
        return [
            start === null ? undefined : format(start, 'yyyy-MM-dd'),
            end === null ? undefined : format(end, 'yyyy-MM-dd'),
        ]
    }, [start, end])

    return (
        <div css={[style.wrapper, css`order: ${flexOrder};`]}
            {...props}
        >
            <input
                type="date"
                value={displayStart}
                onChange={(e) => setStart(e.target.valueAsDate)}
                css={style.input}
                tableau-variable-name={name}
                className='dashDateFilter start'
            />
            <input
                type="date"
                value={displayEnd}
                onChange={(e) => setEnd(e.target.valueAsDate)}
                css={style.input}
                tableau-variable-name={name}
                className='dashDateFilter end'
            />
            <button
                onClick={() => {
                    setFilter(
                        dashId,
                        name,
                        { min: start, max: end },
                        isSecondary
                    )
                    // after using this, disconnect this dashboard from the global date filter.
                    setActiveDashboards(current => {
                        return current.map(d => {
                            if (d.id !== dashId) return d
                            const usesGlobal = !isSecondary ?
                                d.behavior.globalDateFilter :
                                d.secondaryDashboard.behavior.globalDateFilter
                            if (!usesGlobal) return d
                            if (!isSecondary) d.behavior.globalDateFilter = false
                            else d.secondaryDashboard.behavior.globalDateFilter = false
                            return d
                        })
                    })
                    setIsSynced(false)
                }}
                css={style.confirm}
            >
                &#10003; {/* checkmark */}
            </button>
        </div>
    )
}


const style = {
    wrapper: css`display: flex;`,
    input: css`
        font-size: 0.8em;
        color: ${colors.soft};
        cursor: pointer;
        background: ${colors.eventDark};
        border-radius: 5px;
        text-align: center;
        font-family: ${fonts.special};
        margin: 0 1px;

        //for safari:
        display: flex;
        justify-content: center;
        align-items: center;
        //
        transition: color, ${timings.responsive};
        
        &:hover{ 
            color: ${colors.white};
            opacity: 0.8;
        }

        &::-webkit-calendar-picker-indicator{
            filter: invert();
            opacity: 0.8;
            cursor: pointer;
            transform: scale(1.4);
        }
    `,
    confirm: css`
        border: none;
        border-radius: 5px;
        width: 32px;
        height: 32px;
        color: ${colors.main1};
        background: ${colors.eventDark};
        cursor: pointer;
        align-self: center;

        :hover {
            opacity: 0.8;
        }
    `
}