import React, { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { useDrag } from 'react-dnd'
import dndTypes from '../../constants/dnd-types'
import EventTypeIcon from './EventTypeIcon'
import EventFilterContext from '../../context/EventFilter'
import { ReactSVG } from 'react-svg'
import filterImg from '../../assets/icons/filter.svg'
import { colors } from '../../style/vars'
import { getAllEventNames } from '../../utils/eventUtils'
import { useIntl } from 'react-intl'

function EventType({
    eventType,
    setExpanded,
    ...props
}) {
    const [{ isDragging }, drag] = useDrag({
        type: dndTypes.EVENTTYPE,
        item: { eventType },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const [filter, setFilter] = useContext(EventFilterContext)

    const handleFilterChange = useCallback(() => {
        if (!getAllEventNames().includes(eventType.entityShortName)) return
        if (filter.includes(eventType.entityShortName)) {
            setFilter(current => [...current].filter(val => val !== eventType.entityShortName))
        } else {
            setFilter(current => [...current, eventType.entityShortName])
        }
    }, [filter])

    useEffect(() => {
        if (isDragging) setExpanded(false)
    },[isDragging])

    const { formatMessage } = useIntl()

    return (
        <div
            {...props}
            ref={drag}
            css={css`
                display: flex;
                align-items: center;
                margin: 0.5em;
                cursor: grab;
                opacity: ${isDragging ? '0.5' : '1'};
            `}
            title={formatMessage({ id: 'dragToAddEvent' })}
        >
            <EventTypeIcon eventType={eventType.entityShortName} />
            <label
                title={formatMessage({ id: 'toggleFilter' })}
                css={css`
                    margin-left: 0.75em;
                    font-size: 1.25em;
                    cursor: pointer;
                    height: 2.5em;
                    display: grid;
                    place-items: center;
                    userSelect: none;

                    &:hover {
                        opacity: 0.5;
                    }
                    &:active{
                        opacity: 1;
                    }
                `}
                onClick={() => handleFilterChange()}
            >
                {eventType.entityShortNameLocalised}
            </label>
            {filter.includes(eventType.entityShortName) &&
                <ReactSVG
                    src={filterImg}
                    style={{
                        fill: colors.solid,
                        height: '1.5em',
                        width: '1.5em',
                        marginLeft: '0.2em'
                    }}
                />
            }

        </div>
    )
}
EventType.propTypes = {
    setExpanded: PropTypes.func,
    eventType: PropTypes.shape({
        entityShortName: PropTypes.string,
        path: PropTypes.string,
    }),
}

export default EventType
