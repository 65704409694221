import React, { createContext, useMemo } from "react"
import PropTypes from 'prop-types'
import getPhysicalAssessmentColor from "../utils/getPhysicalAssessmentColor"
import PhysicalAssessment from "../prop-types/PhysicalAssessment"

const HighlightsContext = createContext()
export default HighlightsContext

export function HighlightsProvider({ physicalAssessments, ...props }) {
    const highlights = useMemo(() => {
        const h = {}
        if (!physicalAssessments) return h
        for (const physicalAssessment of physicalAssessments) {
            const { healthEffect: { bodyPart } = {} } = physicalAssessment
            if (!h[bodyPart]) {
                h[bodyPart] = physicalAssessment
            } else {
                h[bodyPart] = getEffectToShow(h[bodyPart], physicalAssessment)
            }
        }
        for (const [key, val] of Object.entries(h)) {
            h[key] = getPhysicalAssessmentColor(val)
        }
        return h
    }, [physicalAssessments])
    return <HighlightsContext.Provider value={highlights} {...props} />
}

HighlightsProvider.propTypes = {
    physicalAssessments: PropTypes.arrayOf(
        PhysicalAssessment(),
    ),
}

function getEffectToShow(ass1, ass2) {
    // resolve conflicts by showing negatives before positives, and then severes before lights
    const positionMap = {
        "negative-severe": 4,
        "negative-light": 3,
        "positive-severe": 2,
        "positive-light": 1,
    }

    const lookup1 = `${ass1.healthEffect.negativeEffect ? 'negative' : 'positive'}-${ass1.severity}`
    const lookup2 = `${ass2.healthEffect.negativeEffect ? 'negative' : 'positive'}-${ass2.severity}`

    return positionMap[lookup1] >= positionMap[lookup2] ? ass1 : ass2
}