import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import { fonts } from '../../style/vars'
import Profile from '../../context/Profile'
import useRaw from '../../hooks/useRaw'
import ZoneResults from '../test/ZoneResults'
import Modal from '../_layout/Modal'
import Cta from '../_control/Cta'
import MeasurementPicker from '../_control/MeasurementPicker'
import Test from '../../prop-types/Test'
import useTracker from '../../hooks/useTracker'
import { MTM_EVENT_TYPES } from '../../utils/matomo'
import { FormattedMessage } from 'react-intl'

function TestZoneResultsEditor({
    test,
    backgroundType,
}) {
    const {
        id,
        testCategory,
        rawCertified,
        zoneResults,
    } = test

    const track = useTracker()

    const profile = useContext(Profile) || {}

    const isEditable = useMemo(() => (
        !rawCertified || profile.admin
    ), [rawCertified, profile])

    const [modifiedZoneResults, setModifiedZoneResults] = useState(zoneResults)
    useEffect(() => {
        setModifiedZoneResults(zoneResults)
    }, [zoneResults, setModifiedZoneResults])

    // selecting a zone for editing (null means modal is closed)
    const [modifiedZone, setModifiedZone] = useState(null)
    const selectZoneForModification = useCallback((zone) => {
        setModifiedZone(zone)
    }, [setModifiedZone])

    // close modal
    const closeModal = useCallback(() => {
        setModifiedZone(null)
    }, [setModifiedZone])

    // editing
    const handleChangeMeasurement = useCallback((measurement) => {
        setModifiedZone(zone => ({
            ...zone,
            measurement,
        }))
    }, [setModifiedZone])

    // saving
    const [zoneSubmit, { fetch: doSubmit, fetching: zoneSubmitBusy }] = useRaw(
        modifiedZone?.['@id'],
        [modifiedZone],
        { method: 'PUT', autoFetch: false }
    )
    const handleSave = useCallback(() => {
        doSubmit(modifiedZone)
            .then(() => {
                track({ 'event': MTM_EVENT_TYPES['input-measurement'] })
                closeModal()
            })
    }, [modifiedZoneResults, setModifiedZoneResults, modifiedZone, doSubmit, closeModal])

    useEffect(() => {
        if (zoneSubmit && zoneSubmit['@id']) {
            const zoneIndex = modifiedZoneResults.findIndex(zone => zone.id === zoneSubmit.id)
            if (zoneIndex > -1) {
                const newModifiedZoneResults = [...modifiedZoneResults]
                newModifiedZoneResults[zoneIndex] = zoneSubmit
                setModifiedZoneResults(newModifiedZoneResults)
            }
        }
    }, [zoneSubmit])

    const {
        allowedDecimals = 0,
        minRatingValue = 0,
        maxRatingValue = 100,
    } = testCategory

    return (
        <React.Fragment>
            <ZoneResults
                id={id}
                backgroundType={backgroundType}
                css={css`flex-grow: 1; height: 100%`}
                testCategory={testCategory}
                zoneResults={modifiedZoneResults}
                activeZoneId={modifiedZone && modifiedZone.id}
                enableClicks={isEditable}
                onClickZone={selectZoneForModification}
            />
            {modifiedZone &&
                <Modal css={css`width: 64em;`} onClickClose={closeModal}>
                    <div css={css`display: flex; flex-direction: column; align-items: stretch; width: 100%; gap: 2rem; height: 100%;`}>
                        <h6 css={css`font-size: 2em; font-weight: 300; font-family: ${fonts.special};`}>
                            <FormattedMessage id={'enterAMeasurement'} />
                        </h6>
                        <MeasurementPicker
                            css={css`flex-grow: 1;`}
                            value={modifiedZone.measurement}
                            onChange={handleChangeMeasurement}
                            decimals={allowedDecimals}
                            min={minRatingValue}
                            max={maxRatingValue}
                        />
                        <Cta
                            css={css`align-self: flex-end;`}
                            onClick={handleSave}
                            loading={zoneSubmitBusy}
                            signal='positive'
                        >
                            <FormattedMessage id={'save'} />
                        </Cta>
                    </div>
                </Modal>
            }
        </React.Fragment>
    )
}

TestZoneResultsEditor.propTypes = {
    test: Test(),
    backgroundType: ZoneResults.propTypes.backgroundType,
}

export default TestZoneResultsEditor
