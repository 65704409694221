import { toPng } from 'html-to-image'
import { colors } from '../style/vars'

async function conversion(DomNode) {
    let dataUrl
    try {
        console.debug("converting node", DomNode)
        dataUrl = await toPng(DomNode, { backgroundColor: colors.dark })
    }
    catch (e) {
        dataUrl = null
        console.error(e)
    } finally {
        return dataUrl
    }
}

export default conversion