import React, { createContext, useMemo } from 'react'
import useRawApiState from '../hooks/useRawApiState'

const SportsContext = createContext()

function SportsProvider({ ...props }) {
    const { data, ...state } = useRawApiState('/api/current/clubs?pagination=false')

    const sports = useMemo(() => {
        if (!data) return
        const sportsOfClubs = data?.map(club => club.sport)
        const sportsList = [...(new Set(sportsOfClubs))]
        sportsList.sort((a,b) => a > b)
        const filtered = sportsList.filter(sport => sport !== '')
        return filtered.length > 0 ? filtered : ['football']
    }, [data])

    return (
        <SportsContext.Provider
            {...props}
            value={{
                data: sports ?? [],
                ...state
            }}
        />
    )
}

export default SportsContext
export { SportsProvider }