import React from "react"
import { css } from "@emotion/react"
import { useContext, useMemo } from "react"
import ReporterContext from "../../context/Reporter"
import useRawApiState from "../../hooks/useRawApiState"
import { getDateKey } from "./util"
import Loader from "../_general/Loader"
import { colors, fonts, timings } from "../../style/vars"
import GridListTest from "./GridListTest"
import GridListEvent from "./GridListEvent"
import FallbackMessage from "../_general/FallbackMessage"
import { FormattedMessage } from "react-intl"

export default function GridList({ ...props }) {

    const { multiEventTypeFilter, events, tests1: tests = [], pitchId, reporterBusy } = useContext(ReporterContext)

    const filteredEvents = useMemo(() => {
        return events?.filter(e => multiEventTypeFilter.includes(e.type)) || []
    }, [multiEventTypeFilter, events])

    const testsEventsTimeline = useMemo(() => {
        const combined = [
            ...filteredEvents.map(event => ({
                timestamp: event.timestamp,
                dateKey: getDateKey(new Date(event.timestamp * 1000)),
                type: 'event',
                data: event
            })),
            ...tests.map(test => ({
                timestamp: test.analysisDate,
                dateKey: getDateKey(new Date(test.analysisDate * 1000)),
                type: 'test',
                data: test
            }))
        ]

        combined.sort((a, b) => a.timestamp - b.timestamp)

        let band = false
        let currentKey
        const enriched = combined.map(moment => {
            const dateKey = moment.dateKey
            if (dateKey !== currentKey) {
                currentKey = dateKey
                band = !band
                return { ...moment, band, showDate: true }
            } else {
                return { ...moment, band, showDate: false }
            }
        })

        return enriched
    }, [filteredEvents, tests])

    const { data: pitch } = useRawApiState(
        pitchId && `/api/current/pitches/${pitchId}`, {}, [pitchId]
    )

    const isBusy = useMemo(() => {
        return reporterBusy || !pitch
    }, [reporterBusy, pitch])

    if (isBusy) return <Loader size='4em' css={listStyles.loader} />

    return <div
        css={listStyles.grid}
        {...props}
    >
        {testsEventsTimeline.length === 0 &&
            <FallbackMessage css={listStyles.fallbackMessage}>
                <FormattedMessage id='noDataAvailable' />
            </FallbackMessage>
        }
        {testsEventsTimeline.map(moment => {
            const key = moment.data['@id']
            return moment.type === 'event' ?
                <GridListEvent
                    key={key}
                    eventMoment={moment}
                    pitch={pitch}
                    styles={momentStyles}
                />
                : moment.type === 'test' ?
                    <GridListTest
                        key={key}
                        testMoment={moment}
                        pitch={pitch}
                        styles={momentStyles}
                    />
                    : null
        })}
    </div>
}

const listStyles = {
    loader: css`
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate(-50%, -50%);
    `,
    grid: css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        grid-auto-rows: min-content;
        flex-shrink: 1;
        overflow: auto;
        gap: 0.5em 0;
        padding: 0 0.5em;
    `,
    fallbackMessage: css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5em;
        width: max-content;
        max-width: 90%;
    `
}

const momentStyles = {
    momentGrid: (band) => css`
        display: grid;
        height: 100%;
        min-height: 10em;
        background: ${band ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0.03)'};
        grid-template-rows: 2em 1.5em 1fr;
        padding: 0.5em 0.75em;
        border-right: 1px solid rgba(255,255,255,0.02);
        border-left: 1px solid rgba(255,255,255,0.02);
        gap: 0.2em;

        h3 {
            font-size: 1.2em;
        }
    `,
    title: (showDate) => css`
        opacity: ${showDate ? 1 : 0.15}; 
        color: ${colors.soft}; 
        font-family: ${fonts.special};
        text-transform: uppercase;
    `,
    header: css`
        display: flex; 
        gap: 0.5em; 
        align-items: center; 
        justify-content: space-between;
        border-bottom: 2px solid rgba(255,255,255,0.4);
    `,
    subtitle: css`
        display: flex; 
        justify-content: space-between; 
        align-items: center;
        color: ${colors.soft};
    `,
    clickable: css`
        cursor: pointer;
        transition: outline ${timings.smooth};
        outline: 1px solid transparent;
        &:hover {
            outline: 1px solid ${colors.soft};
        }
    `,
}