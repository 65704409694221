export function valueToStorageString(value) {
    switch (typeof value) {
        case 'object':
            return JSON.stringify(value)
        case 'undefined':
            return '' // JSON.parse can't process the real undefined
        default:
            return value
    }
}

export function valueFromStorageString(str) {
    try {
        return str === '' ? undefined : JSON.parse(str) // handle 'undefined', as seen above
    } catch { // if we can't parse JSON, we just use the string value
        return str
    }
}
