import { useCallback, useState } from 'react'
import log from 'loglevel'

export default function useChecked(initialState) {
    const [checked, setChecked] = useState(!!initialState)

    const handleChange = useCallback((e) => {
        setChecked(e.target.checked)
        log.debug(`checked value: ${e.target.checked}`)
    }, [])

    return [
        checked,
        handleChange,
    ]
}
