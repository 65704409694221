import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { colors, fonts } from '../../style/vars'

const style = {
    title: css`
        font-size: 1.5em;
        font-family: ${fonts.special};
        color: ${colors.subtle};
        margin-bottom: 0.5em;
        margin-left: 0.25em;
        line-height: 1.5;
    `,
}

function InstructionSteps({ title, ...props }) {
    return (
        <div css={css`line-height: 1.5;`} {...props}>
            <h5 css={style.title}>
                {title}
            </h5>
        </div>
    )
}

InstructionSteps.propTypes = {
    title: PropTypes.string,
}

export default InstructionSteps
