import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'
import { FormattedMessage } from 'react-intl'

const style = (color, bright) => css`
    border: 1px solid ${color};
    min-width: 3em;
    aspect-ratio: 1;
    padding: 0 1px;
    border-radius: 50%;
    background-color: transparent;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color, background, ${timings.smooth};
    position: relative;

    span {
        font-size: 1.2em;
        position: absolute;
    }
    
    ${bright ? `
        box-shadow: 0 0 2em ${color};
        background-color: ${color};
        color: ${colors.black};
        font-weight: 400;
    ` : ``}
`

function ScoreIndicator({
    value,
    decimals = 1,
    color = colors.main1,
    bright = false,
    ...props
}) {
    const valueString = typeof value === 'number' ? (+value).toFixed(decimals) : undefined

    // if value is null, override color to something gray
    if (value === null) {
        return (
            <div css={style(colors.liquid, bright)} {...props}>
                <span style={{ opacity: 0.66, fontSize: '1em' }}>
                    <FormattedMessage id='notApplicableAbbr' />
                </span>
            </div>
        )
    }
    return (
        <div css={style(color, bright)} {...props}>
            <span>
                {valueString}
            </span>
        </div>
    )
}

ScoreIndicator.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    decimals: PropTypes.number,
    color: PropTypes.string,
    bright: PropTypes.bool,
}

export default ScoreIndicator
