import { useCallback, useEffect, useState } from 'react'
import log from 'loglevel'
import { get } from '../services/WeatherFlow'

/**
 * @typedef {Object} returnObject
 * @property {Object} data return data
 * @property {Object} error request error
 * @property {boolean} isBusy the resource is being fetched
 * @callback fetch executes a fetch request
*/

/**
 * Custom hook to perform requests to Weatherflow Backend
 * 
 * @param {string} resource 
 * @param {Object.<string, string>} query 
 * @param {Array} dependencies 
 * @returns {returnObject} state 
 */
function useWeatherFlowState(
    resource,
    query = {},
    dependencies = []
) {
    if (!Array.isArray(dependencies)) {
        throw new Error('received dependencies were not an array')
    }

    const [data, setData] = useState(undefined)
    const [error, setError] = useState(undefined)
    const [isBusy, setIsBusy] = useState(true)

    const fetch = useCallback(async () => {
        try {
            setIsBusy(true)
            const { data, error } = await get(resource, query)
            setData(data)
            setError(error)
        } catch (err) {
            log.error(err)
            setData(undefined)
        } finally {
            setIsBusy(false)
        }
    }, [resource, get, ...Object.keys(query), ...Object.values(query)])

    useEffect(() => {
        if (!dependencies.includes(undefined)) {
            fetch()
        }
        else {
            setIsBusy(false);
            setData(undefined)
            setError(undefined)
        }
    }, [fetch, ...dependencies])

    return {
        data,
        error,
        fetch,
        isBusy,
    }
}

export default useWeatherFlowState