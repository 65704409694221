import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import Analysis from './Analysis'
import Venue from './Venue'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            name: PropTypes.string,
            stadium: PropTypes.bool,
            type: PropTypes.string,
            grassType: PropTypes.string,
            analyses: PropTypes.arrayOf(Analysis(newDepth)),
            orientation: PropTypes.oneOf(['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']),
            venue: Venue(newDepth),
        })
    }
    return () => null
}
