import React from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from '../../style/vars'

function FallbackMessage(props) {
    return (
        <span
            css={css`
                color: ${colors.subtle};
                opacity: 0.5;
                font-size: 1.5em;
                text-transform: uppercase;
                font-family: ${fonts.special};
            `}
            {...props}
        />
    )
}

export default FallbackMessage
