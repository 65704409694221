import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import EventTypeIcon from './EventTypeIcon'

const style = {
    base: css`
        flex-shrink: 0;
    `,
}

export default function Event({
    event,
    iconStyle,
    ...props
}) {
    return (
        <div {...props} css={style.base}>
            <EventTypeIcon
                style={iconStyle}
                eventType={event.type}
            />
        </div>
    )
}
Event.propTypes = {
    event: PropTypes.shape({
        timestamp: PropTypes.number,
        type: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    iconStyle: PropTypes.object,
}