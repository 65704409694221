import React, { createContext, useEffect } from 'react'
import useRaw from '../hooks/useRawV2'
import { MTM_EVENT_TYPES } from '../utils/matomo'
import useTracker from '../hooks/useTracker'

const ProfileContext = createContext([])
export default ProfileContext

export function ProfileProvider(props) {
    const profileResult = useRaw('/api/current/profile')

    const track = useTracker()

    useEffect(() => {
        // early return if we have no data yet
        if (!profileResult[0]) return
        // if non-admin, track
        if (!profileResult[0]?.admin) {
            track({ 'event': MTM_EVENT_TYPES.login })
        } else {
            console.log('SETTING DO-NOT-TRACK FOR ADMIN')
            // if admin, manually set tracking ignore cookie for the session
            document.cookie = `mtm_consent_removed=${new Date().getTime()}; SameSite=Lax;`
        }
    }, [profileResult[0]])

    return (
        <ProfileContext.Provider
            value={profileResult}
            {...props}
        />
    )
}
