import React, { useContext } from 'react'
import { css } from '@emotion/react'
import AnalyticsContext from '../../../context/Analytics'
import trashIcon from '@assets/icons/trash.svg'
import { colors } from '../../../style/vars'
import { ReactSVG } from 'react-svg'

export default function Dispose({ id, ...props }) {

    const { dashboards: { setActiveDashboards } } = useContext(AnalyticsContext)

    return (
        <button
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;

                border-radius: 4px;
                border: none;
                margin-left: 1px;
                width: 32px;
                height: 32px;
                margin-top: 1px;
                margin-bottom: 1px;
                cursor: pointer;

                background: ${colors.eventDark};

                &:hover {
                    opacity: 0.8;
                }
            `}
            onClick={() => setActiveDashboards(current => {
                return current.filter(d => d.id !== id)
            })}
            title='close'
        >
            <ReactSVG src={trashIcon} css={css`
                div {
                    width: 20px;
                    height: 20px;
                    fill: ${colors.soft};
                }
            `} />
        </button>
    )
}