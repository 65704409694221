import log from 'loglevel'

export default class Raw {
    static async fetchJson(method, resource, { jwt = undefined, body = undefined, returnOnError = false, acceptLanguage = 'en' }) {
        const options = {
            method,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/ld+json',
                'Accept-Language': acceptLanguage
            }
        }
        if (jwt) {
            options.headers.Authorization = `Bearer ${jwt}`
        }
        if (body) {
            try {
                options.body = JSON.stringify(body)
            } catch {
                throw new Error('Could not parse body to JSON, are you sure you passed an object?')
            }
        }

        log.debug(`🔼 ${method} ${resource} ${jwt ? '(with jwt)' : ''}`)
        const response = await fetch(`${process.env.API_URL}${resource}`, options)
        let json
        try {
            json = await response.json()
        }
        catch (e) { }
        log.debug(
            `🔽 ${method} ${resource} ${jwt ? '(with jwt)' : ''}`, '\n',
            json, '\n'
        )
        if (!returnOnError && !response?.ok) {
            throw new Error('Tried fetching JSON, but response was not OK.')
        }
        return json
    }

    static async getJwt(email, password) {
        const json = await Raw.fetchJson('POST', '/api/authenticate', {
            body: {
                email,
                password,
            },
        })
        return json.token
    }

    static async getRefreshedJwt(jwt) {
        const json = await Raw.fetchJson('GET', '/api/current/refresh-token', { jwt })
        return json.token
    }

}
