import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { format, getUnixTime, startOfDay, subDays } from 'date-fns'
import { colors } from '../../style/vars'
import { scaleLinear } from 'd3'
import { debounce } from 'lodash'
import ReporterContext from '../../context/Reporter'
import LocaleContext from '../../context/Locale'

// uses D3 for the math-y stuff, react for rendering
export default function GenericTimeSeries({
    yAxisOneMin,
    yAxisOneMax,
    yAxisTwoMin,
    yAxisTwoMax,
    domainRender,
    overlay,
    showGrid = true,
    graphStyle,
    ...props
}) {

    const { datefnsLocale } = useContext(LocaleContext)

    const {
        useResultsAsAxis,
        setHighlightDate,
        compareTo,
        untilDate,
        lineColor1: yAxisOneColor,
        lineColor2: yAxisTwoColor,
        zoomOut,
    } = useContext(ReporterContext)

    // refs for working with scrolling
    const wrap = useRef()
    const dragLoc = useRef()
    const dragDistance = useRef()
    const lastZoomState = useRef()

    // timestamp
    const unixUntilDate = useMemo(() => (
        getUnixTime(untilDate)  // check if this is needed
    ), [untilDate])

    // initially calculate dimensions and on resize update
    const [dim, setDim] = useState({ w: 0, h: 0 })
    const recalcDims = useCallback(() => {
        if (wrap.current) {
            const { width, height } = wrap.current.getBoundingClientRect();
            setDim({
                w: width,
                h: height,
            })
        }
    }, [])

    const pixelsPerDay = useMemo(() => {
        return zoomOut ? 12 : 48
    }, [zoomOut])

    const showDays = useMemo(() => {
        return Math.floor(dim.w / pixelsPerDay)
    }, [dim, pixelsPerDay])

    useLayoutEffect(() => {
        const debouncedRecalcDims = debounce(recalcDims, 50)
        debouncedRecalcDims()
        window.addEventListener('resize', debouncedRecalcDims)
        return () => {
            window.removeEventListener('resize', debouncedRecalcDims)
        }
    }, [])

    useEffect(() => {
        recalcDims()
    }, [untilDate, zoomOut])

    // distances
    const margin = useMemo(() => ({ t: 24, b: 36, l: 36, r: 0 }), [])
    const sizes = useMemo(() => ({
        contW: dim.w,
        contH: dim.h,
        graphW: dim.w - margin.l - margin.r,
        graphH: dim.h - margin.t - margin.b,
    }), [dim, margin])
    const xRangeSize = useMemo(() => (
        showDays * 24 * 60 * 60
    ), [showDays])

    // limiting and moving the virtual viewport based on pixel movement (= xDelta)
    const [xDomainOffset, setXDomainOffset] = useState(0)
    const calcAndSetXDomainOffset = useCallback((xDelta) => {
        const domainUnitsPerPixel = xRangeSize / sizes.graphW
        const stateUpdater = prev => prev - (xDelta * domainUnitsPerPixel)
        setXDomainOffset(stateUpdater)
        return stateUpdater(xDomainOffset)
    }, [xRangeSize, sizes, xDomainOffset])

    const xOffset = useMemo(() => (
        xDomainOffset / (xRangeSize / sizes.graphW)
    ), [sizes, xDomainOffset])

    // scales
    const xScale = useMemo(() => (
        scaleLinear()
            .domain([
                unixUntilDate - xRangeSize,
                unixUntilDate])
            .range([0, sizes.graphW])
    ), [xRangeSize, sizes])

    const yScale = useMemo(() => (
        scaleLinear()
            .domain([
                !useResultsAsAxis ? 0 : (yAxisOneMin ?? 0),
                !useResultsAsAxis ? 10 : (yAxisOneMax ?? 20)
            ])
            .range([sizes.graphH, 0])
    ), [sizes, yAxisOneMin, yAxisOneMax, useResultsAsAxis])

    // set up events for scrolling the graph
    useLayoutEffect(() => {
        if (wrap.current) {
            const el = wrap.current

            // scrolling
            el.onwheel = (e) => {
                const multiplier = 6
                const d = e.deltaY
                const newOffset = calcAndSetXDomainOffset(d * multiplier)
                const clickedTimestamp = xScale.invert((sizes.graphW + 40) / 2) - newOffset
                setHighlightDate(new Date(clickedTimestamp * 1000))
            }

            // dragging
            el.onmousedown = (e) => {
                dragLoc.current = e.screenX
                dragDistance.current = 0
            }
            el.onmousemove = (e) => {
                if (dragLoc.current) {
                    const d = dragLoc.current - e.screenX
                    dragLoc.current = e.screenX
                    const newOffset = calcAndSetXDomainOffset(d)
                    const clickedTimestamp = xScale.invert((sizes.graphW + 40) / 2) - newOffset
                    setHighlightDate(new Date(clickedTimestamp * 1000))
                }
                dragDistance.current += Math.abs(e.movementX) + Math.abs(e.movementY)
            }
            el.onmouseup = (e) => {
                dragLoc.current = undefined
                dragDistance.current = undefined
            }

            // touch devices
            el.ontouchstart = (e) => {
                const touch = e.touches[0]
                dragLoc.current = touch.screenX
                dragDistance.current = 0
            }
            el.ontouchmove = (e) => {
                let newOffset
                if (dragLoc.current) {
                    const touch = e.touches[0]
                    const d = dragLoc.current - touch.screenX
                    newOffset = calcAndSetXDomainOffset(d)
                    dragLoc.current = touch.screenX
                }
                dragDistance.current += Math.abs(e.movementX) + Math.abs(e.movementY)
                const clickedTimestamp = xScale.invert(sizes.graphW / 2) - newOffset
                setHighlightDate(new Date(clickedTimestamp * 1000))
            }
            el.ontouchstop = (e) => {
                dragLoc.current = undefined
                dragDistance.current = undefined
            }
        }
    }, [calcAndSetXDomainOffset])

    const yScaleCompared = useMemo(() => (
        scaleLinear()
            .domain([
                !useResultsAsAxis ? 0 : (yAxisTwoMin ?? 0),
                !useResultsAsAxis ? 10 : (yAxisTwoMax ?? 20)
            ])
            .range([sizes.graphH, 0])
    ), [sizes, yAxisTwoMin, yAxisTwoMax, useResultsAsAxis])

    // ticks
    const xTicks = useMemo(() => {
        const scale = scaleLinear()
            .domain([unixUntilDate - xRangeSize - xDomainOffset, unixUntilDate - xDomainOffset])
            .range([0, sizes.graphW])

        // amount of seconds in a day
        const tickSize = 60 * 60 * 24
        const startDate = startOfDay(new Date((unixUntilDate - xDomainOffset) * 1000))

        const ticks = []
        for (let i = 0; i < (xRangeSize / tickSize); i++) {
            const tickDate = subDays(startDate, i)
            if (zoomOut && tickDate.getDate() !== 1) continue
            ticks.push({
                label: format(tickDate, 'd MMM', { locale: datefnsLocale }),
                xOffset: scale(getUnixTime(tickDate)),
            })
        }
        return ticks
    }, [sizes, xRangeSize, xDomainOffset, unixUntilDate, zoomOut])

    const yTicks = useMemo(() => {

        if (useResultsAsAxis && yAxisOneMax) {
            const [min, max] = yScale.domain()
            const step = (max - min) / 10
            let arr = []

            for (
                let x = min; x <= max; x = parseFloat((x + step).toPrecision(15))) {
                arr.push(x)
            }

            return arr.map(value => ({
                label: value,
                yOffset: yScale(value),
            }))
        }

        return yScale.ticks(10)
            .map(value => ({
                label: value,
                yOffset: yScale(value),
            }))

    }, [yScale, yAxisOneMax, useResultsAsAxis])

    const yTicksCompared = useMemo(() => {

        if (useResultsAsAxis && yAxisTwoMax) {
            const [min, max] = yScaleCompared.domain()
            const step = (max - min) / 10
            let arr = []

            for (let x = min; x <= max; x = parseFloat((x + step).toPrecision(15))) {
                arr.push(x)
            }

            return arr.map(value => ({
                label: value,
                yOffset: yScaleCompared(value),
            }))
        }

        return yScaleCompared.ticks(10)
            .map(value => ({
                label: value,
                yOffset: yScaleCompared(value),
            }))

    }, [yScaleCompared, yAxisTwoMax, useResultsAsAxis])

    function roundToOne(num) {
        if (num >= 1000) {
            return `${num / 1000}K`
        }

        if (num > 100) {
            return Math.round(num)
        }
        return +(Math.round(num + "e+1") + "e-1");
    }

    // keep dotted line's date centered when switching zoom levels
    useEffect(() => {
        if (Number.isNaN(xRangeSize)) return // prevent crashes before first real render
        // we time this after new the graph is recalculated for the new zoom level
        // we're listening to dim changes to get domain offset AFTER rendering the new zoom, and apply changes only once
        // so we are limited in what dependencies can be added to this useEffect
        if (lastZoomState.current !== zoomOut) { // check if zoom has changed
            lastZoomState.current = zoomOut
            // set the x offsets we want
            if (zoomOut) setXDomainOffset(current => current - (60 * 60 * 24) * ((showDays / 2.666) - 1.2))
            else setXDomainOffset(current => current + (60 * 60 * 24) * ((showDays * 1.5) - 1.2))
        }
    }, [dim])

    return (
        <div {...props}>
            <div
                ref={wrap}
                style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden', cursor: 'grab', userSelect: 'none' }}
            >
                {overlay &&
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pointerEvents: 'none',
                        }}
                    >
                        {overlay}
                    </div>
                }
                {showDays &&
                    <svg width={sizes.contW} height={sizes.contH} style={graphStyle}>
                        {/* graph */}
                        <g transform={`translate(${margin.l} ${margin.t})`} >
                            {/* X axis */}
                            <g transform={`translate(0 ${sizes.graphH})`} width={margin.l}>
                                <path
                                    d={`M 0 0 H ${sizes.graphW - margin.l + 2}`}
                                    stroke='currentColor'
                                    strokeWidth={2}
                                />
                                {xTicks.map(({ label, xOffset }, i) => (
                                    <g
                                        key={label + '' + i}
                                        transform={`translate(${xOffset} 0)`}
                                    >
                                        <line
                                            y2='6'
                                            stroke='currentColor'
                                            strokeWidth={2}
                                        />
                                        <text
                                            style={{
                                                fontSize: '10px',
                                                textAnchor: 'middle',
                                                transform: 'translateY(20px)',
                                                fill: colors.white,
                                            }}
                                        >
                                            {label}
                                        </text>
                                        {showGrid &&
                                            <line
                                                y1={0}
                                                y2={-sizes.graphH}
                                                stroke='currentColor'
                                                opacity={0.1}
                                                strokeWidth={1}
                                            />
                                        }
                                    </g>
                                ))}
                            </g>

                            {/* Y axis */}
                            <g >
                                <path
                                    d={`M 0 0 V ${sizes.graphH}`}
                                    stroke={yAxisOneColor}
                                    strokeWidth={2}
                                />
                                {((yAxisOneMax || compareTo?.category) ? yTicks : yTicksCompared)?.map(({ label, yOffset }, index) => {
                                    return (
                                        <g
                                            key={label}
                                            transform={`translate(0 ${yOffset})`}
                                        >
                                            <line
                                                x2={-6}
                                                stroke='currentColor'
                                                strokeWidth={2}
                                            />
                                            <text
                                                style={{
                                                    fontSize: '10px',
                                                    textAnchor: 'end',
                                                    userSelect: 'none',
                                                    transform: 'translate(-16px, 4px)',
                                                    fill: colors.white,
                                                }}
                                            >
                                                {roundToOne(Number(label))}
                                            </text>
                                            {showGrid &&
                                                <line
                                                    x1={0}
                                                    x2={sizes.graphW}
                                                    stroke='currentColor'
                                                    opacity={index ? 0.1 : 1.0}
                                                    strokeWidth={1}
                                                />
                                            }
                                        </g>
                                    )
                                })}
                            </g>

                            <g transform={`translate(${sizes.graphW - margin.l + 2} 0)`}>
                                <rect
                                    fill='#242628'
                                    width={50}
                                    height={`${sizes.graphH + 30}`}
                                    transform={`translate(0 ${- 3})`}
                                />
                                <path
                                    d={`M 0 0 V ${sizes.graphH}`}
                                    stroke={yAxisTwoColor}
                                    strokeWidth={2}
                                />
                                {((!compareTo?.category && yAxisOneMax) ? yTicks : yTicksCompared)?.map(({ label, yOffset }) => {
                                    return (
                                        <g
                                            key={label}
                                            transform={`translate(0 ${yOffset})`}
                                        >
                                            <line
                                                x2={6}
                                                stroke='currentColor'
                                                strokeWidth={2}
                                            />
                                            <text
                                                style={{
                                                    fontSize: '10px',
                                                    textAnchor: 'end',
                                                    userSelect: 'none',
                                                    transform: 'translate(26px, 4px)',
                                                    fill: colors.white,
                                                }}
                                            >
                                                {roundToOne(Number(label))}
                                            </text>
                                        </g>
                                    )
                                })}
                            </g>

                            <clipPath id='clipGraph'>
                                {/* we offset with the top margin to prevent datapoints clipping their dot */}
                                <rect
                                    x='0'
                                    y={-margin.t}
                                    width={sizes.graphW - margin.l}
                                    height={sizes.graphH + margin.t}
                                />
                            </clipPath>
                            {/* 'virtual' scroll container, to prevent browser choking while scrolling */}
                            <path
                                d={`M ${sizes.contW / 2} 0 V ${sizes.graphH}`}
                                stroke='#fff'
                                strokeWidth={1}
                                strokeDasharray="4"
                            />
                            <g clipPath='url(#clipGraph)'>
                                <g transform={`translate(${xOffset} 0)`} >
                                    {/* data rendering */}
                                    {typeof domainRender === 'function' &&
                                        domainRender({ sizes, margin, xRangeSize, xScale, yScale, yScaleCompared })
                                    }
                                </g>
                            </g>
                        </g>
                    </svg>
                }
            </div>
        </div>
    )
}
GenericTimeSeries.propTypes = {
    overlay: PropTypes.node,
    domainRender: PropTypes.func,
    graphStyle: PropTypes.shape({}),
    style: PropTypes.shape({}),
}