
import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import FieldSectionSelector from '../_control/FieldSectionSelector'
import { getUnixTime } from 'date-fns'
import NumberScroller from '../_control/NumberScroller'
import Orientation from '../test/Orientation'
import Pitch from '../../context/Pitch'
import FormLoader from './FormLoader'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { zonesToSectionState } from '../../utils/eventUtils'

const columns = 7
const rows = 5

export default function DivotingForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [sections, setSections] = useState([])
    //other
    const [pitch] = useContext(Pitch)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/divoting-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setSections(zonesToSectionState(data.zoneResults))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)

        const payLoad = {
            timeTaken: parseInt(timeTaken * 60),
            timestamp: getUnixTime(selectedDateTime),
        }

        if (event) {
            const { data: putResponse, error } = await put(`${event['@id']}`, { body: payLoad })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            } else if (putResponse) {
                const { data: divotingData } = await get(`/api/current/divoting-events/${putResponse.id}`);

                divotingData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections[zone.posY - 1][zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, divotingData)
            }
        } else {
            const newPayLoad = {
                ...payLoad,
                name: `Divoting event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data: postResponse, error } = await post(`/api/current/${eventType.path}`, { body: newPayLoad })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (postResponse) {
                const { data: divotingData } = await get(`/api/current/divoting-events/${postResponse.id}`);
                divotingData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections[zone.posY - 1][zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, divotingData)
            }
        }
    }, [pitch, timeTaken, sections, selectedDateTime])

    const isBusy = useMemo(() => {
        return isLoading
    }, [isLoading])

    return (
        <div style={{ minHeight: '300px', width: '534px', }}>
            {isBusy ? <FormLoader /> : <>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
                <div css={style.formGroup}>
                    <div css={{ display: 'inline-flex' }}>
                        <NumberScroller
                            label={formatMessage({ id: 'timeTaken' })}
                            minVal={0}
                            maxVal={24}
                            state={timeTaken}
                            unit={formatMessage({ id: 'hours' })}
                            step={0.5}
                            setState={setTimeTaken}
                        />
                    </div>
                </div>
                <div css={style.formGroup}>
                    <h3>
                        <FormattedMessage id='areasCoveredDivoting' />
                    </h3>
                    <FieldSectionSelector
                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                        rows={rows}
                        columns={columns}
                        sections={sections}
                        onChange={setSections}
                    />
                    <Orientation
                        css={style.compass}
                        heading={pitch.orientation}
                    />
                </div>
            </>}
            {formError && <div>{formError}</div>}
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={isBusy}
            >
                <FormattedMessage id='save' />
            </button>
        </div>
    )
}

DivotingForm.propTypes = {
    pitch: PropTypes.object,
    eventType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    day: PropTypes.object,
    onPostRequest: PropTypes.func,
}

const style = {
    formGroup: css`
        margin-bottom: 1em;
        width: min-content;

        h3 {
            margin-bottom: .5em;
        }
    `,
    compass: css`
        position: relative;
        height: 2em;
        width: 2em;
        bottom: -1em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
        margin-bottom:40px;
    `,
}
