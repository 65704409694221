import React, { createContext } from "react"
import PropTypes, { number, string } from "prop-types"
import useRaw from "../hooks/useRawV2"
import { useParams } from "react-router"
import { useSearchParams } from "react-router-dom"

const PitchContext = createContext()
export default PitchContext

export function RouteBasedPitchProvider({ children }) {
    const { pitchId } = useParams()

    return (
        <PitchProvider pitchId={pitchId} children={children} />
    )
}

export function QueryBasedPitchProvider({ children }) {
    const [searchParams] = useSearchParams()

    return (
        <PitchProvider pitchId={searchParams.get('pitch')} children={children} />
    )
}

export function PitchProvider({ pitchId, ...props }) {
    let pitchResult = [{}]
    if (pitchId) pitchResult = useRaw(`/api/current/pitches/${pitchId}`, [pitchId])

    return <PitchContext.Provider value={pitchResult} {...props} />
}

PitchProvider.propTypes = {
    pitchId: PropTypes.oneOfType([number, string])
}

