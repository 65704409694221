import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors, fonts } from '../../style/vars'

function PageSubtitle(props) {
    return (
        <h1
            css={css`
                font-size: 2.25em;
                color: ${rgba(colors.soft, 0.5)};
                margin-bottom: 0.75em;
                font-family: ${fonts.special};
            `}
            {...props}
        />
    )
}

export default PageSubtitle
