import React, { useContext } from 'react'
import { css } from '@emotion/react'
import { colors, timings } from '../../style/vars'
import Profile from '../../context/Profile'
import LoaderText from '../_general/LoaderText'
import { FormattedMessage } from 'react-intl'

const style = {
    cont: css`
        padding: 1.5em;
        padding-right: 1.5em;
        color: ${colors.soft};
        position: absolute;
        top: 0;
        right: 0;
        z-index: 9999;

        @media screen and (max-width: 600px) {
            display: none;
        }
    `,
    link: css`
        text-decoration: underline;
        opacity: 0.66;
        color: inherit;
        transition: all ${timings.fast} ease;
        &:hover {
            transition: all ${timings.responsive} ease;
            color: ${colors.main1};
            opacity: 1;
        }
    `,
}

function TopBar({ ...props }) {
    const [profile] = useContext(Profile) || {}

    const fullName = profile ? `${profile.firstName} ${profile.lastName}` : <LoaderText />

    return (
        <div css={style.cont} {...props}>
            <FormattedMessage id='welcome'
                values={{userFullName: fullName}}
            />
        </div>
    )
}

export default TopBar
