// from: https://stackoverflow.com/a/4819886
export default function isTouchDevice() {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    var mq = function (query) {
        return window.matchMedia(query).matches;
    }
    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}

export const isTouch = ('ontouchstart' in window)