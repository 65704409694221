import log from 'loglevel'

export default function isIos() {
    if (window) {
        try {
            return !!navigator.platform.match(/iPhone|iPod|iPad/)
        } catch (e) {
            log.error(e)
        }
    }
}
