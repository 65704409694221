import PropTypes from 'prop-types'
import ldJsonPropTypes from './_ld-json'
import Rating from './Rating'
import TestParameter from './TestParameter'

export default (depth = 1) => {
    if (depth >= 0) {
        const newDepth = depth - 1
        return PropTypes.shape({
            ...ldJsonPropTypes,
            measurement: PropTypes.number,
            rating: Rating(newDepth),
            testParameter: TestParameter(newDepth),
        })
    }
    return () => null
}
