
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { ReactSVG } from 'react-svg'
import { css, jsx } from '@emotion/react'
import { colors } from '../../style/vars'
import PropTypes from 'prop-types'

function NumberScroller({
    isOptional,
    label,
    img,
    state,
    setState,
    minVal = 0,
    maxVal = Infinity,
    step = 1,
    unit,
    horizontal = null,
    ...props
}) {
    // currentInput is a string, as opposed to state, which is a number
    const [currentInput, setCurrentInput] = useState(state)

    useEffect(() => {
        // make sure when state changes on the outside, changes also reflect in internal state here
        setCurrentInput(state)
    },[state])

    const faded = useMemo(() => (
        currentInput === ''
    ), [currentInput])

    const precision = useMemo(() => (
        step.toString().split('.')?.[1]?.length ?? 0
    ), [step])

    /**
     * truncates input to precision of the step
     * @param {string | number} numString
     * @return {string} numString truncated to the step precision
     */
    const truncateToPrecision = useCallback((numString) => {
        //determine precision of the step
        const [base, decimals, ..._] = numString.toString().split('.')
        return (decimals === undefined || precision === 0) ?
            base
            :
            `${base}.${decimals.slice(0, precision)}`

    }, [step])

    const scrollerWidth = useMemo(() => (
        maxVal < Infinity ?
            Math.max(truncateToPrecision(maxVal).length, 3)
            : 3
    ), [maxVal, truncateToPrecision])

    // handle change of input element
    // input can be number or string containing number (see input type="number")
    // can be handled more cleanly, but that would be premature optimisation
    // TO DO clean up this function
    const handleChange = useCallback((input) => {
        // if not optional, empty will default to a value of 0, and show placeholder at 0
        // if optional, empty will default to null and show no placeholder number

        if (input === '') { // handle empty or invalid input
            setState(isOptional ? null : 0)
            setCurrentInput('')
            return
        }

        const targetValue = Number(input)

        if (targetValue > maxVal) {
            const targetState = Number(maxVal).toFixed(precision)
            setState(Number(targetState))
            setCurrentInput(targetState)
            return
        }

        if (targetValue < minVal) {
            const targetState = Number(minVal).toFixed(precision)
            setState(Number(targetState))
            setCurrentInput(targetState)
            return
        }

        const truncatedInput = truncateToPrecision(input)
        setState(Number(truncatedInput))
        setCurrentInput(truncatedInput)
    }, [setState, setCurrentInput, maxVal, minVal])

    // handle click on increment/ decrement button
    const incrementState = useCallback((value) => {
        handleChange(Number(Number(state) + Number(value)).toFixed(precision))
    }, [handleChange, state])

    const style = {
        icon: {
            width: "48px",
            fill: colors.soft,
            opacity: 0.5
        },

        arrowicon: {
            color: colors.soft,
            width: "1em"
        },

        scroller: css`
            justify-content: center;
            align-items: center;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance: textfield;
            }
        `,

        input: {
            width: `${scrollerWidth * 0.8}em`,
            backgroundColor: "transparent",
            display: "inline-flex",
            top: 1,
            color: faded ? colors.soft : colors.main1,
            background: 'rgba(255,255,255,0.07)',
            fontWeight: "bold",
            fontSize: 30,
            textAlign: "center",
            MozAppearance: "textfield",
            cursor: 'pointer',
        },
        button: css`
            background: transparent;
            border: none;
            color: white;
            cursor: pointer;
            padding: 8px;

            &:focus {
                outline: none;
                border: none;
            }
        `,

    }

    return (
        <div {...props}>
            {label && <label>{label}</label>}

            <div css={{
                display: horizontal ? "inline-flex" : "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: faded && '0.6',
                gap: '0.5em'
            }}>
                {img &&
                    <ReactSVG
                        src={img}
                        style={style.icon} />
                }

                <div css={[
                    css`
                        display: flex; 
                        flex-direction: ${horizontal ? "row-reverse" : "column"}
                    `,
                    style.scroller
                ]}>

                    <button
                        css={style.button}
                        onClick={() => incrementState(step)}>
                        {horizontal ? "►" : "▲"}
                    </button>

                    <input
                        type="number"
                        onChange={e => handleChange(e.target.value)}
                        value={currentInput}
                        placeholder={isOptional ? undefined : '0'}
                        min={minVal}
                        max={maxVal}
                        css={style.input}
                        step={step}
                    />

                    <button
                        css={style.button}
                        onClick={() => incrementState(-step)}>
                        {horizontal ? "◄ " : "▼"}
                    </button>
                </div>

                {unit && <span>{unit}</span>}
            </div>
        </div>
    )
}

NumberScroller.propTypes = {
    label: PropTypes.string,
    img: PropTypes.string,
    state: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    setState: PropTypes.func,
    minVal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    maxVal: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    step: PropTypes.number,
    unit: PropTypes.string,
    horizontal: PropTypes.bool,
}

export default NumberScroller;
