
import React, { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import Selector from '../_control/OptionsSelector'
import BasicMeasurementPicker from '../_control/BasicMeasurementPicker'
import FieldSectionSelector from '../_control/FieldSectionSelector'
import { css } from '@emotion/react'
import { getUnixTime } from 'date-fns'
import Orientation from '../test/Orientation'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import PropTypes from 'prop-types'
import Pitch from '../../context/Pitch'
import FormLoader from './FormLoader'
import useBridge from '../../hooks/useBridge'
import EventDateTimePicker from '../_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { zonesToSectionState } from '../../utils/eventUtils'

const columns = 7
const rows = 5

export default function DiseaseForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [diseaseType, setDiseaseType] = useState()
    const [severeness, setSevereness] = useState(1)
    const [sections, setSections] = useState([])
    //other
    const { data: diseaseTypeOptions, loading: waitingForDiseaseTypes } = useBridge(`/api/current/frontend-bridge/disease-types`)
    const [pitch] = useContext(Pitch)

    const [isLoading, setIsLoading] = useState(!!event)
    const [formError, setFormError] = useState()

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/disease-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setDiseaseType(data.diseaseType)
                setSevereness(data.severeness)
                setSections(zonesToSectionState(data.zoneResults))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        if (!diseaseType) return setFormError(formatMessage({ id: 'pleaseChooseDiseaseType' }))

        setFormError()
        setIsLoading(true)

        const eventPayload = { diseaseType, severeness, timestamp: getUnixTime(selectedDateTime) }

        if (event) {
            const { data: putResponse, error } = await put(`${event['@id']}`, { body: eventPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (putResponse) {
                const { data: diseaseData } = await get(`/api/current/disease-events/${putResponse.id}`);

                diseaseData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections?.[zone.posY - 1]?.[zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, diseaseData)
            }
        } else {
            const newEventPayLoad = {
                ...eventPayload,
                name: `disease event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data: postResponse, error } = await post(`/api/current/${eventType.path}`, { body: newEventPayLoad })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (postResponse) {
                const { data: diseaseData } = await get(`/api/current/disease-events/${postResponse.id}`);
                diseaseData.zoneResults.forEach(async zone => {
                    await put(`${zone['@id']}`, {
                        body: { active: sections?.[zone.posY - 1]?.[zone.posX - 1] ? 1 : null },
                    })
                })
                onPostRequest(true, diseaseData)
            }
        }
    }, [pitch, diseaseType, severeness, sections, selectedDateTime])

    const isBusy = useMemo(() => (
        waitingForDiseaseTypes || isLoading
    ), [waitingForDiseaseTypes, isLoading])

    return (<>
        {isBusy ? <FormLoader /> : <>
            <div css={css`display: flex; flex-direction: column;`}>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
                <Selector
                    label={formatMessage({ id: 'disease' })}
                    optionsObject={diseaseTypeOptions}
                    state={diseaseType}
                    setState={setDiseaseType}
                />
                <h3 style={{ marginBottom: "8px" }}>
                    <FormattedMessage id='severeness' />
                </h3>
                <BasicMeasurementPicker
                    selected={severeness ? severeness : 1}
                    values={[1, 2, 3, 4, 5]}
                    onChange={(e) => setSevereness(e)}
                    css={{
                        marginBottom: 20,
                        width: 'min-content'
                    }}
                />
                <h3 css={{ marginTop: 8, marginBottom: 8 }} >
                    <FormattedMessage id='locations' />
                </h3>
                <div css={css`width: min-content;`}>
                    <FieldSectionSelector
                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                        columns={columns}
                        rows={rows}
                        sections={sections}
                        onChange={setSections}
                    />
                    <Orientation
                        css={style.compass}
                        heading={pitch.orientation}
                    />
                </div>
            </div>
        </>}
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            disabled={isBusy}
            type="button"
            onClick={(e) => validateAndSubmit(e)}
        >
            <FormattedMessage id='save' />
        </button>
    </>)
}

DiseaseForm.propTypes = {
    pitch: PropTypes.object,
    eventType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    event: PropTypes.object,
    day: PropTypes.object,
    onPostRequest: PropTypes.func,
}

const style = {
    compass: css`
        position: relative;
        height: 2em;
        width: 2em;
        bottom: -1em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
        margin-bottom:40px;
    `,
}