import React from "react"
import { css } from '@emotion/react'
import { colors, backendColors } from "../../style/vars"
import TestCategoryIcon from "../testCategory/TestCategoryIcon"
import ScoreIndicator from "../_general/ScoreIndicator"

export default function CompactGroupedTests({ groupedTests, ...props }) {
    return (
        <div css={css`
            display: flex; 
            flex-direction: column;
            gap: 0.5em;
        `}>
            {groupedTests && Object.entries(groupedTests).map(([group, tests]) => (
                <div css={css`
                    display: grid;
                    grid-template-columns: repeat(auto-fill, minmax(6em, 1fr));
                    grid-gap: 0.5em;
                `}
                    key={group}
                >
                    {tests?.map(test => (
                        <div key={test.name} css={css`
                            display: grid;
                            background: ${colors.stuff};
                            border-radius: 5px;
                            padding: 0.5em;
                            grid-template: 1fr / 1fr 1fr;
                            place-items: center;
                            grid-gap: 0 0.8em;
                        `}
                            title={test.name}
                        >
                            <TestCategoryIcon
                                iconName={test.icon}
                                color={group === 'player' ? colors.main2 : group === 'pitch' ? colors.main1 : colors.super}
                                css={css`width: 2em; height: 2em;`}
                            />
                            <ScoreIndicator
                                value={test.avg}
                                color={backendColors[test.color]}
                                decimals={test.decimals}
                                css={css`color: white; font-size: 0.66em;`}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
}