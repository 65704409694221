import React, { useCallback, useMemo } from 'react'
import { css } from '@emotion/react'
import { mix, rgba } from 'polished'
import PropTypes from 'prop-types'
import { colors, backendColors } from '../../style/vars'
import ReactSlider from 'react-slider'
import { useIntl } from 'react-intl'

const style = {
    cont: (disabled) => css`
        display: flex;
        align-items: center;

        @media screen and (max-width: 600px) {
            display: grid;
            grid-template: auto auto / auto 1fr auto;
            grid-template-areas: 
                "value value increments"
                "track track track";
        }

        touch-action: none;
        user-select: none;
        background-color: ${rgba(colors.subtle, 0.1)};
        border-radius: 5px;
        ${disabled ? 'opacity: 0.5;' : ''}
        transition: opacity 200ms ease;
    `,
    value: css`
        grid-area: value;
        height: 100%;
        display: grid;
        place-items: center;
        min-width: 6em;
        border-right: 2px solid ${rgba(colors.subtle, 0.25)};
        padding: 0.25em;
        font-size: 1.75em;
        border-top-left-radius: 5px;
        text-align: center;

        @media screen and (max-width: 600px) {
            border: none;
            background: ${colors.dark};
        }
    `,
    labeledSliderWrapper: css`
        grid-area: track;
        min-height: 1.5em;
        display: grid;
        grid-template: 1fr / auto 1fr auto;
        padding: 2.5em 2em;
        width: 100%;
        gap: 2em;

        > .slider {
            border-radius: 3px;
            background: ${colors.soft};
        }
    `,
    btns: css`
        grid-area: increments;
        display: grid;
        padding: 0.1em;
    `,
    tweakBtn: css`
        touch-action: none;
        margin: 0.125em;
        min-height: 2.5em;
        flex-grow: 1;
        border: none;
        outline: none;
        font-weight: 600;
        color: ${colors.black};
        border-radius: 0.25em;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        transition: all 200ms ease;
        line-height: 0;
        span {
            font-size: 1.50em;
            font-weight: 300;
            width: 1em;
        }

        background-color: ${rgba(mix(0.5, backendColors.good, colors.subtle), 0.75)};
        &:hover {
            transition: all 50ms ease;
            background-color: ${mix(0.8, backendColors.good, colors.subtle)};
        }

        &.negative {
            background-color: ${rgba(mix(0.5, backendColors.bad, colors.subtle), 0.75)};
            &:hover {
                background-color: ${mix(0.8, backendColors.bad, colors.subtle)};
            }
        }
    `,
    thumb: (hide) => css`
        ${hide ? 'visibility: hidden;' : ''}
        height: 250%;
        width: 2em;
        border-radius: 3px;
        translate: 0 -30%;
        transition: box-shadow 250ms ease, background-color 250ms ease;
        background-color: ${colors.solid};
        box-shadow: 0 0 0 0.2em ${colors.sublimate};
        &:active {
            box-shadow: 0 0 0 0.2em ${colors.main1};
        }
    `,
    clearBtn: css`
        margin: 0.125em;
        margin-bottom: 0.75em;
        font-size: 1em;
        border-radius: 0.125em;
        text-transform: uppercase;
        background-color: ${colors.black};
        color: ${colors.white};
        border: none;
        height: 2em;
        cursor: pointer;
        outline: none;
        opacity: 0.45;
        transition: all 200ms ease;
        &:hover {
            opacity: 0.65;
        }
    `,
    range: css`
        opacity: 0.5;
    `,
}

function MeasurementPicker({
    value = 0,
    onChange,
    decimals = 0,
    min = 0,
    max = 100,
    disabled = false,
    ...props
}) {
    const step = useMemo(() => (
        1 / (10 ** decimals)
    ), [decimals])

    const helperButtonSteps = useMemo(() => {
        const helperButtonSteps = []
        for (let i = 0; i <= decimals; i += 1) {
            helperButtonSteps.push(1 / (10 ** i))
        }
        return helperButtonSteps
    }, [decimals])

    // handle slider changes
    const handleChange = useCallback((value) => {
        if (!disabled) onChange(value)
    }, [disabled, onChange])

    const clearValue = useCallback(() => {
        if (!disabled) onChange(null)
    }, [disabled, onChange])

    // handle tweaking the value with buttons
    const tweakMeasurement = useCallback((amount) => {
        if (!disabled) {
            let newVal = value + amount
            newVal = Math.max(newVal, min)
            newVal = Math.min(newVal, max)
            onChange(newVal)
        }
    }, [min, max, value, disabled, onChange])

    const { formatMessage } = useIntl()

    return (
        <div css={style.cont(disabled)} {...props}>
            <div css={style.value}>
                {typeof value === 'number'
                    ? value.toFixed(decimals)
                    : formatMessage({ id: 'noValue' })
                }
            </div>
            <div css={style.labeledSliderWrapper}>
                <div css={style.range}>
                    {min}
                </div>
                <ReactSlider
                    value={value}
                    step={step}
                    min={min}
                    max={max}
                    onChange={(val) => handleChange(val)}
                    renderThumb={(props) => <div {...props} css={style.thumb(value === null)} />}
                />
                <div css={style.range}>
                    {max}
                </div>
            </div>
            {helperButtonSteps.length > 0 &&
                <div css={style.btns}>
                    <button type='button' onClick={clearValue} css={style.clearBtn}>
                        {formatMessage({ id: 'clearDataGeneric' })}
                    </button>
                    {helperButtonSteps.map(helperButtonStep => (
                        <div css={css`display: flex; align-items: stretch; flex-grow: 1;`} key={helperButtonStep}>
                            <button
                                css={style.tweakBtn}
                                className='negative'
                                type='button'
                                disabled={value <= min || disabled}
                                onClick={() => { tweakMeasurement(-helperButtonStep) }}
                            >
                                <span>&minus;&nbsp;</span>
                                {helperButtonStep}
                            </button>
                            <button
                                css={style.tweakBtn}
                                type='button'
                                disabled={value >= max || disabled}
                                onClick={() => { tweakMeasurement(helperButtonStep) }}
                            >
                                <span>+&nbsp;</span>
                                {helperButtonStep}
                            </button>
                        </div>
                    ))}
                </div>
            }
        </div>
    )
}

MeasurementPicker.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    decimals: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
}

export default MeasurementPicker
