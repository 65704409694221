import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Document, Page, View, Image, Text } from '@react-pdf/renderer'
import { colors } from '../../style/vars'
import brandLogo from '../../assets/images/logo_large.png'

export default function Analysisdetailspdf({ pitchName, analysisDate, dataSummaryImages, cardImages, columns, ...props }) {

    const colWidth = useMemo(() => {
        const widthMap = {
            2: '45%',
            3: '32%',
            4: '23%',
            5: '18%',
            [undefined]: '45%'
        }
        return widthMap[columns]
    }, [columns])

    return (
        //"Build" PDF document
        <Document>
            <Page
                size="A4"
                style={{
                    flexDirection: 'column',
                    backgroundColor: colors.dark,
                    color: 'white',
                    paddingTop: "1cm",
                }}>
                <View style={{ margin: "0 1cm" }}>
                    {/* Header */}
                    <View style={{
                        flexDirection: 'row',
                        margin: '3mm',
                        fontSize: 18,
                        justifyContent: 'center',
                        textTransform: "uppercase",
                        borderBottom: '1mm solid ' + colors.eventLight
                    }}
                        wrap>
                        <Text>{pitchName}</Text>
                        <Text style={{ margin: '0 5mm' }}>/</Text>
                        <Text style={{ color: colors.main1 }}>
                            {analysisDate}
                        </Text>
                    </View>

                    {/* Body */}
                    <View>

                        {!!dataSummaryImages.length && <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                        }}>
                            {dataSummaryImages.map((imageString, index) => (
                                    <Image
                                        key={'summary ' + index}
                                        src={`${imageString}`}
                                        style={{
                                            width: '100%',
                                            marginBottom: "1cm",
                                            marginHorizontal: "1mm",
                                            borderRadius: "1mm",
                                        }}
                                    />
                                ))}
                        </View>}

                        {columns ?
                            <View
                                style={{
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                }}
                            >
                                {cardImages.map((imageString, index) => (
                                    <Image
                                        key={index}
                                        src={`${imageString}`}
                                        style={{
                                            width: colWidth,
                                            marginBottom: "1cm",
                                            marginHorizontal: "1mm",
                                            borderRadius: "1mm",
                                        }}
                                    />
                                ))}
                            </View>
                            :
                            cardImages.map((imageString, index) => (
                                <Image
                                    key={index}
                                    src={`${imageString}`}
                                    style={{
                                        marginBottom: "1cm",
                                        marginHorizontal: "1mm",
                                        borderRadius: "1mm",
                                    }}
                                />
                            ))
                        }
                    </View>
                </View>
                {/* Footer */}
                <View
                    style={{
                        marginTop: "auto",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "black",
                        padding: "0.2cm",
                    }}
                    wrap={false}>
                    <View style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Image style={{ height: "1.2cm", marginRight: "7.5mm" }} src={brandLogo} />
                    </View>
                </View>

            </Page>
        </Document>
    )
}

Analysisdetailspdf.propTypes = {
    pitchName: PropTypes.string,
    analysisDate: PropTypes.string,
    cardImages: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.number
}