import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { find, set } from 'lodash'
import { eachDayOfInterval, endOfMonth, format, getDate, getDay, getMonth, getYear, getWeekOfMonth, set as setDate, getUnixTime, formatISO, isToday } from 'date-fns'
import { fonts, timings } from '../../style/vars'
import Loader from './Loader'
import LocaleContext from '../../context/Locale'

const now = new Date()

function MonthlyCalendar({
    children = [],
    renderDay = (node => node),
    onDayClick,
    dateKeyMapper = date => format(date, 'y-M-d'),
    month = getMonth(now),
    year = getYear(now),
    busy = false,
    ...props
}) {

    const { datefnsLocale, weekStart } = useContext(LocaleContext)

    const dayOrder = useMemo(() => {
        return weekStart === 1 ? [1, 2, 3, 4, 5, 6, 0] : [0, 1, 2, 3, 4, 5, 6]
    }, [weekStart])

    const weekRows = useMemo(() => {
        const start = setDate(new Date(), {
            year,
            month,
            date: 1,
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0,
        })
        const end = endOfMonth(start)
        const rowCols = {} // we fill this -> {[row]: {[column]}}
        for (const date of eachDayOfInterval({ start, end })) {
            set(rowCols,
                [getWeekOfMonth(date, { weekStartsOn: weekStart }), getDay(date)],
                setDate(date, { hours: 12 }) // timestamp of days set to noon instead of midnight to work around timezone issues
            )
        }
        return rowCols
    }, [month, year, weekStart])

    return (
        <div css={style.wrapper} {...props}>
            {busy &&
                <div css={style.loader}>
                    <Loader />
                </div>
            }
            {weekRows &&
                <div css={style.table}>
                    {/* iterate over second row to get accurate week layout */}
                    {/* kinda janky, but it works! */}
                    {dayOrder.map(day => {
                        const date = weekRows[Object.keys(weekRows)[1]][day]
                        return (
                            <div key={getDate(date)} css={style.header}>
                                {format(date, 'EEEE', { weekStartsOn: 1, locale: datefnsLocale })}
                            </div>
                        )
                    })}

                    {Object.values(weekRows).map((dayColumns, i) => (
                        dayOrder.map((dayOfWeek) => {
                            const date = dayColumns[dayOfWeek]
                            return (
                                date instanceof Date
                                    ?
                                    <div
                                        css={[
                                            style.cell(isToday(date)),
                                            find(children, { key: dateKeyMapper(date) }) && style.cursorActive
                                        ]}
                                        key={getUnixTime(date)}
                                        onClick={() => onDayClick(date)}
                                    >
                                        <div className='date'>
                                            {getDate(date)}
                                        </div>
                                        <div className='content'>
                                            {renderDay(date, (find(children, { key: dateKeyMapper(date) }) || {}).node)}
                                        </div>
                                    </div>
                                    :
                                    <div
                                        css={style.cell}
                                        style={{ opacity: 0.25 }}
                                        key={`${i}-${dayOfWeek}`}
                                    />
                            )
                        })
                    ))}

                </div>
            }
        </div >
    )
}
MonthlyCalendar.propTypes = {
    children: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any,
        node: PropTypes.node,
    })),
    renderDay: PropTypes.func,
    onDayClick: PropTypes.func,
    dateKeyMapper: PropTypes.func,
    month: PropTypes.number,
    year: PropTypes.number,
    busy: PropTypes.bool,
}

export default MonthlyCalendar

const style = {
    wrapper: css`
        border-radius: 0.25em;
        position: relative;
    `,
    loader: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 16em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(32,32,32,0.5);
        z-index: 1;
    `,
    // top is set to offset vertical alignment shift due to border-spacing
    table: css`
        height: 100%;
        display: grid;
        grid-gap: 0.5em;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: auto repeat(5, 1fr);
    `,
    header: css`
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${fonts.special};
        text-transform: capitalize;
    `,
    cell: (isToday) => css`
        position: relative;
        border-radius: 0.25em;
        overflow: hidden;
        background-color: ${isToday ? 'rgb(96, 99, 106)' : '#3A4046'};
        box-shadow: 0 0.125em 0.25em 0 rgba(0,0,0,0.3);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        &:scroll {
            overflow: visible;
            z-index: 1;
        }

        > .date {
            position: absolute;
            font-size: 0.875em;
            font-family: ${fonts.title};
            padding: 0.25em;
            top: 0;
            height: 1.5em;
        }

        > .content {
            margin-left: 0.25em;
            overflow-Y: auto;
            margin-top: 1.5em;
            flex-grow: 1;
        }
    `,
    cursorActive: css`
        cursor: pointer;
        transition: background ${timings.snappy};
        &:hover {
            background: rgb(96, 99, 106);
        }
    `
}