import React, { useContext, useMemo } from "react"
import { css } from "@emotion/react"
import { useCallback, useState } from "react"
import { colors, timings } from "../../style/vars"
import EventTypeIcon from "../events/EventTypeIcon"
import EventZones from "../events/EventZones"
import { format } from "date-fns"
import showDataIcon from '@assets/icons/article.svg'
import showGridIcon from '@assets/icons/gridToggleButton.svg'
import { ReactSVG } from "react-svg"
import EventSummary from "../events/EventSummary"
import RenderWhenVisible from "../_general/RenderWhenVisible"
import PitchBg from "../_general/pitch/pitchBg"
import { getEventTypeColor } from "../../utils/eventUtils"
import LocaleContext from "../../context/Locale"
import { useIntl } from "react-intl"
import useBridge from "../../hooks/useBridge"

export default React.memo(function GridListEvent({ pitch, eventMoment, styles, ...props }) {

    const { formatMessage } = useIntl()
    const { datefnsLocale } = useContext(LocaleContext)
    const { data, band, timestamp, showDate } = eventMoment
    const [showData, setShowData] = useState(false)
    const toggleShowData = useCallback(() => {
        if (!data.zoneResults) return
        setShowData(current => !current)
    }, [data])

    const backgroundType = useMemo(() => {
        return pitch?.pitchBackground || pitch?.club?.sport
    }, [pitch])

    const { data: eventTypes } = useBridge('/api/current/frontend-bridge/event-types')

    const eventTypeLocalised = useMemo(() => {
        if (!eventTypes) return
        return eventTypes.find(x => x.entityShortName === data.type)?.entityShortNameLocalised
    }, [eventTypes, data])

    return <div
        css={styles.momentGrid(band)}
        {...props}
    >
        <div css={styles.header}>
            <EventTypeIcon
                eventType={data.type}
                css={eventStyles.eventIcon(data.type)}
            />
            <div css={css`display: flex; gap: 1em; flex-grow: 1; justify-content: space-between;`}>
                <h3 css={[styles.title(showDate), css`color:white;`]}>
                    {format(
                        timestamp * 1000,
                        "PP",
                        { locale: datefnsLocale }
                    )}
                </h3>
                <h3 css={styles.title(true)}>
                    {format(
                        timestamp * 1000,
                        'HH:mm',
                        { locale: datefnsLocale }
                    )}
                </h3>
            </div>
        </div>
        <div css={[styles.subtitle, css`color: ${colors.white};`]}>
            {eventTypeLocalised}
            {data.zoneResults &&
                <button
                    css={eventStyles.eventToggleButton}
                    onClick={toggleShowData}
                    title={formatMessage({ id: 'toggleDataOrGrid' })}
                >
                    {showData ?
                        <ReactSVG src={showDataIcon} css={eventStyles.dataIcon} />
                        :
                        <ReactSVG src={showGridIcon} css={eventStyles.gridIcon} />
                    }
                </button>}
        </div>
        <div
            css={[
                data.zoneResults ? styles.clickable : css``,
                css`height: ${showData ? '100%' : 'min-content'};`
            ]}
            onClick={toggleShowData}
            title={data.zoneResults ?
                formatMessage({ id: 'toggleDataOrGrid' })
                : formatMessage({ id: 'thisEventHasNoGrid' })}
        >
            {showData || !data.zoneResults ?
                <EventSummary
                    event={data}
                    css={[
                        eventStyles.dataSummary,
                        css`cursor: ${data.zoneResults ? 'pointer' : 'unset'};`
                    ]} />
                :
                <RenderWhenVisible
                    fallback={<PitchBg type={backgroundType} css={css`display: flex;`} />}
                >
                    <EventZones
                        backgroundType={backgroundType}
                        event={data}
                    />
                </RenderWhenVisible>
            }
        </div>
    </div>
})

const eventStyles = {
    eventIcon: (type) => css`
        div {
            width: 1.5em;
            height: 1.5em;
            padding: 0;
            background: transparent;
            color: ${getEventTypeColor(type)};
        }
        div > div{
            width: 1.5em;
            height: 1.5em;
        }
    `,
    eventToggleButton: css`
        border: none;
        color: ${colors.soft};
        background: rgba(255,255,255,0.1);
        cursor: pointer;
        transition: background ${timings.smooth};
        align-self: center;
        border-radius: 3px;
        height: 1.6em;
        width: 1.6em;
        overflow: hidden;
        display: grid;
        place-self: center;
        place-items: center;
        padding: 0;

        &:hover {
            background: rgba(255,255,255,0.2);
        }
    `,
    dataSummary: css`
        padding: 0.5em 0;
        grid-template-columns: 1fr; 
        grid-auto-rows: min-content;
        grid-gap: 0.25em;
        color: ${colors.soft};
    `,
    dataIcon: css`
        width: 1.5em;
        height: 1.5em;
        fill: ${colors.soft};
        stroke: ${colors.soft};
    `,
    gridIcon: css`
        width: 1.5em;
        height: 1.5em;
        svg {
            width: 1.5em;
            height: 1.5em;
            fill: ${colors.soft};
            stroke: ${colors.soft};
        }
    `,
}