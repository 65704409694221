import React, { useContext } from 'react'
import LocaleContext from '../../context/Locale'
import { FormattedMessage } from 'react-intl'
import SettingsSelect from '../settings/SettingsSelect'
import BridgeContext from '../../context/FrontendBridge'

const localeAbbreviations = {
    'en': <FormattedMessage id='english' />,
    'fr': <FormattedMessage id='french' />,
    'es': <FormattedMessage id='spanish' />,
    'nl': <FormattedMessage id='dutch' />,
}

export default function LanguageSelector({ ...props }) {
    const { locale, setLocale } = useContext(LocaleContext)
    const { clearCache } = useContext(BridgeContext)

    return <SettingsSelect
        value={locale}
        onChange={(e) => {
            const val = e.target.value
            clearCache()
            setLocale(val)
        }}
        options={Object.keys(localeAbbreviations).map(abbr => {
            return <option
                key={abbr}
                value={abbr}
            >
                {localeAbbreviations[abbr]}
            </option>
        })}
        {...props}
    />
}