import React from 'react'
import { colors } from '../../style/vars'
import { css } from '@emotion/react'
import { ReactSVG } from 'react-svg'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

export default function WeatherFieldCard({
    field,
    field1,
    field2,
    handleSelect,
    value,
    label,
    unit,
    icon,
    secondValue,
    ...props }) {

    const isAvailable = useMemo(() => {
        return typeof value === 'number' && isFinite(value)
    }, [value])

    const { formatMessage } = useIntl()

    return (
        <div css={[style.wrapper, css`
                pointer-events: ${!isAvailable && 'none'};
                opacity:  ${!isAvailable && 0.8};
                min-width: 12em;
            `]}
            onClick={() => handleSelect(field)}
            {...props}
        >
            <div css={style.card(field === field1 || field === field2)}>

                <ReactSVG
                    css={css`
                        grid-area: icon;
                        svg {
                            width: 100%;
                            height: 100%;
                            padding: 4px;
                        }
                    `}
                    src={icon}
                />

                <div css={css`grid-area: label;`}>
                    {label}
                </div>

                <div css={css`grid-area: value; font-size: 1.3em;`}>
                    {isAvailable ?
                        <>
                            {value}
                            {secondValue && (' / ' + secondValue)}
                            {unit}
                        </>
                        :
                        formatMessage({ id: 'unavailable' })}
                </div>

            </div>
            <div css={[style.highlight, css`
                position: absolute;
                width: 100%;
                height: 3px;
                bottom: 0;
                background: ${(field === field1 && colors.dataPrimary)};
                background: ${(field === field2 && colors.dataSecondary)};
            `]} />
        </div>
    )
}

const style = {
    wrapper: css`
        flex-grow: 1; 
        background: ${colors.eventLight}; 
        border-radius: 5px; 
        display: grid; 
        place-items: center;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        padding: 0.6em;
        user-select: none;
        -webkit-user-select: none;
        
        &:hover {
            > * {color: ${colors.main1}};
        }
    `,
    card: (isSelected) => css`
        display: grid;
        grid-template: 1.5em 1.5em / 3em 1fr;
        grid-template-areas: 
            'icon label'
            'icon value'; 
        grid-gap: 0.2em;
        align-items: center;
        color: ${isSelected ? colors.white : colors.soft};
    `,
    highlight: css`
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
    `
}

WeatherFieldCard.propTypes = {
    field: PropTypes.string,
    field1: PropTypes.string,
    field2: PropTypes.string,
    handleSelect: PropTypes.func,
    value: PropTypes.number,
    label: PropTypes.string,
    unit: PropTypes.string,
    icon: PropTypes.string,
    secondValue: PropTypes.number,
}