import React, { useContext, useState } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import DatePicker from './DatePicker'
import Cta from './Cta'
import Modal from '../_layout/Modal'
import { useIntl } from 'react-intl'
import LocaleContext from '../../context/Locale'

function DatePickerCta({
    date,
    onDateChange,
    datePickerProps,
    label,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false)
    const { formatMessage } = useIntl()
    const { datefnsLocale } = useContext(LocaleContext)

    const selectADate = formatMessage({ id: 'selectADate' })

    return (
        <div css={css`position: relative;`} {...props}>
            <Cta
                css={css`font-size: 0.875em; min-width: 9em; width: 100%; height: 100%; padding: 0.5em; white-space: nowrap;`}
                signal='positive'
                onClick={() => setIsOpen(val => !val)}
            >
                {label &&
                    <span style={{ fontWeight: 'bold' }}>
                        {label}&nbsp;
                    </span>
                }
                {date ? format(date, 'PP', { locale: datefnsLocale }) : selectADate}
            </Cta>
            {isOpen &&
                <Modal
                    onClickClose={() => { setIsOpen(false) }}
                    css={css`width: min(95%, 40em); height: 550px;`}
                    header={selectADate}
                >
                    <DatePicker
                        css={css`position: static; background-color: none; font-size: 1.5em; text-transform: capitalize;`}
                        onDayClick={(date) => { onDateChange(date); setIsOpen(false) }}
                        selectedDays={date ? [date] : undefined}
                        initialMonth={date}

                        {...datePickerProps}
                    />
                </Modal>
            }
        </div>
    )
}

DatePickerCta.propTypes = {
    date: PropTypes.objectOf(Date),
    onDateChange: PropTypes.func,
    datePickerProps: PropTypes.object,
}

export default DatePickerCta
