import React, { createContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { getAllEventNames } from "../utils/eventUtils"
import log from "loglevel"
import { MTM_EVENT_TYPES } from "../utils/matomo"
import useTracker from "../hooks/useTracker"

const EventFilterContext = createContext()
export default EventFilterContext

export function EventFilterProvider({ ...props }) {
    const [eventFilter, setEventFilter] = useState([])
    const track = useTracker()

    useEffect(() => {
        try {
            const savedFilter = JSON.parse(localStorage.getItem('eventFilter'))
            if (savedFilter?.every(val => getAllEventNames().includes(val))) {
                setEventFilter(savedFilter)
            }
        } catch (err) {
            log.debug("error reading event filter's local storage:" , err)
        }
    }, [])

    useEffect(() => {
        if (eventFilter.length > 0) {
            track({ 
                'event': MTM_EVENT_TYPES["filter-calendar"],
            })
        } 
        localStorage.setItem('eventFilter', JSON.stringify(eventFilter))
    }, [eventFilter])

    return <EventFilterContext.Provider value={[eventFilter, setEventFilter]} {...props} />
}