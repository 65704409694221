import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { getEventTypeIcon } from '../../utils/eventUtils'
import { getEventTypeColor } from '../../utils/eventUtils'
import { ReactSVG } from 'react-svg'

function EventTypeIcon({
    eventType,
    counter = null,
    ...props
}) {
    const style = {
        wrapper: css`
            position: relative;
        `,
        icon: css`
            padding: .5em;
            width: 3em;
            height: 3em;
            display: grid;
            place-items: stretch;
            background-color: #2E3439;
            border-radius: .5em;
            color: ${getEventTypeColor(eventType)}
        `,
        counter: css`
            position: absolute;
            bottom: 0;
            right: 0;
            border: 1.5px solid ${getEventTypeColor(eventType)};
            color: ${getEventTypeColor(eventType)};
            background-color: #2F3437;
            border-radius: 50% !important;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .7em;
    `,
    }

    return (
        <div css={style.wrapper} {...props}>
            <ReactSVG
                src={getEventTypeIcon(eventType)}
                css={style.icon}
            />
            {counter &&
                <span css={style.counter}>
                    {counter}
                </span>
            }
        </div>
    )
}

EventTypeIcon.propTypes = {
    eventType: PropTypes.string,
    counter: PropTypes.number,
}

export default EventTypeIcon;
