import React, { useEffect, useState, useContext, useRef } from 'react'
import PageTitle from '../_general/PageTitle'
import { colors, timings } from '../../style/vars'
import { css } from '@emotion/react'
import TableauAuth from '../tableau/TableauAuth'
import Profile from '../../context/Profile'
import AnalyticsContent from '../tableau/AnalyticsContent'
import { SportsProvider } from '../../context/Sports'
import LoaderBackground from '../tableau/LoaderBackground'
import Cta from '../_control/Cta'
import { NavLink } from 'react-router-dom'
import { AnalyticsProvider } from "../../context/Analytics"
import { FormattedMessage } from 'react-intl'

export default function Analytics({ ...props }) {

    const [profile] = useContext(Profile)
    const [authOk, setAuthOk] = useState(false)

    // force scrollers always on to prevent layout shifts for Tableau
    useEffect(() => {
        const scroller = document.getElementById("pageScroller")
        if (scroller) {
            scroller.style.overflow = '-moz-scrollbars-vertical'
            scroller.style.overflowY = 'scroll'
        }
        return function cleanup() {
            scroller.style.overflow = 'auto'
            scroller.style.overflowY = 'auto'
        }
    }, [])

    // check scrolled status of page with intersect observer
    const phantomRef = useRef()
    const [isScrolled, setIsScrolled] = useState(false)
    useEffect(() => {
        const phantom = phantomRef.current
        const observer = new IntersectionObserver((observerEntries) => {
            const visibleRatio = observerEntries?.[0]?.intersectionRatio
            if (visibleRatio === undefined) return
            setIsScrolled(visibleRatio < 1)
        })
        observer.observe(phantom)
        return () => observer.unobserve(phantom)
    }, [])

    return (
        <div css={style.wrapper}>
            <script src="https://public.tableau.com/javascripts/api/tableau-2.min.js"></script>
            <div
                ref={phantomRef}
                css={css`position: absolute; height: 1px;`}>
            </div>
            <div css={[
                style.header,
                css`box-shadow: ${isScrolled && '0px 0px 2px 1px rgba(100,100,100,0.35)'};
                    transition: box-shadow ${timings.responsive};`
            ]}>
                <PageTitle css={style.title}>
                    <FormattedMessage id='analytics' />
                </PageTitle>
                <TableauAuth
                    setAuthOk={setAuthOk}
                    css={[
                        !profile?.admin && authOk === true && css`visibility: hidden;`,
                        css`margin-right: 2em`,
                    ]}
                />
            </div>
            <SportsProvider>
                {authOk === true ?
                    <AnalyticsProvider>
                        <AnalyticsContent />
                    </AnalyticsProvider>
                    : !authOk ? <LoaderBackground />
                        : authOk === 'error' ?
                            <div css={style.fallback}>
                                <div>
                                    <FormattedMessage id='noAnalyticsLicenseContactUS'
                                        values={{
                                            p: chunks => <p>{chunks}</p>,
                                            br: () => <br />
                                        }}
                                    />
                                    <NavLink to='/account?contactPrefill=requestAnalytics'>
                                        <Cta css={css`font-size: 0.8em;`}>
                                            <FormattedMessage id='contactUs' />
                                        </Cta>
                                    </NavLink>
                                </div>
                            </div>
                            : null}
            </SportsProvider>
        </div>
    )
}

const style = {
    wrapper: css`
        display: flex; 
        flex-direction: column;
        align-items: stretch;
        flex-grow: 1;
        padding: 0; 
    `,
    header: css`
        display: flex; 
        justify-content: 
        space-between; 
        align-items: center;
        position: sticky;
        top: 0;             
        z-index: 1;       
        padding: 1em 2em 1em 2em;
        backdrop-filter: saturate(40%) blur(40px);
        -webkit-backdrop-filter: saturate(40%) blur(40px);
    `,
    fallback: css`
        width: 100%; 
        height: 100%; 
        display: grid; 
        place-items: center;
        padding: 1.5em;
        font-size: 1.2em;
        color: ${colors.soft};

        > div {
            text-align: center;
            padding: 2em;
            border-radius: 5px;
            background: ${colors.substance};
            border: 2px solid ${colors.eventDark};
        }
    `,
    title: css` 
        color: ${colors.main1}; 
        margin: 0;
        @media screen and (max-width: 600px) {
            visibility: hidden;
        }
    `,
}

