import React from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import PhysicalAssessment from '../../prop-types/PhysicalAssessment'
import HealthEffectsFront from './HealthEffectsFront'
import HealthEffectsBack from './HealthEffectsBack'
import { HighlightsProvider } from '../../context/Highlights'

const style = {
    healthEffectWrapper: css`
        height: 100%;
        max-height: 100%;
        width: 50%;
        position: relative;
        display: grid;
        place-items: center;
        overflow: hidden;
        margin: 1px;
    `,
    healthEffect: css`
        height: 100%;
        max-height: 100%;
    `
}

function HealthEffectsMap({ physicalAssessments = [], ...props }) {

    return (
        <div css={{ display: 'flex', height: '100%', position: 'relative', width: '100%' }} {...props}>
            <HighlightsProvider physicalAssessments={physicalAssessments}>
                <div css={style.healthEffectWrapper}>
                    <HealthEffectsFront
                        css={style.healthEffect}
                    />
                </div>
                <div css={style.healthEffectWrapper}>
                    <HealthEffectsBack
                        css={style.healthEffect}
                    />
                </div>
            </HighlightsProvider>
        </div>
    )
}

HealthEffectsMap.propTypes = {
    physicalAssessments: PropTypes.arrayOf(PhysicalAssessment()),
}

export default HealthEffectsMap
