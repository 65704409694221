import React, { useState, useEffect, useContext } from 'react'
import { css } from '@emotion/react'
import { colors, fonts, timings } from '../../style/vars'
import AnalyticsDatePicker from './AnalyticsDatePicker'
import Cta from '../_control/Cta'
import AnalyticsContext from '../../context/Analytics'
import AnalyticsPitchList from './AnalyticsPitchList'
import { FormattedMessage, useIntl } from 'react-intl'

export default function AnalyticsControls({ ...props }) {
    const {
        dates: {
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            isDateUnconfirmed,
            filterDateGlobal,
            showDemoShortcut
        },
    } = useContext(AnalyticsContext)

    const [demoApplied, setDemoApplied] = useState(false)

    function applyDemoDateRange() {
        setStartDate(new Date("2024-01-01"))
        setEndDate(new Date("2024-03-31"))
        setDemoApplied(true)
    }

    useEffect(() => {
        if (demoApplied) filterDateGlobal()
    }, [demoApplied])

    const { formatMessage } = useIntl()

    return (
        <div css={style.wrapper} >

            <h2 css={[style.noSelect, css`margin: 0.2em 0; text-transform: uppercase;`]}>
                <FormattedMessage id='analysisDate' />
            </h2>

            <AnalyticsDatePicker
                date={startDate}
                setDate={setStartDate}
            />
            <AnalyticsDatePicker
                date={endDate}
                setDate={setEndDate}
            />
            {showDemoShortcut && !demoApplied &&
                <Cta onClick={() => applyDemoDateRange()}
                    css={style.confirm}>
                    <FormattedMessage id='demoShortcut' />
                </Cta>
            }
            <Cta
                onClick={filterDateGlobal}
                disabled={!isDateUnconfirmed}
                css={style.confirm}
                title={formatMessage({ id: 'confirmSelection' })}
            >
                &#10003;
            </Cta>
            <hr css={css`width: 100%; opacity: 0.2`} />

            <h2 css={[style.noSelect, css`margin-bottom: 0.2em; text-transform: uppercase;`]}>
                <FormattedMessage id='sportsField' />
            </h2>

            <AnalyticsPitchList />

        </div>
    )
}

const style = {
    wrapper: css`
        position: relative;
        background: ${colors.substance};
        width: 100%;
        height: calc(100% - 1em);
        border-radius: 5px;
        overflow-Y: auto;
        font-family: ${fonts.special};
        font-size: 1.7em;
        color: ${colors.soft};
        padding: 0.2em;
        display: flex;
        flex-direction: column;
        gap: 0.2em;

        -webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;

        > h2 {
            font-size: 0.8em;
        }
    `,
    confirm: css`
        display: flex;
        font-size: 0.55em;
        align-items: center;

        span {
            margin: 0 2em;
        }
    `,
    noSelect: css`
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    `,
    picker: css`
        font-size: 0.8em;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        background: ${colors.dark};
        color: ${colors.soft};
        font-family: ${fonts.special};

        box-shadow: 0 0.07em 0.1em 0 black;
        transition: color, box-shadow, ${timings.responsive};
        
        &:hover{ 
            color: ${colors.white};
            box-shadow: 0 0.1em 0.2em 0 black;
        }
    `
}