import { useCallback, useContext, useEffect, useState } from 'react'
import log from 'loglevel'
import Raw from '../services/Raw'
import Auth from '../context/Auth'
import LocaleContext from '../context/Locale'

export default function useRaw(
    resource,
    dependencies = [],
    {
        method = 'GET',
        autoFetch = true,
        body,
    } = {}
) {
    if (!Array.isArray(dependencies)) {
        throw new Error('useRaw: received dependencies were not an array')
    }

    const { jwt } = useContext(Auth)
    const { locale } = useContext(LocaleContext)

    const [ldjson, setLdjson] = useState(undefined)
    const [fetching, setFetching] = useState(false)
    const [ready, setReady] = useState(false) // ready means: has at least fetched once
    const [failed, setFailed] = useState(false)

    const fetch = useCallback(async (overrideBody) => {
        if (resource === undefined) return
        const options = {
            body: overrideBody || body,
            jwt: jwt || undefined,
            acceptLanguage: locale,
        }

        // start call, reset previous meta states
        setFailed(false)
        setFetching(true)
        setLdjson(undefined)

        // do call, handle result async
        await Raw.fetchJson(method, resource, options)
            .then((json) => {
                setLdjson(json)
            })
            .catch((error) => {
                setFailed(true)
                log.error(error)
            })
            .finally(() => {
                setFetching(false)
                setReady(true)
            })
    }, [method, resource, jwt, body, locale])

    useEffect(() => {
        if (autoFetch) {
            // if there are dependencies, and they are not all set to a value, do not fetch
            if (Array.isArray(dependencies)) {
                for (const dependency of dependencies) {
                    if (typeof dependency !== 'number' && typeof dependency !== 'string') {
                        return
                    }
                }
            }
            fetch()
        }
    }, [...dependencies, fetch])

    return [
        ldjson,
        {
            fetch,
            fetching,
            busy: fetching || !ready,
            waiting: fetching || !ready, // more semantically correct naming of 'busy'
            ready,
            failed,
        },
    ]
}
