import React, { useMemo, useContext } from 'react'
import GenericDiscreteBarTimeseries from './GenericDiscreteBarTimeseries'
import Loader from '../_general/Loader'
import FallbackMessage from '../_general/FallbackMessage'
import { processChartData } from './util'
import ReporterContext from '../../context/Reporter'
import { FormattedMessage } from 'react-intl'

export default function ({ ...props }) {
    const {
        testCategory1,
        useResultsAsAxis,
        tests1,
        compareData,
        reporterBusy,
    } = useContext(ReporterContext)

    const testValues = useMemo(() => {
        const dataArray = processChartData(tests1).map(test => {
            return {
                value: useResultsAsAxis ? test.avg : test.score,
                timestamp: test.timestamp,
                date: new Date(test.timestamp * 1000)
            }
        })
        dataArray.sort((a, b) => a.timestamp - b.timestamp)
        return dataArray
    }, [tests1, useResultsAsAxis])

    const compareTestValues = useMemo(() => {
        const dataArray = processChartData(compareData).map(test => {
            return {
                value: useResultsAsAxis ? test.avg : test.score,
                timestamp: test.timestamp,
                date: new Date(test.timestamp * 1000)
            }
        })
        dataArray.sort((a, b) => a.timestamp - b.timestamp)
        return dataArray
    }, [compareData, useResultsAsAxis])

    const maxRangeTests = useMemo(() => {
        if (!useResultsAsAxis) return 10
        if (tests1 && tests1[0]) {
            return tests1[0].testCategory.maxRatingValue
        }
    }, [tests1, useResultsAsAxis])

    const maxRangeComparedTests = useMemo(() => {
        if (!useResultsAsAxis) return 10
        if (compareData && compareData[0]) {
            return compareData[0].testCategory.maxRatingValue
        }
    }, [compareData, useResultsAsAxis])

    const minRangeTests = useMemo(() => {
        if (!useResultsAsAxis) return 0
        if (tests1 && tests1[0]) {
            return tests1[0].testCategory.minRatingValue
        }
    }, [tests1, useResultsAsAxis])

    const minRangeComparedTests = useMemo(() => {
        if (!useResultsAsAxis) return 0
        if (compareData && compareData[0]) {
            return compareData[0].testCategory.minRatingValue
        }
    }, [compareData, useResultsAsAxis])

    return <GenericDiscreteBarTimeseries
        {...props}
        pixelWidthPerDay={24}
        data={testValues}
        compareData={compareTestValues}
        yAxisOneMin={minRangeTests}
        yAxisOneMax={maxRangeTests}
        yAxisTwoMin={minRangeComparedTests}
        yAxisTwoMax={maxRangeComparedTests}
        overlay={reporterBusy ?
            <Loader size='16em' />
            :
            !testCategory1
                ?
                <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                    <FormattedMessage id='selectATestCategory' />
                </FallbackMessage>
                :
                testValues.length === 0 && compareTestValues.length === 0 ?
                    <FallbackMessage style={{ fontSize: '2.5em', width: '80%' }}>
                        <FormattedMessage id='noTestsForPitches' />
                    </FallbackMessage>
                    : undefined
        }
    />

}