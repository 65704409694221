import { css } from "@emotion/react"
import { colors, backgrounds } from "../../style/vars"
import ModalHeader from "../modals/ModalHeader"
import UserBadge from "./UserBadge"
import { FormattedMessage } from "react-intl"
import { useIntl } from "react-intl"

export default function UserGroupPicker({ userGroups, handleSelect, allowNull = true, ...props }) {
    const { formatMessage } = useIntl()

    return <div css={style.userGroupGrid} {...props}>
        <div css={style.headerWrapper}>
            <ModalHeader header={formatMessage({id: 'chooseContentGroupToSaveIn'})} />
        </div>

        {/* allows selection of no usergroup, 
        assumes the upstream logic will handle a nulled usergroup as something to be saved on the author. */}
        {allowNull &&
            <button
                css={style.userGroupButton}
                onClick={() => handleSelect()}
            >
                <UserBadge css={[style.userGroupImg, css`
                    > div {
                        width: 50%;
                        opacity: 0.8;
                    }
                `]} />
                <FormattedMessage id='myAccount' />
            </button>
        }

        {userGroups?.map(uG =>
            <button
                css={style.userGroupButton}
                onClick={() => handleSelect(uG)}
            >
                <img
                    key={uG.id}
                    alt={uG.name}
                    src={uG.logo?.file}
                    title={uG.name}
                    css={style.userGroupImg}
                />
                {uG.name}
            </button>
        )}
    </div>
}

const style = {
    userGroupGrid: css`
        display: grid; 
        grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
        grid-template-rows: min-content;
        width: 100%;
        height: 100%;
        overflow: auto;
        justify-items: start;
        align-items: start;
        padding: 2em;
        gap: 1em;
    `,
    headerWrapper: css`
        grid-column: 1 / -1;
        height: min-content;
        justify-self: start;
        width: 100%;

        > div {
            width: 100%;
        }
    `,
    userGroupButton: css`
        color: ${colors.soft};
        height: min-content;
        padding: 0;
        border: none;
        background: transparent;
        cursor: pointer;
        width: 100%;
    `,
    userGroupImg: css`
        width: 100%;
        height: auto;
        object-fit: cover; 
        aspect-ratio: 1;
        object-fit: contain;
        padding: 2em;
        background: ${colors.eventDark};
        border-radius: 5px;
        display: grid;
        place-items: center;
        background-image: ${backgrounds.cardGradient};
        border: 2px solid ${colors.eventLight};
        
        &:hover {
            background: ${colors.eventLight};
            background-image: ${backgrounds.cardGradient};
        }
    `,
}