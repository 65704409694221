import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors, fonts, timings } from '../../style/vars'
import getPhysicalAssessmentColor, { getPitchEffectColor } from '../../utils/getPhysicalAssessmentColor'
import BodyPart from '../../context/BodyPart'
import SurfaceLayer from '../../context/SurfaceLayer'
import { useContext } from 'react'

const style = {
    effectsListItem: (isHighlighted) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: ${fonts.base};
        line-height: 1.5;
        font-size: 0.875em;
        padding: 0.6em 0.75em;
        background-clip: padding-box;
        border: 0.2em solid transparent;
        border-radius: 0.125em;
        background-color: ${rgba(colors.subtle, isHighlighted ? 0.4 : 0.2)};
        transform: translateX(${isHighlighted ? '-1em' : '0em'});
        transition: transform, background-color, ${timings.responsive} ease;
    `,
    assessmentOrb: (color) => css`
        margin-left: 1em;
        height: 1em;
        width: 1em;
        min-height: 1em;
        min-width: 1em;
        border-radius: 0.5em;
        background-color: ${color};
        box-shadow: 0 0 0.75em ${color};
    `
}

export default function EffectsList({ effects = [], type, ...props }) {

    const [partFocus, setPartFocus] = useContext(type === 'pitch' ? SurfaceLayer : BodyPart)

    return (
        <div {...props}>
            {(effects && effects.length > 0) &&
                effects.toSorted((a,b) => {
                    return type === 'pitch' ? 
                    (a.pitchEffect?.pitchPart ?? '').localeCompare(b.pitchEffect?.pitchPart ?? '')
                    :
                    (a.healthEffect?.bodyPart ?? '').localeCompare(b.healthEffect?.bodyPart ?? '')
                }).map((effect) => {

                    let id, label, part

                    if (type === 'pitch') {
                        id = effect.id
                        label = effect.pitchEffect?.label
                        part = effect.pitchEffect?.pitchPart
                    } else {
                        id = effect.id
                        label = effect.healthEffect?.label
                        part = effect.healthEffect?.bodyPart
                    }

                    const color = type === 'pitch' ?
                        getPitchEffectColor(effect)
                        : getPhysicalAssessmentColor(effect)

                    return (
                        <div
                            key={id}
                            css={style.effectsListItem(partFocus === part)}
                            onClick={(e) => {
                                e.preventDefault()
                                setPartFocus(current => current === part ? '' : part)
                            }}
                            onMouseEnter={() => {
                                setPartFocus(part)
                            }}
                            onMouseLeave={() => {
                                setPartFocus('')
                            }}
                        >
                            <span>
                                {label}
                            </span>
                            <div css={style.assessmentOrb(color)} />
                        </div>
                    )
                })
            }
        </div>
    )
}