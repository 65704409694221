import React, { createContext, useState, useEffect } from 'react'

const ViewPortContext = createContext()
export default ViewPortContext

export function ViewPortProvider(props) {
    // hides certain elements based on viewport 
    // use media queries where possible, but when a JS solution is needed, use this context
    const [showCompactUI, setShowCompactUI] = useState(window.innerWidth <= 600)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 600) {
                setShowCompactUI(false)
            } else {
                setShowCompactUI(true)
            }
        }

        // Set the initial UI mode correctly before any resize event occurs
        handleResize()

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    return (
        <ViewPortContext.Provider
            value={{ showCompactUI }}
            {...props}
        />
    )
}
