import React, { useState, useEffect, useRef } from 'react'
import useMightyMouse from 'react-hook-mighty-mouse'
import { pitchBgSrc } from '../_general/pitch/pitchBg';


function FieldSelector({
    backgroundType,
    x = null,
    y = null,
    onChange,
}) {
    const { position } = useMightyMouse();
    const ref = useRef(null);

    const [selectedXpos, setSelectedXpos] = useState(((x * 100) * (240 / 100)))
    const [selectedYpos, setSelectedYpos] = useState(((y * 100) * (160 / 100)))

    function selectPosition() {
        const fieldElement = document.getElementById('veld');
        const fieldRect = fieldElement.getBoundingClientRect();
        const fieldX = fieldRect.left;
        const fieldY = fieldRect.top;

        let xPos = position.client.x - fieldX
        xPos = xPos > fieldElement.clientWidth ? fieldElement.clientWidth : xPos
        xPos = xPos < 0 ? 0 : xPos
        setSelectedXpos(xPos)

        let yPos = position.client.y - fieldY
        yPos = yPos > fieldElement.clientHeight ? fieldElement.clientHeight : yPos
        yPos = yPos < 0 ? 0 : yPos
        setSelectedYpos(yPos)
        onChange((xPos / (fieldElement.clientWidth / 100)) / 100, (yPos / (fieldElement.clientHeight / 100)) / 100)
    }

    return (
        <div ref={ref} id="veld" style={{ backgroundImage: `url(${pitchBgSrc(backgroundType)})`, backgroundColor: "#363E45", backgroundSize: "contain", backgroundRepeat: "no-repeat", width: "240px", height: "160px", position: "relative", overflow: "hidden", display: "inline-flex" }} onClick={() => selectPosition()}>
            {selectedXpos ? <span style={{ backgroundColor: "#728695", height: "100%", width: "1px", position: "absolute", left: selectedXpos + 1, top: 0 }}></span> : null}
            {selectedYpos ? <span style={{ backgroundColor: "#728695", height: "1px", width: "100%", position: "absolute", left: 0, top: selectedYpos + 1 }}></span> : null}
            {selectedXpos && selectedYpos ? <span style={{ backgroundColor: "red", height: "3px", width: "3px", position: "absolute", left: selectedXpos, top: selectedYpos }}></span> : null}
        </div >
    )
}

export default FieldSelector
